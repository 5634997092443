import { Pipe, PipeTransform } from '@angular/core';
import { CustomerGroupViewModel } from '../../../core/view-models/customer-group-view-model';
import { cloneDeep, forEach, includes } from 'lodash-es';

@Pipe({
  name: 'groupType'
})
export class GroupTypePipe implements PipeTransform {

  transform(value: CustomerGroupViewModel[], types?: string[]): CustomerGroupViewModel[] {
    // initial dataset to be filtered
    const clonedDataSet = cloneDeep(value);

    return this.filterByGroupType(clonedDataSet, types);
  }

  filterByGroupType(filterDataset: CustomerGroupViewModel[], selectedTypes: string[]): CustomerGroupViewModel[] {
    let filteredResultSet: CustomerGroupViewModel[] = [];

    if (selectedTypes && selectedTypes.length > 0) {
      forEach(filterDataset, (el) => {
        forEach(selectedTypes, (value) => {
          const isChecked = value === el.type;
          // if filtered value present and not already pushed/exiting in array
          if (isChecked && !includes(filteredResultSet, el)) {
            filteredResultSet.push(el);
          }
        });
      });
    } else {
      filteredResultSet = filterDataset;
    }
    return filteredResultSet;
  }
}
