import { Component, ContentChild, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { curveLinear } from 'd3-shape';
import { scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import { sortBy } from 'lodash-es';
import { BaseChartComponent, calculateViewDimensions, ColorHelper, id, LegendPosition, Orientation, ScaleType, ViewDimensions } from '@swimlane/tpf-ngx-charts';

@Component({
  selector: 'hl-diagram-line-double-axis',
  templateUrl: './diagram-line-double-axis.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DiagramLineDoubleAxisComponent extends BaseChartComponent implements OnInit {
  @Input() animations: boolean;
  @Input() legend: boolean;
  @Input() legendTitle;
  @Input() legendPosition = LegendPosition.Right;
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel: boolean;
  @Input() yMainAxisShowLabel: boolean;
  @Input() ySecondaryAxisShowLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yMainAxisLabel: string;
  @Input() ySecondaryAxisLabel: string;
  @Input() autoScale = true;
  @Input() timeline = true;
  @Input() gradient: boolean;
  @Input() showXGridLines = true;
  @Input() showYMainGridLines = true;
  @Input() showYSecondaryGridLines = false;
  @Input() curve: any = curveLinear;
  @Input() activeEntries: any[] = [];
  @Input() rangeFillOpacity: number;
  @Input() xAxisTickFormatting: any;
  @Input() yMainAxisTickFormatting: any;
  @Input() ySecondaryAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yMainAxisTicks: any[];
  @Input() ySecondaryAxisTicks: any[];
  @Input() roundDomains = false;
  @Input() tooltipDisabled = false;
  @Input() showRefLines = false;
  @Input() referenceLines: any;
  @Input() showRefLabels = true;
  @Input() xScaleMin: any;
  @Input() xScaleMax: any;
  @Input() yMainScaleMin: number;
  @Input() yMainScaleMax: number;
  @Input() ySecondaryScaleMin: number;
  @Input() ySecondaryScaleMax: number;
  @Input() yDefaultAxis = 'left';
  @Input('results') chartsData: any; // parent class clear custom properties "secondAxis"
  @Input() yMainAxisScaleFactor: any;
  @Input() ySecondaryAxisScaleFactor: any;
  @Input() orderDates = false;
  @Input() showValueLabels = true;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();
  /**
   * Emitted when any line or line dot is clicked. The data contains the name of clicked series.
   */
  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate') seriesTooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  yMainScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin: any[] = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions: any;
  scaleType: ScaleType = ScaleType.Linear;
  xScaleLine;
  ySecondScale;
  yDomainLine;
  yDomainLine1;
  seriesDomain;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft: Orientation = Orientation.Left;
  yOrientRight: Orientation = Orientation.Right;
  bandwidth;
  lineChart;
  lineChartSecondary;
  clipPathId;
  clipPath;
  hasRange: boolean;

  timelineWidth;
  timelineXDomain;
  timelineXScale;
  timelineYScale;
  timelineTransform;
  timelineHeight = 50;
  timelinePadding = 10;
  dataLabelTopMargin = 15;

  isSSR = isPlatformServer(this.platformId);
  labels = [];

  ngOnInit() {
    super.ngOnInit();
  }

  dataSplit() {
    [this.lineChart, this.lineChartSecondary] = this.chartsData.reduce((acc, item) => {
      if (item.secondAxis) {
        acc[1].push(item);
      } else {
        acc[0].push(item);
      }

      return acc;
    }, [[], []]);
  }

  trackBy(index, item): string {
    return item.name;
  }

  update(): void {
    this.dataSplit();
    super.update();
    this.height = this.height - 20;

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.yMainAxisShowLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.yAxis && this.lineChartSecondary.length) {
      this.dims.width -= 65;
    }

    if (this.timeline) {
      this.dims.height -= (this.timelineHeight + this.margin[2] + this.timelinePadding);
    }

    // line chart
    this.xDomain = this.getXDomainLine();

    if (this.filteredDomain) {
      this.xDomain = this.filteredDomain;
    }

    this.xScaleLine = this.getXScale(this.xDomain, this.dims.width);
    this.seriesDomain = this.getSeriesDomain();
    this.yDomainLine = this.getYDomainLine(this.lineChart);
    this.yMainScale = this.getYScaleLine(this.yDomainLine, this.dims.height);

    if (this.lineChart) {
      this.showYSecondaryGridLines = this.lineChart.length === 0;
    }

    if (this.lineChartSecondary.length) {
      this.yDomainLine1 = this.getYDomainLine(this.lineChartSecondary);
      this.ySecondScale = this.getYScaleLine(this.yDomainLine1, this.dims.height);
    }

    this.updateTimeline();
    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelTopMargin})`;
    this.clipPathId = 'clip' + id().toString();
    this.clipPath = `url(#${this.clipPathId})`;

    if(this.showValueLabels) {
      this.calculateLabels();
    }
  }

  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({value: entry, entries: []});
    }
    this.activeEntries = [];
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomain = this.filteredDomain;
    this.xScaleLine = this.getXScale(this.xDomain, this.dims.width);
  }

  getSeriesDomain(): any[] {
    return this.chartsData.map(d => d.name);
  }

  isDate(value): boolean {
    return value instanceof Date;
  }

  getScaleType(values): ScaleType {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) {
      return ScaleType.Time;
    }
    if (num) {
      return ScaleType.Linear;
    }

    return ScaleType.Ordinal;
  }

  getXDomainLine(): any[] {
    let values = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    if (this.lineChartSecondary.length) {
      for (const results of this.lineChartSecondary) {
        for (const d of results.series) {
          if (!values.includes(d.name)) {
            values.push(d.name);
          }
        }
      }
    }

    if (this.orderDates) {
      values = sortBy(values);
    }

    this.scaleType = this.getScaleType(values);
    let domain;

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;

    return domain;
  }

  getYDomainLine(data): any[] {
    const domain = [];

    for (const results of data) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          this.hasRange = true;

          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          this.hasRange = true;

          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    if (!this.autoScale) {
      domain.push(0);
    }

    const min = this.yMainScaleMin ? this.yMainScaleMin : Math.min(...domain);
    const max = this.yMainScaleMax ? this.yMainScaleMax : Math.max(...domain);

    if (this.ySecondaryAxisScaleFactor) {
      const minMax = this.ySecondaryAxisScaleFactor(min, max);

      return [minMax.min, minMax.max];
    } else {
      if (min === max) {
        return [Math.max(min - 1, 0), max + 1];
      } else if (min === Infinity && max === -Infinity) {
        return [0, 1];
      } else {
        return [min, max];
      }
    }
  }

  getXScale(domain, width): any {
    let scale;

    if (this.scaleType === 'time') {
      scale = scaleTime().range([0, width]).domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear().range([0, width]).domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint().range([0, width]).padding(0.1).domain(domain);
    }

    return scale;
  }

  getYScaleLine(domain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  getYScale(yDomain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(yDomain);

    return this.roundDomains ? scale.nice() : scale;
  }

  onClick(data) {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.seriesDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    this.colorsLine = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.yDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateLineWidth(width): void {
    this.bandwidth = width;
  }

  updateYAxisWidth({width}): void {
    this.yAxisWidth = width + 20;
    this.update();
  }

  updateXAxisHeight({height}): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({value: item, entries: this.activeEntries});
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({value: item, entries: this.activeEntries});
  }

  updateTimeline(): void {
    if (this.timeline) {
      this.timelineWidth = this.dims.width;
      this.timelineXDomain = this.getXDomainLine();
      this.timelineXScale = this.getXScale(this.timelineXDomain, this.timelineWidth);
      const timeLineDomain = this.getYDomainLine(this.chartsData);
      this.timelineYScale = this.getYScale(timeLineDomain, this.timelineHeight);
      this.timelineTransform = `translate(${this.dims.xOffset}, ${-this.margin[2]})`;
    }
  }

  onSelected($event) {
    this.onSelect.emit({series: $event.name});
  }

  calculateLabels() {
    this.labels = [];
    for (const chartData of this.chartsData) {
      for (const series of chartData.series) {
        if (typeof series.value === 'number') {
          const x = this.calculateLabelXPosition(series.name);
          const y = this.calculateLabelYPosition(series.value,
            chartData.secondAxis ? this.yDomainLine1 : this.yDomainLine);
          const labelWithSimilarPosition = this.getLabelWithSimilarPosition(x, y);
          if (labelWithSimilarPosition?.length > 0) {
            labelWithSimilarPosition[0].value += `; ${series.value}`;
          } else {
            this.labels.push({x: x, y: y, value: series.value});
          }
        }
      }
    }
  }

  getLabelWithSimilarPosition(x: number, y: number) {
    return this.labels.filter(item => item.x === x && Math.abs(item.y - y) <= 10);
  }

  calculateLabelXPosition(name: string) {
    let x = this.dims.xOffset + this.dims.width * this.xSet.indexOf(name) / (this.xSet.length - 1);
    if (this.xSet.length == 2) {
      x += this.xSet.indexOf(name) == 0 ? this.dims.xOffset : -this.dims.xOffset;
    }
    if (this.xSet.length == 1) {
      x = this.dims.xOffset + this.dims.width / 2;
    }
    return x;
  }

  calculateLabelYPosition(value: number, domainLine: any) {
    return (this.dims.height * (domainLine[1] - value) / (domainLine[1] - domainLine[0]));
  }
}
