import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ColorHelper, LegendOptions, ScaleType } from '@swimlane/tpf-ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';
import { GuardianDetailSeries } from '../diagram-guardian-common/guardian-detail-series';
import { GuardianBaseChartComponent } from '../diagram-guardian-common/guardian-base-chart.component';
import { ExtendedDataItem } from '../common/common-models';
import { from, groupBy, toArray } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'hl-diagram-guardian-overview',
  templateUrl: './diagram-guardian-overview.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DiagramGuardianOverviewComponent extends GuardianBaseChartComponent implements OnInit {
  @Input('results') chartsData: GuardianDetailSeries[];
  xSet: any;
  xDomain: any;
  yDomain: [number, number];
  seriesDomain: any;

  circles: ExtendedDataItem[] = [];
  groupBySeriesName: Map<string, ExtendedDataItem[]> = new Map<string, ExtendedDataItem[]>();
  whiskers: ExtendedDataItem[] = [];

  updateDomainValues(): void {
    const {xDomainValues, yDomainValues, seriesDomainValues} = this.getDomainValues();
    this.xDomain = xDomainValues;
    this.xSet = xDomainValues;
    this.yDomain = this.getYDomain(yDomainValues);
    this.seriesDomain = seriesDomainValues;
  }

  getDomainValues(): { xDomainValues: any[], yDomainValues: number[], seriesDomainValues: string[] } {
    const xDomainValues = new Set();
    const yDomainValues = new Set<number>();
    const seriesDomainValues = new Set<string>();

    for (const result of this.chartsData) {
      xDomainValues.add(result.name);
      for (const value of result.values) {
        seriesDomainValues.add(value.name);
        for (const s of value.series) {
          yDomainValues.add(s.value);
        }
        for (const w of value.whiskers) {
          yDomainValues.add(w.value);
        }
      }
    }

    return {
      xDomainValues: Array.from(xDomainValues),
      yDomainValues: Array.from(yDomainValues),
      seriesDomainValues: Array.from(seriesDomainValues)
    };
  }

  getYDomain(domainValues: number[]): [number, number] {
    const values = [...domainValues];
    if (!this.autoScale) {
      values.push(0);
    }

    const min = this.yScaleMin || Math.min(...values);

    const max = this.yScaleMax || Math.max(...values);

    return [min, max];
  }

  getXScale() {
    return scaleBand().range([0, this.dims.width]).padding(0.1).domain(this.xDomain.map(d => d.toString()));
  }

  getYScale() {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);

    return this.roundDomains ? scale.nice() : scale;
  }

  getColors() {
    let domain;
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.seriesDomain;
    } else {
      domain = this.yDomain;
    }

    return new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions(): LegendOptions {
    const opts = {
      scaleType: this.schemeType as any,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.yDomain;
      opts.colors = this.colors.scale;
    }

    opts.domain = [...opts.domain];
    opts.domain.unshift(opts.domain.pop());

    return opts;
  }

  onActivate(item, fromLegend = false): void {
    super.onActivate(this.circles.concat(this.whiskers), item, fromLegend);
  }

  processChartData() {
    const circleData = [];
    const whiskerData = [];

    this.chartsData.forEach(item =>
      item.values.forEach(value => {
        value.whiskers.forEach(whisker =>
          whiskerData.push({...whisker, valueName: whisker.name, seriesName: item.name}));
        value.series.forEach(series =>
          circleData.push({
            ...series,
            name: value.name,
            label: series.name,
            valueName: value.name,
            seriesName: item.name
          }));
      }));

    this.circles = circleData;
    from(this.circles).pipe(
      groupBy(c => c.seriesName),
      mergeMap(group => group.pipe(toArray()))
    ).subscribe( groupResult => {
      this.groupBySeriesName.set(groupResult[0].seriesName, groupResult);
    }
   );
    this.whiskers = whiskerData;
  }
}
