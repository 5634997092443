<ngx-charts-chart [view]="[width + legendSpacing, height]" [showLegend]="legend" [legendOptions]="legendOptions" [activeEntries]="activeEntries"
  [animations]="animations" (legendLabelClick)="onSelected($event)" (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)" (select)="onSelected($event)">

  <svg:g [attr.transform]="transform" class="bar-chart chart">
    @if (xAxis) {
      <svg:g ngx-charts-x-axis [xScale]="xScale" [dims]="dims" [showLabel]="showXAxisLabel" [labelText]="xAxisLabel"
        [tickFormatting]="xAxisTickFormatting" (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
    }
    @if (yAxis) {
      <svg:g ngx-charts-y-axis [yScale]="yScale" [dims]="dims" [yOrient]="yOrientLeft" [showGridLines]="showGridLines" [showLabel]="showYAxisLabel"
        [labelText]="yAxisLabel" [tickFormatting]="yAxisTickFormatting" (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    }
    @if (yAxis && showRightYAxis) {
      <svg:g ngx-charts-y-axis [yScale]="yScaleLine" [dims]="dims" [yOrient]="yOrientRight" [showGridLines]="showGridLines"
        [showLabel]="showRightYAxisLabel" [labelText]="yAxisLabelRight" [tickFormatting]="yRightAxisTickFormatting"
        (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    }
    <svg:g ngx-combo-charts-series-vertical [xScale]="xScale" [yScale]="yScale" [colors]="colors" [series]="results" [seriesLine]="lineChart"
      [dims]="dims" [gradient]="gradient" tooltipDisabled="true" [activeEntries]="activeEntries" [animations]="animations"
      (activate)="onActivate($event)" (deactivate)="onDeactivate($event)" (bandwidth)="updateLineWidth($event)" (select)="onSelected($event)"></svg:g>
  </svg:g>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g>
      @for (series of lineChart; track series.name; let i = $index) {
        <svg:g>
          @if (series.clip) {
            <svg:defs>
              <svg:clipPath [attr.id]="clipPathId + i">
                <svg:rect [attr.width]="(dims.width - bandwidth) * series.clip / 100" [attr.height]="dims.height"
                  [attr.transform]="'translate(' + bandwidth / 2 + ')'"/>
              </svg:clipPath>
            </svg:defs>
          }
          <svg:g ngx-charts-line-series [attr.clip-path]="series.clip ? 'url(#' + this.clipPathId + i + ')' : null" [xScale]="xScaleLine"
            [yScale]="yScaleLine" [colors]="colorsLine" [data]="series" [activeEntries]="activeEntries" [scaleType]="scaleType" [curve]="curve"
            [rangeFillOpacity]="rangeFillOpacity" [animations]="animations"/>
        </svg:g>
      }

      @if (!tooltipDisabled && xSet && xSet.length > 0) {
        <svg:g ngx-charts-tooltip-area [dims]="dims" [xSet]="xSet" [xScale]="xScaleLine" [yScale]="yScaleLine" [results]="combinedSeries"
          [colors]="colorsAll" [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate" (hover)="updateHoveredVertical($event)"/>
      }

      @for (series of lineChart; track series) {
        <svg:g>
          <svg:g ngx-charts-circle-series [xScale]="xScaleLine" [yScale]="yScaleLine" [colors]="colorsLine" [data]="series" [scaleType]="scaleType"
            [visibleValue]="hoveredVertical" [activeEntries]="activeEntries" [tooltipDisabled]="tooltipDisabled" (select)="onSelected($event)"
            (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"/>
        </svg:g>
      }
    </svg:g>
  </svg:g>
</ngx-charts-chart>
