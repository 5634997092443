import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AvailabilityOptions } from '../../models/optionsUpgrades/availability-options';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';

export const CENTRICARE_EQUIPMENT_STATUS_CRITICAL = '1A';
export const EQUIPMENT_NO_CONTRACT_AVAILABLE_KEY = 'EQUIPMENT_NO_CONTRACT_AVAILABLE';
export const GENERIC_LABEL_NOT_AVAILABLE_KEY = 'GENERIC_LABEL_NOT_AVAILABLE';

@Injectable({providedIn: 'root'})
export class EquipmentConstantsService {
  constructor(private translateService: TranslateService) {
  }

  getOperationalStatesSkeleton() {
    return [
      {
        title: 'EQUIPMENT_STATUS_EQUIPMENT_OK',
        value: 'green',
        color: 'green'
      },
      {
        title: 'EQUIPMENT_STATUS_EQUIPMENTS_WITH_PARTIAL_BREAKDOWN',
        value: 'yellow',
        color: 'yellow'
      },
      {
        title: 'EQUIPMENT_STATUS_EQUIPMENT_WITH_BREAKDOWN',
        value: 'red',
        color: 'red'
      }
    ];
  }

  getSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'productName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
          value: 'myEquipmentName'
        },
        {
          title: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
          value: 'siemensId'
        },
        {
          title: 'GENERIC_LABEL_PRODUCT_NAME',
          value: 'productName'
        },
        {
          title: 'GENERIC_LABEL_DEPARTMENT',
          value: 'department'
        },
        {
          title: 'GENERIC_LABEL_OPERATIONAL_STATE',
          value: 'statusOrder'
        },
        {
          title: 'GENERIC_LABEL_CUSTOMER_NAME',
          value: 'customerName'
        }
      ]
    };
  }

  // tslint:disable-next-line:no-identical-functions
  getComponentListSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'componentName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'COMPONENT_NAME',
          value: 'componentName'
        },
        {
          title: 'COMPONENT_ID',
          value: 'componentID'
        },
        {
          title: 'COMPONENT_EQUIPMENT_ID',
          value: 'equipmentKey'
        },
        {
          title: 'COMPONENT_OWN_ID',
          value: 'ownId'
        },
        {
          title: 'GENERIC_LABEL_SERIAL_NUMBER',
          value: 'componentSerialNumber'
        },
        {
          title: 'GENERIC_LABEL_INSTALLATION_DATE',
          value: 'componentInstallationDate'
        }
      ]
    };
  }

  // options and upgrades stuff

  getSortSkeletonOptionsList(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'name',
        sortDir: SortDirection.DESC
      },
      items: [
        {
          title: 'OPTIONS_MODULE_SORTING_TITLE',
          value: 'name'
        },
        {
          title: 'OPTIONS_MODULE_SORTING_AVAILABILITY',
          value: 'optionStatus'
        }
      ]
    };
  }

  // tslint:disable-next-line:no-identical-functions
  getSortSkeletonSimpleListDocument(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'creationDateTime',
        sortDir: SortDirection.DESC
      },
      items: [
        {
          title: 'GENERIC_LABEL_FILENAME',
          value: 'name'
        },
        {
          title: 'GENERIC_LABEL_CREATION_DATE',
          value: 'creationDateTime'
        }
      ]
    };
  }

  // tslint:disable-next-line:no-identical-functions
  getSortSkeletonSimpleListDocLib(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'lastModifiedDateTime',
        sortDir: SortDirection.DESC
      },
      items: [
        {
          title: 'GENERIC_LABEL_FILENAME',
          value: 'name'
        },
        {
          title: 'GENERIC_LABEL_LAST_MODIFICATION_DATE',
          value: 'lastModifiedDateTime'
        }
      ]
    };
  }

  getSortSkeletonCollapsibleListDocument(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'name',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'GENERIC_LABEL_FOLDER_NAME',
          value: 'name'
        },
        {
          title: 'GENERIC_LABEL_FILENAME',
          value: 'list..name'
        },
        {
          title: 'GENERIC_LABEL_LAST_MODIFICATION_DATE',
          value: 'list..lastModifiedDateTime'
        }
      ]
    };
  }

  getSortSkeletonCollapsibleListSkbDocument(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'default',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'GENERIC_LABEL_RELEVANCE',
          value: 'default'
        },
        {
          title: 'GENERIC_LABEL_ARTICLE_NAME',
          value: 'name'
        }
      ]
    };
  }

  // tslint:disable-next-line:no-identical-functions
  getSortSkeletonForNewSystemUpdateEquipments(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'productName',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
          value: 'siemensId'
        },
        {
          title: 'GENERIC_LABEL_PRODUCT_NAME',
          value: 'productName'
        }
      ]
    };
  }

  // options availability configuration
  // also used for translation and coloring
  getOptionsAvailabilitySkeleton(): AvailabilityOptions[] {
    return [
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant(
          'OPTION_AVAILABILITY_LEGEND_ALREADYINSTALLED'
        ),
        value: 'AlreadyInstalled',
        color: 'bold'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant(
          'OPTION_AVAILABILITY_LEGEND_WITHOUTPREREQUISITE'
        ),
        value: 'WithoutPrerequisites',
        color: 'green'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant(
          'OPTION_AVAILABILITY_LEGEND_WITHPREREQUISITE'
        ),
        value: 'WithPrerequisites',
        color: 'yellow'
      }
    ];
  }

  // options availability configuration
  getClinicalFieldSkeleton() {
    return [
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_CARDIOVASCULAR'),
        value: '1'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_NEUROLOGY'),
        value: '2'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_ONCOLOGY'),
        value: '3'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_ORTHOPEDICS'),
        value: '4'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_WOMENSHEALTH'),
        value: '5'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_PEDIATRICS'),
        value: '6'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_ANGIOGRAPHY'),
        value: '7'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_FLUOROSCOPY'),
        value: '8'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_RADIOGRAPHY'),
        value: '9'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_CARDIOLOGY'),
        value: '10'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_DUALENERGY'),
        value: '11'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_LOWDOSE'),
        value: '12'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_GENERAL'),
        value: '13'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_SURGERY'),
        value: '14'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('CLINICAL_FIELD_CYBERSECURITY'),
        value: '17'
      }
    ];
  }

  getEquipmentInformationLabelTranslationMap() {
    return {
      myEquipmentName: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
      sapAssetNumber: 'LABEL_ASSET_NUMBER',
      department: 'GENERIC_LABEL_DEPARTMENT',
      street: 'GENERIC_LABEL_STREET',
      city: 'GENERIC_LABEL_CITY',
      zip: 'GENERIC_LABEL_POSTAL_CODE',
      contractType: 'EQUIPMENT_CONTRACT_TYPE',
      softwareVersion: 'SOFTWARE_VERSION',
      serialNumber: 'GENERIC_LABEL_SERIAL_NUMBER',
      materialNumberConsolidated: 'LABEL_MATERIAL_NUMBER',
      installedOnDate: 'GENERIC_LABEL_INSTALLATION_DATE',
      warranty: 'EQUIPMENT_WARRANTY',
      modality: 'LABEL_MODALITY',
      eosDate: 'GENERIC_LABEL_END_OF_SERVICE',
      srsStatus: 'LABEL_SRS_STATUS',
      srsStatusConnected: 'SRS_STATUS_CONNECTED',
      srsStatusDisconnected: 'SRS_STATUS_DISCONNECTED',
      srsStatusUnknown: 'SRS_STATUS_UNKNOWN',
      srsCheckDateTime: 'LABEL_LAST_CHECK_DATE',
      flStartupDate: 'LABEL_STARTUP_DATE',
      customerName: 'GENERIC_LABEL_CUSTOMER_NAME',
      operatingSystemStatus: 'LABEL_OPERATING_SYSTEM_STATUS'
    };
  }
}
