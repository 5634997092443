@if (label && isCounterAbove) {
  <div class="hidden-md-down">
    <hl-list-counter [filteredLength]="items.length" [filteredResultLengthWithoutPagination]="filteredLengthWithoutPagination" [title]="label"
      dataCy={{dataCyInput}}></hl-list-counter>
  </div>
}
@if (isEquipmentList) {
  <div>
    <div class="overview-list" [ngClass]="additionalCollectionClass">
      @if (items && ((label && !isCounterAbove) || sortConfig?.items)) {
        <div class="overview-list__toolbar">
          <div class="collection border-none width-100 hidden-md-down">
            <div class="container-fluid">
              <div class="row padding-0">
                <div class="col-12 col-sm-auto order-sm-1 padding-0">
                  <div class="row padding-0 margin-right-tiny-1 float-right">
                    @if (dropdownDirective) {
                      <div>
                        <ng-container [ngTemplateOutlet]="dropdownDirective.templateRef"></ng-container>
                      </div>
                    }
                  </div>
                </div>
                @if (label) {
                  <div class="col-6 col-sm order-sm-0 padding-0 margin-top-tiny-1">
                    <div class="float-left">
                      <hl-list-counter [filteredLength]="items.length" [filteredResultLengthWithoutPagination]="filteredLengthWithoutPagination"
                        [title]="label" dataCy="{{dataCyInput}}"></hl-list-counter>
                    </div>
                    <div class="float-left padding-left-tiny-2">
                      @if (buttonWithSpinner?.showButton) {
                        <hl-button-with-spinner [buttonClasses]="buttonWithSpinner?.buttonClasses" [showSpinner]="buttonWithSpinner?.showSpinner"
                          [disabled]="buttonWithSpinner?.disableButton" (buttonClicked)="buttonWithSpinnerClickEvent.emit()"
                          [label]="buttonWithSpinner?.label" [withBottomMargin]="buttonWithSpinner?.withBottomMargin"></hl-button-with-spinner>
                      }
                    </div>
                  </div>
                }
                @if (sortConfig?.items) {
                  <div class="col-6 col-sm-auto order-sm-2 padding-0 margin-top-tiny-1">
                    <div class="float-right margin-left-tiny-5">
                      <hl-list-sorter dataCy="{{dataCyInput}}" [options]="sortConfig" (onOptionChange)="emitSortChanges($event)"
                        name="sort"></hl-list-sorter>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="collection border-none width-100 hidden-lg-up">
            <div class="container-fluid">
              <div class="row padding-0">
                @if (label) {
                  <div class="col-4 col-sm order-sm-0 padding-0 margin-top-tiny-1">
                    <hl-list-counter [filteredLength]="items.length" [filteredResultLengthWithoutPagination]="filteredLengthWithoutPagination"
                      [title]="label" dataCy="{{dataCyInput}}"></hl-list-counter>
                  </div>
                }
                <div class="col-4 col-sm-auto order-sm-1 padding-0">
                  <div class="row padding-0 margin-right-tiny-1 float-right">
                    @if (dropdownDirective) {
                      <div>
                        <ng-container [ngTemplateOutlet]="dropdownDirective.templateRef"></ng-container>
                      </div>
                    }
                  </div>
                </div>
                @if (sortConfig?.items) {
                  <div class="col-4 col-sm-auto order-sm-2 padding-0">
                    <div class="float-right margin-left-tiny-5 margin-top-tiny-1">
                      <hl-list-sorter dataCy="{{dataCyInput}}" [options]="sortConfig" (onOptionChange)="emitSortChanges($event)"
                        name="sort"></hl-list-sorter>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
      @if (isLoaded && items && !items.length) {
        <div class="alert alert__margin col-md-10">
          <div class="alert__body">
            <p class="alert__text" [translate]="empty"></p>
          </div>
        </div>
      }
      @if (isLoaded && items?.length) {
        <div class="overview-list__items" [class.collection]="withBorder" attr.data-cy="{{dataCyInput}}-list">
          @for (item of items; track trackByFunction(i, item); let i = $index) {
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item, index: i}"></ng-container>
          }
        </div>
      }
    </div>
  </div>
} @else {
  <div class="overview-list" [ngClass]="additionalCollectionClass">
    @if (items && ((label && !isCounterAbove) || sortConfig?.items)) {
      <div class="overview-list__toolbar">
        @if (headerTemplate) {
          <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        }
        <div class="collection border-none width-100">
          <div class="container-fluid">
            <div class="row padding-0 ">
              <div class="col-12 col-sm-auto order-sm-1 padding-0">
                <div class="row padding-0 margin-right-tiny-1 float-right">
                  @if (nonEqDirective) {
                    <ng-container [ngTemplateOutlet]="nonEqDirective.templateRef"></ng-container>
                  }
                </div>
              </div>
              @if (label) {
                <div class="col-6 col-sm order-sm-0 padding-0 margin-top-tiny-1">
                  <div class="float-left">
                    <hl-list-counter [filteredLength]="items.length" [filteredResultLengthWithoutPagination]="filteredLengthWithoutPagination"
                      [title]="label" [ngClass]='{"hidden-lg-up":isCounterAbove}' dataCy="{{dataCyInput}}"></hl-list-counter>
                  </div>
                  <div class="float-left padding-left-tiny-2">
                    @if (buttonWithSpinner?.showButton) {
                      <hl-button-with-spinner [buttonClasses]="buttonWithSpinner?.buttonClasses" [showSpinner]="buttonWithSpinner?.showSpinner"
                        [disabled]="buttonWithSpinner?.disableButton" (buttonClicked)="buttonWithSpinnerClickEvent.emit()"
                        [label]="buttonWithSpinner?.label" [withBottomMargin]="buttonWithSpinner?.withBottomMargin"></hl-button-with-spinner>
                    }
                  </div>
                </div>
              }
              @if (sortConfig?.items) {
                <div class="col-6 col-sm-auto order-sm-2 padding-0">
                  <div class="float-right margin-left-tiny-5 margin-bottom-tiny-5">
                    <hl-list-sorter dataCy="{{dataCyInput}}" [options]="sortConfig" (onOptionChange)="emitSortChanges($event)"
                      name="sort"></hl-list-sorter>
                  </div>
                </div>
              }
            </div>
            @if (headerMobileTemplate) {
              <ng-container [ngTemplateOutlet]="headerMobileTemplate"></ng-container>
            }
          </div>
        </div>
      </div>
    }
    <hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
    @if (isLoaded && items && !items.length) {
      <div class="row">
        <div class="col-md-10">
          <div class="alert">
            <div class="alert__body" [translate]="empty"></div>
          </div>
        </div>
      </div>
    }
    @if (isLoaded && items?.length) {
      <div class="overview-list__items" [class.collection]="withBorder" attr.data-cy="{{dataCyInput}}-list">
        @for (item of items; track trackByFunction ? trackByFunction(i, item) : item; let i = $index) {
          <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item, index: i}"></ng-container>
        }
      </div>
    }
  </div>
}

