@if (view$ | async; as view) {
  <ul class="legal-links">
    <li class="legal-links__item">{{ view.copyrightLeftLabel }}<span [translate]="'LABEL_COPYRIGHT_RESERVED'"></span></li>
    <li class="legal-links__item"><a class="legal-links__link" [translate]="'IMPRESSUM'" [href]="'IMPRESSUM_LINK' | translate | safeDom: 'url'"
      target="_blank" rel="noopener noreferrer"></a></li>
    <li class="legal-links__item"><a class="legal-links__link" [translate]="'DATENSCHUTZ'"
      [href]="(isMobileApp ? 'PRIVACY_POLICY_MOBILE_APP_LINK' : 'DATENSCHUTZ_LINK') | translate | safeDom: 'url'" target="_blank"
      rel="noopener noreferrer"></a></li>
    <li class="legal-links__item"><a class="legal-links__link" [translate]="'NUTZUNGSBEDINGUNGEN'"
      [href]="'NUTZUNGSBEDINGUNGEN_LINK' | translate | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
    <li class="legal-links__item"><a class="legal-links__link" [translate]="'EXPORTKONTROLLE'"
      [href]="'EXPORTKONTROLLE_LINK' | translate | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
    @if (view.digitalServiceAct) {
      <li class="legal-links__item"><a class="legal-links__link" [translate]="'LABEL_DIGITAL_SERVICE_ACT'"
        [href]="view.digitalServiceAct | safeDom: 'url'" target="_blank" rel="noopener noreferrer"></a></li>
    }
    <li class="legal-links__item"><a class="legal-links__link" [translate]="'LICENSE_TERMS_LABEL'" [href]="view.licenseTermsUrl | safeDom: 'url'"
      target="_blank" rel="noopener noreferrer"></a></li>
  </ul>
}
