<ngx-charts-chart [view]="[width + adjustChartWidth, height]" [showLegend]="legend" [legendOptions]="legendOptions" [activeEntries]="activeEntries"
  [animations]="animations" (legendLabelActivate)="onActivate($event, undefined, true)"
  (legendLabelDeactivate)="onDeactivate($event, undefined, true)" (legendLabelClick)="onClick($event)">
  <ng-template #tooltipTemplate let-model="model">
    <pre>{{ model.tooltipText }}</pre>
  </ng-template>
  <svg:g [attr.transform]="transform" class="bar-chart chart">
    <svg:g ngx-charts-grid-panel-series [xScale]="groupScale" [yScale]="valuesLeftScale" [data]="results" [dims]="dims" orient="vertical"></svg:g>
    @if (xAxis) {
      <svg:g ngx-charts-x-axis [xScale]="groupScale" [dims]="dims" [showLabel]="showXAxisLabel" [labelText]="xAxisLabel" [trimTicks]="trimXAxisTicks"
        [rotateTicks]="rotateXAxisTicks" [maxTickLength]="maxXAxisTickLength" [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks"
        [xAxisOffset]="dataLabelMaxHeight.negative" (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
    }
    @if (yAxis) {
      <svg:g ngx-charts-y-axis [yScale]="valuesLeftScale" [dims]="dims" [showGridLines]="showGridLines" [showLabel]="true"
        [labelText]=" yLeftAxisLabel" [trimTicks]="trimYAxisTicks" [maxTickLength]="maxYAxisTickLength" [tickFormatting]="yAxisTickFormatting"
        [ticks]="yAxisTicks" (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    }
    @if (yAxis && dataSecondaryAxis.length) {
      <svg:g ngx-charts-y-axis [yScale]="valuesRightScale" [dims]="dims" [yOrient]="'right'" [showGridLines]="false" [showLabel]="true"
        [labelText]="yRightAxisLabel" [trimTicks]="trimYAxisTicks" [maxTickLength]="maxYAxisTickLength" [tickFormatting]="yAxisTickFormatting"
        [ticks]="yAxisTicks" (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    }
    @if (isSSR) {
      <ng-container>
        @for (group of results; track group.name; let index = $index) {
          <svg:g ngx-charts-series-vertical [attr.transform]="groupTransform(group)" [activeEntries]="activeEntries" [xScale]="innerScale"
            [yScale]="valuesLeftScale" [colors]="colors" [series]="group.series" [dims]="dims" [gradient]="gradient"
            [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate" [dataLabelFormatting]="dataLabelFormatting"
            [seriesName]="group.name" [roundEdges]="roundEdges" [animations]="animations" [noBarWhenZero]="noBarWhenZero"
            (select)="onClick($event, group)" (activate)="onActivate($event, group)" (deactivate)="onDeactivate($event, group)"
            (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"/>
        }
      </ng-container>
    } @else {
      @for (group of results; track group.name; let index = $index) {
        <svg:g ngx-charts-series-vertical [@animationState]="'active'" [attr.transform]="groupTransform(group)" [activeEntries]="activeEntries"
          [xScale]="innerScale" [yScale]="valuesLeftScale" [colors]="colors" [series]="group.series" [dims]="dims" [gradient]="gradient"
          [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate" [dataLabelFormatting]="dataLabelFormatting"
          [seriesName]="group.name" [roundEdges]="roundEdges" [animations]="animations" [noBarWhenZero]="noBarWhenZero"
          (select)="onClick($event, group)" (activate)="onActivate($event, group)" (deactivate)="onDeactivate($event, group)"
          (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"/>
      }
    }
  </svg:g>
  <svg:g>
    @for (label of labels; track label) {
      <svg:text [attr.x]="label.x" [attr.y]="label.y + 20" [attr.font-size]="10" [attr.text-anchor]="'middle'">{{ label.value }}</svg:text>
    }
  </svg:g>
</ngx-charts-chart>
