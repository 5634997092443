import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {

  isIOS() {
    const iosQuirkPresent = () => {
      const audio = new Audio();

      audio.volume = 0.5;
      return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
    };

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAppleDevice = navigator.userAgent.includes('Macintosh');
    const isTouchScreen = navigator.maxTouchPoints >= 1;

    return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
  }

  isMobileApp() {
    return navigator.userAgent.includes('LnConnect');
  }
}
