import { forkJoin, Observable, of, Subject } from 'rxjs';
import { UpdateTicketEventService } from '../../component-communication-services/update-ticket-event/update-ticket-event.service';
import { CustomerContacts } from '../../models/customer/customer-contacts';
import { OrderByPipe } from 'app/shared/pipes/order-by/order-by.pipe';
import { CustomerRestService } from '../../rest-services/customer-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentModalityType, NotifStatus, restEndPoint } from '../../core-constants.service';
import { Ticket } from '../../models/tickets/ticket';
import { TicketsRestService } from '../../rest-services/tickets-rest.service';
import { EquipmentRestService } from '../../rest-services/equipment-rest.service';
import { LifeNetUtilService } from '../../utils/life-net-util.service';
import { TicketViewModel } from '../../view-models/ticket-view-model';
import { ModalityTypeTicketTypeRel, TicketTypeProblemSeverityRel } from '../../models/tickets/modality-type-ticket-type-rel';
import { SelectOption } from '../../models/select-option';
import { TicketsCacheService } from '../cache/tickets-cache.service';
import { EquipmentUtilService } from '../equipment/equipment-util.service';
import { TicketTypes } from '../../models/tickets/ticket-types';
import { TimezoneApiModel } from '../../models/tickets/timezone-api-model';
import { Equipment } from '../../models/equipment/equipment';
import { SortDirection } from 'app/shared/sorting/sort-object';
import { StringUtilService } from '../../utils/string-util.service';
import { map, switchMap } from 'rxjs/operators';
import { TicketsDetailViewModel } from '../../view-models/tickets-detail-view-model';
import { Injectable } from '@angular/core';
import { ServiceReport } from '../../models/tickets/ServiceReport';
import { clone, filter, find, flow, forEach, includes, isEmpty, isEqual, map as lodashMap, result, sortBy, uniq, without } from 'lodash-es';
import { DropdownOptions } from '../../models/dropdown-options';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';

export interface TicketAddress {
  locationName: string;
  city: string;
  street: string;
  state: string;
  zipCode: string;
}

export const COMBINED_FIELDS_SPACE_SEPARATOR = ' ';
export const COMBINED_FIELDS_COMMA_SEPARATOR = ', ';

@Injectable({providedIn: 'root'})
export class TicketsUtilService {
  /**
   * This is a translation map for tickets dynamic generation of fields.
   * PLEASE USE A 'DATE' or 'TIME' keyword in case a field is a date / datetime type
   * Example: plannedStartDatetime: 'LABEL_TICKET_PLANNED_START'
   */
  ticketsLabelTranslationMap = {
    productName: 'GENERIC_LABEL_PRODUCT_NAME',
    siemensEquipmentId: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
    myEquipmentName: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
    typeDescription: 'TICKET_TYPE',
    taskDescription: 'PROGRESS',
    plannedStartDatetime: 'LABEL_TICKET_PLANNED_START',
    plannedEndDatetime: 'LABEL_TICKET_PLANNED_END',
    problemSeverityDescription: 'GENERIC_LABEL_OPERATIONAL_STATE',
    originalEffectCodeDescription: 'LABEL_ORIGINAL_EFFECT_CODE',
    ticketCreationTimestamp: 'CREATION_TIME',
    customerIncidentId: 'TICKET_FEEDBACK_OWNID',
    contact: 'TICKET_LABEL_CONTACT', // combined field contactForeName + contactLastName
    dangerForPatient: 'PATIENT_SITUATION',
    createdBy: 'CREATED_BY', // combined field creatorForeName + creatorLastName
    city: 'GENERIC_LABEL_CITY', // combined field city + state
    locationName: 'GENERIC_LABEL_LOCATION',
    street: 'GENERIC_LABEL_STREET',
    zipCode: 'GENERIC_LABEL_POSTAL_CODE',
    customerName: 'GENERIC_LABEL_CUSTOMER_NAME',
    citHours: 'LABEL_CIT_HOURS',
    violationHours: 'LABEL_VIOLATION_HOURS',
    componentId: 'COMPONENT_ID',
    customerComponentId: 'LABEL_CUSTOMER_COMPONENT_ID',
    opcHours: 'LABEL_OPC_HOURS'
  };
  ticketsCombinedFields = {
    contact: {
      fields: ['contactForeName', 'contactLastName'],
      separator: COMBINED_FIELDS_SPACE_SEPARATOR
    },
    createdBy: {
      fields: ['creatorForeName', 'creatorLastName'],
      separator: COMBINED_FIELDS_SPACE_SEPARATOR
    },
    city: {
      fields: ['city', 'state'],
      separator: COMBINED_FIELDS_COMMA_SEPARATOR
    }
  };
  private onShowCreateTicketModal: Subject<any> = new Subject();
  private _selectedOpenTicket: Ticket = null;
  private _selectedClosedTicket: Ticket = null;
  private _selectedTicketAddress: TicketAddress = null;
  onShowCreateTicketModal$ = this.onShowCreateTicketModal.asObservable();

  constructor(private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private equipmentUtilService: EquipmentUtilService,
    private ticketsRestService: TicketsRestService,
    private customerRestService: CustomerRestService,
    private translateService: TranslateService,
    private orderByPipe: OrderByPipe,
    private updateTicketEventService: UpdateTicketEventService,
    private ticketsCacheService: TicketsCacheService,
    private stringUtilService: StringUtilService) {
  }

  get selectedOpenTicket(): Ticket {
    return this._selectedOpenTicket;
  }

  set selectedOpenTicket(selectedOpenTicket: Ticket) {
    this._selectedOpenTicket = selectedOpenTicket;
  }

  get selectedClosedTicket(): Ticket {
    return this._selectedClosedTicket;
  }

  set selectedClosedTicket(selectedClosedTicket: Ticket) {
    this._selectedClosedTicket = selectedClosedTicket;
  }

  get selectedTicketAddress(): TicketAddress {
    return this._selectedTicketAddress;
  }

  set selectedTicketAddress(value: TicketAddress) {
    this._selectedTicketAddress = value;
  }

  /**
   * @description
   * Returns the view model for tickets by merging properties from /equipment to /tickets
   *
   * @param notifStatus ticket status (open / closed)
   * @param refreshData value for refreshing data from sources
   */
  getTicketsViewModelList(notifStatus: NotifStatus, refreshData = false): Observable<TicketViewModel[]> {

    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: ['productName', 'modality', 'modalityTranslation', 'cmdbEquipment', 'customerName', 'serialNumber']
    };

    if (notifStatus === NotifStatus.ALL) {
      return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
        forkJoin([
          this.ticketsRestService.getTickets(NotifStatus.OPEN, refreshData),
          this.ticketsRestService.getTickets(NotifStatus.CLOSED, refreshData)]).pipe(
          map(([openTickets, closedTickets]) => openTickets.concat(closedTickets))),
        findObject);
    }
    return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
      this.ticketsRestService.getTickets(notifStatus, refreshData), findObject);
  }

  /**
   * @description
   * Returns the view model for equipment ticket history
   * @param equipment the equipment for which ticket history is loaded
   * @param dateStart
   * @param dateEnd
   * @param refreshDataFromDB value for refreshing data from DB
   * */
  getTicketsHistoryViewModelList(equipment: Equipment, dateStart: string, dateEnd: string, refreshDataFromDB = false): Observable<TicketViewModel[]> {

    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: ['productName', 'modality', 'modalityTranslation', 'cmdbEquipment', 'customerName']
    };

    return this.lifeNetUtilService.createViewModels(of([equipment]),
      this.ticketsRestService.getTicketHistory(equipment.key, dateStart, dateEnd, refreshDataFromDB), findObject);
  }

  /**
   *
   * @description
   * Set types dynamically on initially filtered tickets (may used in ticket filter for types)
   */
  getTicketTypes(tickets) {
    /**
     * NOTE: possible types kept for initially filtered tickets and are not depending on other current filtering
     * A dynamic update of the type-filter based on current filtering would cause more dependencies as an
     * current type-filter setting may get invalid because of other filters
     */
    const ticketTypes = [];

    if (tickets.length > 0) {

      // first we get unique typeIDs
      const uniqProp = flow(t => lodashMap(t, 'typeID'), uniq, sortBy)(tickets);
      const typeIDs = without(uniqProp, null, undefined, '');

      // now iterate over typeIDs and pick related type as descr. from first item resulting
      // NOTE: assumes that type as descr. is same for each typeID
      forEach(typeIDs, typeID => {
        const ticketByTypeID = find(tickets, {typeID});

        // NOTE: typeDescription may occur as null, we use at least the typeID if no description is available
        const typeDesc = ticketByTypeID['typeDescription'] ? ticketByTypeID['typeDescription'] : typeID;
        ticketTypes.push({title: typeDesc, value: typeID});
      });
    }

    return ticketTypes;

  }

  /**
   * @description
   * Compute the operational state/ problem severity for tickets
   */
  getOperationalStateTickets(): Observable<SelectOption[]> {
    return this.ticketsRestService.getProblemSeverities().pipe(map(problemSeverityResponse => {
      const operationalStateTkt = [];
      forEach(problemSeverityResponse, item => {
        operationalStateTkt.push({
          title: item.problemSeverityDescription,
          value: item.problemSeverityId
        });
      });
      return operationalStateTkt;
    }));
  }

  /**
   * @param filterOptions - enabled operational states, if empty filtering is not required
   * @description - Methods merge all enabled operational states according to their descriptions
   */
  getOperationalStateDescriptions(filterOptions: string[]): Observable<SelectOption[]> {
    return this.getOperationalStateTickets().pipe(map(problemSeverityResponse => {
      const operationalStateDescriptions: SelectOption[] = [];
      let operationalStateTickets: SelectOption[];
      if (isEmpty(filterOptions[0])) {
        operationalStateTickets = problemSeverityResponse;
      } else {
        operationalStateTickets = filter(problemSeverityResponse, item => {
            return includes(filterOptions, item.value);
          }
        );
      }

      operationalStateTickets.forEach(status => {
        const description: SelectOption = operationalStateDescriptions.find(item => item.title === status.title);
        if (description) {
          description.value = description.value + ',' + status.value;
        } else {
          operationalStateDescriptions.push(status);
        }
      });
      return operationalStateDescriptions;
    }));
  }

  /**
   * @description - method maps id to description and returns arrays of them
   * @param selectedValues - selected id's
   * @param operationalStateDescriptions - array of descriptions and their keys
   */
  mapProblemIdToProblemDescription(selectedValues: string[], operationalStateDescriptions: SelectOption[]): string[] {
    if (!operationalStateDescriptions) {
      return [];
    }
    return operationalStateDescriptions
      .filter(state => selectedValues.includes(state.value))
      .map(state => state.title);
  }

  /**
   * @param ticketTypesAndSeverityRelations - all the ticketTypes for the modalityType ("INVITRO", "SYNGO", ...)
   *  of the selected equipment
   *  (the modalityType is defined by the modality code ( 11, 12, ...) e.g. defined in MODALITY_CODE_INVITRO)
   * @param selectedTicketType - string e.g. "MS", "MS6", ...
   * @description
   * Compute the operational state (aka problem severity) based on selected equipment in ticket create screen
   * from US LifeNet story
   */
  getOperationalStatesAsOption(ticketTypesAndSeverityRelations: TicketTypeProblemSeverityRel[],
    selectedTicketType: string): Observable<SelectOption[]> {

    let problemSeverities: string[];

    // input is a list of ticketTypes and each ticketType has its problem severities
    forEach(ticketTypesAndSeverityRelations, (rel) => {
      if (rel.ticketType && isEqual(rel.ticketType.typeId, selectedTicketType)) {
        problemSeverities = rel.problemSeverity;
        return false;
      }
    });

    // get the translated descriptions for the list of problem severities
    return this.ticketsRestService.getProblemSeverities().pipe(map(problemSeverityResponse => {
      const operationalStateTkt = [];
      forEach(problemSeverityResponse, item => {
        if (includes(problemSeverities, item.problemSeverityId)) {
          operationalStateTkt.push({
            title: item.problemSeverityDescription,
            value: item.problemSeverityId
          });
        }

      });
      return operationalStateTkt;
    }));
  }

  getEquipmentModalityType(equipmentModality: string, config: any): EquipmentModalityType {
    if (this.equipmentUtilService.isSyngo(equipmentModality, config['MODALITY_CODE_SYNGO'])) {
      return EquipmentModalityType.SYNGO;
    } else if (this.equipmentUtilService.isInvitro(equipmentModality, config['MODALITY_CODE_INVITRO'])) {
      return EquipmentModalityType.INVITRO;
    } else if (this.equipmentUtilService.isMultiVendor(equipmentModality, config['MODALITY_CODE_MULTIVENDOR'])) {
      return EquipmentModalityType.MULTIVENDOR;
    } else {
      return EquipmentModalityType.DEFAULT;
    }
  }

  /**
   *
   * @param ticketKey
   * @param notifStatus ticket status (open/closed)
   * @description get a single ticket filtered from tickets list by ticket key
   *
   * Note:-
   * This gives a single ticket from /rest/v1/tickets?statusFilter=1
   * which doesn't contain value for service event logs, attachments.
   * For getting attachments, service event logs we need to make another request to BE with
   * customerNumber, equipment key and ticket key as query params.
   */
  getSingleTicketFromList(ticketKey: string, notifStatus: NotifStatus): Observable<Ticket> {
    return this.ticketsRestService.getTickets(notifStatus).pipe(
      map(ticketListResponse => ticketListResponse.filter(tkt => tkt.ticketKey === ticketKey)[0])
    );
  }

  /**
   *
   * @description get an individual ticket details like attachment, service event logs, long text.
   *
   * Note:-
   * Here first we need to get ticket from tickets list and then call BE with
   * ticketKey, customerNumber, equipmentKey as query params.
   *
   * In case the Ticket from the Notif_GETDETAILS has the following fields empty, then we need to copy their respective values
   * from the ticket from Notif_GETLIST.
   */
  getTicketViewModel(ticketKey: string, notifStatus: NotifStatus): Observable<TicketViewModel> {

    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: ['productName', 'contractType']
    };

    return this.getSingleTicketFromList(ticketKey, notifStatus).pipe(
      switchMap(ticketFromList => {
        if (ticketFromList || this._selectedOpenTicket) {
          const ticket = ticketFromList == null ? this._selectedOpenTicket : ticketFromList;
          return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
            this.ticketsRestService.getTicket(ticket.ticketKey, ticket.customerId, ticket.equipmentKey), findObject)
            .pipe(map(ticketDetails => {
              return this.mapperFunction(ticket, ticketDetails);
            }));
        } else {
          // possibly the case, that this method is called with the notifStatus (e.g. closed) and a ticketKey
          //  for an open ticket (when switching the view between open and closed tickets)
          // console.log('unexpected call - no matching ticket found: ticketKey:   ', ticketKey);
          // console.log('unexpected call - no matching ticket found: notifStatus: ', notifStatus);
          // returning undefined - KNOWN ISSUE
          return of(null);
        }
      })
    );

  }

  /**
   *
   * @param closedTicket | closed ticket input passed
   * @description get closed ticket details like attachment, service event logs, long text.
   *
   */
  getClosedTicketViewModel(closedTicket: Ticket): Observable<TicketViewModel> {

    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: ['productName', 'contractType']
    };

    return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
      this.ticketsRestService.getTicket(closedTicket.ticketKey, closedTicket.customerId, closedTicket.equipmentKey), findObject)
      .pipe(map(ticketDetails => {
        return this.mapperFunction(closedTicket, ticketDetails);
      }));
  }

  /**
   *
   * @param equipmentKey
   * @description get all tickets for a particular equipment
   *
   */
  getTicketsByEquipmentKey(equipmentKey: string): Observable<TicketViewModel[]> {
    const notifStatus = this.ticketsCacheService.getSelectedOpenClosedStatus();
    return this.getTicketsViewModelList(notifStatus).pipe(
      map((ticketsResponse: TicketViewModel[]) => {
        // get the filtered result
        return filter(ticketsResponse, (item) => {
          const tktEquipmentKeyWithoutTrailZero = clone(item.equipmentKey).replace(/_0+/, '_');
          return isEqual(equipmentKey, tktEquipmentKeyWithoutTrailZero);
        });
      }));
  }

  /**
   * First we get the equipment object from the equipment key extracted from the current ticket.
   * Then we get the modality Type from this util service's function.
   * Based on the modality type, we extract the available ticket types from DB.
   * @param ticket
   * @param config
   * @returns {Observable}
   */
  getAllowedTicketTypesUpdateByEquipmentKey(ticket: TicketViewModel | ActivitiesViewModel, config): Observable<TicketTypes[]> {
    if (!ticket) {
      return of([]);
    }
    const equipmentKey = ticket.equipmentKey;
    const ticketType = ticket['typeID'] || ticket['type'];
    return forkJoin([
      this.equipmentUtilService.getEquipment(equipmentKey),
      this.ticketsRestService.getTicketTypesAndSeverityOptions('update')]
    ).pipe(map(responses => {
      const equipment = responses[0];
      const ticketTypes = responses[1];

      const allowedTicketTypesUpdate = config.TICKET_UPDATE_ALLOWED_TICKET_TYPES.split(',');
      if (isEmpty(allowedTicketTypesUpdate) || !includes(allowedTicketTypesUpdate, ticketType)) {
        return [];
      }

      const filteredTicketTypes = this.filterTicketTypesByModality(ticketTypes, equipment.modality, config);
      return this.getAllowedTicketTypesUpdate(filteredTicketTypes, allowedTicketTypesUpdate);
    }));
  }

  /**
   *
   * @description
   * Check if service report is allowed to render
   */
  isServiceReportRender(vivoRender, vitroRender, equipmentKey) {

    let isAllowed = false;

    // Check if service report is shown or not
    if (isEqual(vivoRender, 'true') && !this.equipmentUtilService.checkIsLdEquipment(equipmentKey)) {
      isAllowed = true;
    } else if (isEqual(vitroRender, 'true') && this.equipmentUtilService.checkIsLdEquipment(equipmentKey)) {
      isAllowed = true;
    }

    return isAllowed;
  }

  getServiceReportLinkForSpp(ticketKey, fileName, customerId) {
    return restEndPoint + 'reports/' +
      ticketKey +
      '?customerId=' + customerId +
      '&fileName=' + fileName.replace('&', '%26');
  }

  /**
   *
   * @description
   * Compute the additional contacts as key, value pair
   */
  getMappedAdditionalContacts(): Observable<DropdownOptions[]> {
    return this.customerRestService.getCustomerContacts().pipe(
      map((contactsResponse: CustomerContacts[]) =>
        this.orderByPipe.transform(contactsResponse, {
          sortBy: 'lastName',
          sortDir: SortDirection.ASC
        })
      ),
      map(sortedContacts => sortedContacts.map(contact => ({
        title: `${contact.lastName}, ${contact.firstName}`,
        value: JSON.stringify(contact)
      }))));
  }

  /**
   *
   * @param dataset
   * @param findKey
   * @param findValue
   * @param resultKey
   *
   * @description
   * map the property id with the property value for given dataset
   * @returns {*} corresponding mapped value
   */
  getMappedPropertyIdDataset(dataset, findKey, findValue, resultKey) {

    let mappedValue = null;
    const findObject = {};

    // do the mapping for SAP PI
    if (isEmpty(findValue)) {
      findObject[findKey] = findValue;

      // get the single object out of the dataset (i.e. array of objects)
      const findSalutation = find(dataset, findObject);
      if (findSalutation) {
        mappedValue = result(findSalutation, resultKey);
      }
    }

    return mappedValue;
  }

  /**
   * @description Emit an event on successful ticket updated or closed.
   */
  onSuccessfulTicketUpdatedOrClosed() {
    this.updateTicketEventService.emitTicketUpdated();
  }

  /**
   * @param configResponse - what you receive, when you subscribe to the config service
   * @param {string} modality of the selected equipment
   * @param {string} ticketType the ticket type, that the user selected
   * @returns {boolean}
   */
  shouldRenderSystemAvailabilityInTicketCreateByConfig(configResponse: any, modality: string, ticketType: string): boolean {
    return this.shouldRenderSystemAvailabilityInTicketCreate(
      configResponse['SHOW_TICKET_CREATE_SYSTEM_AVAILABILITY'],
      configResponse['SYSTEM_AVAILABILITY_ALLOWED_TYPES'],
      configResponse['MODALITY_CODE_SYNGO'],
      modality, ticketType);
  }

  /**
   * @param {string} showCreateSystemAvailabilityString SHOW_TICKET_CREATE_SYSTEM_AVAILABILITY from config
   * @param {string} systemAvailabilityAllowedTypesString SYSTEM_AVAILABILITY_ALLOWED_TYPES from config
   * @param {string} modalityCodesSyngoString MODALITY_CODE_SYNGO from config
   * @param {string} modality of the selected equipment
   * @param {string} ticketType the ticket type, that the user selected
   * @return {boolean}
   */
  shouldRenderSystemAvailabilityInTicketCreate(showCreateSystemAvailabilityString: string,
    systemAvailabilityAllowedTypesString: string, modalityCodesSyngoString: string,
    modality: string, ticketType: string): boolean {
    let isRenderAllowed = false;

    if (isEqual(showCreateSystemAvailabilityString, 'true')
      && this.stringUtilService.isContainedIn(ticketType, systemAvailabilityAllowedTypesString)
      && !this.equipmentUtilService.isSyngo(modality, modalityCodesSyngoString)) {
      isRenderAllowed = true;
    }
    return isRenderAllowed;
  }

  getTimezone(city: string, state: string): Observable<TimezoneApiModel> {
    return this.ticketsRestService.getTimezone(city, state);
  }

  /**
   * First we find the ticket from the NOTIF_GETLIST response.
   * The selected ticket must have the same ticketKey as the one we are searching.
   * once this is found, we copy the needed values into the viewModelTicket object.
   * @param ticketFrom
   * @param ticketTo
   */
  copyTicketListFields(ticketFrom: Ticket, ticketTo: TicketViewModel): TicketViewModel {
    if (ticketFrom) {

      if (!ticketTo.ticketStatus) {
        ticketTo.ticketStatus = ticketFrom.ticketStatus;
      }
      if (!ticketTo.ticketStatusDescription) {
        ticketTo.ticketStatusDescription = ticketFrom.ticketStatusDescription;
      }
      if (!ticketTo.taskID) {
        ticketTo.taskID = ticketFrom.taskID;
      }
      if (!ticketTo.taskDescription) {
        ticketTo.taskDescription = ticketFrom.taskDescription;
      }
      if (!ticketTo.problemSeverity) {
        ticketTo.problemSeverity = ticketFrom.problemSeverity;
      }
      if (!ticketTo.problemSeverityDescription) {
        ticketTo.problemSeverityDescription = ticketFrom.problemSeverityDescription;
      }
      if (!ticketTo.plannedStartDatetime) {
        ticketTo.plannedStartDatetime = ticketFrom.plannedStartDatetime;
      }
      if (!ticketTo.plannedEndDatetime) {
        ticketTo.plannedEndDatetime = ticketFrom.plannedEndDatetime;
      }
      if (!ticketTo.originalEffectCodeDescription) {
        ticketTo.originalEffectCodeDescription = ticketFrom.originalEffectCodeDescription;
      }
    }
    return this.copySiebelComponentFields(ticketFrom, ticketTo);
  }

  copySiebelComponentFields(ticketFrom: Ticket, ticketTo: TicketViewModel): TicketViewModel {
    if (ticketFrom) {
      ticketTo.componentId = ticketFrom.componentId;
      ticketTo.customerComponentId = ticketFrom.customerComponentId;
    }
    return ticketTo;
  }

  convertTicketTypesToSelectOptions(ticketTypes: TicketTypes[]): SelectOption[] {
    return lodashMap(ticketTypes, (ticketType) => {
      return {
        value: ticketType.typeId,
        title: ticketType.typeDescription
      };
    });
  }

  getTicketDetailFields(config, viewModelTicket: TicketViewModel): TicketsDetailViewModel[] {
    const ticketDetailFields: TicketsDetailViewModel[] = [];
    const configFields = config.TICKET_DETAILS_ATTRIBUTES;

    if (!configFields) {
      return ticketDetailFields;
    }

    const ticketFields = configFields.split(',');
    forEach(ticketFields, (field) => {
      if (this.filterTicketField(field, config, viewModelTicket)) {
        return;
      }

      const fieldTranslationKey = this.ticketsLabelTranslationMap[field];
      // tslint:disable-next-line:ban
      const fieldTranslation = fieldTranslationKey ? this.translateService.instant(fieldTranslationKey) : undefined;
      if (fieldTranslation) {
        ticketDetailFields.push({
          label: fieldTranslation,
          value: this.getProperFieldValue(viewModelTicket, field),
          datePattern: this.getDatetimePattern(config, field, fieldTranslationKey),
          fieldName: field
        });
      }
    });

    return ticketDetailFields;
  }

  getDatetimePattern(config, field: string, fieldTranslationKey: string): string {
    if (!fieldTranslationKey) {
      return undefined;
    }

    if (fieldTranslationKey.toLowerCase().includes('_datetime') || field.toLowerCase().includes('datetime') ||
      fieldTranslationKey.toLowerCase().includes('_time') || field.toLowerCase().includes('time')) {
      return config.GENERIC_DATE_TIME_PATTERN;
    }

    if (fieldTranslationKey.toLowerCase().includes('_date') || field.toLowerCase().includes('date')) {
      return config.GENERIC_DATE_PATTERN;
    }
  }

  filterTicketField(field: string, config, viewModelTicket: TicketViewModel): boolean {
    return (field === 'plannedEndDatetime' && (config && isEqual(config.PLANNED_ACTIVITY_END_TIME_RENDER, 'false'))) ||
      (field === 'dangerForPatient' && (config && isEqual(config.TICKET_DANGER_FOR_PATIENT_RENDER, 'false'))) ||
      (field === 'originalEffectCodeDescription' &&
        !config?.ALLOWED_TICKET_TYPES_FOR_ORIGINAL_EFFECT_CODE?.split(',')?.includes(viewModelTicket?.typeID));
  }

  getProperFieldValue(viewModelTicket: TicketViewModel, field: string): string {
    if (field === 'dangerForPatient') {
      // tslint:disable-next-line:ban
      return this.translateService.instant(
        `TICKET_FILTER_PATIENTSITUATIONCRITICAL_${
          viewModelTicket[field] ? 'TRUE' : 'FALSE'
        }`
      );
    }

    const combinedFields = this.getCombinedFields(viewModelTicket, field);

    return combinedFields ? combinedFields : viewModelTicket[field];
  }

  isTicketStatusClosed(ticketStatus: string, config) {
    // For e.g South American countries status is returned as empty
    // Hence we need to explicitly check for empty and only in a modal view, it is returned true
    if (isEmpty(ticketStatus)) {
      return true;
    }
    const closedTicketStatus = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');

    return includes(closedTicketStatus, ticketStatus);
  }

  getCombinedFields(viewModelTicket: TicketViewModel, field: string): string {
    const combinedFields = this.ticketsCombinedFields[field];
    if (!combinedFields) {
      return undefined;
    }

    const combinedValue: string[] = [];

    combinedFields.fields.forEach(combinedField => {
      if (viewModelTicket[combinedField]) {
        combinedValue.push(viewModelTicket[combinedField]);
      }
    });

    return combinedValue.length > 1 ? combinedValue.join(combinedFields.separator) : combinedValue.toString();
  }

  getAvailableServiceReportsForSpp(
    ticketKey: string,
    customerId: string,
    ticketNumber: string
  ): Observable<ServiceReport[]> {
    return this.ticketsRestService.getServiceReportsForTicket(ticketNumber).pipe(
      map(serviceReports => serviceReports.map(serviceReport => ({
        fileName: serviceReport.name,
        reportLink: this.getServiceReportLinkForSpp(ticketKey, serviceReport.name, customerId)
      })))
    );
  }

  mapperFunction(ticket: Ticket, ticketDetails: TicketViewModel): TicketViewModel {
    if (ticketDetails && (!ticketDetails.ticketStatus ||
      !ticketDetails.ticketStatusDescription ||
      !ticketDetails.taskID ||
      !ticketDetails.taskDescription ||
      !ticketDetails.problemSeverity ||
      !ticketDetails.problemSeverityDescription ||
      !ticketDetails.plannedStartDatetime ||
      !ticketDetails.plannedEndDatetime)) {
      return this.copyTicketListFields(ticket, ticketDetails);
    }
    return this.copySiebelComponentFields(ticket, ticketDetails);
  }

  private filterTicketTypesByModality(ticketTypes: ModalityTypeTicketTypeRel[], modality: string, config): TicketTypes[] {
    const modalityType = this.getEquipmentModalityType(modality, config);
    const filteredTicketTypes = [];

    let ticketTypesAndSeverityOptions = find(ticketTypes, {'modalityType': modalityType});
    if (isEmpty(ticketTypesAndSeverityOptions)) {
      ticketTypesAndSeverityOptions = find(ticketTypes, {'modalityType': EquipmentModalityType.DEFAULT});
    }
    if (ticketTypesAndSeverityOptions === undefined) {
      return filteredTicketTypes;
    }
    const selectedTicketTypeSeverityRelations = ticketTypesAndSeverityOptions.ticketTypesSevRel;
    forEach(selectedTicketTypeSeverityRelations, ticketTypeRel => {
      filteredTicketTypes.push(ticketTypeRel.ticketType);
    });
    return filteredTicketTypes;
  }

  private getAllowedTicketTypesUpdate(ticketTypes: TicketTypes[], allowedTicketTypes: string[]): TicketTypes[] {
    const filteredTicketTypes: TicketTypes[] = [];
    forEach(ticketTypes, type => {
      if (includes(allowedTicketTypes, type.typeId)) {
        filteredTicketTypes.push(type);
      }
    });
    return filteredTicketTypes;
  }

  emitShowCreateTicketDialog(equipmentKey) {
    this.onShowCreateTicketModal.next(equipmentKey);
  }

  getAllowedOperationalStateInList(ticketList: TicketViewModel[], allowedStates: string[]): Observable<SelectOption[]> {
    const operationalStatesInList = ticketList.filter(ticket => ticket.ticketStatus).map(item => item.problemSeverityID);

    return this.getOperationalStateDescriptions(allowedStates).pipe(map(response => {
      return response.filter(item => operationalStatesInList.includes(item.value));
    }));
  }

  getTicketSparePartMaterialNumbers(equipmentKey: string, ticketNumber: string) {
    return this.ticketsRestService.getTicketSparePartMaterialNumbers(equipmentKey, ticketNumber);
  }
}
