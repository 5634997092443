import { NgModule } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';
import { TooltipWithScrollbarDirective } from './tooltip-with-scrollbar.directive';

@NgModule({
  declarations: [
    TooltipDirective,
    TooltipWithScrollbarDirective
  ],
  exports: [
    TooltipDirective,
    TooltipWithScrollbarDirective
  ]
})
export class TooltipModule {
}
