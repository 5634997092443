import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { MyEquipmentSwitchService } from 'app/equipment/my-equipment-switch/my-equipment-switch.service';
import { MyFiltersService } from 'app/core/services/my-filters.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { Equipment } from '../models/equipment/equipment';
import { UserUtilService } from './user/user-util.service';
import { roles } from '../core-constants.service';
import { AuthService } from 'app/auth.service';
import { MyEquipmentService } from './my-equipment.service';

@Injectable({providedIn: 'root'})
export class MyFiltersAdapterService implements OnDestroy {

  private readonly statusSubject = new ReplaySubject<{ overallSwitch: boolean, equipment: Equipment[] }>(1);

  private readonly unsubscribe$ = new Subject<void>();
  private isInitializing = true;

  get status$() {
    // tslint:disable-next-line: rxjs-no-unsafe-scope
    return this.statusSubject.asObservable().pipe(skipWhile(() => this.isInitializing));
  }

  get filterEquipmentKeys$() {
    return this.status$.pipe(
      map(s => s.equipment.map(e => e.key))
    );
  }

  constructor(
    private readonly configService: CountryConfigRestService,
    private readonly myFiltersService: MyFiltersService,
    private readonly myEquipmentSwitchService: MyEquipmentSwitchService,
    private readonly equipmentRestService: EquipmentRestService,
    private myEquipmentService: MyEquipmentService,
    private readonly userUtilService: UserUtilService,
    private authService: AuthService
  ) {
    this.initialize();
  }

  initialize() {
    this.unsubscribe$.next();
    this.authService.isUserAuthenticated.pipe(
      filter(isAuth => isAuth),
      tap(() => this.isInitializing = true),
      switchMap(() => this.configService.getConfig().pipe(
          first(),
          map(c => !isEqual(c.FEATURE_TOGGLE_MY_FILTERS, 'true')),
          tap(isLegacy => {
            this.myEquipmentService.initialize();
            if (isLegacy) {
              this.myEquipmentSwitchService.initialize();
            } else {
              this.myFiltersService.initialize();
            }
          }))
      ),
      switchMap(isLegacy => isLegacy ? this.getLegacyStatus() : this.myFiltersService.status$),
      takeUntil(this.unsubscribe$)
    ).subscribe(status => {
      this.isInitializing = false;
      this.statusSubject.next(status);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLegacyStatus() {
    return combineLatest([
      this.myEquipmentService.myEquipmentObservable$,
      this.equipmentRestService.getEquipmentForMyFilters(), // gets all equipment
      this.myEquipmentSwitchService.showOnlyMine$,
      this.userUtilService.checkUserRoles({viewEquipment: roles.viewEquipmentRole})
        .pipe(map(hasRole => hasRole.viewEquipment as boolean))
    ]).pipe(
      map(([myEquipment, allEquipment, showOnlyMine, hasViewEquipmentRole]) =>
        [myEquipment, allEquipment, hasViewEquipmentRole && showOnlyMine]),
      map(([myEquipment, allEquipment, overallSwitch]: [string[], Equipment[], boolean]) => ({
        overallSwitch,
        equipment: !overallSwitch ? allEquipment : allEquipment.filter(e => myEquipment.indexOf(e.key) !== -1)
      }))
    );
  }

  getDataForAllEquipments() {
    return this.myEquipmentSwitchService.getLoadAllEquipments();
  }
}
