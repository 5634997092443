import { Pipe, PipeTransform } from '@angular/core';
import { find, forEach, includes } from 'lodash-es';
import { OptionsUpgradesViewModel } from '../../../core/view-models/options-upgrades-view-model';

/**
 * used for options and upgrades
 */
@Pipe({
  name: 'clinicalFields'
})
export class ClinicalFieldsPipe implements PipeTransform {

  transform(value: OptionsUpgradesViewModel[], args?: string[]): any {
    return this.clinicalFields(value, args);
  }

  /**
   *
   * @description
   * Method that recursively iterate through all filters and call individual filters
   */
  clinicalFields(filterDataset: OptionsUpgradesViewModel[], selectedFilters: string[]) {
    let filteredResultSet = [];

    if (selectedFilters && selectedFilters.length > 0) {
      forEach(filterDataset, (el) => {
        forEach(selectedFilters, (value) => {

          // find role exists
          const isChecked = find(el.clinicalFields, {id: value});

          // if filtered value present and not already pushed/exiting in array
          if (isChecked && !includes(filteredResultSet, el)) {
            filteredResultSet.push(el);
          }
        });
      });
    } else {
      filteredResultSet = filterDataset;
    }
    return filteredResultSet;
  }
}
