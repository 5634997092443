import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignedCustomer } from '../models/assigned-customer';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Logout } from '../models/logout';
import { UserRoles } from '../models/user-roles';
import { User } from '../models/user';

import { restEndPoint } from '../core-constants.service';
import { UserSettings } from '../models/UserSettings';
import { UserAccount } from '../models/user-account';
import { FilterValues } from '../models/my-filters.model';
import { AuthService } from '../../auth.service';

@Injectable({providedIn: 'root'})
export class UserRestService {
  // users
  userRestName = restEndPoint + 'users/self';
  userRolesRestName = restEndPoint + 'users/self/roles';
  userLogoutRestName = restEndPoint + 'users/logout';
  userAssignedCustomersRestName = restEndPoint + 'users/assignedCustomers';
  userAssignedCustomersByLocationRestName = restEndPoint + 'users/customersByLocation';
  userAvailableModalitiesRestName = restEndPoint + 'users/availableModalities';
  userLocaleRestName = restEndPoint + 'users/locale';
  userSelfTermsConditionRestName = restEndPoint + 'users/self/acceptTerms';
  userSelfCookiesRestName = restEndPoint + 'users/self/acceptCookies';
  userSelfIntroShownRestName = restEndPoint + 'users/intro/introShown ';
  userSelfIntroClosedRestName = restEndPoint + 'users/intro/closed ';
  userSettingsRestName = restEndPoint + 'users/self/settings';
  userAccountsRestName = restEndPoint + 'user-accounts';
  userFilterValuesRestName = restEndPoint + 'equipments/myProfile/userFilterValues';
  userRolesTranslatedRestName = restEndPoint + 'user-accounts/roles/translated';

  constructor(private http: CacheableHttpClient,
    private authService: AuthService) {
  }

  getUser(): Observable<User> {
    return this.http.get<User>(this.userRestName);
  }

  clearUserCache() {
    return this.http.clearCache(this.userRestName);
  }

  getUserRoles(): Observable<UserRoles[]> {
    return this.http.get<UserRoles[]>(this.userRolesRestName);
  }

  logout(): Observable<Logout> {
    this.http.clearCache();
    this.authService.isUserAuthenticatedSource.next(false);
    return this.http.get<Logout>(this.userLogoutRestName);
  }

  getAssignedCustomers(): Observable<AssignedCustomer[]> {
    return this.http.get<AssignedCustomer[]>(this.userAssignedCustomersRestName);
  }

  getAssignedCustomersByLocation(): Observable<AssignedCustomer[][]> {
    return this.http.get<AssignedCustomer[][]>(this.userAssignedCustomersByLocationRestName);
  }

  putLocaleUpdate(body): Observable<any> {
    this.http.clearCache();
    return this.http.put(this.userLocaleRestName, body, {
      responseType: 'text'
    });
  }

  postTermsNCondition(): Observable<any> {
    return this.http.post(this.userSelfTermsConditionRestName, {});
  }

  postCookieAccepted(): Observable<any> {
    return this.http.post(this.userSelfCookiesRestName, {});
  }

  showIntroModal(): Observable<any> {
    return this.http.get<any>(this.userSelfIntroShownRestName);
  }

  postIntroModalClosed(): Observable<any> {
    return this.http.post(this.userSelfIntroClosedRestName, {});
  }

  getUserSettings(): Observable<UserSettings> {
    this.http.clearCache();
    return this.http.get<UserSettings>(this.userSettingsRestName);
  }

  postUserSettings(userSettings: UserSettings): Observable<any> {
    this.http.clearCache(this.userSettingsRestName);
    return this.http.post(this.userSettingsRestName, userSettings);
  }

  postUserFilterValues(filterValues: FilterValues): Observable<any> {
    return this.http.post(this.userFilterValuesRestName, filterValues);
  }

  getUserFilterValues(): Observable<FilterValues> {
    return this.http.get(this.userFilterValuesRestName, {cache: false});
  }

  getUserAccounts(): Observable<UserAccount[]> {
    return this.http.get(this.userAccountsRestName);
  }

  getTranslatedRoles(): Observable<string[]> {
    return this.http.get(this.userRolesTranslatedRestName);
  }

  getUserModalitiesList(): Observable<any[]> {
    return this.http.get(this.userAvailableModalitiesRestName, {cache: false});
  }
}
