import { NgModule } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { HandleLocationChangeOnBrowserBackDirective } from '../shared/directives/handle-location-change-on-browser-back/handle-location-change-on-browser-back.directive';
import { AddRemoveEquipmentToMyProfileDirective } from '../shared/directives/add-remove-equipment-to-my-profile/add-remove-equipment-to-my-profile.directive';
import { TextfieldAnimatorDirective } from '../shared/directives/textfield-animator/textfield-animator.directive';
import { AddOmnitureAndRouterStateNameDirective } from '../shared/directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { AddRootClassesDirective } from '../shared/directives/add-root-classes/add-root-classes.directive';
import { DropdownCloserDirective } from '../shared/directives/dropdown-closer/dropdown-closer.directive';
import { SpaceValidator } from '../shared/validators/space.validator';
import { FilterNoScrollDirective } from '../shared/directives/filter-no-scroll/filter-no-scroll.directive';
import { ScrollTopDirective } from '../shared/directives/scroll-top/scroll-top.directive';
import { ItemShowHideHandlerDirective } from '../shared/directives/item-show-hide-handler/item-show-hide-handler.directive';
import { TextareaAutoResizeDirective } from '../shared/directives/textarea-auto-resize/textarea-auto-resize.directive';
import { WalkMeDirective } from '../shared/directives/walk-me/walk-me.directive';
import { HelperTextFormsDirective } from '../shared/directives/helper-text-forms/helper-text-forms.directive';
import { GoogleMapsDirective } from '../shared/directives/google-maps/google-maps.directive';
import { CollapsableDirective } from '../shared/directives/collapsable/collapsable.directive';
import { CookieBannerDirective } from '../shared/directives/cookie-banner/cookie-banner.directive';
import { HtmlLinkDirective } from 'app/shared/directives/html-link/html-link.directive';

@NgModule({
  declarations: [
    AddRootClassesDirective,
    AddOmnitureAndRouterStateNameDirective,
    CookieBannerDirective,
    ItemShowHideHandlerDirective,
    AddRemoveEquipmentToMyProfileDirective,
    GoogleMapsDirective,
    HandleLocationChangeOnBrowserBackDirective,
    ScrollTopDirective,
    TextfieldAnimatorDirective,
    DropdownCloserDirective,
    CollapsableDirective,
    FilterNoScrollDirective,
    HelperTextFormsDirective,
    WalkMeDirective,
    TextareaAutoResizeDirective,
    SpaceValidator,
    HtmlLinkDirective
  ],
  providers: [
    FormGroupDirective
  ],
  exports: [
    AddRootClassesDirective,
    AddOmnitureAndRouterStateNameDirective,
    CookieBannerDirective,
    ItemShowHideHandlerDirective,
    AddRemoveEquipmentToMyProfileDirective,
    GoogleMapsDirective,
    ScrollTopDirective,
    HandleLocationChangeOnBrowserBackDirective,
    TextfieldAnimatorDirective,
    DropdownCloserDirective,
    CollapsableDirective,
    FilterNoScrollDirective,
    HelperTextFormsDirective,
    WalkMeDirective,
    TextareaAutoResizeDirective,
    SpaceValidator,
    HtmlLinkDirective
  ]
})
export class DirectivesModule {
}
