import { ModuleWithProviders, NgModule } from '@angular/core';
import { BarChartModule, ChartCommonModule, LineChartModule, PieChartModule } from '@swimlane/tpf-ngx-charts';
import { DiagramDoughnutComponent } from './diagram-doughnut.component';
import { DiagramBarsComponent } from './diagram-bars.component';
import { RouterModule } from '@angular/router';
import { DiagramComboComponent } from './diagram-combo.component';
import { DiagramComboVerticalComponent } from './diagram-combo-vertical.component';
import { DiagramStackedBarsComponent } from './diagram-stacked-bars.component';
import { DiagramGroupedBarsComponent } from './diagram-grouped-bars.component';
import { TranslateModule } from '@ngx-translate/core';
import { GetCountWithUnitPipe } from './get-count-with-unit-diagram/get-count-with-unit.pipe';
import { GetTotalFontSizePipe } from './get-total-font-size-diagram/get-total-font-size.pipe';
import { DiagramTimelineComponent } from './diagram-timeline.component';
import { DiagramLineDoubleAxisComponent } from './diagram-line-double-axis.component';
import { WidgetDiagramBarsComponent } from './widget-diagram-bars.component';
import { DiagramBarVerticalCandles } from './diagram-bar-vertical-candles/diagram-bar-vertical-candles.component';
import { SeriesVerticalComponent } from './diagram-bar-vertical-candles/diagram-bar-vertical-series.component';
import { DiagramBarDoubleAxisVerticalComponent } from './diagram-bar-double-axis/diagram-bar-double-axis-vertical.component';
import { DiagramBarDoubleAxisHorizontalComponent } from './diagram-bar-double-axis/diagram-bar-double-axis-horizontal.component';
import { DiagramXAxisCustomComponent } from './diagram-bar-double-axis/diagram-x-axis-custom.component';
import { DiagramBarDoubleAxisBaseComponent } from './diagram-bar-double-axis/diagram-bar-double-axis-base.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { LineSeriesComponent } from './line-series/line-series.component';
import { DiagramGuardianOverviewComponent } from './diagram-guardian-overview/diagram-guardian-overview.component';
import { DiagramWhiskerComponent } from './common/whisker/diagram-whisker.component';
import { GuardianCircleComponent } from './diagram-guardian-common/guardian-circle.component';
import { DiagramGuardianProductComponent } from './diagram-guardian-product/diagram-guardian-product.component';
import { GuardianRangeSeriesComponent } from './diagram-guardian-common/guardian-range-series.component';
import { DiagramBarVerticalStackedComponent } from './diagram-bar-vertical-stacked/diagram-bar-vertical-stacked.component';
import { DiagramYAxisSsrComponent } from './axis/chart-y-axis-ssr.component';
import { DiagramEquipmentHeliumLevelComponent } from './diagram-equipment-helium-level/diagram-equipment-helium-level.component';
import { DiagramBarComponent } from './common/bar/diagram-bar.component';
import { SprStackedBarsTooltipLabelPipe } from './pipes/spr-stacked-bars-tooltip-label/spr-stacked-bars-tooltip-label.pipe';

export { DataDiagram, GeneratedDataDiagram } from './diagram';

@NgModule({
  imports: [
    BarChartModule,
    ChartCommonModule,
    PieChartModule,
    LineChartModule,
    RouterModule,
    TranslateModule,
    TooltipModule
  ],
  declarations: [
    DiagramDoughnutComponent,
    DiagramBarsComponent,
    DiagramGroupedBarsComponent,
    DiagramStackedBarsComponent,
    DiagramBarVerticalStackedComponent,
    DiagramComboComponent,
    DiagramComboVerticalComponent,
    GetCountWithUnitPipe,
    GetTotalFontSizePipe,
    SprStackedBarsTooltipLabelPipe,
    DiagramTimelineComponent,
    WidgetDiagramBarsComponent,
    DiagramLineDoubleAxisComponent,
    DiagramBarVerticalCandles,
    SeriesVerticalComponent,
    DiagramBarDoubleAxisBaseComponent,
    DiagramBarDoubleAxisVerticalComponent,
    DiagramBarDoubleAxisHorizontalComponent,
    DiagramXAxisCustomComponent,
    DiagramYAxisSsrComponent,
    LineSeriesComponent,
    DiagramGuardianOverviewComponent,
    DiagramGuardianProductComponent,
    DiagramWhiskerComponent,
    DiagramBarComponent,
    GuardianCircleComponent,
    GuardianRangeSeriesComponent,
    DiagramEquipmentHeliumLevelComponent
  ],
  exports: [
    DiagramDoughnutComponent,
    DiagramBarsComponent,
    DiagramGroupedBarsComponent,
    DiagramStackedBarsComponent,
    DiagramBarVerticalStackedComponent,
    DiagramComboComponent,
    GetCountWithUnitPipe,
    GetTotalFontSizePipe,
    SprStackedBarsTooltipLabelPipe,
    DiagramTimelineComponent,
    WidgetDiagramBarsComponent,
    DiagramLineDoubleAxisComponent,
    DiagramBarVerticalCandles,
    SeriesVerticalComponent,
    DiagramBarDoubleAxisBaseComponent,
    DiagramBarDoubleAxisVerticalComponent,
    DiagramBarDoubleAxisHorizontalComponent,
    DiagramXAxisCustomComponent,
    DiagramYAxisSsrComponent,
    LineSeriesComponent,
    DiagramGuardianOverviewComponent,
    DiagramGuardianProductComponent,
    DiagramWhiskerComponent,
    DiagramBarComponent,
    GuardianCircleComponent,
    GuardianRangeSeriesComponent,
    DiagramEquipmentHeliumLevelComponent
  ],
  providers: []
})
export class DiagramModule {
  static forRoot(): ModuleWithProviders<DiagramModule> {
    return {
      ngModule: DiagramModule,
      providers: []
    };
  }
}
