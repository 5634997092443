import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { Injectable, SecurityContext } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SanitizerWrapperService {

  domSanitizer: DomSanitizer;

  constructor(private sanitizer: DomSanitizer) {
    this.domSanitizer = sanitizer;
  }
  public sanitizeDom(input: string): string {
    const sanitizedString: SafeValue = this.domSanitizer.sanitize(SecurityContext.HTML, input);
    return sanitizedString ? sanitizedString.toString() : '';
  }

  public sanitizeUrl(input: string, bypassSecurity: boolean = false): string {
    let sanitizedString: SafeValue;
    if (!bypassSecurity) {
      sanitizedString = this.domSanitizer.sanitize(SecurityContext.URL, input);
    } else {
      sanitizedString = this.sanitizer.bypassSecurityTrustResourceUrl(input);
      sanitizedString = this.domSanitizer.sanitize(SecurityContext.URL, sanitizedString);
    }
    return sanitizedString ? sanitizedString.toString() : '';
  }

  public sanitizeResourceUrl(input: string, bypassSecurity: boolean = false): string {
    let sanitizedString: SafeValue;
    if (!bypassSecurity) {
      sanitizedString = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, input);
    } else {
      sanitizedString = this.sanitizer.bypassSecurityTrustResourceUrl(input);
      sanitizedString = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, sanitizedString);
    }
    return sanitizedString ? sanitizedString.toString() : '';
  }
}
