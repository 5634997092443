import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { WindowService } from '../../../core/window.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'hl-multiline-input',
  templateUrl: './multiline-input.component.html'
})
export class MultilineInputComponent {

  private readonly allowedKeyCodes = [8, 37, 38, 39, 40];
  private readonly allowedKeyCodesWithCtrlKey = [65, 90, 86, 67, 88];

  private _innerValue: string;
  textValue: string;

  @Input() set value(value: string) {
    if (value !== this._innerValue) {
      this.textValue = value;
      this._innerValue = value;
    }
  }

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() styleObject: object;
  @Input() isDisabled = false;
  @Input() maxLength: number;

  constructor(
    private windowService: WindowService,
    @Inject(DOCUMENT) private document: any) {
  }

  onValueChange(event) {
    const newValue = event.target.innerText;

    if (this._innerValue === newValue) {
      return;
    }

    this._innerValue = newValue;
    this.valueChange.emit(newValue);
  }

  evaluatePaste(event) {
    if (this.maxLength > 0) {
      const pastedText =
        event.clipboardData?.getData('text/plain') ||
        this.windowService.nativeWindow.clipboardData?.getData('Text');
      const contentLength = event.target.innerText?.length;
      const selectedText = this.getSelectedTextByElement(event.target);
      const allowedPasteLength = this.maxLength - contentLength + selectedText.length;
      const slicedPasteText = pastedText?.substring(0, allowedPasteLength);

      event.preventDefault();

      const inserted = this.document.execCommand &&
        this.document.execCommand('insertHTML', false, slicedPasteText);
      if (!inserted) {
        this.insertTextAtCaret(slicedPasteText);
      }

      this.onValueChange(event);
    }
  }

  private getSelectedTextByElement(element) {
    return this.document.activeElement === element ?
      this.windowService.nativeWindow.getSelection().toString() :
      '';
  }

  private insertTextAtCaret(text) {
    if (this.windowService.nativeWindow.getSelection) {
      const sel = this.windowService.nativeWindow.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        const range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(this.document.createTextNode(text));
      }
    } else if (this.document.selection && this.document.selection.createRange) {
      this.document.selection.createRange().text = text;
    }
  }

  evaluateKeyDown(event) {
    if (this.maxLength > 0) {
      const isAllowedKey = this.allowedKeyCodes.includes(event.keyCode);
      const isShortcut = event.ctrlKey && this.allowedKeyCodesWithCtrlKey.includes(event.keyCode);
      const isAllowedAction = isAllowedKey || isShortcut;

      if (isAllowedAction) {
        return;
      }

      const contentLength = event.target.innerText?.length;

      if (contentLength >= this.maxLength) {
        event.preventDefault();
      }
    }
  }
}
