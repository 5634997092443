import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

export interface ButtonWithSpinner {
  showButton: boolean;
  disableButton: boolean;
  showSpinner: boolean;
  label: string;
  withBottomMargin: boolean
  buttonClasses?: string;
}

@Component({
  selector: 'hl-button-with-spinner',
  templateUrl: './button-with-spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonWithSpinnerComponent {

  @Output() buttonClicked = new EventEmitter<void>();

  @ViewChild('labelSpan') labelSpan: ElementRef;
  @Input() showSpinner = false;
  @Input() disabled = false;
  @Input() withBottomMargin = false;
  @Input() label: string;
  @Input() dataCy: string;
  @Input() buttonClasses = 'button button--small';

  emitButtonClick() {
    this.buttonClicked.next();
  }
}
