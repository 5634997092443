<ngx-charts-chart
  [view]="[width, height]"
  [animations]="animations"
  xmlns:svg="http://www.w3.org/2000/svg"
>
  <svg:g class="bar-chart chart" [attr.transform]="transform">

  @if (bar) {
    <svg:g
      [attr.stroke]="strokeColor"
      [attr.stroke-width]="strokeSize"
    >
      @if (animations) {
        <svg:g>
          <svg:g
            ln-charts-diagram-bar
            [@animationState]="'active'"
            [@.disabled]="!animations"
            [width]="bar.width"
            [height]="bar.height"
            [x]="bar.x"
            [y]="bar.y"
            [fill]="bar.color"
            [stops]="bar.gradientStops"
            [data]="bar.data"
            [orientation]="barOrientation.Vertical"
            [roundEdges]="bar.roundEdges"
            [ariaLabel]="bar.ariaLabel"
            [isActive]="isActive(bar.data)"
            (select)="select.emit($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            ngx-tooltip
            [tooltipDisabled]="tooltipDisabled"
            [tooltipType]="styleTypes.tooltip"
            [tooltipTitle]="tooltipTemplate ? undefined : bar.tooltipText"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipContext]="bar.data"
            [animations]="animations"
          ></svg:g>
        </svg:g>
      }
      @if (!animations) {
        <svg:g>
          <svg:g
            ln-charts-diagram-bar
            [width]="bar.width"
            [height]="bar.height"
            [x]="bar.x"
            [y]="bar.y"
            [fill]="bar.color"
            [stops]="bar.gradientStops"
            [data]="bar.data"
            [orientation]="barOrientation.Vertical"
            [roundEdges]="bar.roundEdges"
            [ariaLabel]="bar.ariaLabel"
            [isActive]="isActive(bar.data)"
            (select)="select.emit($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            ngx-tooltip
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTitle]="tooltipTemplate ? undefined : bar.tooltipText"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipContext]="bar.data"
            [animations]="animations"
          ></svg:g>
          </svg:g>
        }
        @if (data?.showThreshold && threshold) {
          <svg:g
            ln-charts-diagram-whisker
            [data]="threshold"
            [strokeSize]="strokeSize*thresholdStrokeSizeRatio"
            [xAxisBandwidthSize]="xAxisBandwidthSize"
            [bandwidthSplitSize]="bandwidthSplitSize"
            [xScale]="xScale"
            [yScale]="yScale"
            [colors]="colors"
            [activeEntries]="activeEntries"
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTemplate]="tooltipTemplate"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          />
        }
      </svg:g>
    }
  </svg:g>
</ngx-charts-chart>
