import { Router } from '@angular/router';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { DomUtilService } from '../../../core/utils/dom-util.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements AfterViewInit, OnDestroy, AfterViewChecked {
  @Input()
  isMyInstitutions = false;
  @Input()
  noMarginBottom = false;
  @Input()
  navigationMenu = false;
  isDropdownOpen = false;
  menuItemClassName = 'menu__item';

  private eventListeners = [];
  @Input()
  moreEnabled = false;

  @Input()
  moreAdditionalStyle;
  @Input()
  subTabsView = false;
  @Input()
  private dropdownDirection: 'right' | 'left' = 'right';

  @Input()
  subTabsCollectionTabName;

  @Input()
  cypressTag = '';

  @Input()
  burgerMenuMobileLayout = false;

  @Input()
  isBurgerMenuOpen = false;

  private menuItemClassSelector = '.' + this.menuItemClassName;
  private tabsItemClassName = 'tabs__item';
  private tabsItemClassSelector = '.' + this.tabsItemClassName;

  @ViewChild('tabsContainer')
  tabsContainerEl: ElementRef;
  @ViewChild('tabsItemMore')
  tabsItemMoreEl: ElementRef;
  @ViewChild('tabsList')
  tabsListEl: ElementRef;
  @ViewChild('tabsListMore')
  tabsListMoreEl: ElementRef;
  @ViewChild('tabsToggleMore')
  tabsToggleMoreEl: ElementRef;
  @ViewChild('tabsActiveLine')
  tabsActiveLineEl: ElementRef;

  private observer: MutationObserver;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private domService: DomUtilService,
    private router: Router,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
  }

  @HostListener('window:resize')
  onResize() {
    if (this.moreEnabled) {
      this.calcWidth();
    }

    this.setSubtabDropdownStyleInBurgerMenu();
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (
      this.moreEnabled && this.isDropdownOpen &&
      !this.tabsToggleMoreEl.nativeElement.contains(event.target)
    ) {
      if (this.subTabsView || event.target.className.includes('menu__item') ||
        event.target.parentNode?.className?.includes('menu__item') ||
        !this.tabsListMoreEl.nativeElement.contains(event.target)) {
        this.isDropdownOpen = false;
      }

      this.cd.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.moreEnabled) {
      this.calcWidth();
    }
    this.setSubtabDropdownStyleInBurgerMenu();

    this.addClickListenersToTabs();

    // TODO: fix this temporary hack for tabs in clickdummy
    setTimeout(() => {
      this.onResize();
    }, 1000);
  }

  ngAfterViewChecked() {
    this.initActiveItem();
  }

  resetEventListeners() {
    setTimeout(() => {
      this.unregisterEventListeners();
      this.addClickListenersToTabs();
    }, 100);
  }

  getDropdownDirectionClassName(): string {
    return `dropdown--${this.dropdownDirection}`;
  }

  private addClickListenersToTabs() {
    const items = this.el.nativeElement.querySelectorAll(
      this.tabsItemClassSelector + ':not(.dropdown), ' + this.menuItemClassSelector
    );
    items.forEach(el => {
      this.addListenerToTabNotToTabMenu(el);
    });
  }

  /* This function adds click listener to separate tab item. If input element is a group of tabs and
     not an inner tab or separate tab, listener is not added. This is important for navigation tabs,
     which are using grouped tabs. As a result, active line is applied on grouped menu only when
     user clicks on inner tab.
   */
  private addListenerToTabNotToTabMenu(el) {
    if (el.tagName !== 'HL-TABS') {
      const clickListener = this.renderer.listen(el, 'click', () => {
        this.setActiveLine(el);
      });
      this.eventListeners.push(clickListener);
    }
  }

  ngOnDestroy() {
    this.unregisterEventListeners();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private unregisterEventListeners() {
    for (const listener of this.eventListeners) {
      listener();
    }
  }

  initActiveItemTimeout() {
    setTimeout(() => {
      this.initActiveItem();
    }, 1000);
  }

  initActiveItem() {
    const activeItem = this.el.nativeElement.querySelector('.is-active');
    if (activeItem) {
      this.setActiveLine(activeItem);
    }
  }

  setActiveLine(item) {
    if (!this.subTabsView && !item.parentNode?.className?.includes('subtab__menu') && !this.isBurgerMenuOpen) {
      if (item.classList.contains(this.menuItemClassName)) {
        item = this.tabsItemMoreEl.nativeElement;
      }
      const width = item.offsetWidth;
      const left = item.offsetLeft;

      if (width + 'px' !== this.tabsActiveLineEl.nativeElement.style.width ||
        left + 'px' !== this.tabsActiveLineEl.nativeElement.style.left) {
        this.renderer.setStyle(
          this.tabsActiveLineEl.nativeElement,
          'width',
          width + 'px'
        );
        this.renderer.setStyle(
          this.tabsActiveLineEl.nativeElement,
          'left',
          left + 'px'
        );
      }
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  calcWidth() {
    const containerStyle = getComputedStyle(this.tabsContainerEl.nativeElement);

    const containerWidth =
      this.tabsContainerEl.nativeElement.offsetWidth -
      parseFloat(containerStyle.paddingLeft) -
      parseFloat(containerStyle.paddingRight);
    const moreEl = this.tabsItemMoreEl.nativeElement;
    const moreWidth = this.domService.outerWidthWithMargin(moreEl);
    let menuListEl = this.el.nativeElement.querySelectorAll(
      this.tabsItemClassSelector + ':not(' + this.menuItemClassSelector + '):not(.dropdown)');
    const list = this.tabsListEl.nativeElement;
    const listMore = this.tabsListMoreEl.nativeElement;

    let navWidth = 0;
    menuListEl.forEach(el => {
      navWidth += this.domService.outerWidthWithMargin(el);
    });

    if ((navWidth + moreWidth >= containerWidth) || this.subTabsView) {
      if (menuListEl.length > 0) {
        const lastItem = menuListEl[menuListEl.length - 1];

        this.renderer.setAttribute(
          lastItem,
          'width',
          this.domService.outerWidthWithMargin(lastItem).toString()
        );
        this.renderer.removeClass(lastItem, this.tabsItemClassName);
        this.renderer.addClass(lastItem, this.menuItemClassName);

        const firstMoreElement = listMore.querySelector(this.menuItemClassSelector);
        if (firstMoreElement) {
          this.renderer.insertBefore(listMore, lastItem, firstMoreElement);
        } else {
          this.renderer.appendChild(listMore, lastItem);
        }

        this.setStyleForSubTabDropdown(lastItem, true);

        this.calcWidth();
      }
    } else {
      let firstMoreElement = listMore.querySelector(this.menuItemClassSelector);
      const firstMoreElementCount = listMore.querySelectorAll(this.menuItemClassSelector).length;

      if (firstMoreElement) {
        const firstMoreElementWidth = parseFloat(
          firstMoreElement.getAttribute('width')
        );

        let widthOfNewNav = navWidth + firstMoreElementWidth;
        if (firstMoreElementCount > 1) {
          widthOfNewNav += moreWidth;
        }

        if (widthOfNewNav < containerWidth) {
          this.renderer.removeClass(firstMoreElement, this.menuItemClassName);
          this.renderer.addClass(firstMoreElement, this.tabsItemClassName);
          this.renderer.insertBefore(list, firstMoreElement, moreEl);

          this.setStyleForSubTabDropdown(firstMoreElement, false);

          firstMoreElement = listMore.querySelector(this.menuItemClassSelector);
        }
        menuListEl = this.el.nativeElement.querySelectorAll(
          this.tabsItemClassSelector + ':not(' + this.menuItemClassSelector + '):not(.dropdown)');

        let tabsWidth = 0;
        menuListEl.forEach(el => {
          tabsWidth += this.domService.outerWidthWithMargin(el);
        });

        const dropdownRightClass = 'dropdown--right';
        const dropdownLeftClass = 'dropdown--right-unused';
        const dropdownCenterClass = 'dropdown--center';
        const smallDevice = window.innerWidth <= 576;
        if (firstMoreElement != null) {
          if (tabsWidth + parseInt(firstMoreElement.getAttribute('width'), 10) >
            this.domService.outerWidthWithMargin(this.tabsContainerEl.nativeElement)) {
            this.renderer.removeClass(this.tabsItemMoreEl.nativeElement, dropdownLeftClass);
            this.renderer.removeClass(this.tabsItemMoreEl.nativeElement, smallDevice ? dropdownRightClass : dropdownCenterClass);
            this.renderer.addClass(this.tabsItemMoreEl.nativeElement, smallDevice ? dropdownCenterClass : dropdownRightClass);
          } else {
            this.renderer.removeClass(this.tabsItemMoreEl.nativeElement, dropdownRightClass);
            this.renderer.removeClass(this.tabsItemMoreEl.nativeElement, smallDevice ? dropdownLeftClass : dropdownCenterClass);
            this.renderer.addClass(this.tabsItemMoreEl.nativeElement, smallDevice ? dropdownCenterClass : dropdownLeftClass);
          }
        }
      }

      if (!this.observer) {
        this.observer = new MutationObserver(() => this.calcWidth());

        this.observer.observe(list, {
          childList: true
        });
      }
    }

    if (moreEl.querySelectorAll(this.menuItemClassSelector).length > 0) {
      this.renderer.removeClass(moreEl, 'hidden');
    } else {
      this.renderer.addClass(moreEl, 'hidden');
    }
  }

  setStyleForSubTabDropdown(element: any, subtabIsEmbeddedInMainNavigationMoreTab: boolean) {
    if (element && element.tagName === 'HL-TABS') {
      const menuElementOfSubTab = element.querySelector('.menu');

      if (subtabIsEmbeddedInMainNavigationMoreTab) {
        this.renderer.removeClass(menuElementOfSubTab, 'dropdown__panel');
        this.renderer.addClass(menuElementOfSubTab, 'margin-left-tiny-5');
      } else {
        this.renderer.addClass(menuElementOfSubTab, 'dropdown__panel');
        this.renderer.removeClass(menuElementOfSubTab, 'margin-left-tiny-5');
      }
    }
  }

  private setSubtabDropdownStyleInBurgerMenu() {
    if (this.burgerMenuMobileLayout) {
      const menuListEl = this.el.nativeElement.querySelectorAll(
        this.tabsItemClassSelector + ':not(' + this.menuItemClassSelector + '):not(.dropdown)');
      if (this.isBurgerMenuOpen) {
        menuListEl.forEach(el => {
          this.setStyleForSubTabDropdown(el, true);
        });
      } else {
        menuListEl.forEach(el => {
          this.setStyleForSubTabDropdown(el, false);
        });
      }
    }
  }
}
