import { Pipe, PipeTransform } from '@angular/core';
import { compact, forEach, uniq } from 'lodash-es';

interface SearchObject {
  searchValue: string;
  searchColumns?: string[];
  searchDeep?: SearchDeepObject;
}

interface SearchDeepObject {
  lookDeepAttribute: string;  // a single attribute, which contains either an object or an array of objects
  searchColumns: string[];
}

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(dataSet: any, searchObject?: SearchObject): any {
    // if search value is null, then return original data set
    if (!searchObject.searchValue) {
      return dataSet;
    }

    let searchSplit = searchObject.searchValue.split(',');
    const resultSet = [];

    // check for comma separated values in search input field
    if (searchSplit.length > 1 && searchObject.searchColumns) {
      // trim values and remove empty strings
      searchSplit = compact(searchSplit.map(value => value.trim()));

      forEach(searchSplit, (value) => {
        Array.prototype.push.apply(resultSet, this.computeSearchResultSet(dataSet,
          value, searchObject.searchColumns));
      });
      return uniq(resultSet);
    }

    // if only one search input field
    if (searchObject.searchColumns && searchSplit.length === 1) {
      Array.prototype.push.apply(resultSet, this.computeSearchResultSet(dataSet,
        searchObject.searchValue, searchObject.searchColumns));

      // if the dataset needs to be search for property which are nested/in depth search
      if (searchObject.searchDeep) {
        Array.prototype.push.apply(resultSet, this.computeDeepSearchResultSet(dataSet,
          searchObject.searchValue, searchObject.searchDeep.lookDeepAttribute,
          searchObject.searchDeep.searchColumns));
      }
      return uniq(resultSet);
    }
  }

  /**
   *
   * @param dataSet | dataset on which search needs to be carried out
   * @param searchValue | value to search for
   * @param searchColumns | allowed list of object properties to search value for
   */
  computeSearchResultSet(dataSet, searchValue, searchColumns) {
    const filteredData = [];
    forEach(dataSet, (filteredDataset) => {
      forEach(searchColumns, (column) => {
        if (filteredDataset[column]) {

          if (column === 'equipmentKey') {
            const searchValueIndex = (filteredDataset['equipmentKey'].toString().toLowerCase()).indexOf(searchValue.toLowerCase());
            if (searchValueIndex !== -1) {
              filteredData.push(filteredDataset);
              return false;
            }
          }

          if (this.trySearchExactlyShallow(searchValue, filteredData, filteredDataset, column)) {
            return false;
          }

          const isMatch = (filteredDataset[column].toString().toLowerCase()).indexOf(searchValue.toLowerCase());
          if (isMatch !== -1) {
            filteredData.push(filteredDataset);

            // Note: we leave iterating columns on first hit for search on any column
            return false;
          }
        } else if (column === 'equipmentNumber') {
          filteredData.push(...this.filterByEquipmentNumber(dataSet, searchValue));
        }
      });
    });
    return filteredData;
  }

  private filterByEquipmentNumber(dataSet: any[], searchValue: string) {
    return dataSet.filter((filteredDataset: { [x: string]: { toString: () => string; }; }) => {
      const parts = filteredDataset['equipmentKey'].toString().toLowerCase().split('_');
      return parts[parts.length - 1].includes(searchValue.toLowerCase());
    });
  }

  /**
   *
   * @description
   * Get the search filtered result
   */
  computeDeepSearchResultSet(dataSet, searchValue, lookDeepProperty, searchColumns) {
    const filteredData = [];
    forEach(dataSet, (filteredDataset) => {

      // attribute is an array (deep search)
      const lookDeepPropertyValues = filteredDataset[lookDeepProperty];

      // look deep property is an array
      if (lookDeepPropertyValues && lookDeepPropertyValues.length > 0) {
        let isMatch = null;
        forEach(lookDeepPropertyValues, (deepDataSet) => {
          forEach(searchColumns, (column) => {
            if (deepDataSet[column]) {
              if (this.trySearchExactlyDeep(searchValue, filteredData, filteredDataset, deepDataSet, column)) {
                isMatch = 1;
                return false;
              }

              isMatch = (deepDataSet[column].toLowerCase()).indexOf(searchValue.toLowerCase());
              if (isMatch !== -1) {
                filteredData.push(filteredDataset);
                // Note: we leave iterating columns on first hit for search on any column
                return false;
              }
            }
          });

          if (isMatch !== -1) {
            return false;
          }
        });
      } else if (lookDeepPropertyValues) {
        // look deep property is an object
        forEach(searchColumns, (column) => {
          if (lookDeepPropertyValues[column]) {
            if (this.trySearchExactlyDeep(searchValue, filteredData, filteredDataset, lookDeepPropertyValues, column)) {
              return false;
            }

            const isMatch = (lookDeepPropertyValues[column].toLowerCase()).indexOf(searchValue.toLowerCase());
            if (isMatch !== -1) {
              filteredData.push(filteredDataset);
              // Note: we leave iterating columns on first hit for search on any column
              return false;
            }
          }
        });
      }
    });
    return filteredData;
  }

  /**
   * checks if the searching data value is in double quotes and if the dataset column contains said data
   * @param searchValue | searching data value
   * @param filteredData | array when the dataset will be pushed if his column contains search value
   * @param filteredDataset | dataset which contains column for comparing with search value
   * @param column | dataset column that will be compared to search data
   */
  trySearchExactlyShallow(searchValue: string, filteredData, filteredDataset, column: string): boolean {
    return this.trySearchExactly(searchValue, filteredData, filteredDataset, filteredDataset[column].toString());
  }

  /**
   * checks if the searching data value is in double quotes and if the dataset column contains said data
   * @param searchValue | searching data value
   * @param filteredData | array when the dataset will be pushed if his column contains search value
   * @param filteredDataset | dataset which will be pushed to array
   * @param deepDataSet | dataset which contains column for comparing with search value
   * @param column | dataset column that will be compared to search data
   */
  trySearchExactlyDeep(searchValue: string, filteredData, filteredDataset, deepDataSet, column: string): boolean {
    return this.trySearchExactly(searchValue, filteredData, filteredDataset, deepDataSet[column].toString());
  }

  /**
   * checks if the searching data value is in double quotes and if the dataset column contains said data
   * @param searchValue | searching data value
   * @param filteredData | array when the dataset will be pushed if his column contains search value
   * @param filteredDataset | dataset which contains column for comparing with search value
   * @param searchedData | the data to search in
   */
  trySearchExactly(searchValue: string, filteredData, filteredDataset, searchedData: string): boolean {
    if (this.isDoubleQuoted(searchValue)) {
      const data = searchedData.toLowerCase();

      if (this.getQuotedString(searchValue).toLowerCase() === data) {
        filteredData.push(filteredDataset);
        return true;
      }
    }
    return false;
  }

  /**
   * Check whether the string starts and ends with double quotes
   * @param str | string that will be checked
   * @returns true if string starts and ends with double quotes
   */
  isDoubleQuoted(str: string): boolean {
    return str[0] === '"' && str[str.length - 1] === '"';
  }

  /**
   * Removes first and last character from string
   * @param str | string used to create substring
   * @returns string value without first and last character
   */
  getQuotedString(str: string): string {
    return str.substring(1, str.length - 1);
  }
}
