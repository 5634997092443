<ngx-charts-chart [view]="[width, height]" [showLegend]="legend" [legendOptions]="legendOptions" [activeEntries]="activeEntries"
  [animations]="animations" (legendLabelActivate)="onActivate($event, undefined, true)"
  (legendLabelDeactivate)="onDeactivate($event, undefined, true)" (legendLabelClick)="onClick($event)">
  <ng-template #tooltipTemplate let-model="model">
    <pre>{{ model.tooltipText }}</pre>
  </ng-template>
  <svg:g [attr.transform]="transform" class="bar-chart chart">
    <svg:g ngx-charts-grid-panel-series [xScale]="valueMainScale" [yScale]="groupScale" [data]="results" [dims]="dims" orient="horizontal"></svg:g>
    @if (xAxis) {
      <svg:g ngx-charts-x-axis [xScale]="valueMainScale" [dims]="dims" [showGridLines]="showGridLines" [showLabel]="xMainAxisShowLabel"
        [labelText]="xMainAxisLabel" [trimTicks]="trimXAxisTicks" [rotateTicks]="rotateXAxisTicks" [maxTickLength]="maxXAxisTickLength"
        [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks" (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
    }
    @if (xAxis && dataSecondaryAxis.length) {
      <svg:g diagram-x-axis-custom [xOrient]="'top'" [xScale]="valueSecondaryScale" [dims]="dims" [showGridLines]="false"
        [showLabel]="xSecondaryAxisShowLabel" [labelText]="xSecondaryAxisLabel" [trimTicks]="trimXAxisTicks" [rotateTicks]="rotateXAxisTicks"
        [maxTickLength]="maxXAxisTickLength" [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks"
        (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
    }
    @if (yAxis) {
      <svg:g ngx-charts-y-axis [yScale]="groupScale" [dims]="dims" [showLabel]="showYAxisLabel" [labelText]="yAxisLabel" [trimTicks]="trimYAxisTicks"
        [maxTickLength]="maxYAxisTickLength" [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks" [yAxisOffset]="dataLabelMaxWidth.negative"
        (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    }

    @if (isSSR) {
      <ng-container>
        @for (group of results; track group.name; let index = $index) {
          <svg:g [attr.transform]="groupTransform(group)">
            <svg:g ngx-charts-series-horizontal [xScale]="valueMainScale" [activeEntries]="activeEntries" [yScale]="innerScale" [colors]="colors"
              [series]="group.series" [dims]="dims" [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate"
              [seriesName]="group.name" [roundEdges]="roundEdges" [animations]="animations" [showDataLabel]="showDataLabel"
              [dataLabelFormatting]="dataLabelFormatting" [noBarWhenZero]="noBarWhenZero" (select)="onClick($event, group)"
              (activate)="onActivate($event, group)" (deactivate)="onDeactivate($event, group)"
              (dataLabelWidthChanged)="onDataLabelMaxWidthChanged($event, index)"/>
          </svg:g>
        }
      </ng-container>
    } @else {
      @for (group of results; track group.name; let index = $index) {
        <svg:g [@animationState]="'active'" [attr.transform]="groupTransform(group)">
          <svg:g ngx-charts-series-horizontal [xScale]="valueMainScale" [activeEntries]="activeEntries" [yScale]="innerScale" [colors]="colors"
            [series]="group.series" [dims]="dims" [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate"
            [seriesName]="group.name" [roundEdges]="roundEdges" [animations]="animations" [showDataLabel]="showDataLabel"
            [dataLabelFormatting]="dataLabelFormatting" [noBarWhenZero]="noBarWhenZero" (select)="onClick($event, group)"
            (activate)="onActivate($event, group)" (deactivate)="onDeactivate($event, group)"
            (dataLabelWidthChanged)="onDataLabelMaxWidthChanged($event, index)"/>
        </svg:g>
      }
    }
  </svg:g>
</ngx-charts-chart>
