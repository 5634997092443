import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[hlHelperTextForms]'
})
export class HelperTextFormsDirective implements AfterViewInit {
  @Input()
  selector = 'textfield__input';
  helperSelector = 'form__helper-container';
  input: Element;
  helperText: Element;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.input = this.el.nativeElement.getElementsByClassName(this.selector)[0];
    if (this.input) {
      this.input.addEventListener('focus', () => {
        this.helperText = this.el.nativeElement.getElementsByClassName(this.helperSelector)[0];
        if (this.helperSelector) {
          this.showHelperText();
        }
      });

      this.input.addEventListener('blur', () => {
        this.helperText = this.el.nativeElement.getElementsByClassName(this.helperSelector)[0];
        if (this.helperSelector) {
          this.hideHelperText();
        }
      });
    }
  }

  showHelperText() {
    this.renderer.addClass(this.helperText, 'is-visible');
  }

  hideHelperText() {
    this.renderer.removeClass(this.helperText, 'is-visible');
  }
}
