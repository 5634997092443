import { HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheItem } from './cache-item';
import { restEndPoint } from '../../core-constants.service';

@Injectable({providedIn: 'root'})
export class HttpCacheService {

  private cachedRequests: Map<string, CacheItem> = new Map<string, CacheItem>();

  /**
   * clears cashed object for an individual request. Can also be used to clear
   * all requests matching a url string.
   *
   * @param {(HttpRequest | string)} url - url string
   */
  clearIndividualRequest(url: string): void {
    const urlWithoutParams = url.includes('?') ? url.slice(0, url.indexOf('?')) : url;
    this.cachedRequests.forEach(r => {
      const keyWithoutParams = r.cacheItemKey.includes('|') ? r.cacheItemKey.slice(0, r.cacheItemKey.indexOf('|')) : r.cacheItemKey;
      if (urlWithoutParams === keyWithoutParams) {
        this.cachedRequests.delete(r.cacheItemKey);
      }
    });
  }

  // Future method for removing items from cache if HttpParams are provided
  clearIndividualRequestWithParams(url: string, params?: HttpParams): void {
    this.cachedRequests.delete(CacheItem.generateKey(url, params));
  }

  /**
   * the method 'modifyBody' you are passing as a parameter should return the modified body - this
   * body will then replace the former body inside the cache
   *
   * @param {HttpRequest | string} request
   * @param {(toBeModified: HttpResponse<any>) => any} modifyBody
   */
  modifyEntry<T>(request: HttpRequest<any> | string, modifyBody: (toBeModified: HttpResponse<T>) => T): void {
    if (request instanceof HttpRequest) {
      const cachedResponse = this.cachedRequests.get(CacheItem.generateKey(request.url, request.params));
      if (cachedResponse) {
        const newBody = modifyBody(cachedResponse.response);
        this.replaceInCache(cachedResponse, newBody, request);
      }
    }
  }

  /**
   * clears the internal cache completely
   *
   * @memberof HttpCacheService
   */
  clearAll(): void {
    this.cachedRequests.forEach((request: CacheItem) => {
      this.removeCacheItemFromCacheIfFinished(request);
    });
  }

  /**
   * For specific URLs (ticket/activity detail) puts into the cache all responses
   * incl. error responses to avoid redundant SAP calls
   * @param request
   * @param response
   */
  cacheSapCallResponseOrErrorResponse(request: HttpRequest<any>, response: any) {
    const cachedUrls = [
      'activities\/.*details.*\?customerNumber',
      'tickets\/.*\?customerNumber.*&equipmentKey'];
    for (const cachedUrl of cachedUrls) {
      if (request.urlWithParams.match(restEndPoint + cachedUrl)) {
        this.putToMap(request.url, request.params, response);
      }
    }
  }

  /**
   * replaced the response with a cloned response with the new body
   * @param cachedResponse
   * @param newBody
   * @param {HttpRequest} request
   */
  private replaceInCache(cachedResponse: CacheItem, newBody: any, request: HttpRequest<any>) {
    const newResponse = cachedResponse.response.clone({
      body: newBody,
      headers: cachedResponse.response.headers,
      status: cachedResponse.response.status,
      statusText: cachedResponse.response.statusText,
      url: cachedResponse.response.url
    });
    this.putToMap(request.url, request.params, newResponse);
  }

  private removeCacheItemFromCacheIfFinished(request: CacheItem): void {
    if (request && request.isRequestFinished()) {
      this.cachedRequests.delete(request.cacheItemKey);
    }
  }

  /**
   * Save or load request from map
   */
  getFromMap(url: string, params?: HttpParams): CacheItem | null {
    return this.cachedRequests.get(CacheItem.generateKey(url, params)) || null;
  }

  putToMap(url: string, params?: HttpParams, response?: any): CacheItem {
    if (!url) {
      return;
    }
    const cachedRequestKey = CacheItem.generateKey(url, params);
    let cachedRequest = this.cachedRequests.get(cachedRequestKey);

    if (cachedRequest && response != null) {
      cachedRequest.cache(response);
    } else if (!cachedRequest) {
      cachedRequest = new CacheItem(url, params, response);
      this.cachedRequests.set(cachedRequestKey, cachedRequest);
    }
    return cachedRequest;
  }

  raiseError(url: string, params?: HttpParams, error?: any) {
    const cachedRequestKey = CacheItem.generateKey(url, params);

    this.cachedRequests.get(cachedRequestKey).raiseResponseSubjectError(error);
  }

  replaceResponseValueInMap(url: string, params?: HttpParams, response?: any) {
    const cachedRequestKey = CacheItem.generateKey(url, params);
    const cachedRequest = this.cachedRequests.get(cachedRequestKey);

    if (cachedRequest && response != null) {
      cachedRequest.replaceCached(response);
    }
  }
}
