import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { area, line } from 'd3-shape';

import { BarOrientation, ColorHelper, Gradient, id, ScaleType, Series, sortByDomain, sortByTime, sortLinear } from '@swimlane/tpf-ngx-charts';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'g[hl-charts-line-series]',
  template: `
    <svg:g>
      <defs>
        @if (hasGradient) {
          <svg:g
            ngx-charts-svg-linear-gradient
            [orientation]="barOrientation.Vertical"
            [name]="gradientId"
            [stops]="gradientStops"
          />
        }
      </defs>
      @if (!isSSR) {
        <svg:g
          ngx-charts-area
          class="line-highlight"
          [data]="data"
          [path]="areaPath"
          [fill]="hasGradient ? gradientUrl : colors.getColor(data.name)"
          [opacity]="0.25"
          [startOpacity]="0"
          [gradient]="true"
          [stops]="areaGradientStops"
          [class.active]="isActive(data)"
          [class.inactive]="isInactive(data)"
          [animations]="animations"
        />
      }
      <svg:g
        ngx-charts-line
        class="line-series"
        [data]="data"
        [path]="path"
        [stroke]="stroke"
        [animations]="animations"
        [class.active]="isActive(data)"
        [class.inactive]="isInactive(data)"
      />
      @if (hasRange) {
        <svg:g
          ngx-charts-area
          class="line-series-range"
          [data]="data"
          [path]="outerPath"
          [fill]="hasGradient ? gradientUrl : colors.getColor(data.name)"
          [class.active]="isActive(data)"
          [class.inactive]="isInactive(data)"
          [opacity]="rangeFillOpacity"
          [animations]="animations"
        />
      }
      </svg:g>
      @for (data of this.edgePoints; track data; let i = $index) {
        @if (data.value || data.value === 0) {
          <svg:g ngx-charts-circle
            class="circle"
            [cx]="getCx(data.name)"
            [cy]="this.yScale(data.value)"
            [r]="5"
            [fill]="getColor(data.value)"
            [class.active]="true"
            [classNames]="'circle-data{{i}}'"/>
        }
      }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineSeriesComponent implements OnChanges {
  @Input() data: Series;
  @Input() xScale;
  @Input() yScale;
  @Input() colors: ColorHelper;
  @Input() scaleType: ScaleType;
  @Input() curve: any;
  @Input() activeEntries: any[];
  @Input() rangeFillOpacity: number;
  @Input() hasRange: boolean;
  @Input() animations = true;

  path: string;
  outerPath: string;
  areaPath: string;
  gradientId: string;
  gradientUrl: string;
  hasGradient: boolean;
  gradientStops: Gradient[];
  areaGradientStops: Gradient[];
  stroke: string;
  edgePoints;

  barOrientation = BarOrientation;
  isSSR: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object) {
    this.isSSR = isPlatformServer(platformId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(): void {
    this.updateGradients();

    const data = this.sortData(this.data.series);
    this.edgePoints = this.extractEdgePoints(data);

    const lineGen = this.getLineGenerator();
    this.path = lineGen(data) || '';

    const areaGen = this.getAreaGenerator();
    this.areaPath = areaGen(data) || '';

    if (this.hasRange) {
      const range = this.getRangeGenerator();
      this.outerPath = range(data) || '';
    }

    if (this.hasGradient) {
      this.stroke = this.gradientUrl;
      const values = this.data.series.map(d => d.value);
      const max = Math.max(...values);
      const min = Math.min(...values);
      if (max === min) {
        this.stroke = this.colors.getColor(max);
      }
    } else {
      this.stroke = this.colors.getColor(this.data.name);
    }
  }

  extractEdgePoints(data) {
    if (data.length === 1 && data[0].value !== null && data[0].value !== undefined) {
      return [data[0]];
    }
    const pointSer = [];
    data.forEach((item, index) => {
      if ((item.value === null || item.value === undefined) && index > 0) {
        if ((data[index - 1].value !== null && data[index - 1].value !== undefined) && !pointSer.includes(data[index - 1])) {
          pointSer.push(data[index - 1]);
        }
        if (index < data.length - 1 && (data[index + 1].value !== null && data[index + 1].value !== undefined)
          && !pointSer.includes(data[index + 1])) {
          pointSer.push(data[index + 1]);
        }
      }
    });
    return pointSer;
  }

  getLineGenerator(): any {
    return line<any>()
      .x(d => this.applyXScale(d))
      .y(d => this.yScale(d.value))
      .curve(this.curve)
      .defined(d => d.value !== undefined && d.value !== null);
  }

  private applyXScale(d) {
    const label = d.name;
    let value;
    if (this.scaleType === ScaleType.Time) {
      value = this.xScale(label);
    } else if (this.scaleType === ScaleType.Linear) {
      value = this.xScale(Number(label));
    } else {
      value = this.xScale(label);
    }
    return value;
  }

  getRangeGenerator(): any {
    return area<any>()
      .x(d => this.applyXScale(d))
      .y0(d => this.yScale(typeof d.min === 'number' ? d.min : d.value))
      .y1(d => this.yScale(typeof d.max === 'number' ? d.max : d.value))
      .curve(this.curve)
      .defined(d => d.value !== undefined && d.value !== null);
  }

  getAreaGenerator(): any {
    const xProperty = d => {
      const label = d.name;
      return this.xScale(label);
    };

    return area<any>()
      .x(xProperty)
      .y0(() => this.yScale.range()[0])
      .y1(d => this.yScale(d.value))
      .curve(this.curve)
      .defined(d => d.value !== undefined && d.value !== null);
  }

  sortData(data) {
    if (this.scaleType === ScaleType.Linear) {
      data = sortLinear(data, 'name');
    } else if (this.scaleType === ScaleType.Time) {
      data = sortByTime(data, 'name');
    } else {
      data = sortByDomain(data, 'name', 'asc', this.xScale.domain());
    }

    return data;
  }

  updateGradients() {
    if (this.colors.scaleType === ScaleType.Linear) {
      this.hasGradient = true;
      this.gradientId = 'grad' + id().toString();
      this.gradientUrl = `url(#${this.gradientId})`;
      const values = this.data.series.map(d => d.value);
      const max = Math.max(...values);
      const min = Math.min(...values);
      this.gradientStops = this.colors.getLinearGradientStops(max, min);
      this.areaGradientStops = this.colors.getLinearGradientStops(max);
    } else {
      this.hasGradient = false;
      this.gradientStops = undefined;
      this.areaGradientStops = undefined;
    }
  }

  isActive(entry): boolean {
    if (!this.activeEntries) {
      return false;
    }
    const item = this.activeEntries.find(d => {
      return entry.name === d.name;
    });
    return item !== undefined;
  }

  isInactive(entry): boolean {
    if (!this.activeEntries || this.activeEntries.length === 0) {
      return false;
    }
    const item = this.activeEntries.find(d => {
      return entry.name === d.name;
    });
    return item === undefined;
  }

  getColor(value) {
    if (this.colors.scaleType === ScaleType.Linear) {
      return this.colors.getColor(value);
    } else {
      return this.colors.getColor(this.data.name);
    }
  }

  getCx(label: string) {
    let cx;
    if (this.scaleType === ScaleType.Time) {
      cx = this.xScale(label);
    } else if (this.scaleType === ScaleType.Linear) {
      cx = this.xScale(Number(label));
    } else {
      cx = this.xScale(label);
    }
    return cx;
  }
}
