export interface DataDiagram {
  name: string;
  value: number;
  color: string;
  colorMonochrome?: string;
  routerLink: string;
  queryParams: object;
  valueUnit?: string;
  tooltipValue?: number;
}

export interface GeneratedDataDiagram {
  data: {
    name: string;
    value: string;
  };
}

export interface SeriesData {
  name: any;
  value: number | any;
  kpiName?: string;
  kpiId?: number;
  secondAxis?: boolean;
  additionalData?: number;
}

export interface Series {
  name: any;
  series: SeriesData[];
  equipmentKey?: string;
  secondAxis?: boolean;
}

export const NOT_AVAILABLE = 'LABEL_REPORTING_NOT_AVAILABLE';
export const DURATION_UNIT_HOUR = 'GENERIC_LABEL_HOUR';
export const DURATION_UNIT_HOURS = 'GENERIC_LABEL_HOURS';
export const DURATION_UNIT_PERCENTAGE = 'LABEL_DURATION_PERCENTAGE';
export const DURATION_UNIT_DAY = 'GENERIC_LABEL_DAY';
export const DURATION_UNIT_DAYS = 'GENERIC_LABEL_DAYS';
export const DURATION_UNIT_MINUTE = 'GENERIC_LABEL_MINUTE';
export const DURATION_UNIT_MINUTES = 'GENERIC_LABEL_MINUTES';
