<div class="selectbox nested-selectbox" (click)="dropdownToggle()" [class.is-disabled]="isDisabled" [class.is-open]="isDropdownOpen"
  data-cy="spr-widget-nested-selectbox" [class.has-focus]="isDropdownOpen" [class.is-active]="isActive" hlTooltip
  [isActive]="tooltipSelected && !isDropdownOpen" [title]="tooltipSelected" [documentOverlay]=documentOverlay
  [class.display-block]="setCssDisplayBlock">
  <label class="selectbox__label">
    <span>{{ label | translate }}</span>
    @if (isRequired) {
      <span>*</span>
    }
  </label>
  <div class="multi-selectbox__label">
    <span data-cy="spr-widget-nested-selectbox-title" [class.font-style-italic]="_valueObject?.cursive">{{ _valueObject?.title | translate }}</span>
  </div>
  @if (invalidLabel && isInvalid) {
    <small class="selectbox__hint selectbox__hint--validation" [translate]="invalidLabel"></small>
  }
  @if (!isDisabled) {
    <ul class="menu dropdown__panel selectbox__option-list col-12">
      @if (enableSearch) {
        <li class="menu__item multi-selectbox__option nested-selectbox__option nested-selectbox__option-no-padding nested-selectbox__option-search"
          (click)="$event.stopPropagation()" data-cy="search_field">
          <hl-search-field class="width-100" searchFieldClasses="textfield--large" [(searchInput)]="searchValue"
            (searchInputChange)="filterOptions($event)"></hl-search-field>
        </li>
      }
      <hl-nested-selectbox-tree [enableCollapsing]="enableCollapsing" [scrollOnExpand]="scrollOnExpand" [options]="options"
        [selectedOption]="_valueObject" (superScrollEvent)="expandScroll($event)" (superSelectedEvent)="acceptSetSelected($event)"
        data-cy="nested-selectbox-tree"></hl-nested-selectbox-tree>
    </ul>
  }
</div>
