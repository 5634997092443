import { Pipe, PipeTransform } from '@angular/core';
import { trimStart } from 'lodash-es';

@Pipe({
  name: 'removeLeadingZero'
})
export class RemoveLeadingZeroPipe implements PipeTransform {

  transform(value: any): any {
    return trimStart(value, '0');
  }

}
