<hl-tabs [ngClass]="{'filter-panel__tabs': !isMyInstitutions}" [isMyInstitutions]="isMyInstitutions">
  <a class="tabs__item" [class.is-active]="selectedFilterOption === FILTER_OPTION_FILTER" [translate]="'FILTER'"
    (click)="this.selectedFilterOption = FILTER_OPTION_FILTER"></a>
  <a class="tabs__item" [class.is-active]="selectedFilterOption === FILTER_OPTION_SAVED_FILTERS"
    (click)="this.selectedFilterOption = FILTER_OPTION_SAVED_FILTERS" data-cy="saved-filter-tab">{{
      'FAVORITE_TITLE' | translate
    }}
    @if (numberOfFavorites()) {
      <span class="label bg-color-black label--superscript">{{ numberOfFavorites() }}</span>
    }
  </a>
</hl-tabs>

<div>
  @switch (selectedFilterOption) {
    @case (FILTER_OPTION_FILTER) {
      <div>
        <ng-content select="[main-filter]"></ng-content>
      </div>
    }
    @case (FILTER_OPTION_SAVED_FILTERS) {
      <div>
        <ng-content select="[saved-filters]"></ng-content>
      </div>
    }
  }
</div>
