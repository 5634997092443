import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCountryPrefix'
})
export class RemoveCountryPrefixPipe implements PipeTransform {

  transform(text: string): string {
    if (text) {
      return text.replace(/^X?[A-Z]{2}_/, '');
    }
    return text;
  }
}
