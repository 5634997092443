import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { formatLabel } from '@swimlane/tpf-ngx-charts';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'g[ngx-combo-charts-series-vertical]',
  template: `
    @for (bar of bars; track bar.label) {
      @if (bar.value !== null) {
        <svg:g ngx-charts-bar [@animationState]="'active'" [width]="bar.width" [height]="bar.height"
          [x]="bar.x" [y]="bar.y" [fill]="bar.color" [stops]="bar.gradientStops" [data]="bar.data" [orientation]="'vertical'"
          [roundEdges]="bar.roundEdges" [gradient]="gradient" [isActive]="isActive(bar.data)" [animations]="animations"
          (select)="onClick($event)" (activate)="activate.emit($event)" (deactivate)="deactivate.emit($event)" ngx-tooltip
          [tooltipDisabled]="tooltipDisabled" [tooltipPlacement]="'top'" [tooltipType]="'tooltip'" [tooltipTitle]="bar.tooltipText"></svg:g>
        }
      }
    `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition('* => void', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({opacity: 0, transform: 'scale(0)'}))
      ])
    ])
  ]
})
export class DiagramComboVerticalComponent implements OnChanges {

  @Input() dims;
  @Input() type = 'standard';
  @Input() series;
  @Input() seriesLine: any[];
  @Input() xScale;
  @Input() yScale;
  @Input() colors;
  @Input() tooltipDisabled = false;
  @Input() gradient: boolean;
  @Input() activeEntries: any[];
  @Input() seriesName: string;
  @Input() animations = true;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();
  @Output() bandwidth = new EventEmitter();

  bars: any;
  x: any;
  y: any;

  ngOnChanges(changes): void {
    this.update();
  }

  update(): void {
    let width;
    if (this.series.length) {
      width = this.xScale.bandwidth();
      this.bandwidth.emit(width);
    }

    let d0 = 0;
    let total;
    if (this.type === 'normalized') {
      total = this.series.map(d => d.value).reduce((sum, d) => sum + d, 0);
    }

    this.bars = this.series.map((d, index) => {

      let value = d.value;
      const label = d.name;
      const formattedLabel = formatLabel(label);
      const roundEdges = false;

      const bar: any = {
        value,
        label,
        roundEdges,
        data: d,
        width,
        formattedLabel,
        height: 0,
        x: 0,
        y: 0
      };

      if (this.type === 'standard') {
        this.setDataForStandardType(bar, value, label);
      } else if (this.type === 'stacked') {
        d0 = this.setDataForStackedType(d0, value, bar);
      } else if (this.type === 'normalized') {
        const __ret = this.setDataForNormalizedType(d0, value, total, bar);
        d0 = __ret.d0;
        value = __ret.value;
      }

      if (this.colors.scaleType === 'ordinal') {
        bar.color = this.colors.getColor(label);
      } else {
        if (this.type === 'standard') {
          bar.color = this.colors.getColor(value);
          bar.gradientStops = this.colors.getLinearGradientStops(value);
        } else {
          bar.color = this.colors.getColor(bar.offset1);
          bar.gradientStops = this.colors.getLinearGradientStops(bar.offset1, bar.offset0);
        }
      }
      this.setTooltip(formattedLabel, index, bar, value);

      return bar;
    });
  }

  private setDataForStandardType(bar: any, value, label) {
    bar.height = Math.abs(this.yScale(value) - this.yScale(0));
    bar.x = this.xScale(label);

    if (value < 0) {
      bar.y = this.yScale(0);
    } else {
      bar.y = this.yScale(value);
    }
  }

  private setDataForStackedType(d0: number, value, bar: any) {
    const offset0 = d0;
    const offset1 = offset0 + value;
    d0 += value;

    bar.height = this.yScale(offset0) - this.yScale(offset1);
    bar.x = 0;
    bar.y = this.yScale(offset1);
    bar.offset0 = offset0;
    bar.offset1 = offset1;
    return d0;
  }

  private setDataForNormalizedType(d0: number, value, total, bar: any) {
    let offset0 = d0;
    let offset1 = offset0 + value;
    d0 += value;

    if (total > 0) {
      offset0 = (offset0 * 100) / total;
      offset1 = (offset1 * 100) / total;
    } else {
      offset0 = 0;
      offset1 = 0;
    }

    bar.height = this.yScale(offset0) - this.yScale(offset1);
    bar.x = 0;
    bar.y = this.yScale(offset1);
    bar.offset0 = offset0;
    bar.offset1 = offset1;
    value = (offset1 - offset0).toFixed(2) + '%';
    return {d0, value};
  }

  private setTooltip(formattedLabel: string, index, bar: any, value) {
    let tooltipLabel = formattedLabel;
    if (this.seriesName) {
      tooltipLabel = `${this.seriesName} • ${formattedLabel}`;
    }

    if (this.seriesLine && this.seriesLine.length > 0) {
      const lineValue = this.seriesLine[0].series[index].value;
      bar.tooltipText = `<span class="tooltip-label">${tooltipLabel}</span>`;
      bar.tooltipText += value === null ? '' :
        `<span class="tooltip-val"> Y1 - ${value.toLocaleString()} • Y2 - ${lineValue.toLocaleString()}%</span>`;
    } else {
      bar.tooltipText = `<span class="tooltip-label">${tooltipLabel}</span>`;
      bar.tooltipText += value === null ? '' : `<span class="tooltip-val"> Y1 - ${value.toLocaleString()}</span>`;
    }
  }

  isActive(entry): boolean {
    if (!this.activeEntries) {
      return false;
    }
    const item = this.activeEntries.find(d => {
      return entry.name === d.name && entry.series === d.series;
    });
    return item !== undefined;
  }

  onClick(data): void {
    this.select.emit(data);
  }
}
