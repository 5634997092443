<div class="dropdown dropdown--right" [class.is-open]="isDropdownVisible">
  <span class="dropdown__toggle-button font-size-xsmall" (click)="showDropdown()" [attr.data-cy]="dataCy + '-list-sorter'">
    {{ sortByLabel | translate }}
    <i class="icon-dropdown"></i>
  </span>

  <div class="menu dropdown__panel font-size-xsmall" [(ngModel)]="options.sortObject.sortBy" ngDefaultControl
    [attr.data-cy]="dataCy + '-list-sorter-dropdown'">
    @for (option of options.items; track option; let i = $index) {
      <div (click)="onChangeOption(option)" class="menu__item" [class.is-selected]="option.value === options.sortObject.sortBy"
        [attr.data-cy]="'option-' + (i+1) + '-' + dataCy">{{ option.title | translate }}
      </div>
    }
    <div class="menu__divider"></div>
    <div (click)="sortAscending()" class="menu__item" [class.is-selected]="isAscending()">
      <i class="icon-double-arrow-up-light"></i> <span translate="GENERIC_LABEL_FILTERUP"></span>
    </div>
    <div (click)="sortDescending()" class="menu__item" [class.is-selected]="!isAscending()">
      <i class="icon-double-arrow-down-light"></i> <span translate="GENERIC_LABEL_FILTERDOWN"></span>
    </div>
  </div>
</div>
