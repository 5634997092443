import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Orientation, ViewDimensions, YAxisTicksComponent } from '@swimlane/tpf-ngx-charts';

@Component({
  selector: 'g[charts-y-axis-ssr]',
  template: `
    <svg:g [attr.class]="yAxisClassName" [attr.transform]="transform">
      @if (yScale) {
        <svg:g ngx-charts-y-axis-ticks [trimTicks]="trimTicks"
          [maxTickLength]="maxTickLength" [tickFormatting]="tickFormatting"
          [tickArguments]="tickArguments" [tickValues]="ticks" [tickStroke]="tickStroke"
          [scale]="yScale" [orient]="yOrient" [showGridLines]="showGridLines"
          [gridLineWidth]="dims.width" [referenceLines]="referenceLines" [showRefLines]="showRefLines"
          [showRefLabels]="showRefLabels" [height]="dims.height"
          (dimensionsChanged)="emitTicksWidth($event)"/>
      }

      @if (showLabel) {
        <svg:g ngx-charts-axis-label [label]="labelText" [offset]="labelOffset"
          [orient]="yOrient" [height]="dims.height" [width]="dims.width"></svg:g>
        }
        </svg:g>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramYAxisSsrComponent implements OnChanges {
  @Input() yScale;
  @Input() dims: ViewDimensions;
  @Input() trimTicks: boolean;
  @Input() maxTickLength: number;
  @Input() tickFormatting;
  @Input() ticks: any[];
  @Input() showGridLines = false;
  @Input() showLabel: boolean;
  @Input() labelText: string;
  @Input() yAxisTickCount: any;
  @Input() yOrient: Orientation = Orientation.Left;
  @Input() referenceLines;
  @Input() showRefLines: boolean;
  @Input() showRefLabels: boolean;
  @Input() yAxisOffset = 0;
  @Output() dimensionsChanged = new EventEmitter();

  yAxisClassName = 'y axis';
  tickArguments: number[];
  offset: number;
  transform: string;
  labelOffset = 15;
  tickStroke = '#CCC';
  padding = 5;

  @ViewChild(YAxisTicksComponent) ticksComponent: YAxisTicksComponent;

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(): void {
    this.offset = -(this.yAxisOffset + this.padding);
    if (this.yOrient === Orientation.Right) {
      this.labelOffset = 5;
      this.transform = `translate(${this.offset + this.dims.width} , 0)`;
    } else {
      this.transform = `translate(${this.offset} , 0)`;
    }

    if (this.yAxisTickCount !== undefined) {
      this.tickArguments = [this.yAxisTickCount];
    }
  }

  emitTicksWidth({width}): void {
    if (width !== this.labelOffset && this.yOrient === Orientation.Right) {
      this.labelOffset = width + this.labelOffset;
      setTimeout(() => {
        this.dimensionsChanged.emit({width});
      }, 0);
    } else if (width !== this.labelOffset) {
      this.labelOffset = width;
      setTimeout(() => {
        this.dimensionsChanged.emit({width});
      }, 0);
    }
  }
}
