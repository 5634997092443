import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class DomUtilService {

  /*
  (hopefully) temporary workaround for showing a text properly for Vietnam users
  SH fonts are currently not fully supported for Vietnam
  so they are suppressed by other fonts
   */
  static replaceUnsupportedFont(language: string) {
    const STYLE_ID = 'replacedFontStyle';
    const UNSUPPORTED_LANGUAGE = 'vi';
    if (language === UNSUPPORTED_LANGUAGE && !document.getElementById(STYLE_ID)) {
      const sheet = document.createElement('style');
      sheet.id = STYLE_ID;
      sheet.innerHTML = 'blockquote,blockquote p,.navigation-bar__link,.inpage-navigation__link,h1,.h1,h2,.h2,h3,.h3,h4,.h4 {font-family: Calibri,Open Sans,Roboto,Droid Sans,-apple-system,BlinkMacSystemFont,sans-serif; font-weight: 700}';
      document.body.appendChild(sheet);
    } else {
      const sheet = document.getElementById(STYLE_ID);
      if (sheet !== null) {
        const sheetParent = sheet.parentNode;
        sheetParent.removeChild(sheet);
      }
    }
  }

  outerWidthWithMargin(el): number {
    if (el) {
      let width = el.offsetWidth;
      const style = getComputedStyle(el);

      if (!el.classList.contains('hidden')) {
        width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      }
      return width;
    } else {
      return 0;
    }
  }
}
