import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class UpdateTicketEventService {

  // Observable updateTicket sources
  private updateTicketSource = new Subject<void>();

  // Observable updateTicket streams
  updateTicketSource$ = this.updateTicketSource.asObservable();

  // Service updateTicket -> (ticket update or ticket close)
  emitTicketUpdated() {
    this.updateTicketSource.next();
  }

}
