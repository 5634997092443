import { Injectable } from '@angular/core';

const getWindow = (): any => window;

@Injectable({providedIn: 'root'})
export class WindowService {

  consoleLogEnabled = false;
  WINDOW_POPUPS_ALLOWED = 'popups_allowed';

  get nativeWindow(): any {
    return getWindow();
  }

  get hostname(): string {
    return this.nativeWindow.location.hostname;
  }

  get protocol(): string {
    return this.nativeWindow.location.protocol;
  }

  setPopupsAllowed(allowed: boolean) {
    this.nativeWindow[this.WINDOW_POPUPS_ALLOWED] = allowed;
  }
}
