import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SortSkeleton } from '../../sorting/sort-skeleton';
import { EquipmentDropdownDirective } from '../../../equipment/equipment-dropdown.directive';
import { NonEquipmentTemplateDirective } from '../../directives/non-equipment-template/non-equipment-template.directive';
import { ButtonWithSpinner } from '../button-with-spinner/button-with-spinner.component';

@Component({
  selector: 'hl-list',
  templateUrl: './list.component.html'
})
export class ListComponent<TItem> {
  @Input()
  items: TItem[];
  @Input()
  isLoaded: boolean;
  @Input()
  label: string;
  @Input()
  empty: string;
  @Input()
  isCounterAbove = false;
  @Input()
  additionalCollectionClass: string;
  @Input()
  dataCyInput: string;
  @Input()
  withBorder = true;
  @Input()
  isEquipmentList = false;

  @ContentChild(EquipmentDropdownDirective) dropdownDirective!: EquipmentDropdownDirective;
  @ContentChild(NonEquipmentTemplateDirective) nonEqDirective!: NonEquipmentTemplateDirective;
  @ContentChild('header') headerTemplate: TemplateRef<any>|null;
  @ContentChild('header_mobile') headerMobileTemplate: TemplateRef<any>|null;

  @ViewChild('createUploadModal')
  createUploadModal;

  @Input()
  sortConfig: any = {sortObject: {}};
  @Input()
  downloadConfig: any;

  @ContentChild(TemplateRef)
  itemTemplate;
  @Output()
  onSortOptionChange = new EventEmitter<SortSkeleton>();
  @Input()
  trackByFunction;
  @Input()
  filteredLengthWithoutPagination = 0;

  @Input()
  showUploadButton = false;

  @Input()
  onExportGenerate: (exportAll: boolean) => any[];
  @Input() buttonWithSpinner: ButtonWithSpinner;

  @Output() buttonWithSpinnerClickEvent =  new EventEmitter();

  emitSortChanges(options: SortSkeleton) {
    this.onSortOptionChange.emit(options);
  }
}
