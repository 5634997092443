import { Pipe, PipeTransform } from '@angular/core';
import { contractAvailability } from '../../../core/core-constants.service';
import { filter, includes, isEmpty } from 'lodash-es';

@Pipe({
  name: 'contractAvailability'
})
export class ContractAvailabilityPipe implements PipeTransform {

  transform(dataset: any, selectedValue: string): any {
    let filtered = dataset;

    if (includes(selectedValue, contractAvailability.contractAvailabilityYes)
      && includes(selectedValue, contractAvailability.contractAvailabilityNo)) {
      return filtered;
    }

    if (includes(selectedValue, contractAvailability.contractAvailabilityYes)) {
      filtered = filter(filtered, (item) => {
        return !isEmpty(item['contractType']);
      });
    }

    if (includes(selectedValue, contractAvailability.contractAvailabilityNo)) {
      filtered = filter(filtered, (item) => {
        return isEmpty(item['contractType']);
      });
    }
    return filtered;
  }
}
