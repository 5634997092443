import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[hlEquipmentDropdown]'
})
export class EquipmentDropdownDirective {

  constructor(public templateRef: TemplateRef<unknown>) { }

}
