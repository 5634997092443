import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NestedDropdownOption } from '../../../core/models/dropdown-options';

@Component({
  selector: 'hl-nested-multi-selectbox-tree',
  templateUrl: './nested-multi-selectbox-tree.component.html'
})
export class NestedMultiSelectboxTreeComponent {
  @Input()
  options: NestedDropdownOption[];

  @Input()
  translateKeys = false;

  @Output()
  superSelectedEvent: EventEmitter<NestedDropdownOption[]> = new EventEmitter();

  @Output()
  superScrollEvent: EventEmitter<number> = new EventEmitter();

  @Input() showIcon: boolean;
  @Input() chainShrinking = true;
  @Input() scrollOnExpand;
  @Input() dataCy = '';

  toggleExpand = (event: Event, option: NestedDropdownOption) => {
    event.preventDefault();
    event.stopPropagation();
    if (option.disabled) {
      return;
    }

    option.isExpanded = !option.isExpanded;

    if (!option.isExpanded && this.chainShrinking) {
      this.shrinkChildren(option);
    }

    if (option.isExpanded && this.scrollOnExpand) {
      setTimeout(() => this.superScrollEvent.emit((event.target as HTMLElement).parentElement.offsetTop), 5);
    }
  }

  private shrinkChildren(option: NestedDropdownOption) {
    if (option.children) {
      option.children.forEach(child => {
        child.isExpanded = false;
        this.shrinkChildren(child);
      });
    }
  }

  toggleSelected = (event: Event, option: NestedDropdownOption, path?: NestedDropdownOption[]) => {
    this.stopPropagateEvent(event);

    if (!option || option.disabled) {
      return;
    }

    if (path) {
      path.unshift(option);
      this.superSelectedEvent.emit(path);
    } else {
      this.superSelectedEvent.emit([option]);
    }
  }

  stopPropagateEvent = (event: Event) => {
    if (event) {
      event.stopPropagation();
    }
  }
}
