import { Injectable } from '@angular/core';
import { configRestEndPoint, translationPath, translationRestApi } from './core/core-constants.service';
import { CacheableHttpClient } from './core/services/cache/cacheable-http-client';
import { fallBackLocale, fallBackLocaleFromAssets, languageFilePrefix } from './core/languages';

interface TranslationPathLoader {
  translation: string;
  suffix: string;
}

@Injectable({providedIn: 'root'})
export class ConfigLoaderService {

  isConfigServerAvailable = true;

  constructor(private http: CacheableHttpClient) {
  }

  load(): Promise<TranslationPathLoader> {
    return new Promise((resolve) => {
      this.http.get<{ status: string }>(configRestEndPoint)
        .subscribe({
          next: ({status}) => {
            resolve(status === 'UP' ? this.useRestApi() : this.useLocalAssets());
          },
          error: () => {
            resolve(this.useLocalAssets());
          }
        });
    });
  }

  private useRestApi(): TranslationPathLoader {
    this.isConfigServerAvailable = true;
    return {
      translation: translationRestApi,
      suffix: ''
    };
  }

  private useLocalAssets(): TranslationPathLoader {
    this.isConfigServerAvailable = false;
    return {
      translation: translationPath,
      suffix: '.json'
    };
  }

  getTranslateFile(langCountryCode: string): string {
    return !this.isConfigServerAvailable ? languageFilePrefix + langCountryCode : langCountryCode;
  }

  getDefaultTranslateFile(): string {
    return this.isConfigServerAvailable ? fallBackLocale : fallBackLocaleFromAssets;
  }
}
