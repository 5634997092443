import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SortDirection } from '../../sorting/sort-object';
import { SortSkeleton } from '../../sorting/sort-skeleton';
import { SortOption } from '../../sorting/sort-option';

@Component({
  selector: 'hl-list-sorter',
  templateUrl: './list-sorter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSorterComponent {

  @Input() dataCy: string;
  @Input() disabled = false;
  isDropdownVisible = false;
  sortByLabel = 'GENERIC_LABEL_SORT_BY';
  @Output() onOptionChange = new EventEmitter<SortSkeleton>();

  constructor(private elementRef: ElementRef) {
  }

  // options for sort list
  private _options: SortSkeleton;

  get options() {
    return this._options;
  }

  @Input() set options(value) {
    this.sortByLabel = 'GENERIC_LABEL_SORT_BY';
    this._options = value;
    if (value.sortObject.sortBy) {
      this.sortByLabel = this.getItem(value.sortObject.sortBy).title;
    }
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownVisible && !this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownVisible = false;
    }
  }

  onChangeOption(option: any) {
    this._options.sortObject.sortBy = option.value;
    this._options.sortObject.thenSortBy = this.getItem(option.value).thenSortBy;
    this.isDropdownVisible = false;
    this.sortByLabel = option.title;
    this.emitOptionChange();
  }

  emitOptionChange() {
    this.onOptionChange.emit(this._options);
  }

  showDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  sortAscending() {
    this._options.sortObject.sortDir = SortDirection.ASC;
    this.isDropdownVisible = false;
    this.emitOptionChange();
  }

  sortDescending() {
    this._options.sortObject.sortDir = SortDirection.DESC;
    this.isDropdownVisible = false;
    this.emitOptionChange();
  }

  isAscending(): boolean {
    return this._options.sortObject.sortDir === SortDirection.ASC;
  }

  private getItem(value: string): SortOption {
    return this._options.items.find(item => item.value === value);
  }
}
