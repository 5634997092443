import { Toast } from '../../models/toast';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToasterService {
  // Observable toast sources
  private toastSource = new Subject<Toast>();

  // Observable toast streams
  toastSource$ = this.toastSource.asObservable();

  emitFrontendGenericErrorMessage() {
    this.emitToast({
      type: 'error',
      isBodyTranslationKey: true,
      body: 'FRONTEND_GENERIC_ERROR_MESSAGE'
    });
  }

  showTranslatedSuccessToaster(body: string) {
    this.emitToast({ type: 'success', isBodyTranslationKey: true, body });
  }

  showNotTranslatedSuccessToaster(body: string) {
    this.emitToast({ type: 'success', isBodyTranslationKey: false, body });
  }

  showTranslatedErrorToaster(body: string) {
    this.emitToast({ type: 'error', isBodyTranslationKey: true, body })
  }

  showNotTranslatedErrorToaster(body: string) {
    this.emitToast({ type: 'error', isBodyTranslationKey: false, body });
  }

  emitToast(toast: Toast) {
    this.toastSource.next(toast);
  }
}
