import { Equipment } from '../../models/equipment/equipment';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class AddRemoveEquipmentEventService {

  // Observable addRemoveEquipment sources
  private addRemoveEquipmentSource = new Subject<Equipment>();

  // Observable addRemoveEquipment streams
  addRemoveEquipmentSource$ = this.addRemoveEquipmentSource.asObservable();

  // Service addRemoveEquipment commands
  emitEquipmentAddedOrRemoved(equipment: Equipment) {
    this.addRemoveEquipmentSource.next(equipment);
  }

}
