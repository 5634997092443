import { Pipe, PipeTransform } from '@angular/core';
import { SubTypeOfPreliminaryInvoices } from '../../../invoices/type-of-invoices';

const statusLabel = new Map<string, string>([
  [SubTypeOfPreliminaryInvoices.UNVIEWED, 'INVOICE_STATUS_LABEL_UNVIEWED'],
  [SubTypeOfPreliminaryInvoices.UNSUBMITTED, 'INVOICE_STATUS_LABEL_UNSUBMITTED'],
  [SubTypeOfPreliminaryInvoices.SUBMITTED, 'INVOICE_STATUS_LABEL_SUBMITTED']
]);

@Pipe({
  name: 'preliminaryInvoiceStatusLabelPipe'
})
export class PreliminaryInvoiceStatusLabelPipe implements PipeTransform {

  transform(preliminaryInvoiceStatus: string): string {
    return statusLabel.get(preliminaryInvoiceStatus);
  }
}
