import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkify-html';

const options = { defaultProtocol: 'https', className: 'button--link', target: '_blank' };
const fleetLinkIcon = ' <i class="icon-arrow"></i></a>';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return linkifyHtml(value, options).replace(/<\/a>/g, fleetLinkIcon);
  }

}
