import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Bar, BarOrientation, ColorHelper, D0Types, DataItem, escapeLabel, formatLabel, PlacementTypes, StringOrNumberOrDate, StyleTypes } from '@swimlane/tpf-ngx-charts';
import { ExtendedDataItem } from '../common/common-models';

@Component({
  selector: 'g[ln-charts-guardian-range-series]',
  template: `
    @if (animations) {
      <svg:g>
        @for (bar of bars; track bar.label) {
          <svg:g
            ngx-charts-bar
            [@animationState]="'active'"
            [@.disabled]="!animations"
            [width]="bar.width"
            [height]="bar.height"
            [x]="bar.x"
            [y]="bar.y"
            [fill]="bar.color"
            [stops]="bar.gradientStops"
            [data]="bar.data"
            [orientation]="barOrientation.Vertical"
            [roundEdges]="bar.roundEdges"
            [gradient]="gradient"
            [ariaLabel]="bar.ariaLabel"
            [isActive]="isActive(bar.data)"
            (select)="onClick($event)"
            (activate)="activate.emit($event)"
            (deactivate)="deactivate.emit($event)"
            ngx-tooltip
            [tooltipDisabled]="tooltipDisabled"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipType]="tooltipType"
            [tooltipTitle]="tooltipTemplate ? undefined : bar.tooltipText"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipContext]="bar.data"
            [noBarWhenZero]="noBarWhenZero"
            [animations]="animations"
          ></svg:g>
        }
      </svg:g>
    }
    @if (!animations) {
      <svg:g>
        @for (bar of bars; track bar.ariaLabel) {
          <svg:g
            ngx-charts-bar
            [width]="bar.width"
            [height]="bar.height"
            [x]="bar.x"
            [y]="bar.y"
            [fill]="bar.color"
            [stops]="bar.gradientStops"
            [data]="bar.data"
            [orientation]="barOrientation.Vertical"
            [roundEdges]="bar.roundEdges"
            [gradient]="gradient"
            [ariaLabel]="bar.ariaLabel"
            [isActive]="isActive(bar.data)"
            (select)="onClick($event)"
            (activate)="activate.emit($event)"
            (deactivate)="deactivate.emit($event)"
            ngx-tooltip
            [tooltipDisabled]="tooltipDisabled"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipType]="tooltipType"
            [tooltipTitle]="tooltipTemplate ? undefined : bar.tooltipText"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipContext]="bar.data"
            [noBarWhenZero]="noBarWhenZero"
            [animations]="animations"
          ></svg:g>
        }
      </svg:g>
    }
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate(500, style({opacity: 0}))
      ])
    ])
  ]
})
export class GuardianRangeSeriesComponent implements OnChanges {
  @Input() series: ExtendedDataItem[];
  @Input() xAxisBandwidthSize: number;
  @Input() bandwidthSplitSize = 3;
  @Input() xScale;
  @Input() yScale;
  @Input() colors: ColorHelper;
  @Input() gradient: boolean;
  @Input() activeEntries: ExtendedDataItem[];
  @Input() seriesName: StringOrNumberOrDate;
  @Input() tooltipDisabled = false;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() roundEdges: boolean;
  @Input() animations = true;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero = true;

  @Output() select: EventEmitter<ExtendedDataItem> = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();

  tooltipPlacement = PlacementTypes.Top;
  tooltipType = StyleTypes.tooltip;

  bars: Bar[];

  barOrientation = BarOrientation;

  ngOnChanges(changes): void {
    this.update();
  }

  private update(): void {
    let width = 0;
    if (this.series.length) {
      width = Math.max(this.xAxisBandwidthSize / this.bandwidthSplitSize, 1);
    }

    const correctionOffset = (this.xAxisBandwidthSize / 2) - (width / 2);

    const sortedSeries = [...this.series];

    sortedSeries.sort((a, b) => a.value - b.value);

    const d0 = {
      [D0Types.positive]: sortedSeries[0]?.value || 0,
      [D0Types.negative]: sortedSeries[0]?.value || 0
    };
    let d0Type = D0Types.positive;

    this.bars = sortedSeries.slice(1).map(d => {
      const value = d.value as any;
      const label = this.getLabel(d);
      const formattedLabel = formatLabel(label);
      const roundEdges = this.roundEdges;
      d0Type = value > 0 ? D0Types.positive : D0Types.negative;

      const data = Object.assign({}, d, {label});

      const bar: any = {
        value,
        label,
        roundEdges,
        data,
        width,
        formattedLabel,
        height: 0,
        x: 0,
        y: 0
      };

      const offset0 = d0[d0Type];
      const offset1 = value;
      d0[d0Type] = value;

      bar.height = this.yScale(offset0) - this.yScale(offset1);
      bar.x = correctionOffset;
      bar.y = this.yScale(offset1);

      bar.color = this.colors.getColor(label);

      let tooltipLabel = formattedLabel;
      const localeStringValue = value !== null ? ' ' + value.toLocaleString() : '';
      bar.ariaLabel = formattedLabel + localeStringValue;
      bar.data.min = offset0;
      bar.data.max = offset1;
      if (this.seriesName) {
        tooltipLabel = `${this.seriesName} • ${formattedLabel}`;
        bar.data.series = this.seriesName;
        bar.ariaLabel = this.seriesName + ' ' + bar.ariaLabel;
      }

      const formattedLabelValue = this.dataLabelFormatting ? this.dataLabelFormatting(value || '') : localeStringValue
      bar.tooltipText = this.tooltipDisabled ? undefined : this.getTooltipText(tooltipLabel, formattedLabelValue);

      return bar;
    });
  }

  private getTooltipText(tooltipLabel: string, formattedLabelValue) {
    return `
        <span class="tooltip-label">${escapeLabel(tooltipLabel)}</span>
        <span class="tooltip-val">${formattedLabelValue}</span>
      `;
  }

  isActive(entry: DataItem): boolean {
    if (!this.activeEntries) {
      return false;
    }

    const typedEntry = entry as ExtendedDataItem;
    return this.activeEntries.some(active =>
      typedEntry.name === active.name && typedEntry.value === active.value && typedEntry.seriesName === active.seriesName
    );
  }

  onClick(data: ExtendedDataItem): void {
    this.select.emit(data);
  }

  getLabel(dataItem: ExtendedDataItem): StringOrNumberOrDate {
    if (dataItem.label) {
      return dataItem.label;
    }
    return dataItem.name;
  }
}
