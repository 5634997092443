import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DropdownOptions } from '../../../../core/models/dropdown-options';


const SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TableSelectboxComponent),
  multi: true
};

@Component({
  selector: 'hl-table-selectbox',
  templateUrl: './table-selectbox.component.html',
  providers: [SELECT_VALUE_ACCESSOR]
})
export class TableSelectboxComponent implements ControlValueAccessor {

  isDropdownOpen = false;
  isActive = false;
  translatedOptions$: Observable<DropdownOptions[]>;

  @Input() options: DropdownOptions[]; // dropdown list option
  @Input() translateKeys?: boolean; // translate keys
  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() isInvalid: boolean;
  @Input() label: string; // translation key
  @Input() invalidLabel: string;
  @Input() setPositionAbsolute: boolean;
  @Output() valueChange = new EventEmitter<string>(true);

  constructor(
    private translate: TranslateService,
    private el: ElementRef,
    private zone: NgZone
  ) {
    this.el = el;
  }

  _value = '';

  get value() {
    return this._value;
  }

  @Input() set value(val: string) {
    this.setValue(val);
  }

  setValue(val: string) {
    if (val === this._value) {
      return;
    }
    if (val === '' && this.isRequired && this.options && this.options.length === 1) {
      this._value = this.options[0].value;
    } else {
      this._value = val;
    }
    this.isActive = !!this._value;
  }

  onDropdownSelect(newValue: string) {
    this.setValue(newValue);
    this.valueChange.emit(newValue);
    this.newDropdownSet();
  }

  newDropdownSet() {
    this.onModelChange(this.value);
    this.onModelTouched();
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownOpen && !this.el.nativeElement.contains(event.target)) {
      this.zone.run(() => {
        this.isDropdownOpen = false;
      });
    }
  }

  dropdownToggle() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onModelChange: Function = (_: any) => {
  };
  onModelTouched: Function = () => {
  };

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
