import { Injectable } from '@angular/core';
import { ticketsAllRestName } from '../../rest-services/ticket-rest-constants.service';
import { CacheableHttpClient } from '../cache/cacheable-http-client';

@Injectable({providedIn: 'root'})
export class TicketsWebsocketStateService {
  refreshButtonDisabledState = true;

  constructor(private cacheableHttpClient: CacheableHttpClient) {
  }

  manageCacheForWebsocketTickets() {
    this.refreshButtonDisabledState = true;
    this.cacheableHttpClient.clearCache(ticketsAllRestName);
  }
}
