<div>
  <div [hlTextfieldAnimator]="formControl ? formControl : null" class="textfield textfield--large" [ngClass]="additionalClass ? additionalClass : ''"
    [class.is-invalid]="isInvalid" [class.is-disabled]="isDisabled" [class.is-active]="isActive">
    <input [type]="type" [min]="min" [max]="max" class="textfield__input" [(ngModel)]="value" attr.data-cy="{{dataCy}}"
      (ngModelChange)="onValueChange($event)" [name]="name" [id]="name" [maxlength]="maxlength"
      [attr.placeholder]="placeholder ? (placeholder | translate) : null" [attr.disabled]="isDisabled ? true : null"
      [attr.autocomplete]="disableAutocomplete ? 'off' : null">
    <label for="name" class="textfield__label">
      <span [translate]="label"></span>
      @if (isRequired) {
        <span>*</span>
      }
    </label>
  </div>
  @if (invalidLabel && isInvalid) {
    <small class="textfield__hint textfield__hint--validation" [translate]="invalidLabel" attr.date-cy="{{dataCy}}-warning"></small>
  }
</div>

