import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { Pipe, PipeTransform } from '@angular/core';
import { filter, forEach, isEqual } from 'lodash-es';

@Pipe({
  name: 'activitiesStatus'
})
export class ActivitiesStatusPipe implements PipeTransform {

  transform(dataset: ActivitiesViewModel[], activityStatus: any): any {
    let filtered: ActivitiesViewModel[];

    // check if there is at least one item in list
    if (activityStatus && activityStatus.length > 0) {

      filtered = this.filterResult(dataset, activityStatus);

    } else {
      // do nothing; return the full object
      filtered = dataset;
    }
    return filtered;
  }

  /**
   *
   * @description Filtering is based on the pmStatus.
   *
   * @param {ActivitiesViewModel[]} dataset
   * @param selectedActivityStatus
   * @returns {ActivitiesViewModel[]}
   */
  filterResult(dataset: ActivitiesViewModel[], selectedActivityStatus): ActivitiesViewModel[] {
    const filtered: ActivitiesViewModel[] = [];
    filter(dataset, (activity) => {
      forEach(selectedActivityStatus, (status) => {
        if (isEqual(status, activity.pmDescription)) {
          filtered.push(activity);
        }
      });
      return false;
    });
    return filtered;
  }
}
