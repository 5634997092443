<!-- default options item template -->
<ng-template #defaultItemTemplate let-model="item">
  {{ model }}
</ng-template>

<div hlTextfieldAnimator #searchContainer [ngClass]="searchFieldClasses" class="textfield textfield--with-prefix search-field"
  [class.search-field__disabled]="disabled" [class.has-results]="displayAutocomplete">
  <i class="textfield__prefix icon-search"></i>
  <input name="search"
    type="search"
    class="textfield__input search-field__input"
    spellcheck="false"
    autocomplete="off"
    (mousedown)="onFocus()"
    attr.data-cy="{{dataCy}}-search-field"
    [ngModel]="searchInput"
    [disabled]="disabled"
    (ngModelChange)="onChange($event)" (keyup)="onKeyup($event)" placeholder="{{placeholder || ('SEARCH_TERM' | translate)}}" #searchInputField/>
    @if (autocomplete) {
      <ul class="menu dropdown__panel search-field__autocomplete font-size-medium" #autocompleteMenu infiniteScroll
        [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="loadMore()">
        @defer (on viewport) {
        @if (!useCollection) {
          @for (item of this.autocompleteItems; track item; let i = $index) {
            <li class="menu__item" [class.is-selected]="i === this.currentSelectedItemNum"
              (click)="onItemClick(item)">
              <ng-container [ngTemplateOutlet]="autocompleteItemTemplate || defaultItemTemplate"
                [ngTemplateOutletContext]="{ item: item, index: i}"></ng-container>
            </li>
          }
        } @else {
          @for (item of this.autocompleteItems; track item; let i = $index) {
            <div class="collection-item list-item" (click)="onItemClick(item)">
              <ng-container [ngTemplateOutlet]="autocompleteItemTemplate || defaultItemTemplate"
                [ngTemplateOutletContext]="{ item: item, index: i}"></ng-container>
            </div>
          }
        }
        } @placeholder {
        <li> </li>
      }
    </ul>
  }
  <ng-content></ng-content>
</div>
