import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { scaleBand, scaleLinear } from 'd3-scale';

import { BarChartType, BaseChartComponent, calculateViewDimensions, ColorHelper, LegendOptions, LegendPosition, ScaleType, Series, ViewDimensions } from '@swimlane/tpf-ngx-charts';
import { inRange } from 'lodash-es';

@Component({
  selector: 'hl-bar-vertical-stacked',
  template: `
    <ngx-charts-chart [view]="[width, height]" [showLegend]="legend" [legendOptions]="legendOptions"
      [activeEntries]="activeEntries" [animations]="animations"
      (legendLabelActivate)="onActivate($event, undefined, true)"
      (legendLabelDeactivate)="onDeactivate($event, undefined, true)"
      (legendLabelClick)="onClick($event)">
      <svg:g [attr.transform]="transform" class="bar-chart chart">
        @if (xAxis) {
          <svg:g ngx-charts-x-axis [xScale]="xScale" [dims]="dims"
            [showLabel]="showXAxisLabel" [labelText]="xAxisLabel" [trimTicks]="trimXAxisTicks"
            [rotateTicks]="rotateXAxisTicks" [maxTickLength]="maxXAxisTickLength"
            [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks"
            [xAxisOffset]="dataLabelMaxHeight.negative"
            (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
        }
        @if (yAxis) {
          <svg:g ngx-charts-y-axis [yScale]="yScale" [dims]="dims"
            [showGridLines]="showGridLines" [showLabel]="showYAxisLabel" [labelText]="yAxisLabel"
            [trimTicks]="trimYAxisTicks" [maxTickLength]="maxYAxisTickLength"
            [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks"
            (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
        }
        @if (!isSSR) {
          <svg:g>
            @for (group of results; track trackBy(index, group); let index = $index) {
              <svg:g
                [@animationState]="'active'" [attr.transform]="groupTransform(group)">
                <svg:g ngx-charts-series-vertical [type]="barChartType.Stacked" [xScale]="xScale"
                  [yScale]="yScale" [activeEntries]="activeEntries" [colors]="colors"
                  [series]="group.series" [dims]="dims" [gradient]="gradient"
                  [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate"
                  [showDataLabel]="showDataLabel" [dataLabelFormatting]="dataLabelFormatting"
                  [seriesName]="group.name" [animations]="animations" [noBarWhenZero]="noBarWhenZero"
                  (select)="onClick($event, group)" (activate)="onActivate($event, group)"
                  (deactivate)="onDeactivate($event, group)"
                  (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"/>
                </svg:g>
              }
            </svg:g>
          }
          @if (isSSR) {
            <svg:g>
              @for (group of results; track trackBy(index, group); let index = $index) {
                <svg:g
                  [attr.transform]="groupTransform(group)">
                  <svg:g ngx-charts-series-vertical [type]="barChartType.Stacked" [xScale]="xScale"
                    [yScale]="yScale" [activeEntries]="activeEntries" [colors]="colors"
                    [series]="group.series" [dims]="dims" [gradient]="gradient"
                    [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate"
                    [showDataLabel]="showDataLabel" [dataLabelFormatting]="dataLabelFormatting"
                    [seriesName]="group.name" [animations]="animations" [noBarWhenZero]="noBarWhenZero"
                    (select)="onClick($event, group)" (activate)="onActivate($event, group)"
                    (deactivate)="onDeactivate($event, group)"
                    (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"/>
                </svg:g>
              }
              </svg:g>
            }
            </svg:g>
            @for (label of labels; track label) {
              <svg:g>
                <svg:text [attr.x]="label.x" [attr.y]="label.y" [attr.font-size]="10" >{{label.value}}</svg:text>
                </svg:g>
            }
            </ngx-charts-chart>
`,
  styleUrls: ['./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({opacity: 0, transform: 'scale(0)'}))
      ])
    ])
  ]
})
export class DiagramBarVerticalStackedComponent extends BaseChartComponent {
  @Input() legend = false;
  @Input() legendTitle;
  @Input() legendPosition: LegendPosition = LegendPosition.Right;
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() tooltipDisabled = false;
  @Input() gradient: boolean;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: ScaleType;
  @Input() trimXAxisTicks = true;
  @Input() trimYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yAxisTicks: any[];
  @Input() barPadding = 8;
  @Input() roundDomains = false;
  @Input() yScaleMax: number;
  @Input() showDataLabel = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero = true;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  groupDomain: string[];
  innerDomain: string[];
  valueDomain: [number, number];
  xScale: any;
  yScale: any;
  transform: string;
  tickFormatting: (label: string) => string;
  colors: ColorHelper;
  margin: number[] = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions: LegendOptions;
  dataLabelMaxHeight: any = {negative: 0, positive: 0};
  isSSR = false;
  labels = [];

  barChartType = BarChartType;
  dataLabelWidthMargin = 15;

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.isSSR = true;
    }
  }

  update(): void {
    super.update();

    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = {negative: 0, positive: 0};
    }
    this.margin = [this.showDataLabel? 50 : 10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }

    if (this.labels.length > 0) {
      this.dims.width -= this.dataLabelWidthMargin;
    }

    this.formatDates();

    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
    this.calculateLabels();
  }

  getGroupDomain(): string[] {
    const domain = [];
    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }
    return domain;
  }

  getInnerDomain(): string[] {
    const domain = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }
    return domain;
  }

  getValueDomain(): [number, number] {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
      let smallestSum = 0;
      let biggestSum = 0;
      for (const d of group.series) {
        if (d.value < 0) {
          smallestSum += d.value;
        } else {
          biggestSum += d.value;
        }
        smallest = d.value < smallest ? d.value : smallest;
        biggest = d.value > biggest ? d.value : biggest;
      }
      domain.push(smallestSum);
      domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);

    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }

  getXScale(): any {
    const spacing = this.groupDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().rangeRound([0, this.dims.width]).paddingInner(spacing).domain(this.groupDomain);
  }

  getYScale(): any {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  onDataLabelMaxHeightChanged(event, groupIndex: number) {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  groupTransform(group: Series): string {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }

  onClick(data, group?: Series) {
    if (group) {
      data.series = group.name;
    }

    this.select.emit(data);
  }

  trackBy: TrackByFunction<Series> = (index: number, item: Series) => {
    return item.name;
  }

  setColors(): void {
    let domain;
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.innerDomain;
    } else {
      domain = this.valueDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions(): LegendOptions {
    const opts = {
      scaleType: this.schemeType as any,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valueDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({width}: { width: number }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({height}: { height: number }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(event, group, fromLegend: boolean = false): void {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map(g => g.series)
      .flat()
      .filter(i => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({value: item, entries: this.activeEntries});
  }

  onDeactivate(event, group: Series, fromLegend: boolean = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter(i => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({value: item, entries: this.activeEntries});
  }

  calculateLabels() {
    this.labels = [];
    let index = 0;
    for (const result of this.results) {
      const x = this.calculateLabelXPosition(index);
      const y1 = result.series[1].value === 0 ? this.dims.height + this.margin[0] : this.margin[0];
      const y0 = this.calculateY0Position(result, y1);
      this.addLabel(result.series[0].value, x, y0);
      this.addLabel(result.series[1].value, x, y1);
      index++;
    }
  }

  addLabel(value: any, x: number, y: number) {
    if (value !== null) {
      this.labels.push({x: x, y: y, value: `${value}%`});
    }
  }

  calculateY0Position(result: any, y1: number) {
    let y0 = this.margin[0] + this.dims.height - this.dims.height * result.series[0].value / 100;
    if (inRange(y0 - y1, 0, 10)) {
      return y1 + 10;
    }
    return y0;
  }

  calculateLabelXPosition(index: number) {
    if (index === this.results.length - 1) {
      return this.results.length > 1 ?
        this.dims.xOffset + this.dims.width : this.dims.xOffset + this.dims.width * 0.79;
    }
    return this.xScale(this.results[index + 1].name) + this.dims.xOffset - this.barPadding + 4;
  }

}
