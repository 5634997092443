import { BarComponent } from '@swimlane/tpf-ngx-charts';
import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';

@Component({
  selector: 'g[ln-charts-diagram-bar]',
  template: `
    @if (hasGradient) {
      <svg:defs>
        <svg:g ngx-charts-svg-linear-gradient [orientation]="orientation" [name]="gradientId" [stops]="gradientStops" />
        </svg:defs>
      }
      <svg:path
        class="bar"
        role="img"
        tabIndex="-1"
        [class.active]="isActive"
        [class.hidden]="hideBar"
        [attr.d]="path"
        [attr.aria-label]="ariaLabel"
        [attr.fill]="hasGradient ? gradientFill : fill"
        (click)="select.emit(data)"
        />
    `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramBarComponent extends BarComponent {
  constructor(element: ElementRef) {
    super(element);
  }
}
