import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { IVector2D } from '@swimlane/tpf-ngx-charts/lib/models/coordinates.model';
import { ColorHelper, escapeLabel, formatLabel, PlacementTypes, StyleTypes } from '@swimlane/tpf-ngx-charts';
import { ExtendedDataItem } from '../common-models';

interface Whisker {
  line: IVector2D;
  color: string;
  strokeSize: number;
  data: ExtendedDataItem;
}

@Component({
  selector: 'g[ln-charts-diagram-whisker]',
  template: `
    <svg:line
      class="bar-line"
      [attr.x1]="whisker.line.v1.x"
      [attr.y1]="whisker.line.v1.y"
      [attr.x2]="whisker.line.v2.x"
      [attr.y2]="whisker.line.v2.y"
      [attr.stroke]="whisker.color"
      [attr.stroke-width]="whisker.strokeSize"
      [class.active]="isActive(whisker.data)"
      [class.cursor-default]="defaultPointer"
      fill="none"
      ngx-tooltip
      [tooltipDisabled]="tooltipDisabled"
      [tooltipPlacement]="placementTypes.Top"
      [tooltipType]="styleTypes.tooltip"
      [tooltipTitle]="tooltipTemplate ? undefined : getTooltipText(whisker)"
      [tooltipTemplate]="tooltipTemplate"
      [tooltipContext]="whisker.data"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./diagram-whisker.component.scss']
})
export class DiagramWhiskerComponent implements OnChanges {
  @Input() data: ExtendedDataItem;
  @Input() strokeSize: number;
  @Input() xAxisBandwidthSize: number;
  @Input() bandwidthSplitSize = 3;
  @Input() xScale;
  @Input() yScale;
  @Input() colors: ColorHelper;
  @Input() activeEntries: ExtendedDataItem[];
  @Input() tooltipDisabled = false;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() defaultPointer = false;

  @Output() activate: EventEmitter<ExtendedDataItem> = new EventEmitter();
  @Output() deactivate: EventEmitter<ExtendedDataItem> = new EventEmitter();

  whisker: Whisker;

  placementTypes = PlacementTypes;
  styleTypes = StyleTypes;

  ngOnChanges(): void {
    this.update();
  }

  update(): void {
    this.whisker = this.mapDataPointToWhisker(this.data, this.xAxisBandwidthSize, this.strokeSize);
  }

  mapDataPointToWhisker(d: ExtendedDataItem, xAxisBandwidthSize: number, whiskerStrokeSize: number): Whisker {
    const correctionOffset = xAxisBandwidthSize / 2;
    const commonX = this.xScale(d.seriesName);
    const offsetX = commonX + correctionOffset;

    const whiskerLineWidth = Math.max(xAxisBandwidthSize / this.bandwidthSplitSize, 1);

    const wy = this.yScale(d.value);

    const color = this.colors.getColor(d.valueName);
    const strokeSize = Math.max(whiskerStrokeSize / 2, 1);

    const line: IVector2D = {
      v1: {x: offsetX + whiskerLineWidth / 2, y: wy},
      v2: {x: offsetX - whiskerLineWidth / 2, y: wy}
    };

    const data = Object.assign({}, d, {
      name: d.valueName,
      label: d.label || d.name
    });

    return {
      line,
      color,
      strokeSize,
      data
    };
  }

  getTooltipText(whisker: Whisker): string {
    return `
      <span class="tooltip-label">${escapeLabel(formatLabel(whisker.data.label))}</span>
      <span class="tooltip-val">${whisker.data.value.toLocaleString()}</span>
    `;
  }

  isActive(item): boolean {
    if (!this.activeEntries) {
      return false;
    }
    return this.activeEntries.some(d => d.name === item.name && d.value === item.value && d.seriesName === item.seriesName);
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.activate.emit(this.whisker.data);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.deactivate.emit(this.whisker.data);
  }
}
