@if (options.length <= maxButtonsShow) {
  @if (options.length) {
    <p class="form__label" [translate]="label"></p>
  }
  @for (set of sets; track set) {
    <div class="button-group button-group--large" [ngClass]="{'button-group--wrapped': wrapButtons}">
      @for (option of set; track option; let i = $index) {
        <button class="button toggle-button" [class.is-active]="isSelected(option.value)" [attr.data-cy]="dataCy + '-' + (i+1)"
          (click)="setSelected($event, option)">{{ option.title | translate }}
        </button>
      }
    </div>
  }
} @else {
  <hl-multi-selectbox [options]="options" [(value)]="value" dataCy="{{dataCy}}" (valueChange)="valueChanged()" [label]="label"
    [allChecked]="allChecked" [translateKeys]="true"></hl-multi-selectbox>
}
