import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { User } from './core/models/user';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class AuthService {

  isUserAuthenticatedSource = new BehaviorSubject<boolean>(false);

  get isUserAuthenticated() {
    return this.isUserAuthenticatedSource.asObservable();
  }

  lastAuthenticatedUserSource = new ReplaySubject<User>(1);

  get lastAuthenticatedUser() {
    return this.lastAuthenticatedUserSource.asObservable().pipe(distinctUntilChanged(isEqual));
  }

  setAuthenticatedUser(value: User) {
    this.lastAuthenticatedUserSource.next(value);
    this.isUserAuthenticatedSource.next(!!value);
  }
}
