import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { isEqual } from 'lodash-es';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hl-list-counter',
  templateUrl: './list-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCounterComponent implements OnChanges {

  @Input() filteredLength: number;
  @Input() filteredResultLengthWithoutPagination: number;
  @Input() title: string;
  @Input() dataCy: string;

  technicalDisplayListCount$ = this.config.getConfig().pipe(map(c => isEqual(c.TECHNICAL_DISPLAY_LIST_COUNT, 'true')));
  numberOfItems: number;

  constructor(private config: CountryConfigRestService) {
  }

  ngOnChanges() {
    this.numberOfItems = this.filteredResultLengthWithoutPagination > 0 ? this.filteredResultLengthWithoutPagination : this.filteredLength
  }
}
