import { Pipe, PipeTransform } from '@angular/core';
import { gray, green, red, yellow } from '../../../core/core-constants.service';

export enum statusTypes {
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  ASSIGNED = 'Assigned',
  OPEN = 'Open'
}

const statusColor = new Map<string, string>([
  [statusTypes.CANCELLED, red],
  [statusTypes.CLOSED, gray],
  [statusTypes.ASSIGNED, green],
  [statusTypes.OPEN, yellow]
]);

@Pipe({
  name: 'wescanStatusColorPipe'
})
export class WescanStatusColorPipe implements PipeTransform {

  transform(wescanStatus: string): string {
    return 'label-status-' + statusColor.get(wescanStatus);
  }
}
