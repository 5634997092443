import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[hlTooltip]'
})
export class TooltipDirective implements OnDestroy {
  isShown = false;
  supportsTouch = false;
  instance = null;
  touchListener: () => void;

  @Input()
  title: string;
  @Input()
  isActive = true;
  @Input()
  documentOverlay: boolean;
  @Input()
  showTooltipOnTouchScreen = false;
  @Input()
  darkTransparent75 = false;
  @Input()
  keepShown = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    const supportsTouch = 'ontouchstart' in window || navigator['msMaxTouchPoints'];
    if (supportsTouch && supportsTouch > 0) {
      this.supportsTouch = true;
    }
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: Event): void {
    if (!this.supportsTouch) {
      this.show();
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: Event): void {
    this.hideIfNeeded();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (!this.keepShown) {
      this.hideIfNeeded();
    }
    if (this.showTooltipOnTouchScreen && this.supportsTouch && !this.isShown) {
      this.show();
      setTimeout(() => {
        this.hide();
      }, 5000);
    }
  }

  hideIfNeeded() {
    if (!this.supportsTouch) {
      this.hide();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.calculatePosition();
  }
  ngOnDestroy(): void {
    if (this.touchListener) {
      this.touchListener();
    }
  }

  scroll = (): void => {
    this.calculatePosition();
  }

  calculatePosition() {
    if (!this.isShown) {
      return;
    }

    const divider = 2;
    const win = {
      width: window.innerWidth,
      top: window.pageYOffset || document.documentElement.scrollTop,
      left: window.pageXOffset || document.documentElement.scrollLeft
    };

    const tooltip = {
      width: this.instance.offsetWidth,
      height: this.instance.offsetHeight,
      position: {
        x: 0,
        y: 0
      }
    };

    const elRect = this.el.nativeElement.getBoundingClientRect();
    const container = {
      width: this.el.nativeElement.offsetWidth,
      height: this.el.nativeElement.offsetHeight,
      offset: {
        left: elRect.left + win.left,
        top: elRect.top + win.top
      }
    };

    const scrollDistance = window.scrollY;

    // set initial position information
    tooltip.position.x = container.offset.left + (container.width / divider) - (tooltip.width / divider);
    tooltip.position.y = container.offset.top - tooltip.height;

    if (tooltip.position.x < 0) {
      tooltip.position.x = container.offset.left;
      this.renderer.addClass(this.instance, 'tooltip--left');
    } else {
      this.renderer.removeClass(this.instance, 'tooltip--left');
    }

    if (tooltip.position.x + tooltip.width > win.width) {
      tooltip.position.x =
        container.offset.left + container.width - tooltip.width;
      this.renderer.addClass(this.instance, 'tooltip--right');
    } else {
      this.renderer.removeClass(this.instance, 'tooltip--right');
    }

    if (tooltip.position.y < scrollDistance) {
      tooltip.position.y = container.offset.top + container.height;
      this.renderer.addClass(this.instance, 'tooltip--top');
    } else {
      this.renderer.removeClass(this.instance, 'tooltip--top');
    }

    this.renderer.setStyle(this.instance, 'left', tooltip.position.x + 'px');
    this.renderer.setStyle(this.instance, 'top', tooltip.position.y + 'px');
    this.renderer.setStyle(this.instance, 'word-wrap', 'break-word');
  }

  show() {
    if (this.isActive) {

      this.instance = this.createTooltip();

      this.renderer.appendChild(document.body, this.instance);

      this.isShown = true;
      this.renderer.addClass(this.instance, 'is-visible');
      if (this.documentOverlay) {
        this.renderer.addClass(this.instance, 'document-overlay');
      }
      this.calculatePosition();

      // https://stackoverflow.com/questions/44516017/how-to-handle-window-scroll-event-in-angular-4
      // https://github.com/angular/angular/issues/11200
      // Angular does not provide an option to pass in the event listener options, so addEventListener is used
      // ('useCapture: true' needed to capture all scroll events, which will also be from tiny scrollable containers)
      window.addEventListener('scroll', this.scroll, true);
    }
  }

  private createTooltip() {
    const instance = this.renderer.createElement('div');

    if (this.el?.nativeElement?.classList?.contains('overlay-icon')) {
      this.renderer.addClass(instance, 'ln-tooltip--overlay-tooltip');
    }

    this.renderer.addClass(instance, 'tooltip');
    this.renderer.addClass(instance, 'ln-tooltip');
    this.renderer.setAttribute(instance, 'data-cy', 'tooltip');
    this.renderer.setProperty(instance, 'innerHTML', this.title);

    if (this.darkTransparent75) {
      this.renderer.addClass(instance, 'ln-tooltip--dark-75');
    }
    return instance;
  }

  setTempTitle(title: string) {
    this.renderer.setProperty(this.instance, 'innerHTML', title);
    this.calculatePosition();
  }

  hide() {
    window.removeEventListener('scroll', this.scroll, true);
    if (this.instance) {
      this.renderer.removeClass(this.instance, 'is-visible');
      if (this.documentOverlay) {
        this.renderer.removeClass(this.instance, 'document-overlay');
      }
      if (this.instance.parentNode) {
        this.instance.parentNode.removeChild(this.instance);
      }
    }
    this.isShown = false;
  }
}
