import { DestroyRef, Directive, inject, Renderer2 } from '@angular/core';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { roles } from '../../../core/core-constants.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { StateService } from '../../../core/services/state.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { WindowService } from '../../../core/window.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { filter, tap } from 'rxjs/operators';
import { TeamplayRestService } from 'app/core/rest-services/teamplay-rest.service';
import { find, isEqual, isUndefined, without } from 'lodash-es';
import { AuthService } from '../../../auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const classesToKeep = ['overflow-hidden', 'modal-open'];

enum userTypeEnum {
  INTERNAL = 'int',
  EXTERNAL = 'ext'
}

enum widgetStateEnum {
  NO_WIDGET = 'No Amplify widget',
  WIDGET_WITHOUT_DATA = 'Amplify widget without data',
  WIDGET_WITH_DATA = 'Amplify widget with data'
}

const LOGGED_IN = 'logged_in';

@Directive({
  selector: '[hlAddOmnitureAndRouterStateName]'
})
export class AddOmnitureAndRouterStateNameDirective {

  static actions = {
    EQUIPMENT_DOWNLOAD_SERVICE_REPORTS_ACTION: 'equipment.downloadServiceReports',
    SPR_GLOSSARY_ACTION: 'spr.glossary',
    DOCUMENT_DOWNLOAD_ACTION: 'document.download',
    PAGE_READY_ACTION: 'page.ready',
    SRS_STATUS_CHECK_ACTION: 'srs.statusCheck',
    CAROUSEL_NEXT_ACTION: 'carousel.next',
    CAROUSEL_PREV_ACTION: 'carousel.prev',
    PRODUCT_SHARE_ACTION: 'product.share',
    LAYER_OPEN_ACTION: 'layer.open'
  };
  static formTypes = {
    EQUIPMENT_DEACTIVATE_TYPE: 'deactivateequipment',
    RESCHEDULE_ACTIVITY_TYPE: 'rescheduleactivity',
    TICKET_UPDATE_TYPE: 'updateticket',
    TICKET_CREATE_TYPE: 'newticket',
    WE_SCAN_REQUEST_TYPE: 'wescanrequest',
    QUOTE_TYPE: 'quote',
    TRIAL_TYPE: 'trial'
  };
  static formStates = {
    FORM_OPEN: 'form.open',
    FORM_SENT: 'form.sent'
  };
  static formRecipients = {
    RECIPIENT_GENERAL: 'general'
  };
  static layerNames = {
    CPC_OPTION_INSTALLED_ON_SYSTEMS: 'cpcInstalledOn',
    CPC_OPTION_AVAILABLE_FOR_SYSTEMS: 'cpcAvailableFor'
  };
  private static additionalData: string;

  private readonly destroyRef = inject(DestroyRef);
  window = this.windowService.nativeWindow;
  teamplayWidgetFeatureToggle = false;

  constructor(private windowService: WindowService,
    private userRestService: UserRestService,
    private filterUtilService: FilterUtilService,
    private configService: CountryConfigRestService,
    private teamplayRestService: TeamplayRestService,
    private state: StateService,
    private renderer: Renderer2,
    private authService: AuthService) {
    this.init();
  }

  /**
   * @description
   * Generate the customer number in the format needed for omniture
   *
   * @param customerNumbers
   */
  static generateCustNoFormat(customerNumbers: string[]) {
    let custNumberInput: string;
    if (customerNumbers.length > 0) {
      custNumberInput = customerNumbers.join('|');
    } else {
      custNumberInput = customerNumbers[0];
    }
    return custNumberInput;
  }

  static trackPage(userData, custNoInput, userType, widget) {
    const data = {
      action: AddOmnitureAndRouterStateNameDirective.actions.PAGE_READY_ACTION,
      data: {
        page: {
          country: userData.country,
          language: userData.language,
          path: window.location.pathname,
          widget
        },
        user: {
          loginStatus: LOGGED_IN,
          custNo: custNoInput,
          userType,
          role: userData.jobFunction
        }
      }
    };
    if (!userData.jobFunction) {
      delete data.data.user.role;
    }
    AddOmnitureAndRouterStateNameDirective.triggerAction(data);
  }

  static trackSrsStatusCheck() {
    AddOmnitureAndRouterStateNameDirective.triggerAction({
      action: AddOmnitureAndRouterStateNameDirective.actions.SRS_STATUS_CHECK_ACTION, data: {}
    });
  }

  static trackGeneral(action, type, attachment = false) {
    const data = {
      action,
      data: {
        form: {
          type,
          recipient: AddOmnitureAndRouterStateNameDirective.formRecipients.RECIPIENT_GENERAL,
          attachment: 'hasattachment'
        }
      }
    };
    if (!attachment) {
      delete data.data.form.attachment;
    }
    AddOmnitureAndRouterStateNameDirective.triggerAction(data);
  }

  static trackCpcModals(action, type = null, id = null) {
    const data = {
      action,
      data: {
        form: {
          type,
          recipient: AddOmnitureAndRouterStateNameDirective.formRecipients.RECIPIENT_GENERAL
        },
        product: {
          id
        }
      }
    };
    if (!type) {
      delete data.data.form;
    }
    if (!id) {
      delete data.data.product;
    }
    AddOmnitureAndRouterStateNameDirective.triggerAction(data);
  }

  static trackCpcGraphArrowButtons(action, name) {
    const data = {
      action,
      data: {
        carousel: {
          type: 'graph',
          name
        }
      }
    };
    AddOmnitureAndRouterStateNameDirective.triggerAction(data);
  }

  static trackCpcLayers(action, name, id) {
    const data = {
      action,
      data: {
        layer: {name},
        product: {id}
      }
    };
    AddOmnitureAndRouterStateNameDirective.triggerAction(data);
  }

  static trackCpcOptionAndUpgrades(isFilter: boolean, resultCount: number) {
    const lastCpcPageItem = window['ste_statistic']?._log
      ?.findLast(item => item?.action === AddOmnitureAndRouterStateNameDirective.actions.PAGE_READY_ACTION
        && item?.data?.page?.path === '/clinical-performance-companion');

    const data = {
      action: lastCpcPageItem?.action,
      data: {
        page: lastCpcPageItem?.data?.page,
        user: lastCpcPageItem?.data?.user,
        search: {
          source: 'Clinical Performance Companion Options & Upgrades',
          resultCount,
          filters: {name: 'Search Filter', values: ['' + isFilter]}
        }
      }
    };

    AddOmnitureAndRouterStateNameDirective.triggerAction(data);
  }

  /**
   * Omniture tracking when downloading documents with added url params
   * @param filename
   * @param url
   */
  static triggerDocumentsDownloadTracking(filename: string, url = '') {
    const {nativeWindow} = new WindowService();
    const fullUrl = nativeWindow.location.protocol + '//' + nativeWindow.location.hostname + url;
    this.triggerDocumentsDownloadTrackingFullUrl(filename, url ? fullUrl : nativeWindow.location.href);
  }

  /**
   * Omniture tracking when downloading documents
   * @param filename
   * @param url
   */
  static triggerDocumentsDownloadTrackingFullUrl(filename: string, url: string) {
    AddOmnitureAndRouterStateNameDirective.triggerAction({
      action: AddOmnitureAndRouterStateNameDirective.actions.DOCUMENT_DOWNLOAD_ACTION,
      data: {
        document: {
          filename,
          url
        }
      }
    });
  }

  /**
   * Tracking simple actions
   * @param data
   */
  static triggerAction(data: any) {
    window['ste_statistic'] = window['ste_statistic'] || [];
    window['ste_statistic'].push(data);
    this.fireEvent(data);
  }

  static addAdditionalData(data: string) {
    AddOmnitureAndRouterStateNameDirective.additionalData = data;
  }

  private static fireEvent(data: any) {
    if (!window.CustomEvent || typeof window.CustomEvent !== 'function') {
      return;
    }
    const event = new CustomEvent('ste:action', {detail: data});
    document.dispatchEvent(event);
  }

  init() {
    const bodyElem = document.body;
    this.state.getActiveStateName()
      .pipe(
        filter(stateName => !!stateName),
        tap(stateName => {
          const bodyClassesToRemove = without(bodyElem.classList, ...classesToKeep);
          bodyElem.classList.remove(...bodyClassesToRemove);
          this.renderer.addClass(bodyElem, stateName);
        }),
        this.triggerOmniture(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([userData, assignedCustomerData, userRolesData, teamplayAvailable, teamplay]) => {
        const teamplayData = teamplay?.teamplayData;
        const listOfCustomers =
          this.filterUtilService.getListOfPropertyValuesFromListOfObject(assignedCustomerData, 'customerNumber');

        // check if we received also IMPERSONATE_USER role and appropriately set user type
        const checkImpersonationRole = find(userRolesData, {identifier: roles.impersonateUserRole});
        const userType = (!!checkImpersonationRole) ? userTypeEnum.INTERNAL : userTypeEnum.EXTERNAL;

        // check for only one customer or multiple customer
        let custNoInput = AddOmnitureAndRouterStateNameDirective.generateCustNoFormat(listOfCustomers);
        if (isUndefined(custNoInput)) {
          custNoInput = '';
        }
        const widget: any = AddOmnitureAndRouterStateNameDirective.additionalData ?
          AddOmnitureAndRouterStateNameDirective.additionalData :
          this.getWidgetState(teamplayAvailable, teamplayData);
        delete AddOmnitureAndRouterStateNameDirective.additionalData;
        AddOmnitureAndRouterStateNameDirective.trackPage(userData, custNoInput, userType, widget);
      });
    this.configService.getConfig().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(config => {
      this.teamplayWidgetFeatureToggle = isEqual(config.FEATURE_TOGGLE_DISPLAY_DASHBOARD_WIDGET_TP, 'true');
    });
  }

  private triggerOmniture() {
    return (source: Observable<any>) => source.pipe(
      switchMap(() => this.authService.isUserAuthenticated),
      filter(isAuthenticated => isAuthenticated),
      switchMap(() => this.triggerOmnitureAndSetValuesFromAuthenticatedUser()),
    )
  }

  private triggerOmnitureAndSetValuesFromAuthenticatedUser() {
    const user$ = this.userRestService.getUser();
    const assignedCustomers$ = this.userRestService.getAssignedCustomers();
    const userRoles$ = this.userRestService.getUserRoles();
    const teamplayAvailable$ = this.teamplayRestService.isTeamplayAvailable();
    const teamplayData$ = this.teamplayRestService.getTeamplayData();

    return forkJoin([user$, assignedCustomers$, userRoles$, teamplayAvailable$, teamplayData$]);
  }

  getWidgetState(teamplayAvailable, teamplayData) {
    if (!this.teamplayWidgetFeatureToggle || !teamplayAvailable) {
      return widgetStateEnum.NO_WIDGET;
    }
    if (!teamplayData.length) {
      return widgetStateEnum.WIDGET_WITHOUT_DATA;
    }
    return widgetStateEnum.WIDGET_WITH_DATA;
  }
}
