import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { find, forEach, isEqual, pick } from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserRestService } from '../rest-services/user-rest.service';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { ConfigLoaderService } from '../../config-loader.service';
import { WindowService } from '../window.service';
import { ViewModelMerge } from './view-model.merge';

@Injectable({providedIn: 'root'})
export class LifeNetUtilService {
  private window: any;

  constructor(private userRestService: UserRestService,
    private router: Router,
    private translateService: TranslateService,
    private configService: CountryConfigRestService,
    private configLoaderService: ConfigLoaderService,
    windowService: WindowService) {
    this.window = windowService.nativeWindow;
  }

  /**
   *
   * @param sourceFrom$ | BE response model from which properties are extracted
   * @param sourceTo$ | BE response model to which extracted properties are merged
   * @param objectToFindAndMerge | contains the search key, value and properties to be merged
   *
   * @description Generate the view models by merging properties from different BE response.
   * For e.g. merging equipment status and equipment list
   */
  createViewModels(sourceFrom$: Observable<any>, sourceTo$: Observable<any>, objectToFindAndMerge: any): Observable<any> {
    return combineLatest([sourceFrom$, sourceTo$]).pipe(map(responses => {
      return this.mapToViewModel(responses, objectToFindAndMerge);
    }));
  }

  mapToViewModel(responses: [any, any], objectToFindAndMerge: any) {
    return new ViewModelMerge().mapToViewModel(responses, objectToFindAndMerge);
  }

  /**
   * A lot of merging was needed for the Export List Story-1366.
   * This method, takes as input two objects, and an array of strings.
   * the first object sourceFrom, is from where the fields in the propertiesToMerge object will be copied.
   * the second object sourceTo, is where the fields will be copied into.
   */
  mergeObjects(objectFrom, objectTo, propertiesToMerge: string[]): any {
    const mergedObject = objectTo;
    // the objectFrom / objectTo will never be arrays.
    forEach(propertiesToMerge, property => {
      mergedObject[property] = objectFrom[property];
    });
    return mergedObject;
  }

  /**
   * @ngdoc method
   * @name getAddressFields
   *
   * @param viewModel | passed entity model (equipmentViewModel, activitiesViewModel)
   * @param addressOrder
   * @param configurableModalEditProp | for configuring equipment detail editing
   *
   * @description
   * Generate list based on a given order
   */
  getAddressFields(viewModel, addressOrder, configurableModalEditProp) {
    const resultSet = [];
    forEach(addressOrder.split(','), item => {

      const tempObj: { [k: string]: any } = {};

      if (isEqual(item, 'street')) {
        // tslint:disable-next-line:ban
        tempObj.label = this.translateService.instant('GENERIC_LABEL_STREET');
        tempObj.value = viewModel[item];
      } else if (isEqual(item, 'zip')) {
        // tslint:disable-next-line:ban
        tempObj.label = this.translateService.instant('GENERIC_LABEL_POSTAL_CODE');
        tempObj.value = viewModel[item];
      } else if (isEqual(item, 'city')) {
        // tslint:disable-next-line:ban
        tempObj.label = this.translateService.instant('GENERIC_LABEL_CITY');
        tempObj.value = viewModel[item];
      }

      // New feature for US LifeNet
      // append state if filled
      if (isEqual(item, 'city') && viewModel['state']) {
        tempObj.value = tempObj.value + ', ' + viewModel['state'];
      }

      // Note:- Configuration done only for equipment edit (overview), we need these properties
      if (configurableModalEditProp) {
        tempObj.maxLength = configurableModalEditProp[item].maxLength;
        tempObj.attribute = item;
      }

      resultSet.push(tempObj);
    });

    return resultSet;
  }

  /**
   * Removes the caches, session log out from SAML
   */
  invalidateSession() {
    this.userRestService.logout().subscribe((logoutResponse) => {

      if (logoutResponse.logoutUrl) {
        this.window.location.href = logoutResponse.logoutUrl;
      } else {
        this.router.navigate(['/welcome']);
      }
    }, (_error: HttpErrorResponse) => {
      this.router.navigate(['/welcome']);
    });
  }

  /**
   * Pick the property value from an object after a predicate find.
   */
  pickPropertyFromObject(dataSet, findObjectToExtract, propertyToExtract) {

    let extractedProperty = null;

    if (dataSet && findObjectToExtract && propertyToExtract) {
      const extractedPropertyObject = pick(find(dataSet, findObjectToExtract), propertyToExtract);
      extractedProperty = extractedPropertyObject[propertyToExtract];
    }
    return extractedProperty;
  }

  setTranslationFile(langCountryCode: string): Observable<any> {
    return this.translateService.use(this.configLoaderService.getTranslateFile(langCountryCode));
  }

  setConfigurationFile(countryCode: string) {
    this.configService.setConfig(countryCode);
  }

  getViewLayout(): string {
    const viewWidth = this.window.innerWidth;

    if (viewWidth < 700) {
      return 'mobile';
    } else if (viewWidth < 992) {
      return 'wrapped';
    } else if (viewWidth < 1101) {
      return 'mobile';
    } else if (viewWidth < 1701) {
      return 'wrapped';
    } else {
      return 'inline';
    }
  }
}
