<select id="selectbox" class="selectbox__select" [(ngModel)]="value" style="pointer-events: none" #selectBox>
  <option class="selectbox__select-option"></option>
  @if (translatedOptions$ | async; as translatedOptions) {
    @for (option of translatedOptions; track option) {
      <option class="selectbox__select-option" [ngValue]="option.value">{{ (option.title) | replaceBreakLinesWithComma }}</option>
    }
  }
</select><label class="selectbox__label" for="selectbox">
  <span>{{ label | translate }}</span>
  @if (isRequired) {
    <span>*</span>
  }
</label>@if (invalidLabel && isInvalid) {
  <small class="selectbox__hint selectbox__hint--validation" [translate]="invalidLabel"></small>
}
@if (!isDisabled) {
  <ul id="tableSelectbox" class="menu dropdown__panel selectbox__option-list col-12">
    @if (translatedOptions$ | async; as translatedOptions) {
      @for (option of translatedOptions | filterHidden; track option; let i = $index) {
        <li class="menu__item selectbox__option" [class.option-disabled]="option.disabled" (click)="setSelected(option)" [innerHTML]="option.title"
          attr.data-cy="table-select-box-{{i+1}}" [title]="(option.tooltip | translate) || ''"></li>
      }
    }
  </ul>
}
<ng-content></ng-content>
