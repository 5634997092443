<!--legend label activation does not work, because it only sends object with name and in this component name and value are checked-->
<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [animations]="animations"
  [activeEntries]="activeEntries"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event, true)"
  (legendLabelDeactivate)="onDeactivate($event, true)"
  xmlns:svg="http://www.w3.org/2000/svg"
  >
  <svg:g [attr.transform]="transform" class="bar-chart chart">
    <svg:g
      ngx-charts-x-axis
      [showGridLines]="showXGridLines"
      [dims]="dims"
      [xScale]="xScale"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      (dimensionsChanged)="updateXAxisHeight($event)"
    />
    <svg:g
      ngx-charts-y-axis
      [showGridLines]="showYGridLines"
      [dims]="dims"
      [yScale]="yScale"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      (dimensionsChanged)="updateYAxisWidth($event)"
    />

    @if (animations) {
      <svg:g>
      @for (group of bars; track trackBy(index, group); let index = $index) {
        <svg:g
          [@animationState]="'active'"
          [attr.transform]="groupTransform(group)"
        >
        <svg:g
          ln-charts-guardian-range-series
          [xAxisBandwidthSize]="xAxisBandwidthSize"
          [bandwidthSplitSize]="bandwidthSplitSize"
          [xScale]="xScale"
          [yScale]="yScale"
          [activeEntries]="activeEntries"
          [colors]="colors"
          [series]="group.series"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="tooltipTemplate"
          [seriesName]="group.name"
          [animations]="animations"
          [noBarWhenZero]="noBarWhenZero"
          (select)="onClick($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
        />
        </svg:g>
    }
    </svg:g>
  }
  </svg:g>
  @if (!animations) {
    <svg:g>
    @for (group of bars; track trackBy(index, group); let index = $index) {
      <svg:g
        [attr.transform]="groupTransform(group)"
      >
      <svg:g
        ln-charts-guardian-range-series
        [xAxisBandwidthSize]="xAxisBandwidthSize"
        [bandwidthSplitSize]="bandwidthSplitSize"
        [xScale]="xScale"
        [yScale]="yScale"
        [activeEntries]="activeEntries"
        [colors]="colors"
        [series]="group.series"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="tooltipTemplate"
        [seriesName]="group.name"
        [animations]="animations"
        [noBarWhenZero]="noBarWhenZero"
        (select)="onClick($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
      />
      </svg:g>
    }
    </svg:g>
  }

  <svg:g [attr.transform]="transform">
    @for (whisker of whiskers; track whisker) {
      <svg:g
        ln-charts-diagram-whisker
        [data]="whisker"
        [strokeSize]="strokeSize"
        [xAxisBandwidthSize]="xAxisBandwidthSize"
        [bandwidthSplitSize]="bandwidthSplitSize"
        [xScale]="xScale"
        [yScale]="yScale"
        [colors]="colors"
        [activeEntries]="activeEntries"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="tooltipTemplate"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
      />
    }
    @for (circle of circles; track trackBy(index, circle); let index = $index) {
      <svg:g
        ln-charts-guardian-circle
        [data]="circle"
        [index]="index"
        [xAxisBandwidthSize]="xAxisBandwidthSize"
        [xScale]="xScale"
        [yScale]="yScale"
        [colors]="colors"
        [activeEntries]="activeEntries"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="tooltipTemplate"
        [animations]="animations"
        (select)="onClick($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
      />
    }
  </svg:g>
</ngx-charts-chart>
