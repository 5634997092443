import { cloneDeep, extend, forEach, isArray, isObject, pick } from 'lodash-es';

export class ViewModelMerge {

  public mapToViewModel(responses: [any, any], objectToFindAndMerge: any) {
    const objectsWithAdditionalFields = responses[0];
    const viewModelList = responses[1];
    const additionalObjects: Map<string, any> = new Map<string, any>();

    forEach(objectsWithAdditionalFields, item => {
      additionalObjects.set(item[objectToFindAndMerge.findKey], pick(item, objectToFindAndMerge.propertiesToMerge));
    });

    if (objectsWithAdditionalFields && viewModelList) {
      if (isArray(viewModelList)) {
        if (objectToFindAndMerge.mergeInNestedObjects) {
          return viewModelList
            .map(i => cloneDeep(i))
            .map(item => {
              Object.entries(item)
                .filter(([, v]) => isObject(v))
                .forEach(([k, v]) => {
                  item[k] = this.merge(additionalObjects, v, objectToFindAndMerge);
                });
              return item;
            });
        } else {
          return viewModelList.map(item => this.merge(additionalObjects, item, objectToFindAndMerge));
        }
      } else {
        return this.merge(additionalObjects, viewModelList, objectToFindAndMerge);
      }
    }
  }

  private merge(additionalObjects: Map<string, any>, item, objectToFindAndMerge: any) {
    const foundObjectToAdd = additionalObjects.get(item[objectToFindAndMerge.findValue]);
    const fieldsToAdd = foundObjectToAdd !== undefined ?
      foundObjectToAdd :
      this.createObjectWithEmptyFields(objectToFindAndMerge.propertiesToMerge);
    return extend({}, fieldsToAdd, item);
  }

  private createObjectWithEmptyFields(fields: any[]) {
    const obj = {};
    forEach(fields, property => {
      obj[property] = '';
    });
    return obj;
  }
}
