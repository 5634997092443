
export const Colors = {
  'orange-dark':      '#cf4b00',
  'orange':           '#ec6602',
  'orange-50':        '#f9b591',
  'orange-25':        '#fdddcb',

  'petrol-dark':      '#02787d',
  'petrol':           '#009999',
  'petrol-50':        '#87d2d2',
  'petrol-25':        '#c8e6e6',

  'berry':            '#7a162d',
  'berry-50':         '#c69b9e',
  'berry-25':         '#e9d1d4',

  'black':            '#000000',
  'white':            '#ffffff',

  'black-5':          '#f2f2f2',
  'black-7':          '#ededed',
  'black-10':         '#e6e6e6',
  'black-20':         '#cccccc',
  'black-25':         '#bfbfbf',
  'black-30':         '#b3b3b3',
  'black-40':         '#999999',
  'black-50':         '#808080',
  'black-60':         '#666666',
  'black-70':         '#4d4d4d',
  'black-75':         '#404040',
  'black-80':         '#333333',
  'black-90':         '#1a1a1a',

  'yellow':           '#ffd200',
  'yellow-50':        '#ffe980',
  'yellow-25':        '#fff3bf',

  'red-dark':         '#d9001d',
  'red':              '#e7001d',
  'red-50':           '#f3808e',
  'red-25':           '#f9bfc7',

  'blue':             '#2b2483',
  'blue-50':          '#9592c1',
  'blue-25':          '#cac8e0',

  'cyan':             '#3abfed',
  'cyan-50':          '#9ddff6',
  'cyan-25':          '#ceeffb',

  'green-dark':       '#197c3a',
  'green':            '#009a38',
  'green-50':         '#80cd9c',
  'green-25':         '#bfe6cd'
};
