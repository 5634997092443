import { Pipe, PipeTransform } from '@angular/core';
import { filter, gte, lte } from 'lodash-es';
import { DateRangeObject } from '../../filtering/dateRange/date-range-object';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {

  parseDateToUnixTimeStamp(input: string | number | Date): number {
    if (typeof input === 'string') {
      const dateSplit = input.split('T');
      return new Date(dateSplit[0]).getTime();
    } else if (input instanceof Date) {
      const newDate = new Date(input.getFullYear(), input.getMonth(), input.getDate(), 0, 0, 0, 0);
      return newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000;
    }
    return input;
  }

  /**
   * @ngdoc method
   * @name parseTimeZoneOffsetAndReturnTimeStamp
   *
   *
   * @description
   *
   * Datepicker shows one day behind when using UTC format in model
   *
   * FYI ISSUE:- https://github.com/angular-ui/bootstrap/issues/2628
   *
   * Solution:- http://stackoverflow.com/a/17329571
   *
   * Note:- should be parsed only for selected date picker input
   *
   */
  parseTimeZoneOffsetAndReturnTimeStamp(input) {
    // check for valid date
    if (!isNaN(Date.parse(input))) {
      const d = new Date(input);
      d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);

      const offsetDate = new Date(d).toISOString();
      return this.parseDateToUnixTimeStamp(offsetDate);
    } else {
      return false;
    }

  }

  transform(dataset: any[], dateRangeObject: DateRangeObject): any {
    /**
     * Scenario 1:-
     * fromDate is null and toDate is null
     */
    if (dateRangeObject.rangeOfDate.fromDate === null && dateRangeObject.rangeOfDate.toDate === null) {
      return dataset;
    }

    /**
     * Scenario 2:-
     * fromDate not null and toDate is null
     */
    if (dateRangeObject.rangeOfDate.fromDate !== null && dateRangeObject.rangeOfDate.toDate === null) {
      return this.transformByFromDate(dataset, dateRangeObject);
    }

    /**
     * Scenario 3:-
     * fromDate not null and toDate is not null
     */
    if (dateRangeObject.rangeOfDate.fromDate !== null && dateRangeObject.rangeOfDate.toDate !== null) {
      return this.transformByBothDates(dataset, dateRangeObject);
    }

    /**
     * Scenario 4:-
     * Fix for ISHL-30
     * Requirement
     * When fromDate is null and toDate is defined.
     */
    if (dateRangeObject.rangeOfDate.fromDate === null && dateRangeObject.rangeOfDate.toDate !== null) {
      return this.transformByToDate(dataset, dateRangeObject);
    }
  }

  transformByFromDate(dataset: any[], dateRangeObject: DateRangeObject) {
    const dateFrom = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.fromDate);

    return this.transformByDate(dataset, dateRangeObject,
      !!dateFrom,
      (readPropertyParsed) => gte(readPropertyParsed, dateFrom));
  }

  transformByToDate(dataset: any[], dateRangeObject: DateRangeObject) {
    const dateToParsed = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.toDate);

    return this.transformByDate(dataset, dateRangeObject,
      !!dateToParsed,
      (readPropertyParsed) => lte(readPropertyParsed, dateToParsed));
  }

  transformByBothDates(dataset: any[], dateRangeObject: DateRangeObject) {
    const dateFrom = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.fromDate);
    const dateTo = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.toDate);

    return this.transformByDate(dataset, dateRangeObject,
      (!!dateFrom && !!dateTo),
      (readPropertyParsed) => gte(readPropertyParsed, dateFrom) && lte(readPropertyParsed, dateTo));
  }

  transformByDate(dataset: any[], dateRangeObject: DateRangeObject, shouldProcess: boolean, compareFunc: (timestamp: number) => boolean) {
    const result = [];

    if (shouldProcess) {
      filter(dataset, (el) => {
        let propertyValues: any[];
        if (typeof dateRangeObject.propertyKey === 'string') {
          propertyValues = [[dateRangeObject.propertyKey, el[dateRangeObject.propertyKey]]];
        } else {
          propertyValues = Object.entries(el)
            .filter(([key, value]) => (dateRangeObject.propertyKey as string[]).includes(key) && !!value);
        }

        if (propertyValues.length > 0) {
          propertyValues.forEach(value => {
            const readPropertyParsed = this.parseDateToUnixTimeStamp(value[1]);
            if (compareFunc(readPropertyParsed) && !result.includes(el)) {
              result.push(el);
            }
          });
        }
      });
    }

    return result;
  }
}
