import { WindowService } from '../../../core/window.service';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[hlScrollTop]'
})
export class ScrollTopDirective {

  window = this.windowService.nativeWindow;

  constructor(private elRef: ElementRef,
    private windowService: WindowService) { }

  @HostListener('click', ['$event'])
  onClick($event) {
    $event.stopImmediatePropagation();
    if (typeof this.window.scrollTo !== 'undefined') {
      this.window.scrollTo(0, 0);
    }
  }
}
