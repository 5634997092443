<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)"
  xmlns:svg="http://www.w3.org/2000/svg"
  >
  <svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect
        [attr.width]="dims.width + 10"
        [attr.height]="dims.height + 10"
        [attr.transform]="'translate(-5, -5)'"/>
    </svg:clipPath>
    </svg:defs>
    <svg:g [attr.transform]="transform" class="line-chart chart">
    @if (xAxis) {
      <svg:g ngx-charts-x-axis
        [xScale]="xScaleLine"
        [dims]="dims"
        [showLabel]="showXAxisLabel"
        [labelText]="xAxisLabel"
        [tickFormatting]="xAxisTickFormatting"
        [ticks]="xAxisTicks"
        (dimensionsChanged)="updateXAxisHeight($event)">
      </svg:g>
    }
    @if (yAxis) {
      <svg:g ngx-charts-y-axis
        [yScale]="yMainScale"
        [dims]="dims"
        [yOrient]="yOrientLeft"
        [showGridLines]="showYMainGridLines"
        [showLabel]="yMainAxisShowLabel"
        [labelText]="yMainAxisLabel"
        [tickFormatting]="yMainAxisTickFormatting"
        [ticks]="yMainAxisTicks"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        (dimensionsChanged)="updateYAxisWidth($event)">
      </svg:g>
    }
    @if (isSSR) {
      @if (yAxis && lineChartSecondary.length) {
        <svg:g charts-y-axis-ssr
          [yScale]="ySecondScale"
          [dims]="dims"
          [yOrient]="yOrientRight"
          [showGridLines]="showYSecondaryGridLines"
          [showLabel]="ySecondaryAxisShowLabel"
          [labelText]="ySecondaryAxisLabel"
          [tickFormatting]="ySecondaryAxisTickFormatting"
          [ticks]="ySecondaryAxisTicks"
          [referenceLines]="referenceLines"
          [showRefLines]="showRefLines"
          [showRefLabels]="showRefLabels">
        </svg:g>
      }
    } @else {
      @if (yAxis && lineChartSecondary.length) {
        <svg:g ngx-charts-y-axis
          [yScale]="ySecondScale"
          [dims]="dims"
          [yOrient]="yOrientRight"
          [showGridLines]="showYSecondaryGridLines"
          [showLabel]="ySecondaryAxisShowLabel"
          [labelText]="ySecondaryAxisLabel"
          [tickFormatting]="ySecondaryAxisTickFormatting"
          [ticks]="ySecondaryAxisTicks"
          [referenceLines]="referenceLines"
          [showRefLines]="showRefLines"
          [showRefLabels]="showRefLabels"
          (dimensionsChanged)="updateYAxisWidth($event)">
        </svg:g>
      }
    }
    <svg:g [attr.clip-path]="clipPath">
      @for (series of lineChart; track trackBy($index, series)) {
        <svg:g>
          <svg:g hl-charts-line-series
            [xScale]="xScaleLine"
            [yScale]="yMainScale"
            [colors]="colorsLine"
            [data]="series"
            [activeEntries]="isSSR ? chartsData[0].series : activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [hasRange]="hasRange"
            [rangeFillOpacity]="rangeFillOpacity"
            [animations]="animations"
            />
            </svg:g>
          }

      @for (seriesSecondary of lineChartSecondary; track trackBy($index, seriesSecondary)) {
        <svg:g>
          <svg:g ngx-charts-line-series
            [xScale]="xScaleLine"
            [yScale]="ySecondScale"
            [colors]="colorsLine"
            [data]="seriesSecondary"
            [activeEntries]="activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [hasRange]="hasRange"
            [rangeFillOpacity]="rangeFillOpacity"
            [animations]="animations"
          />
        </svg:g>
      }

      @if (!tooltipDisabled) {
        <svg:g ngx-charts-tooltip-area
          [dims]="dims"
          [xSet]="xSet"
          [xScale]="xScaleLine"
          [yScale]="yMainScale"
          [results]="chartsData"
          [colors]="colorsLine"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="seriesTooltipTemplate"
          (hover)="updateHoveredVertical($event)"
        />
      }

      @for (series of lineChart; track series) {
        <svg:g>
          <svg:g ngx-charts-circle-series
            [xScale]="xScaleLine"
            [yScale]="yMainScale"
            [colors]="colorsLine"
            [data]="series"
            [scaleType]="scaleType"
            [visibleValue]="hoveredVertical"
            [activeEntries]="activeEntries"
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTemplate]="tooltipTemplate"
            (select)="onClick($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          />
        </svg:g>
      }

      @for (series of lineChartSecondary; track series) {
        <svg:g>
          <svg:g ngx-charts-circle-series
            [xScale]="xScaleLine"
            [yScale]="ySecondScale"
            [colors]="colorsLine"
            [data]="series"
            [scaleType]="scaleType"
            [visibleValue]="hoveredVertical"
            [activeEntries]="activeEntries"
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTemplate]="tooltipTemplate"
            (select)="onClick($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          />
        </svg:g>
      }
      </svg:g>
      </svg:g>
      @if (timeline) {
        <svg:g ngx-charts-timeline
          [attr.transform]="timelineTransform"
          [results]="chartsData"
          [view]="[timelineWidth, height]"
          [height]="timelineHeight"
          [scheme]="scheme"
          [customColors]="customColors"
          [scaleType]="scaleType"
          [legend]="legend"
          (select)="onSelected($event)"
          (onDomainChange)="updateDomain($event)">
          @for (series of chartsData; track trackBy($index, series)) {
            <svg:g>
              <svg:g ngx-charts-line-series
                [xScale]="timelineXScale"
                [yScale]="timelineYScale"
                [colors]="colors"
                [data]="series"
                [scaleType]="scaleType"
                [curve]="curve"
                [hasRange]="hasRange"
                [animations]="animations"
              />
            </svg:g>
          }
        </svg:g>
      }
      @for (label of labels; track label) {
        <svg:g>
          <svg:text [attr.x]="label.x - 5" [attr.y]="label.y + 10" [attr.font-size]="10" >{{label.value}}</svg:text>
        </svg:g>
      }
    </ngx-charts-chart>
