import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'hl-nav-list-link',
  templateUrl: './nav-list-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavListLinkComponent implements OnChanges {

  @Input() elements: number;
  @Input() currentIndex: number;
  @Input() currentPaginationItems: number;
  @Input() title: string;

  // for notifying parent component to update current selection
  @Output() navLinkUpdate = new EventEmitter<number>();
  @Output() paginationUpdate = new EventEmitter<any>();
  pages: number[];

  ngOnChanges(): void {
    this.pages = Array.from({length: this.elements}, (value, index) => index + 1);
  }

  doNext() {
    if (this.elements > 0 && this.currentIndex + 1 !== this.elements) {
      if (this.currentIndex + 2 >= this.currentPaginationItems) {
        this.paginationUpdate.emit();
      }
      this.navLinkUpdate.emit(this.getTargetIndex(this.currentIndex + 1));
    }
  }

  doPrev() {
    if (this.elements > 0 && this.currentIndex !== 0) {
      this.navLinkUpdate.emit(this.getTargetIndex(this.currentIndex - 1));
    }
  }

  doPage(page: number) {
    const nextIndex = page - 1;
    if (page === this.currentIndex + 1) {
      return;
    } else if (this.currentIndex > nextIndex) {
      this.currentIndex = nextIndex + 1;
      this.doPrev();
    } else {
      this.currentIndex = nextIndex - 1;
      this.doNext();
    }
  }

  getTargetIndex(index) {
    let targetIndex = index;
    if (index > this.elements - 1) {
      targetIndex = 0;
    } else {
      if (index < 0) {
        targetIndex = this.elements - 1;
      }
    }
    return targetIndex;
  }

  isPageHidden(page: number): boolean {
    const currentPage = this.currentIndex + 1;
    const range = 9;
    let maxPage = range;

    if (currentPage + 1 > 5) {
      if (currentPage === this.elements) {
        maxPage = currentPage;
      } else if (currentPage + 4 >= this.elements) {
        maxPage = this.elements;
      } else {
        maxPage = currentPage + 4;
      }
    }
    const minPage = maxPage - range + 1;

    return (page > maxPage || page < minPage);
  }
}
