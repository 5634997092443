@if (data?.length) {
  <div class="container card-slider-container">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="card-slider slick-initialized slick-slider slick-dotted" [class.is-exceeding-left]="enableLeftSlide()"
          [class.is-exceeding-right]="enableRightSlide()">
          @if (showArrows()) {
            <button class="slick-prev slick-arrow icon-arrow-left-large" [class.slick-disabled]="!enableLeftSlide()" [disabled]="!enableLeftSlide()"
              (click)="slideLeft()" aria-label="Previous" type="button" tabindex="0">
              Previous
            </button>
          }
          <div class="slick-list draggable" #cardSlickList>
            <div class="slick-track" #cardSlickTrack
              [ngStyle]="{'width': slideTrackWidth + 'px', 'transform': 'translate('+ slideTrackOffset +'px, 0px)'}">
              @for (card of data; track card; let i = $index) {
                <div class="card card--small card-slider__item slick-slide card--linked" [class.card--clickable]="isBodyAndImageClickable"
                  tabindex="-1" role="tabpanel" aria-hidden="true" [ngStyle]="{'width': cardWidth + 'px'}"
                  [attr.data-cy]="'card-slider-card-' + (i + 1)">
                  <div class="card__image" (click)="emitValue(card)">
                    <div class="aspect-ratio-16x9" [ngClass]="isImageFitSetToContain ? 'aspect-ratio--contain' : 'aspect-ratio--cover'">
                      <img [alt]="card.imgDesc" [src]="card.imgSrc">
                    </div>
                    @if (additionalImageContent && card.additionalImageContent) {
                      <ng-container
                        *ngTemplateOutlet="additionalImageContent; context: {$implicit: card.additionalImageContent, index: i}"></ng-container>
                    }
                  </div>
                  <div class="card__body" (click)="emitValue(card)">
                    <h4 class="margin-top-0" [innerHTML]="card.title"></h4>
                    @if (!bodyTemplate) {
                      <p>{{ card.body }}</p>
                    } @else {
                      <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: card.body, index: i}"></ng-container>
                    }
                  </div>
                  @if (footerTemplate) {
                    <div class="card__footer">
                      <ng-container
                        *ngTemplateOutlet="footerTemplate; context: {$implicit: card.footer, additionalProperties: card.additionalProperties, index: i}"></ng-container>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          @if (showArrows()) {
            <button class="slick-next slick-arrow icon-arrow-right-large" [class.slick-disabled]="!enableRightSlide()"
              [disabled]="!enableRightSlide()" (click)="slideRight()" aria-label="Next" type="button" tabindex="0">
              Next
            </button>
          }
          @if (dots) {
            <ul class="slick-dots" role="tablist">
              @for (_ of dots; track _; let i = $index) {
                <li role="presentation" [class.slick-active]="isDotActive(i)">
                  <button type="button" role="tab" tabindex="0" (click)="slideDot(i)">{{ i }}</button>
                </li>
              }
            </ul>
          }
        </div>
      </div>
    </div>
  </div>
}
