import { Pipe, PipeTransform, TemplateRef } from '@angular/core';

export interface TicketItemListInput {
  type: string;
  ticketNumber: TemplateRef<any>;
  productName: TemplateRef<any>;
  siemensEquipmentId: TemplateRef<any>;
  ticketCreationTimestamp: TemplateRef<any>;
  problemSeverityDescription: TemplateRef<any>;
  originalEffectCodeDescription: TemplateRef<any>;
  customerName: TemplateRef<any>;
  city: TemplateRef<any>;
  serialNumber: TemplateRef<any>;
}

@Pipe({
  name: 'ticketListItemTemplateMapPipe'
})
export class TicketListItemTemplateMapPipe implements PipeTransform {
  transform(input: TicketItemListInput): TemplateRef<any> {
    switch (input.type) {
      case 'ticketNumber':
        return input.ticketNumber;
      case 'productName_componentId':
        return input.productName;
      case 'siemensEquipmentId_customerComponentId':
        return input.siemensEquipmentId;
      case 'ticketCreationTimestamp':
        return input.ticketCreationTimestamp;
      case 'problemSeverityDescription':
        return input.problemSeverityDescription;
      case 'originalEffectCodeDescription':
        return input.originalEffectCodeDescription;
      case 'customerName':
        return input.customerName;
      case 'city':
        return input.city;
      case 'serialNumber':
        return input.serialNumber;
      default:
        return null;
    }
  }
}
