import { IMessage, StompHeaders } from '@stomp/stompjs';
import { Observable } from 'rxjs';
import { RxStompWrapper } from './rx-stomp-wrapper';

export class RxStompService {

  private watchCache: Map<string, Observable<IMessage>> = new Map<string, Observable<IMessage>>();

  constructor(private rxStompWrapper: RxStompWrapper) {
  }

  watch(destination: string, headers?: StompHeaders): Observable<IMessage> {
    if (this.watchCache.has(destination)) {
      return this.watchCache.get(destination);
    }
    this.ensureActive();
    const watch = this.rxStompWrapper.watch(destination, headers);
    this.watchCache.set(destination, watch);
    return watch;
  }

  private ensureActive() {
    if (!this.rxStompWrapper.active()) {
      this.rxStompWrapper.activate();
    }
  }
}
