import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Series } from './diagram';
import { BarVerticalComponent, Color, LegendOptions, LegendPosition } from '@swimlane/tpf-ngx-charts';

@Component({
  selector: 'hl-diagram-stacked-bars',
  template: `
    @if (orientation === 'horizontal') {
      <ngx-charts-bar-horizontal-stacked #chart
        [class.transparent-bar]="transparentBar" [view]="view" [roundDomains]="true"
        [gradient]="gradient" [customColors]="customColors" [results]="barData" [xAxis]="showXAxis"
        [yAxis]="showYAxis" [legend]="showLegend" [legendPosition]="legendPosition"
        [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
        [maxYAxisTickLength]="maxYAxisTickLength" [xScaleMax]="xScaleMax" [scheme]="scheme"
        [xAxisTickFormatting]="xAxisTickFormatting" [animations]="animations"
        [barPadding]="barPaddingHorizontal" [tooltipDisabled]="transparentBar"
        (select)="onSelected($event)" [legendTitle]="legendTitle" [showDataLabel]="false">
        @if (showCustomTooltip) {
          <ng-template #tooltipTemplate let-model="model">
            <ng-container *ngTemplateOutlet="tooltip; context: {model}"></ng-container>
          </ng-template>
        }
      </ngx-charts-bar-horizontal-stacked>
    } @else {
      <hl-bar-vertical-stacked #chart [view]="view" [class.transparent-bar]="transparentBar"
        [roundDomains]="true" [gradient]="gradient" [customColors]="customColors"
        [results]="barData" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
        [legendPosition]="legendPosition" [showGridLines]="showGridLines"
        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [yScaleMax]="yScaleMax"
        [scheme]="scheme" [yAxisTickFormatting]="yAxisTickFormatting" [animations]="animations"
        [barPadding]="barPaddingVertical" [tooltipDisabled]="transparentBar"
        [trimXAxisTicks]="trimXAxisTicks" (select)="onSelected($event)" [legendTitle]="legendTitle">
        @if (showCustomTooltip) {
          <ng-template #tooltipTemplate let-model="model">
            <ng-container *ngTemplateOutlet="tooltip; context: {model}"></ng-container>
          </ng-template>
        }
      </hl-bar-vertical-stacked>
    }
    <ng-template #tooltip let-model="model">
      <span class="tooltip-label">{{model | sprStackedBarsTooltipLabel}}</span>
      <span class="tooltip-val">{{model.additionalData}} ({{model.value.toLocaleString()}}%)</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramStackedBarsComponent {
  @ViewChild('chart')
  private chart: BarVerticalComponent;

  @Input() scheme: string | Color;
  @Input() animations: boolean;
  @Input() showGridLines = true;
  @Input() gradient = true;
  @Input() showDataLabel = false;
  @Input() useMonochrome = false;

  @Input() set results(series: Series[]) {
    if (!!series && !series.length) {
      this.barData = [{
        name: '',
        series: [
          {name: '', value: '0'},
          {name: '', value: '100'}
        ]
      }];
      this.transparentBar = true;
      // tslint:disable-next-line:triple-equals
    } else if (series.length === 1 && series[0].series[0].value == 0 && series[0].series[1].value == 0) {
      this.barData = series;
      this.barData[0].series[0].value = 100;
      this.transparentBar = true;
    } else {
      this.barData = series;
      this.transparentBar = false;
    }
    this.showCustomTooltip = this.areAdditionalDataPresent(series);
  }

  @Input() view: [number, number] = null;
  @Input() orientation: string;
  /**
   * The number of results to start the dynamic bar padding
   */
  @Input() minResultsForDynamicPadding: number;

  @Input() showXAxis = true;
  @Input() trimXAxisTicks = true;
  @Input() showXAxisLabel = false;
  @Input() xAxisLabel: string;
  @Input() xScaleMax: number;
  @Input() xAxisTickFormatting: any;

  @Input() showYAxis = true;
  @Input() showYAxisLabel = false;
  @Input() yAxisLabel: string;
  @Input() yScaleMax: number;
  @Input() yAxisTickFormatting: any;
  @Input() maxYAxisTickLength: number;

  @Input() showLegend = false;
  @Input() legendPosition = LegendPosition.Below;
  @Input() legendTitle: string;

  /**
   * Emitted when any stacked bar is clicked. The data contains the name of clicked series.
   */
  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  barData: Series[] = [];
  customColors: object[] = [];
  transparentBar = false;
  showCustomTooltip = false;

  get barPaddingVertical(): number {
    return this.chart && this.chart.width ? Math.ceil(this.chart.width / this.barData.length * .7) : 45;
  }

  get barPaddingHorizontal(): number {
    const barPadding = 5;
    return this.barData.length > this.minResultsForDynamicPadding ?
      barPadding :
      barPadding + (this.minResultsForDynamicPadding - this.barData.length) * barPadding * 0.1;
  }

  onSelected($event) {
    this.onSelect.emit($event);
  }

  getLegendOptions(): LegendOptions {
    return this.chart.getLegendOptions();
  }

  private areAdditionalDataPresent(series: Series[]) {
    return series.findIndex(seriesData => seriesData.series.findIndex(data => !!data?.additionalData ||
      data?.additionalData === 0) >= 0) >= 0;
  }
}
