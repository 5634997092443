import { Injectable, OnDestroy } from '@angular/core';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyEquipmentService implements OnDestroy {

  private myEquipment$ = new BehaviorSubject<string[]>([]);

  get myEquipmentObservable$(): Observable<string[]> {
    return this.myEquipment$.asObservable();
  }

  constructor(private equipmentRestService: EquipmentRestService) {
  }

  toggleEquipmentInMyEquipmentList(equipmentKey: string) {
    const equipment = [...this.myEquipment$.value];
    const index = equipment.indexOf(equipmentKey);
    if (index === -1) {
      equipment.push(equipmentKey);
    } else {
      equipment.splice(index, 1);
    }
    this.myEquipment$.next(equipment);
  }

  initialize(): void {
    this.equipmentRestService.getMyProfile().subscribe(response => {
      this.myEquipment$.next(response.equipment);
    });
  }

  ngOnDestroy(): void {
    this.myEquipment$.complete();
  }
}
