import { Pipe, PipeTransform } from '@angular/core';
import { filter, forEach, includes, isEqual } from 'lodash-es';

@Pipe({
  name: 'multiSelect'
})
export class MultiSelectPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.execFilterAll(value, args);
  }

  transformArray(value: any, args?: any, orLogic: boolean = false): any {
    return this.execArrayFilterAll(value, args, orLogic);
  }

  /**
   * @description
   * Method that recursively iterate through all filters and call individual filters
   */
  execFilterAll(filterDataset, listOfFilters) {
    forEach(listOfFilters, (values, key) => {
      if (values && values.length > 0) {
        filterDataset = this.execFilter(key, values, filterDataset);
      }
    });
    return filterDataset;
  }

  /**
   *
   * @description
   * Method that recursively iterate through all filters and call individual array filters
   */
  execArrayFilterAll(filterDataset, listOfFilters, orLogic: boolean = false) {
    forEach(listOfFilters, (values, key) => {
      if (values && values.length > 0) {
        if (orLogic) {
          filterDataset = this.execArrayFilterOrLogic(key, values, filterDataset);
        } else {
          filterDataset = this.execArrayFilter(key, values, filterDataset);
        }
      }
    });
    return filterDataset;
  }

  /**
   *
   * @description
   * Filter individual filtered result set by the given predicate object.
   */
  execFilter(checkKey, values, filteredTempRes) {
    const filtered = filteredTempRes;
    const newFiltered = [];
    filter(filtered, el => {
      forEach(values, value => {
        // We need to iterate through full list since values can be same for same key
        if (isEqual(el[checkKey], value)) {
          newFiltered.push(el);
        }
      });
    });
    return newFiltered;
  }

  execArrayFilter(checkKey, values, filteredTempRes): any[] {
    return filter(filteredTempRes, item => {
      for (const value of values) {
        if (!includes(item[checkKey], value)) {
          return false;
        }
      }
      return true;
    });
  }

  execArrayFilterOrLogic(checkKey, values, filteredTempRes) {
    return filter(filteredTempRes, item => {
      for (const value of values) {
        if (includes(item[checkKey], value)) {
          return true;
        }
      }
      return false;
    });
  }
}
