import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../cache/cacheable-http-client';
import { equipmentStatusRestName } from '../../rest-services/equipment-rest-constants.service';

@Injectable({providedIn: 'root'})
export class EquipmentStatusWebsocketStateService {
  isWebsocketForEquipmentStatusActive = false;

  constructor(private cacheableHttpClient: CacheableHttpClient) {
  }

  manageCacheForWebsocketEquipmentStatus() {
    if (this.isWebsocketForEquipmentStatusActive) {
      this.cacheableHttpClient.clearCache(equipmentStatusRestName);
    }
  }
}
