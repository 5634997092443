import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CardSliderCard } from './card-slider-card';
import { cardSliderConfig } from './card-slider-config';

@Component({
  selector: 'hl-card-slider',
  templateUrl: './card-slider.component.html'
})
export class CardSliderComponent implements AfterViewInit, OnDestroy {

  @ViewChild('cardSlickList') cardSlickList: ElementRef;
  @ViewChild('cardSlickTrack') cardSlickTrack: ElementRef;

  data: CardSliderCard[];

  @Input()
  set cardSliderData(data: CardSliderCard[]) {
    this.data = data;
    window.clearTimeout(this.animationDelay);
    this.cdr.detectChanges();
    this.currentCardIndex = 0;
    this.initialize();
  }

  @Input() isBodyAndImageClickable = false;
  @Input() isImageFitSetToContain = false;
  @Output() onBodyOrImageClick: EventEmitter<CardSliderCard> = new EventEmitter<CardSliderCard>();

  @Input() additionalImageContent: TemplateRef<ElementRef>;
  @Input() bodyTemplate: TemplateRef<ElementRef>;
  @Input() footerTemplate: TemplateRef<ElementRef>;

  resizeDelay;
  windowWidth;

  cardWidth: number;
  slideTrackWidth: number;
  slideTrackOffset = 0;
  oldSlideTrackOffset = 0;
  dots: any[];
  animating = false;
  animationDelay;

  currentCardIndex = 0;
  slidesToShow = cardSliderConfig.defaultSlidesToShow;
  slidesToScroll = cardSliderConfig.defaultSlidesToScroll;
  slidesToMinSwipe = cardSliderConfig.defaultSlidesToMinSwipe;

  // swiping/dragging variables
  touchmoveTarget;
  touchendListenFunc;
  touchcancelListenFunc;
  dragging = false;
  scrolling = false;
  swiping = false;
  startX: number;
  startY: number;
  curX: number;
  curY: number;
  swipeLength: number;
  config: any;


  private getCorrectConfig(baseWidth: number): { slidesToShow, slidesToScroll, slidesToMinSwipe } {
    let config = {slidesToShow: 3.2, slidesToScroll: 2, slidesToMinSwipe: 1.5};

    if (cardSliderConfig?.responsive?.length) {
      for (const breakpoint in cardSliderConfig.responsive) {
        if (baseWidth < cardSliderConfig.responsive[breakpoint].breakpoint) {
          config = cardSliderConfig.responsive[breakpoint].settings;
        }
      }
    }
    return config;
  }

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
  ) {
  }

  emitValue(card: CardSliderCard) {
    if (this.isBodyAndImageClickable && this.startX === this.curX && this.startY === this.curY) {
      this.onBodyOrImageClick.emit(card);
    }
  }

  ngAfterViewInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.removePreviousTouchListeners();
  }

  @HostListener('window:resize')
  resizeWindow() {
    if (window.innerWidth !== this.windowWidth) {
      window.clearTimeout(this.resizeDelay);
      this.resizeDelay = window.setTimeout(() => {
        this.windowWidth = window.innerWidth;
        this.initialize();
      }, 50);
    }
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onStart(event) {
    this.swipeStart(event);
  }

  @HostListener('mousemove', ['$event'])
  onMove(event) {
    this.swipeMove(event);
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  onEnd() {
    this.swipeEnd();
  }

  getSlideCount() {
    return this.data?.length ?? 0;
  }

  initialize() {
    // First get width of available space to show slider and whole element
    const baseWidth = this.cardSlickList?.nativeElement?.getBoundingClientRect()?.width ?? window.innerWidth;
    const elementWidth = this.el?.nativeElement?.getBoundingClientRect()?.width ?? window.innerWidth;

    // Secondly set correct config based on width
    const configSetting = !!this.config ? this.config : this.getCorrectConfig(elementWidth);
    this.slidesToShow = configSetting.slidesToShow;
    this.slidesToScroll = configSetting.slidesToScroll;
    this.slidesToMinSwipe = configSetting.slidesToMinSwipe;

    // Initialize other elements
    this.cardWidth = this.getCardWidth(baseWidth);
    this.dots = this.getDotList();
    this.updateElements();

    this.cdr.detectChanges();
  }

  private getCardWidth(baseWidth: number): number {
    return baseWidth / this.slidesToShow;
  }

  private getDotList(): any[] {
    return this.getSlideCount() > this.slidesToShow ?
      Array(Math.ceil((this.getSlideCount() - this.slidesToShow) / this.slidesToScroll) + 1) : null;
  }

  private updateElements() {
    this.slideTrackWidth = this.cardWidth * this.getSlideCount();

    this.slideCards(this.currentCardIndex);
  }

  swipeStart = (event) => {
    if (this.getSlideCount() <= this.slidesToShow || (event.touches && event.touches.length > 1)) {
      this.dragging = false;
      return false;
    }

    this.dragging = true;
    this.swipeLength = 0;
    this.oldSlideTrackOffset = this.slideTrackOffset;

    if (event?.touches?.length) {
      const singleTouch = event.touches[0];

      this.startX = this.curX = singleTouch.pageX;
      this.startY = this.curY = singleTouch.pageY;

      this.removePreviousTouchListeners();
      this.registerTouchListeners(event);
    } else {
      this.startX = this.curX = event.clientX;
      this.startY = this.curY = event.clientY;
    }
  }

  swipeMove = (event) => {
    const touches = event?.touches;

    if (!this.dragging || this.scrolling || touches && touches.length !== 1) {
      return false;
    }

    const singleTouch = touches && touches[0];
    this.curX = singleTouch?.pageX ?? event.clientX;
    this.curY = singleTouch?.pageY ?? event.clientY;

    const verticalSwipeLength = Math.round(Math.sqrt(Math.pow(this.curY - this.startY, 2)));

    if (!this.swiping && verticalSwipeLength > 4) {
      this.scrolling = true;
      return false;
    }

    this.swipeLength = Math.round(Math.sqrt(Math.pow(this.curX - this.startX, 2)));

    if (event && this.swipeLength > 4) {
      this.swiping = true;
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }

    const swipeDirection = this.swipeDirection();
    let swipeLength = this.swipeLength;

    if ((this.currentCardIndex <= 0 && swipeDirection === 'right') ||
      (this.currentCardIndex >= (this.getSlideCount() - this.slidesToShow) && swipeDirection === 'left')) {
      swipeLength = this.swipeLength * cardSliderConfig.edgeFriction;
    }

    if (this.animating === true) {
      return false;
    }

    const positionOffset = this.curX > this.startX ? 1 : -1;

    this.slideTrackOffset = this.oldSlideTrackOffset + (swipeLength * positionOffset);
  }

  swipeEnd = () => {
    if (!this.dragging || this.scrolling || !this.curX) {
      this.scrolling = false;
      return false;
    }

    this.removePreviousTouchListeners();
    this.dragging = false;
    this.swiping = false;

    this.slideTrackOffset = this.oldSlideTrackOffset;

    if (this.swipeLength >= (this.cardWidth * this.slidesToMinSwipe)) {
      const swipeDirection = this.swipeDirection();

      let newIndex = this.currentCardIndex;

      switch (swipeDirection) {
        case 'left':
          newIndex = this.leftSwipeIndex(newIndex);
          break;
        case 'right':
          newIndex = this.rightSwipeIndex(newIndex);
          break;
        case 'vertical':
        default:
          break;
      }

      this.slideCards(newIndex);
    } else if (this.startX !== this.curX) {
      this.slideCards(this.currentCardIndex);
    }
  }

  private leftSwipeIndex(newIndex: number) {
    const slideCount = this.getSlideCount();
    let resultIndex = newIndex;

    if (this.currentCardIndex < (slideCount - this.slidesToShow)) {
      const newIndexBySlidesToScroll = this.currentCardIndex + this.slidesToScroll;

      resultIndex = newIndexBySlidesToScroll <= slideCount ?
        newIndexBySlidesToScroll :
        ((this.dots.length - 1) * this.slidesToScroll);
    }

    return resultIndex;
  }

  private rightSwipeIndex(newIndex: number) {
    let resultIndex = newIndex;

    if (this.currentCardIndex > 0) {
      const newIndexBySlidesToScroll = this.currentCardIndex - this.slidesToScroll;
      resultIndex = newIndexBySlidesToScroll >= 0 ? newIndexBySlidesToScroll : 0;
    }

    return resultIndex;
  }

  private registerTouchListeners(event) {
    this.touchmoveTarget = event.target;
    this.touchmoveTarget.addEventListener('touchmove', this.swipeMove);

    this.touchendListenFunc = this.renderer.listen(event.target, 'touchend', this.swipeEnd);
    this.touchcancelListenFunc = this.renderer.listen(event.target, 'touchcancel', this.swipeEnd);
  }

  private removePreviousTouchListeners() {
    this.touchmoveTarget?.removeEventListener('touchmove', this.swipeMove);
    this.touchmoveTarget = null;

    if (this.touchendListenFunc) {
      this.touchendListenFunc();
    }
    if (this.touchcancelListenFunc) {
      this.touchcancelListenFunc();
    }

    this.touchendListenFunc = null;
    this.touchcancelListenFunc = null;
  }

  private swipeDirection() {

    const xDist = this.startX - this.curX;
    const yDist = this.startY - this.curY;
    const r = Math.atan2(yDist, xDist);

    let swipeAngle = Math.round(r * 180 / Math.PI);
    if (swipeAngle < 0) {
      swipeAngle = 360 - Math.abs(swipeAngle);
    }

    if ((swipeAngle <= 45) && (swipeAngle >= 0)) {
      return 'left';
    } else if ((swipeAngle <= 360) && (swipeAngle >= 315)) {
      return 'left';
    } else if ((swipeAngle >= 135) && (swipeAngle <= 225)) {
      return 'right';
    }

    return 'vertical';
  }

  showArrows(): boolean {
    return this.getSlideCount() > this.slidesToShow;
  }

  enableLeftSlide(): boolean {
    return this.getSlideCount() > this.slidesToShow && this.currentCardIndex > 0;
  }

  enableRightSlide(): boolean {
    return this.getSlideCount() > this.slidesToShow && (this.currentCardIndex + this.slidesToShow) < this.getSlideCount();
  }

  isDotActive(dot: number): boolean {
    return Math.floor(this.currentCardIndex / this.slidesToScroll) === dot;
  }

  slideLeft() {
    const newIndex = this.currentCardIndex < this.slidesToScroll ?
      0 : (this.currentCardIndex - this.slidesToScroll);

    this.slideCards(newIndex);
  }

  slideRight() {
    const newIndex = this.currentCardIndex + this.slidesToScroll;

    this.slideCards(newIndex);
  }

  slideDot(dot: number) {
    const newIndex = dot * this.slidesToScroll;

    this.slideCards(newIndex);
  }

  slideCards(targetIndex: number) {
    if (!this.cardSlickTrack?.nativeElement) {
      return;
    }

    this.currentCardIndex = targetIndex;

    let newOffset = 0;
    const slideCount = this.getSlideCount();
    if (slideCount > this.slidesToShow) {
      newOffset = -((
        (this.currentCardIndex + this.slidesToShow) >= slideCount ?
          slideCount - this.slidesToShow :
          this.currentCardIndex
      ) * this.cardWidth);
    }

    this.animating = true;
    window.clearTimeout(this.animationDelay);

    this.renderer.setStyle(
      this.cardSlickTrack.nativeElement,
      'transition',
      'transform ' + cardSliderConfig.animationDuration + 'ms ease'
    );

    this.slideTrackOffset = newOffset;

    this.animationDelay = window.setTimeout(() => {
      this.renderer.removeStyle(
        this.cardSlickTrack.nativeElement,
        'transition'
      );
      this.animating = false;
    }, cardSliderConfig.animationDuration);
  }
}
