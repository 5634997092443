<div class="tabs" [ngClass]="{'tabs--my-institutions': isMyInstitutions}" attr.data-cy="{{cypressTag}}-tabs" #tabsContainer>
  <div [ngClass]="{'margin-bottom-0': isMyInstitutions || noMarginBottom, 'bare-list tabs__navigation-menu': navigationMenu,
  'bare-list tabs__list display-flex ': !subTabsView && !navigationMenu, 'tabs__mobile-navigation-menu': isBurgerMenuOpen && !subTabsView }"
    #tabsList>
    <ng-content></ng-content>
    @if (moreEnabled) {
      <div class="dropdown padding-0" [class.tabs__item]="!subTabsView" [class.is-open]="isDropdownOpen" [ngClass]="getDropdownDirectionClassName()"
        #tabsItemMore>
        <div class="dropdown__toggle tabs__subtab-padding" [ngClass]="moreAdditionalStyle" (click)="toggleDropdown()" #tabsToggleMore>
          <span [translate]="subTabsCollectionTabName ? subTabsCollectionTabName : 'GENERIC_MORE_LINK'"
            attr.data-cy="{{cypressTag}}-tabs-more"></span>
          <i class="icon-arrow-down-small"></i>
        </div>
        <div class="menu dropdown__panel bare-list" [class.hide-subtab-content]="subTabsView && !isDropdownOpen" #tabsListMore
          [class.subtab__menu]="subTabsView"></div>
      </div>
    }
    @if (!subTabsView && !isBurgerMenuOpen) {
      <div class="tabs__active-line" #tabsActiveLine></div>
    }
  </div>
</div>
