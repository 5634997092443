<div #selectbox class="selectbox multi-selectbox nested-multi-selectbox" (click)="toggleDropdown($event)"
  [ngClass]="{'is-open has-focus': isDropdownOpen, 'is-active': nestedValue && nestedValue.length > 0, 'dropup': isDropUp, 'is-disabled': disabled}">
  <label class="selectbox__label">
    @if (label) {
      <span>{{ label | translate }}</span>
    }
    @if (isRequired) {
      <span>*</span>
    }
    @if (nestedValue?.length > 0) {
      <span>
        @if (nestedValue.length === nestedOptionsLength) {
          &nbsp;(<span [translate]="'GENERIC_LABEL_ALL'"></span>)
        } @else {
          &nbsp;(<span>{{ nestedValue.length }} {{ 'GENERIC_LABEL_OF' | translate }} {{ nestedOptionsLength }}</span>)
        }
      </span>
    }
  </label>
  <div class="multi-selectbox__label">
    @for (item of nestedValue; track item; let last = $last) {
      <span>{{ translateKeys ? (item.title | translate) : item.title }}{{ last ? '' : ', ' }}</span>
    }
  </div>
  <div class="multi-selectbox__button-wrapper">
    <button class="button button--link icon-arrow-left-large" (click)="closeDropdown($event)">Back</button>
  </div>
  @if (!disabled) {
    <ul class="menu dropdown__panel multi-selectbox__option-list">
      @if (showAllCheckItem) {
        <li class="menu__item multi-selectbox__option nested-multi-selectbox__option" (click)="nestedSelectAllWithUserClick($event)">
          <input type="checkbox" class="checkbox" [checked]="isAllChecked"/>
          <label>
            @if (showIcon) {
              <span class="icon"></span>
            }
            <span [translate]="isAllChecked ? 'GENERIC_LABEL_DESELECT_ALL' : 'GENERIC_LABEL_SELECT_ALL'"></span>
          </label>
        </li>
      }
      <hl-nested-multi-selectbox-tree [options]="nestedOptions" (superSelectedEvent)="acceptSetSelected($event)" [showIcon]="showIcon"
        [chainShrinking]="chainShrinking" [scrollOnExpand]="scrollOnExpand" (superScrollEvent)="expandScroll($event)"
        [translateKeys]="translateKeys"></hl-nested-multi-selectbox-tree>
    </ul>
  }
</div>

