import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, args: any): any {
    let limit = 20;
    let trail = '...';
    if (Array.isArray(args)) {
      limit = args.length > 0 ? parseInt(args[0], 10) : limit;
      trail = args.length > 1 ? args[1] : trail;
    } else if (typeof args === 'number') {
      limit = args;
    }
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
