/**
 * Config for card slider component contains:
 *  - default slides to show/scroll values
 *  - default minimal slides amount to trigger slide after swipe
 *  - edge friction modifier for preventing of dragging slider out of view too far
 *  - duration of animation in millis
 *  - responsive breakpoints (needs to be sorted in descending breakpoint order)
 */
export const cardSliderConfig = {
  defaultSlidesToShow: 3.2,
  defaultSlidesToScroll: 2,
  defaultSlidesToMinSwipe: 1.5,
  edgeFriction: 0.35,
  animationDuration: 500,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3.15,
        slidesToScroll: 2,
        slidesToMinSwipe: 1.5
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        slidesToMinSwipe: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        slidesToMinSwipe: 1
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        slidesToMinSwipe: 0.5
      }
    },
    {
      breakpoint: 401,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        slidesToMinSwipe: 0.4
      }
    }
  ]
};
