import { UntypedFormControl } from '@angular/forms';
import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[hlTextfieldAnimator]'
})
export class TextfieldAnimatorDirective implements AfterViewInit {
  private activeClass = 'is-active';
  private focusClass = 'has-focus';
  private inputClass = 'textfield__input';

  private isActive = false;
  private isFocused = false;
  
  @Input() hlTextfieldAnimator: UntypedFormControl;
  
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const input: HTMLInputElement = this.el.nativeElement.getElementsByClassName(this.inputClass )[0];
    if (input.hasAttribute('placeholder')) {
      this.setActive();
    }
    input.addEventListener('focus', () => {
      this.setFocus();
    });
    input.addEventListener('blur', () => {
      this.removeFocus();
      this.removeIsActive();
    });
  }

  blur() {
    this.renderer.removeClass(this.el.nativeElement, this.activeClass);
    this.renderer.removeClass(this.el.nativeElement, this.focusClass);
    this.isActive = false;
    this.isFocused = false;
  }

  setFocus() {
    this.renderer.addClass(this.el.nativeElement, this.activeClass);
    this.renderer.addClass(this.el.nativeElement, this.focusClass);
    this.isActive = true;
    this.isFocused = true;
  }

  removeFocus() {
    this.renderer.removeClass(this.el.nativeElement, this.focusClass);
    this.isFocused = false;
  }

  removeIsActive() {
    const input = this.el.nativeElement.getElementsByClassName(this.inputClass)[0];
    if (
      input.value.length <= 0 &&
      this.isFocused === false &&
      input.attributes.getNamedItem('placeholder') === null
    ) {
      this.renderer.removeClass(this.el.nativeElement, this.activeClass);
      this.isActive = false;
    }
  }

  setActive() {
    this.renderer.addClass(this.el.nativeElement, this.activeClass);
    this.isActive = true;
  }
}
