<div #selectbox class="selectbox multi-selectbox"
  [ngClass]="{'is-open has-focus': isDropdownOpen, 'is-active': value?.length > 0, 'dropup': isDropUp, 'is-required': isRequired}">
  <label class="selectbox__label" attr.dataCy="{{dataCy}}-status">
    <span>{{ translatedLabel }} </span>
    @if (value?.length > 0) {
      <span>
        @if (value.length === options.length) {
          &nbsp;(<span [translate]="'GENERIC_LABEL_ALL'"></span>)
        } @else {
          &nbsp;(<span>{{ value.length }} {{ 'GENERIC_LABEL_OF' | translate }} {{ options.length }}</span>)
        }
      </span>
    }
  </label>
  <div class="multi-selectbox__label">
    @for (item of value; track item; let last = $last) {
      <span>{{ getOption(item)?.title }}{{ last ? '' : ', ' }}</span>
    }
  </div>
  <div class="search-multi-selectbox__mobile-view-backround">
    <div class="multi-selectbox__button-wrapper search-mobile">
      <button class="button button--link icon-arrow-left-large" (click)="closeSearchDropdown($event)">Back</button>
    </div>
    @if (isDropdownOpen) {
      <div class="search-multi-selectbox__mobile-view">
        <hl-search-field class="search-multi-selectbox__text-field search-multi-selectbox__text-field-mobile" [(searchInput)]="searchInput"
          (searchInputChange)="onSearchInputChange()"></hl-search-field>
      </div>
    }
  </div>
  <ul class="menu dropdown__panel multi-selectbox__option-list search-multi-selectbox__option-list service-metric-margin">
    @if (showAllCheckItem) {
      <li class="menu__item multi-selectbox__option" (click)="selectAllWithUserClick($event)" attr.dataCy="{{dataCy}}">
        <input type="checkbox" class="checkbox" [checked]="isAllChecked"/>
        <label>
          @if (showIcon) {
            <span class="icon"></span>
          } <span [translate]="isAllChecked ? 'GENERIC_LABEL_DESELECT_ALL' : 'GENERIC_LABEL_SELECT_ALL'"></span>
        </label>
      </li>
    }
    @for (option of options; track option; let i = $index) {
      <li class="menu__item multi-selectbox__option" (click)="setSelected($event, option)" attr.dataCy="{{dataCy}}-{{i+1}}">
        <input type="checkbox" class="checkbox" [checked]="isSelected(option)" attr.dataCy="{{dataCy}}-{{i+1}}-checkbox"
          [disabled]="option.disabled"/>
        <label>
          @if (showIcon) {
            <span class="icon icon-status-{{option.color}}"></span>
          } <span>{{ option.title }}</span>
        </label>
      </li>
    }
  </ul>
</div>
