<div>
  <div hlTextfieldAnimator
    class="textfield textfield--large date-time-picker textfield--with-prefix"
    [class.textfield--with-postfix]="dateModel && showClearButton && !isDisabled"
    [class.is-active]="dateModel"
    [class.is-required]="isRequired"
    [class.is-invalid]="isInvalid"
    [class.is-disabled]="isDisabled">
    <i class="textfield__prefix icon-calendar"></i>
    @if (dateModel && showClearButton && !isDisabled) {
      <i class="textfield__postfix textfield__postfix--clickable icon-close" (click)="clear()"></i>
    }
    <input #month
      type="text"
      class="textfield__input date-time-picker__input"
      [ngClass]="{'date-time-picker__is-disabled': isDisabled}"
      [disabled]="isDisabled"
      [(ngModel)]="formattedDateModel"
      (ngModelChange)="selectFromString($event)"
      (click)="open()"
      autocomplete="off">
      <label [htmlFor]="month" class="textfield__label date-time-picker__label" [translate]="label"></label>

      <div class="month-picker" [class.is-open]="isOpen && !isDisabled" #picker>
        <div class="month-picker__header">
          <button class="month-picker__header__prev-year" (click)="openYear = openYear - 1">
            <i class="icon-arrow-left-large"></i>
          </button>
          <div class="month-picker__header__current-year">{{ openYear }}</div>
          <button class="month-picker__header__next-year" (click)="openYear = openYear + 1">
            <i class="icon-arrow-right-large"></i>
          </button>
        </div>

        <div class="month-picker__months">
          @for (month of MONTHS; track month; let i = $index) {
            <span class="month-picker__month" (click)="select(openYear, i)"
              [class.is-selected]="isMonthSelected(openYear, i)" [class.is-disabled]="isMonthDisabled(openYear, i)">{{ month }}</span>
          }
        </div>
      </div>
    </div>
    @if (invalidLabel && isInvalid) {
      <small class="textfield__hint textfield__hint--validation" [translate]="invalidLabel"></small>
    }
  </div>
