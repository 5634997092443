<div #selectbox class="selectbox multi-selectbox" (click)="toggleDropdown($event)" [attr.data-cy]="dataCy + '-multi-selectbox'"
  [ngClass]="{'is-open has-focus': isDropdownOpen, 'is-active': value && value.length > 0, 'dropup': isDropUp, 'is-disabled': disabled}">
  <label class="selectbox__label" [attr.data-cy]="dataCy + '-status'">
    <span>{{ translatedLabel }}</span>
    @if (isRequired) {
      <span>*</span>
    }
    @if (showNumberOfValues && value?.length > 0) {
      <span>
        @if (value.length === options.length) {
          &nbsp;(<span [translate]="'GENERIC_LABEL_ALL'"></span>)
        } @else {
          &nbsp;(<span>{{ value.length }} {{ 'GENERIC_LABEL_OF' | translate }} {{ options.length }}</span>)
        }
      </span>
    }
  </label>
  <div class="multi-selectbox__label">
    @for (item of value; track item; let last = $last) {
      <span>{{ getOption(item)?.title | translate }}{{ last ? '' : delimiter }}</span>
    }

  </div>
  <div class="multi-selectbox__button-wrapper">
    <button class="button button--link icon-arrow-left-large" (click)="closeDropdown($event)">Back</button>
  </div>
  @if (!disabled) {
    <ul class="menu dropdown__panel multi-selectbox__option-list">
      @if (showAllCheckItem) {
        <li class="menu__item multi-selectbox__option" (click)="selectAllWithUserClick($event)" [attr.data-cy]="dataCy">
          <input type="checkbox" class="checkbox" [checked]="isAllChecked"/>
          <label>
            @if (showIcon) {
              <span class="icon"></span>
            }
            <span [translate]="isAllChecked ? 'GENERIC_LABEL_DESELECT_ALL' : 'GENERIC_LABEL_SELECT_ALL'"></span>
          </label>
        </li>
      }
      @if (showTooltip) {
        @for (option of options; track option; let i = $index) {
          <li class="menu__item multi-selectbox__option" (click)="setSelected($event, option)" [title]="option.tooltip | translate"
            [attr.data-cy]="dataCy + '-' + (i+1)">
            <input type="checkbox" class="checkbox" [checked]="isSelected(option)" [attr.data-cy]="dataCy + '-' + (i+1) + '-checkbox'"
              [disabled]="option.disabled"/>
            <label>
              @if (showIcon) {
                <span class="icon icon-status-{{option.color}}"></span>
              }
              <span>{{ option.title }}</span>
            </label>
          </li>
        }
      } @else {
        @for (option of options; track option; let i = $index) {
          <li class="menu__item multi-selectbox__option" (click)="setSelected($event, option)" [attr.data-cy]="dataCy + '-' + (i+1)"
            [class.option-disabled]="option.disabled">
            <input type="checkbox" class="checkbox" [checked]="isSelected(option)" [attr.data-cy]="dataCy + '-' + (i+1) + '-checkbox'"
              [disabled]="option.disabled"/>
            <label>
              @if (showIcon) {
                <span class="icon icon-status-{{option.color}}"></span>
              }
              <div [ngClass]="showLabelWithColorStatus ? 'evaluation ' + (option.value | labelColorStatus) : ''">
                <span>{{ option.title | translate }}</span></div>
            </label>
          </li>
        }
      }
    </ul>
  }
</div>
