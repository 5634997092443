import { Pipe, PipeTransform } from '@angular/core';
import { filter, includes } from 'lodash-es';
import { ProfessionalServiceRequest } from '../../../core/models/psr/professional-service-request';

@Pipe({
  name: 'psrRequestStatus'
})
export class PsrRequestStatusPipe implements PipeTransform {

  transform(dataset: ProfessionalServiceRequest[], selectedStatus: string[]): any {
    let filtered: ProfessionalServiceRequest[];

    if (selectedStatus && selectedStatus.length > 0) {
      filtered = this.filterResult(dataset, selectedStatus);
    } else {
      filtered = dataset;
    }
    return filtered;
  }

  private filterResult(dataset: ProfessionalServiceRequest[], selectedStatus: string[]): ProfessionalServiceRequest[] {
    return filter(dataset, (psr) => includes(selectedStatus, psr.quoteStatus));
  }
}
