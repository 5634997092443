import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  IterableDiffers,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MultiSelectboxComponent } from '../multi-selectbox/multi-selectbox.component';
import { DropdownOptions, NestedDropdownOption } from '../../../core/models/dropdown-options';
import { isEqual } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NestedMultiSelectboxComponent),
  multi: true
};

@Component({
  selector: 'hl-nested-multi-selectbox',
  templateUrl: './nested-multi-selectbox.component.html',
  providers: [MULTISELECT_VALUE_ACCESSOR]
})
export class NestedMultiSelectboxComponent
  extends MultiSelectboxComponent implements OnChanges, ControlValueAccessor {
  private _nestedOptions: NestedDropdownOption[];
  private _nestedOptionsLength: number;
  private _nestedValue: DropdownOptions[] = [];

  @Input() chainSelectingParents = true;
  @Input() chainSelectingChildren = true;
  @Input() chainShrinking = true;
  @Input() expandChildrenOnSelect = false;
  @Input() scrollOnExpand = true;

  @Input() filterNonExistingAfterChange = false;

  @Input()
  set nestedOptions(options: NestedDropdownOption[]) {
    if (options) {
      this._nestedOptions = options;
      this.countOptions(options);
      this._nestedOptions.forEach(option => {
        option.isExpanded = false;
        this.setExpandedOnAllChildren(option, false);
      });
      this.nestedCheckAllSelected();
    } else {
      this._nestedOptions = [];
      this._nestedOptionsLength = 0;
    }
  }

  get nestedOptions(): NestedDropdownOption[] {
    return this._nestedOptions;
  }

  get nestedOptionsLength() {
    return this._nestedOptionsLength;
  }

  @Input()
  set nestedValue(val: DropdownOptions[]) {
    this._nestedValue = val || [];
    this.onModelChange(this.nestedValue);
    this.onModelTouched();
    this.nestedCheckAllSelected();
  }

  get nestedValue() {
    return this._nestedValue;
  }

  @Output()
  nestedValueChange = new EventEmitter(true);

  constructor(
    translate: TranslateService,
    elementRef: ElementRef,
    differs: IterableDiffers,
    zone: NgZone,
    private el: ElementRef
  ) {
    super(translate, elementRef, differs, zone);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.options && !changes.options.firstChange &&
      !isEqual(changes.options.previousValue, changes.options.currentValue) && this.allChecked) {
      this.nestedToggleSelectAll(true);
    }
    if (this.filterNonExistingAfterChange && this._nestedValue.length > 0) {
      this.filterNonExistingValues();
    }
  }

  init() {
    if (this.allChecked) {
      this.nestedToggleSelectAll(true);
    }
    // this is needed in case some of the saved filters
    // with all values selected is applied
    this.nestedCheckAllSelected();
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.nestedValue = value;
    this.isValuesSettingsInProcess = false;
  }

  acceptSetSelected = (path: NestedDropdownOption[]) => {
    if (path && path.length) {
      const selectedOption = path[path.length - 1];
      selectedOption.isSelected = !selectedOption.isSelected;
      if (this.expandChildrenOnSelect && selectedOption.isSelected) {
        selectedOption.isExpanded = true;
      }
      if (this.chainSelectingParents && selectedOption.isSelected) {
        path.forEach(parent => parent.isSelected = true);
      }
      if (this.chainSelectingChildren) {
        this.setSelectOnAllChildren(selectedOption, selectedOption.isSelected);
      }

      this.nestedCheckAllSelected(true);
    }
  }

  private setSelectOnAllChildren(option: NestedDropdownOption, value: boolean) {
    if (option && option.children) {
      option.children.forEach(child => {
        child.isSelected = value;
        this.setSelectOnAllChildren(child, value);
      });
    }
  }

  private setExpandedOnAllChildren(option: NestedDropdownOption, value: boolean) {
    if (option && option.children) {
      option.children.forEach(child => {
        child.isExpanded = value;
        this.setExpandedOnAllChildren(child, value);
      });
    }
  }

  expandScroll = (positionToScroll: number) => {
    if (this.el && (this.el.nativeElement as HTMLElement).getElementsByTagName('ul').length && positionToScroll > 0) {
      (this.el.nativeElement as HTMLElement).getElementsByTagName('ul')[0].scrollTop = positionToScroll;
    }
  }

  private countOptions(options: NestedDropdownOption[]) {
    let count = 0;
    if (options && options.length) {
      options.forEach(option => {
        if (!option.disabledSelect) {
          count += 1;
        }
        count += this.countChildrenInOption(option);
      });
    }
    this._nestedOptionsLength = count;
  }

  private countChildrenInOption(option: NestedDropdownOption): number {
    let count = 0;
    if (option.children) {
      count += option.children.length;
      option.children.forEach(child => count += this.countChildrenInOption(child));
    }
    return count;
  }

  nestedSelectAllWithUserClick(event: Event) {
    event.stopPropagation();
    this.nestedToggleSelectAll(!this.isAllChecked);
  }

  private nestedToggleSelectAll(value: boolean) {
    this._nestedOptions.forEach(option => {
      option.isSelected = value;
      this.setSelectOnAllChildren(option, value);
    });
    this.nestedCheckAllSelected(true);
  }

  private nestedCheckAllSelected(updateValue = false) {
    if (!this._nestedOptions || !this._nestedOptions.length) {
      this.isAllChecked = false;
      if (updateValue) {
        this._nestedValue = [];
      }
      return;
    }

    this.isAllChecked = this.recursiveCheckAllSelected(this._nestedOptions, updateValue);
    if (updateValue) {
      this.nestedValue = [...this.nestedValue];
      this.nestedValueChange.emit(this._nestedValue);
    }
  }

  private recursiveCheckAllSelected(options: NestedDropdownOption[], updateValue = false): boolean {
    let isAllTrue = true;
    if (options && options.length) {
      if (updateValue) {
        options.forEach(option => {
          this.updateNestedValue(option);
          if ((option.children && !this.recursiveCheckAllSelected(option.children, updateValue)) || !option.isSelected) {
            isAllTrue = false;
          }
        });
      } else {
        isAllTrue = !options.some(option =>
          (option.children && !this.recursiveCheckAllSelected(option.children, updateValue)) || !option.isSelected);
      }
    }
    return isAllTrue;
  }

  updateNestedValue(option: NestedDropdownOption) {
    if (option.disabledSelect) {
      option.isSelected = !!option.children?.some(child => child.isSelected);
      return;
    }
    if (option.isSelected && !this._nestedValue.includes(option)) {
      this._nestedValue.push(option);
    } else if (!option.isSelected && this._nestedValue.includes(option)) {
      this._nestedValue.splice(this._nestedValue.indexOf(option), 1);
    }
  }

  filterNonExistingValues() {
    const filteredValue = this._nestedValue.filter(option => this.nestedIsSelectedOptionWithName(option.title));

    if (this._nestedValue.length !== filteredValue.length) {
      this._nestedValue = filteredValue;
      this.nestedValueChange.emit(this._nestedValue);
      this.onModelChange(this.nestedValue);
    }
  }

  private nestedIsSelectedOptionWithName(name: string): boolean {
    for (const option of this._nestedOptions) {
      if ((option.title === name && option.isSelected) ||
        this.nestedIsSelectedChildrenOptionWithName(option, name)) {
        return true;
      }
    }

    return false;
  }

  private nestedIsSelectedChildrenOptionWithName(option: NestedDropdownOption, name: string): boolean {
    if (option && option.children && name) {
      for (const children of option.children) {
        if ((children.title === name && children.isSelected) ||
          this.nestedIsSelectedChildrenOptionWithName(children, name)) {
          return true;
        }
      }
    }
    return false;
  }
}
