import { Pipe, PipeTransform } from '@angular/core';
import { LabelStatus, StatusTranslation } from 'app/core/services/security-notifications/security-notifications.service';
import { gray, green, orange, red, yellow } from '../../../core/core-constants.service';

const mapping = {
  [LabelStatus.CRITICAL]: red,
  [StatusTranslation.SECURITY_SEVERITY_CRITICAL]: red,
  [LabelStatus.HIGH]: orange,
  [StatusTranslation.SECURITY_SEVERITY_HIGH]: orange,
  [LabelStatus.MEDIUM]: yellow,
  [StatusTranslation.SECURITY_SEVERITY_MEDIUM]: yellow,
  [LabelStatus.LOW]: green,
  [StatusTranslation.SECURITY_SEVERITY_LOW]: green
};

@Pipe({
  name: 'labelColorStatus'
})
export class LabelColorStatusPipe implements PipeTransform {

  transform(value: string): string {
    return `evaluation--${mapping[value.toUpperCase()] ?? gray}`;
  }
}
