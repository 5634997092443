import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class AddRootClassesService {

  // Observable addRootClass sources
  private addRootClassSource = new Subject<string>();

  // Observable addRootClass streams
  addRootClassSource$ = this.addRootClassSource.asObservable();

  // Observable removeRootClass sources
  private removeRootClassSource = new Subject<string>();

  // Observable removeRootClass streams
  removeRootClass$ = this.removeRootClassSource.asObservable();

  constructor() { }

  // Service addRootClass commands
  emitToAddRootClass(cssClass: string) {
    this.addRootClassSource.next(cssClass);
  }

  // Service removeRootClass commands
  emitToRemoveRootClass(cssClass: string) {
    this.removeRootClassSource.next(cssClass);
  }

}
