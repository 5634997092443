import { Directive, HostListener, Input } from '@angular/core';
import { isEqual } from 'lodash-es';
import { AddRemoveEquipmentEventService } from '../../../core/component-communication-services/add-remove-equipment-event/add-remove-equipment-event.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { addEquipment, removeEquipment } from '../../../core/core-constants.service';
import { Equipment } from '../../../core/models/equipment/equipment';
import { MyEquipmentService } from '../../../core/services/my-equipment.service';

@Directive({
  selector: '[hlAddRemoveEquipmentToMyProfile]'
})
export class AddRemoveEquipmentToMyProfileDirective {

  @Input() action: string;
  @Input() equipment: Equipment;

  constructor(private equipmentRestService: EquipmentRestService,
    private toasterService: ToasterService,
    private addRemoveEquipmentEvent: AddRemoveEquipmentEventService,
    private myEquipmentService: MyEquipmentService) {
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    $event.stopImmediatePropagation();

    if (isEqual(this.action, addEquipment)) {
      this.addToMyList(this.equipment);
    } else if (isEqual(this.action, removeEquipment)) {
      this.removeFromMyList(this.equipment);
    }
  }

  addToMyList(equipment) {
    this.myEquipmentService.toggleEquipmentInMyEquipmentList(equipment.key);
    this.equipmentRestService.postAddEquipmentToMyProfile(equipment.key)
      .subscribe(
        () => this.showToasterAndEmitEvent('ADD_MY_SIEMENS_EQUIPMENT_SUCCESS', equipment),
        () => this.myEquipmentService.toggleEquipmentInMyEquipmentList(equipment.key)
      );
  }

  removeFromMyList(equipment) {
    this.myEquipmentService.toggleEquipmentInMyEquipmentList(equipment.key);
    this.equipmentRestService.removeEquipmentFromMyProfile(equipment.key)
      .subscribe(
        () => this.showToasterAndEmitEvent('REMOVE_MY_SIEMENS_EQUIPMENT_SUCCESS', equipment),
        () => this.myEquipmentService.toggleEquipmentInMyEquipmentList(equipment.key)
      );
  }

  showToasterAndEmitEvent(toasterKey, equipment) {
    this.toasterService.showTranslatedSuccessToaster(toasterKey);
    this.addRemoveEquipmentEvent.emitEquipmentAddedOrRemoved(equipment);
  }
}
