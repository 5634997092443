import { Injectable } from '@angular/core';
import { SelectOption } from '../../models/select-option';
import { NotifStatus } from '../../core-constants.service';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';

@Injectable({providedIn: 'root'})
export class ActivitiesConstantsService {

  getSortSkeletonForActivities(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'sortDate',
        sortDir: SortDirection.ASC
      },
      items: [{
        title: 'GENERIC_LABEL_DATE',
        value: 'sortDate'
      }, {
        title: 'TYPE',
        value: 'shortText'
      }, {
        title: 'GENERIC_LABEL_PRODUCT_NAME',
        value: 'productName'
      }, {
        title: 'GENERIC_LABEL_TICKET_NUMBER',
        value: 'ticketNumber'
      }, {
        title: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
        value: 'siemensId'
      }, {
        title: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
        value: 'myEquipmentName'
      }, {
        title: 'GENERIC_LABEL_CUSTOMER_NAME',
        value: 'customerName'
      }]
    };
  }

  // tslint:disable-next-line:no-identical-functions
  getSortSkeletonForAppointments(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'sortDate',
        sortDir: SortDirection.ASC
      },
      items: [{
        title: 'GENERIC_LABEL_DATE',
        value: 'sortDate'
      }, {
        title: 'TYPE',
        value: 'shortText'
      }, {
        title: 'GENERIC_LABEL_PRODUCT_NAME',
        value: 'productName'
      }, {
        title: 'GENERIC_LABEL_APPOINTMENT_NUMBER',
        value: 'appointmentNumber'
      }, {
        title: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
        value: 'siemensId'
      }, {
        title: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
        value: 'myEquipmentName'
      }, {
        title: 'GENERIC_LABEL_CUSTOMER_NAME',
        value: 'customerName'
      }]
    };
  }

  getSortSkeletonForEquipmentDetail(): SortSkeleton {
    // sorter configuration for equipment sub tab activities
    return {
      sortObject: {
        sortBy: 'sortDate',
        sortDir: SortDirection.DESC
      },
      items: [{
        title: 'GENERIC_LABEL_DATE',
        value: 'sortDate'
      }, {
        title: 'TYPE',
        value: 'shortText'
      }, {
        title: 'GENERIC_LABEL_TICKET_NUMBER',
        value: 'ticketNumber'
      }]
    };
  }

  // tslint:disable-next-line:no-identical-functions
  getSortSkeletonForAppointmentsInEquipmentDetail(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'sortDate',
        sortDir: SortDirection.DESC
      },
      items: [{
        title: 'GENERIC_LABEL_DATE',
        value: 'sortDate'
      }, {
        title: 'TYPE',
        value: 'shortText'
      }, {
        title: 'GENERIC_LABEL_APPOINTMENT_NUMBER',
        value: 'appointmentNumber'
      }]
    };
  }

  getOpenCloseSkeleton(): SelectOption[] {
    return [
      {
        title: 'LABEL_OPEN_PLANNED_ACTIVITIES',
        value: NotifStatus.OPEN
      },
      {
        title: 'LABEL_CLOSED_PLANNED_ACTIVITIES',
        value: NotifStatus.CLOSED
      }
    ];
  }

  getClosedActivityStatusForSapSystem(sapSystem: string): string[] {
    switch (sapSystem) {
      case 'D35':
        return ['9'];
      case  'P40':
        return ['3', '4'];
      default:
        return ['2'];
    }
  }
}
