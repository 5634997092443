import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

const Keycode = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13
};

@Component({
  selector: 'hl-search-field',
  templateUrl: './search-field.component.html'
})
export class SearchFieldComponent implements AfterViewInit, OnDestroy {
  @Input() autocomplete = false;
  @Input() autocompleteMinLength = 1;
  @Input() autocompleteWaitMs = 200;
  @Input() autocompleteItems$: Observable<any>;
  @Input() autocompleteItemTemplate: TemplateRef<any>;
  @Input() useCollection = false;
  @Input() waitForInput = true;
  @Input() searchFieldClasses: string;
  @Input() placeholder: string;
  @Input() searchInput: string;
  @Input() dataCy: string;
  @Input() disabled = false;
  @Output() autocompleteOnSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchInputChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('searchContainer') searchContainerEl: ElementRef;
  @ViewChild('autocompleteMenu') autocompleteMenuEl: ElementRef;
  @ViewChild('searchInputField') searchInputEl: ElementRef;
  currentSelectedItemNum = -1;
  displayAutocomplete: boolean;
  autocompleteItems = [];
  protected searchEventEmitter: EventEmitter<any> = new EventEmitter();
  private readonly destroy$ = new Subject<void>();
  private allItems = [];
  private itemCache = [];
  private readonly searchLimit = 5;
  private numberOfItemsToShow = 0;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.onFocusOut();
    }
  }

  ngAfterViewInit() {
    if (this.autocomplete) {
      this.autocompleteItems$.pipe(
        distinctUntilChanged(),
        debounceTime(this.autocompleteWaitMs),
        takeUntil(this.destroy$)
      ).subscribe((items: any) => {
        this.showItems(items);
        this.itemCache = items;
      })
    }
    this.searchEventEmitter.pipe(
      debounceTime(this.autocomplete ? 0 : 200)
    ).subscribe(searchText =>
      this.searchInputChange.emit(searchText)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChange(newInput: string) {
    this.searchInput = newInput.trim();
    this.searchEventEmitter.emit(this.searchInput);
  }

  onKeyup(event: any) {
    if (!this.autocomplete) {
      return;
    }

    switch (event.keyCode) {
      case Keycode.UP:
        this.previousAutocompleteItem();
        return;
      case Keycode.DOWN:
        this.nextAutocompleteItem();
        return;
      case Keycode.ENTER:
        if (this.currentSelectedItemNum >= 0) {
          this.onItemClick(this.allItems[this.currentSelectedItemNum]);
        }
        return;
      default:
        break;
    }
  }

  onFocus() {
    if (!this.waitForInput) {
      this.autocompleteMinLength = 0;
      this.showItems(this.itemCache);
    }
  }

  onFocusOut() {
    if (!this.waitForInput) {
      this.hideAutocomplete();
      this.autocompleteMinLength = 1;
    }
    if (this.waitForInput && !this.searchInput) {
      this.hideAutocomplete();
    }
  }

  scrollToCurrentItem() {
    const currentItem = this.autocompleteMenuEl.nativeElement.children[
      this.currentSelectedItemNum
      ];
    if (currentItem) {
      this.autocompleteMenuEl.nativeElement.scrollTop = currentItem.offsetTop;
    }
  }

  previousAutocompleteItem() {
    const itemCount = this.allItems.length;

    if (itemCount === 0) {
      return;
    }

    if (this.currentSelectedItemNum < 0) {
      this.currentSelectedItemNum = this.autocompleteItems.length - 1;
    } else {
      this.currentSelectedItemNum--;
    }

    this.scrollToCurrentItem();
  }

  nextAutocompleteItem() {
    const itemCount = this.allItems.length;

    if (itemCount === 0) {
      return;
    }

    if (this.currentSelectedItemNum >= itemCount) {
      this.currentSelectedItemNum = 0;
    } else {
      this.currentSelectedItemNum++;
    }
    this.scrollToCurrentItem();
  }

  resetAutocomplete() {
    this.currentSelectedItemNum = -1;
    this.autocompleteItems = [];
    this.allItems = [];
    this.numberOfItemsToShow = 0;
  }

  onItemClick(item) {
    this.resetAutocomplete();
    this.autocompleteOnSelect.emit(item);
  }

  showItems(items: any[]): void {
    this.resetAutocomplete();

    if (items.length === 0 || this.isSearchTextTooShort()) {
      this.hideAutocomplete();
    } else {
      this.showAutocomplete();
      this.allItems = items;
      this.loadMore();
    }
    this.scrollToTop();
    this.changeDetector.detectChanges();
  }

  public setFocusOnInput(): void {
    setTimeout(() => this.searchInputEl.nativeElement.focus(), 800);
  }

  loadMore() {
    this.numberOfItemsToShow += this.searchLimit;
    this.autocompleteItems = this.allItems.slice(0, this.numberOfItemsToShow);
  }

  protected showAutocomplete() {
    this.displayAutocomplete = true;
  }

  protected hideAutocomplete() {
    this.displayAutocomplete = false;

  }

  private isSearchTextTooShort() {
    return (this.searchInput ? this.searchInput.length : 0) < this.autocompleteMinLength;
  }

  private scrollToTop() {
    this.currentSelectedItemNum = 0;
    this.scrollToCurrentItem();
  }
}
