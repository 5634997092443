import { Injectable } from '@angular/core';
import { drop, includes, isEqual } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class StringUtilService {

  /**
   * Requires string and allowed string length and returns the
   * truncated filename (truncates the string without the extension) and then adds its extension at the end.
   * @returns {string}
   * @param filename
   * @param allowedLength
   */
  truncateFilename(filename: string, allowedLength: number): string {
    const lastIndexOfDot = filename.lastIndexOf('.');
    if (lastIndexOfDot < 0 || filename.length <= allowedLength) {
      return filename;
    }
    const extension = filename.substring(lastIndexOfDot);
    const allowedFilenameLength = allowedLength - extension.length;
    const truncatedFilename = filename.substring(0, allowedFilenameLength);
    return truncatedFilename + extension;
  }

  /**
   * checks if the single string is contained in the list
   * @param {string} type
   * @param listOfTypes - a string with comma separated entries
   * @returns {boolean}
   */
  isContainedIn(type: string, listOfTypes: string): boolean {
    return includes(listOfTypes.split(','), type);
  }

  /**
   *
   * @description
   * Convert BE response text with /n to lines and
   * drop if first character is empty string.
   */
  parseAndSplitInputStringToLines(input: string): string[] {

    let text = input.split('\n');
    if (text.length > 0 && isEqual(text[0], '')) {
      text = drop(text);
    }
    return text;
  }
}
