import { Pipe, PipeTransform } from '@angular/core';
import { filter, isEmpty } from 'lodash-es';

@Pipe({
  name: 'dangerForPatient'
})
export class DangerForPatientPipe implements PipeTransform {

  transform(dataset: any, selectedValue: any): any {

    const filtered = [];

    if (isEmpty(selectedValue)) {
      return dataset;
    }

    selectedValue.forEach(valueInBoolean => {
      filter(dataset, {dangerForPatient: valueInBoolean}).forEach(item => {
        filtered.push(item);
      });
    });
    return filtered;
  }

}
