import { Injectable } from '@angular/core';
import { Activities } from '../../models/activities/activities';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ActivitiesStatusDescriptionTranslationService {
  constructor(private translateService: TranslateService) {
  }

  /**
   * Method returns translated pmDescription for the activity
   * @param activity
   */
  getActivityStatusDescriptionTranslation(activity: Activities): Observable<string> {
    return this.translateService.get(this.getActivityStatusDescriptionTranslationKey(activity));
  }

  getActivityStatusDescriptionTranslationKey(activity: Activities): string {
    if (activity.sapSystem === 'P40') {
      return this.getP40ActivityStatusDescription(activity.pmStatus);
    }

    if (activity.sapSystem === 'P58' && activity.tpFstatus) {
      return activity.tpFstatus !== '-1' ? 'LABEL_ACTIVITY_APPOINTMENT_TPF_STATUS_' + activity.tpFstatus : 'LABEL_ACTIVITY_APPOINTMENT_STATUS_-1';
    }

    if (!activity.scheduled && activity.pmStatus === '0') {
      return 'PLANNED_ACTIVITIES_NOT_SCHEDULED';
    } else if (activity.pmStatus) {
      return 'LABEL_ACTIVITY_STATUS_' + activity.pmStatus;
    } else {
      return 'PLANNED_ACTIVITIES_NOT_SCHEDULED';
    }
  }

  getP40ActivityStatusDescription(pmStatus: string): string {
    switch (pmStatus) {
      case '0':
        return 'LABEL_ACTIVITY_STATUS_1';
      case '1':
        return 'LABEL_ACTIVITY_STATUS_8';
      case '2':
        return 'LABEL_ACTIVITY_STATUS_9';
      case '3':
        return 'LABEL_ACTIVITY_STATUS_5';
      case '4':
        return 'LABEL_ACTIVITY_STATUS_6';
      default:
        return 'LABEL_ACTIVITY_STATUS_UNKNOWN';
    }
  }
}
