import { defer, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, map, tap } from 'rxjs/operators';
import { CacheItem } from './cache-item';
import { HttpCacheService } from './http-cache.service';
import { LogService } from '../log/log.service';
import { EquipmentStatusForCustomer } from '../../models/equipment/equipment-status';

export interface GetRequestOptions {
  cache?: boolean;
  params?: HttpParams;
  body?: any;
  headers?: HttpHeaders;
  reportProgress?: boolean;
  // tslint:disable-next-line:max-union-size
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  observe?: 'body' | 'response';
}

@Injectable({ providedIn: 'root' })
export class CacheableHttpClient extends HttpClient {
  constructor(
    private httpHandler: HttpHandler,
    private cacheService: HttpCacheService,
    private logService: LogService
  ) {
    super(httpHandler);
  }

  private static getParams(options: GetRequestOptions): HttpParams {
    return options && options.params && options.params instanceof HttpParams ? options.params : undefined;
  }

  /**
   * intermediate public get<> function
   * We do not cache all the config-<country>.json since this is a special observable case and its size does not really affect performance.
   * We let the browser handle the caching.
   * We also do not cache all the request that explicitly define { cache: false }
   */

  public get<T>(url: string, options?: any): Observable<T> {
    // disable cache completely due to bug with clickdummy
    return this.getHandler(url, options).pipe(map((r: T) => r));

    // if (options && options.cache === false) {
    //   return this.getHandler(url, options).pipe(map((r: T) => r));
    // }

    // const calledRequest =
    //   this.cacheService.getFromMap(url, CacheableHttpClient.getParams(options)) ||
    //   this.createRequestAndCacheRequest(url, options);
    // return calledRequest.responseObservable;
    // return defer(() => {
    //   const calledRequest =
    //     this.cacheService.getFromMap(url, CacheableHttpClient.getParams(options)) ||
    //     this.createRequestAndCacheRequest(url, options);
    //   return calledRequest.responseObservable;
    // });
  }

  private createRequestAndCacheRequest(url: string, options: GetRequestOptions): CacheItem {
    const params = CacheableHttpClient.getParams(options);
    const cacheRequest = this.cacheService.putToMap(url, params);

    this.invokeHandler(url, options);
    return cacheRequest;
  }

  private invokeHandler(url: string, options?: GetRequestOptions) {
    this.getHandler(url, options).subscribe({
      next: response => {
        const params = CacheableHttpClient.getParams(options);
        this.cacheService.putToMap(url, params, response);
      },
      error: error => {
        this.logService.error('Error invoking handler in cacheable http client', error);
        const params = CacheableHttpClient.getParams(options);
        this.cacheService.raiseError(url, params, error);
      }
    });
  }

  updateCache(url: string, options?: GetRequestOptions, response?: any) {
    const params = CacheableHttpClient.getParams(options);
    this.cacheService.putToMap(url, params, response);
  }

  updateCacheWithoutTriggeringReload(url: string, options?: GetRequestOptions, response?: any) {
    const params = CacheableHttpClient.getParams(options);
    this.cacheService.replaceResponseValueInMap(url, params, response);
  }

  /**
   * this code is retrieved from HttpClient request method, as we do need to make a get call
   * but with an CacheableHttpRequest object instead of url and options.
   * Just calling `super.request` with an HttpRequest instead of url and options does resolve to
   * a response (see HttpClient request code)
   */
  public getHandler<T>(url: string, options?: GetRequestOptions): Observable<T | HttpResponse<T>> {
    if (options === undefined) {
      options = {};
    }

    const request = new HttpRequest('GET', url, options.body || null, {
      headers: options.headers,
      params: options.params,
      reportProgress: options.reportProgress,
      // By default, JSON is assumed to be returned for all calls.
      responseType: options.responseType || 'json',
      withCredentials: options.withCredentials
    });

    const res$ = this.httpHandler.handle(request).pipe(
      tap(event => {
        if (!(event instanceof HttpResponse)) {
          this.cacheService.cacheSapCallResponseOrErrorResponse(request, null);
        }
      }),
      filter(event => event instanceof HttpResponse),
      map(resp => resp as HttpResponse<T>)
    );

    // Decide which stream to return.
    switch (options.observe || 'body') {
      case 'body':
        // The requested stream is the body. Map the response stream to the response
        // body. This could be done more simply, but a misbehaving interceptor might
        // transform the response body into a different format and ignore the requested
        // responseType. Guard against this by validating that the response is of the
        // requested type.
        return this.mapToObserveType(request?.responseType, res$);
      case 'response':
        // The response stream was requested directly, so return it.
        return res$;
      default:
        // Guard against new future observe types being added.
        throw new Error('Unreachable: unhandled observe type: ' + options.observe);
    }
  }

  private mapToObserveType<T>(
    responseType: string,
    res$: Observable<HttpResponse<T>>
  ): Observable<T | HttpResponse<T>> {
    return res$.pipe(
      map(res => {
        const responseBody = res?.body;

        switch (responseType) {
          case 'arraybuffer':
            // Validate that the body is an ArrayBuffer.
            if (responseBody && !(responseBody instanceof ArrayBuffer)) {
              throw new Error('Response is not an ArrayBuffer.');
            }
            return responseBody;
          case 'blob':
            // Validate that the body is a Blob.
            if (responseBody && !(responseBody instanceof Blob)) {
              throw new Error('Response is not a Blob.');
            }
            return responseBody;
          case 'text':
            // Validate that the body is a string.
            if (responseBody && typeof responseBody !== 'string') {
              throw new Error('Response is not a string.');
            }
            return responseBody;
          case 'json':
          default:
            // No validation needed for JSON responses, as they can be of any type.
            return responseBody;
        }
      })
    );
  }

  public clearCache(url?: string, options?: GetRequestOptions): void {
    const params = CacheableHttpClient.getParams(options);
    if (url && params) {
      this.cacheService.clearIndividualRequestWithParams(url, params);
    } else if (url) {
      this.cacheService.clearIndividualRequest(url);
    } else {
      this.cacheService.clearAll();
    }
  }

  public getCachedValueForRequest(url, options): EquipmentStatusForCustomer[] {
    return this.cacheService.getFromMap(url, CacheableHttpClient.getParams(options))?.response;
  }
}
