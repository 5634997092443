<div>
  <div hlTextfieldAnimator
    class="textfield textfield--large date-time-picker textfield--with-prefix"
    [class.textfield--with-postfix]="dateModel"
    [class.is-active]="dateModel"
    [class.is-required]="isRequired"
    [class.is-invalid]="isInvalid"
    [class.is-disabled]="disabled"
    [ngClass]="{'margin-bottom-0 border-bottom-none': isTable}"
    data-mode="range">
    <i aria-hidden="true" class="textfield__prefix icon-calendar"></i>
    @if (clearButtonVisible() && dateModel && !disabled) {
      <i (click)="resetDate($event)" aria-hidden="true"
      class="textfield__postfix textfield__postfix--clickable icon-close" data-cy="date-picker-close-icon"></i>
    }
    <input #date
      mwlFlatpickr
      type="text"
      class="textfield__input date-time-picker__input"
      [class.padding-top-0]="isTable"
      [disabled]="disabled"
      [dateFormat]="datePatternFlatpickr"
      [(ngModel)]="dateModel"
      (ngModelChange)="updateDateModel($event)"
      (input)="updateDateModel($event.target.value)"
      (flatpickrValueUpdate)="updateDateModel($event.selectedDates[0])"
      [prevArrow]="prevArrow"
      [nextArrow]="nextArrow"
      [allowInput]="true"
      [disableMobile]="true"
      [disable]="disableDays()"
      autocomplete="off"
      [clickOpens]="true"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [appendTo]="appendToElement">
      @if (labelText) {
        <label class="textfield__label date-time-picker__label" [translate]="labelText"></label>
      }
    </div>
    @if (invalidLabel && isInvalid) {
      <small class="textfield__hint textfield__hint--validation" [translate]="invalidLabel"></small>
    }
  </div>
