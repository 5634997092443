<div class="multi-column">
  <div class="pagination multi-column__item">
    <a class="pagination__item" data-cy="pagination-prev" [routerLink]="currentIndex === 0 ? null: []" (click)="doPrev()"
      [title]="'GENERIC_LABEL_PREVIOUS' | translate">
      <i class="icon-arrow-left-large"></i>
    </a>
    @for (element of pages; track element) {
      <a [ngClass]="{'hidden': isPageHidden(element)}" class="pagination__item" [routerLink]="currentIndex + 1 === element ? null: []"
        (click)="doPage(element)">{{ element }}</a>
    }
    <a class="pagination__item" data-cy="pagination-next" [routerLink]="currentIndex + 1 === elements ? null: []" (click)="doNext()"
      [title]="'GENERIC_LABEL_NEXT' | translate">
      <i class="icon-arrow-right-large"></i>
    </a>
  </div>
  <div class="pagination-label font-size-small multi-column__item">{{ elements }} {{ title }}</div>
</div>
