import { Pipe, PipeTransform } from '@angular/core';
import { filter, forEach, isEqual, some } from 'lodash-es';

@Pipe({
  name: 'operationalStatus'
})
export class OperationalStatusPipe implements PipeTransform {

  /**
   * Check if dataset has status value because rest service for /equipments/status
   * return empty list and then when merged with /equipments/all,
   * status attribute will be "" (empty string)
   */
  private static hasStatus = (dataSet: any) => some(dataSet, i => i.status);

  transform(dataset: any, operationalStatus?: any): any {

    if (dataset.length > 0 &&
      OperationalStatusPipe.hasStatus(dataset) &&
      operationalStatus && operationalStatus.status.length > 0) {
      //  Note: operationalState.status value can be for e.g: ['red', 'yellow', 'green']
      return this.filterResult(dataset, operationalStatus);
    } else {
      // do nothing; return the full object
      return dataset;
    }
  }

  filterResult(dataset, operationalStatus) {
    const filtered = [];

    let supportedStatuses;

    if (operationalStatus.config) {
      supportedStatuses = {
        green: operationalStatus.config.green,
        red: operationalStatus.config.red,
        yellow: operationalStatus.config.yellow,
        redCritical: operationalStatus.config.redCritical
      };
    }

    filter(dataset, element => {
      forEach(operationalStatus.status, v => {
        if (!!supportedStatuses[v] && isEqual(supportedStatuses[v], element['status'])) {
          filtered.push(element);
        }
      });
    });
    return filtered;
  }
}
