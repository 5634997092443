import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removePrefix'
})
export class RemovePrefixPipe implements PipeTransform {

  transform(value: string, regex: string): any {
    if (regex) {
      return value.replace(new RegExp(regex), '');
    }
    return value;
  }

}
