import { Component, Input, OnDestroy } from '@angular/core';
import { EMPTY, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { restEndPoint } from '../../../core/core-constants.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { GenerateExcelRestService } from '../../../core/rest-services/generate-excel-rest.service';
import { DownloadUtilService } from '../../../core/utils/download-util.service';
import { AddOmnitureAndRouterStateNameDirective } from '../../directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';

// excel export
export const generateExcelRestName = restEndPoint + 'generateExcel';
export const excelContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

@Component({
  selector: 'hl-excel-export-from-be',
  templateUrl: './excel-export-from-be.component.html'
})
export class ExcelExportFromBeComponent implements OnDestroy {

  private subscription: Subscription;

  @Input() endpoint: string = generateExcelRestName;
  @Input() fileName: string;
  @Input() requestBody: Observable<any>;
  @Input() rowCount: number;
  @Input() label: string;
  isLoading = false;

  constructor(
    private configService: CountryConfigRestService,
    private downloadUtilService: DownloadUtilService,
    private generateExcelRestService: GenerateExcelRestService
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  export() {
    if (!this.subscription) {
      this.subscription = new Subscription();
    }
    this.isLoading = true;
    const exportSubscription = combineLatest([
      this.configService.getConfig().pipe(
        map(config => config[this.fileName]),
        filter(fileName => !!fileName)
      ),
      this.requestBody
    ]).pipe(
      switchMap(([fileName, requestBody]) =>
        this.generateExcelRestService.postExcelData(this.endpoint, requestBody).pipe(
          switchMap(result => {
            AddOmnitureAndRouterStateNameDirective.triggerDocumentsDownloadTracking(fileName, this.endpoint);
            this.downloadUtilService.generateBlobAndDownload(result, excelContentType, fileName);
            exportSubscription.unsubscribe();
            this.subscription.remove(exportSubscription);
            return EMPTY;
          }),
          finalize(() => this.isLoading = false)
        )
      )
    ).subscribe();
    this.subscription.add(exportSubscription);
  }
}
