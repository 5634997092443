import { Injectable } from '@angular/core';
import { WindowService } from '../window.service';
import { restEndPoint } from '../core-constants.service';
import { ToasterService } from '../component-communication-services/toaster/toaster.service';
import { SanitizerWrapperService } from './sanitizer-wrapper.service';

@Injectable({providedIn: 'root'})
export class DownloadUtilService {

  private readonly notAvailable = 'NOT-AVAILABLE';

  constructor(
    private windowService: WindowService,
    private toast: ToasterService,
    private sanitizer: SanitizerWrapperService) {
  }

  generateBlobAndDownload(data: any, contentType: string, fileName: string) {

    const file = new Blob([data], {type: contentType});
    this.executeDownload(file, fileName);
  }

  executeDownload(file: Blob, fileName: string) {
    if (this.windowService.nativeWindow.navigator.msSaveOrOpenBlob) {
      navigator['msSaveBlob'](file, fileName);
    } else {

      const objectUrl = this.sanitizer.sanitizeResourceUrl(URL.createObjectURL(file), true);
      if (!objectUrl) {
        this.toast.emitFrontendGenericErrorMessage();
        return;
      }

      const downloadContainer = document.createElement('div');
      downloadContainer.setAttribute('data-tap-disabled', 'true');

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', objectUrl);
      downloadLink.setAttribute('download', fileName);
      downloadLink.setAttribute('target', '_blank');

      downloadContainer.appendChild(downloadLink);

      document.body.appendChild(downloadContainer);

      setTimeout(() => {
        downloadLink.click();
        downloadLink.remove();
        this.handleAndroidCordovaDownload(file, fileName);
        URL.revokeObjectURL(objectUrl);
      }, null);
    }
  }

  convertBlobtoBase64(blob: Blob, callback: Function) {
    const reader = new FileReader();
    reader.onload = () => {
      let result = reader.result as String;
      const base64String = result.split(',')[1];
      callback(base64String);
    };
    reader.readAsDataURL(blob);
  }

  handleAndroidCordovaDownload(file: Blob, fileName: string) {
    this.convertBlobtoBase64(file, base64 => {
      if (this.windowService.nativeWindow.cordova_iab) {
        this.windowService.nativeWindow.cordova_iab.saveFile(base64, fileName);
      }
    });
  }

  validate(url: string, isAccessible: boolean = true, emitErrorMessage: boolean = true) {
    if (isAccessible === undefined) {
      isAccessible = true;
    }
    if (url === this.notAvailable || !isAccessible) {
      if (emitErrorMessage) {
        this.toast.emitFrontendGenericErrorMessage();
      }
      return false;
    }
    return true;
  }

  downloadFileByUrl(filename: string, url: string, isAccessible: boolean = true, bypassSecurityTrust: boolean = false) {
    if (!this.validate(url, isAccessible)) {
      return;
    }
    url = this.sanitizer.sanitizeUrl(url, bypassSecurityTrust);
    if (!url) {
      this.toast.emitFrontendGenericErrorMessage();
      return;
    }

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename);
    downloadLink.setAttribute('target', '_blank');
    document.body.appendChild(downloadLink);

    setTimeout(() => {
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }, null);
  }

  generateDownloadLink(type: string, attachment: {
    id: string,
    name: string,
    payload: string,
    available?: boolean
  }): string {
    if (attachment.available === undefined) {
      attachment.available = true;
    }
    return (attachment.available && attachment.payload) ?
      restEndPoint + type + '/' + attachment.id + '/urlAttachments/' +
      encodeURIComponent(attachment.name) + '?u=' + encodeURIComponent(attachment.payload) :
      this.notAvailable;
  }
}
