import { Injectable } from '@angular/core';
import { ExcelExportDefinition } from '../models/excel-export-definition';
import { forEach } from 'lodash-es';
import { DatePipeWrapperPipe } from '../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { TranslateService } from '@ngx-translate/core';
import { GetActivityStatusColorPipe } from '../../shared/pipes/get-activity-status-color/get-activity-status-color.pipe';

@Injectable({providedIn: 'root'})
export class ExportUtilService {

  constructor(
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private translateService: TranslateService,
    private activityStatus: GetActivityStatusColorPipe) {
  }

  /**
   *
   * @description
   * Get data to be exported as an array by definitions for labels and columns
   *
   */
  getExportArray(data, labels, columns: ExcelExportDefinition[], rowCount: number) {
    if (!data || !labels || !columns) {
      return [];
    }
    if (labels.length !== columns.length) {
      return [];
    }
    const exportArr = [];

    // Note: we generate the data array for export by the definitions for export columns and labels
    forEach(data, (item) => {
      const exportRow = [];

      forEach(columns, (column) => {
        exportRow.push(this.processColumn(item, column));
      });
      exportArr.push(exportRow);
    });

    this.addExtraData(labels, exportArr, rowCount, data);
    return exportArr;
  }

  getExportOrder(exportOrderConfig: string, exportGenerate: any[]): string[] {
    const exportOrder: string[] = exportOrderConfig.split(',');
    for (const item of exportGenerate) {
      for (const key of item?.additionalExportData?.baseData || []) {
        if (!exportOrder.includes(key)) {
          exportOrder.push(key);
        }
      }
    }
    return exportOrder;
  }

  addExtraData(headers: string[], exportedArr: any[], rowCount: number, exportGenerate: any[]) {
    for (const extraHeader of this.getExtraHeaders(exportGenerate)) {
      headers.push(extraHeader);
      for (let i = 0; i < rowCount; i++) {
        const extraArr = (exportGenerate[i]?.additionalExportData?.extraData || []).filter(item => item.label === extraHeader);
        exportedArr[i].push(extraArr.length > 0 ? extraArr[0].value : '');
      }
    }
  }

  getExtraHeaders(exportGenerate: any[]): string[] {
    const extraHeaders = [];
    for (const item of exportGenerate) {
      for (const extraDataItem of item?.additionalExportData?.extraData || []) {
        const extra: any = extraDataItem;
        if (!extraHeaders.includes(extra.label)) {
          extraHeaders.push(extra.label);
        }
      }
    }
    return extraHeaders;
  }

  private processColumn(item, column) {
    if (column.columnValue === 'prelimStatus') {
      column.columnValue = 'status';
    }
    let value = item[column.columnValue] ? item[column.columnValue] : '';

    // Note: format or concat the value independent if it is related
    if (column && column.dateFormat && value) {
      value = this.datePipeWrapperPipe.transform(value, column.dateFormat);
    }

    if (column && column.mapping && value) {
      if (column.columnValue === 'pmStatus') {
        value = this.activityStatus.transform(item).label;
      } else {
        value = column.mapping[value];
      }
      // tslint:disable-next-line:ban
      value = this.translateService.instant(value);
    }
    return value;
  }
}
