<!-- default options item template -->
<ng-template #defaultItemTemplate let-model="item">
  {{ model }}
</ng-template>

<div hlTextfieldAnimator [ngClass]="searchFieldClasses"
  class="textfield textfield--with-prefix textfield--with-postfix search-field search-field--with-postfix" [class.has-results]="displayAutocomplete"
  #searchContainer>
  <i class="textfield__prefix icon-world"></i>
  <input name="timezone" id="timezone" type="search" class="textfield__input date-time-picker__input" spellcheck="false" autocomplete="off"
    (focus)="onFocus()" (blur)="onBlur()" [ngModel]="searchInput" (ngModelChange)="onChange($event)" (keyup)="onKeyup($event)"
    placeholder="{{('GENERIC_BUTTON_SEARCH' | translate)}}" #searchInputField/>
  @if (searchInput) {
    <i (click)="reset()" aria-hidden="true" class="textfield__postfix textfield__postfix--clickable icon-close"></i>
  }
  <label for="timezone" class="textfield__label date-time-picker__label">
    <span [translate]="'LABEL_TIME_ZONE'"></span>
  </label>
  <ul class="menu dropdown__panel search-field__autocomplete font-size-medium" #autocompleteMenu infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="loadMore()">
    @for (item of this.autocompleteItems; track item; let i = $index) {
      <li class="menu__item" [class.is-selected]="i === this.currentSelectedItemNum" (mousedown)="onItemClick(item)">
        <ng-container [ngTemplateOutlet]="autocompleteItemTemplate || defaultItemTemplate"
          [ngTemplateOutletContext]="{ item: item, index: i}"></ng-container>
      </li>
    }
  </ul>
  <ng-content></ng-content>
</div>
