import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SeriesData } from './diagram';
import { BarVerticalComponent, Color, LegendPosition } from '@swimlane/tpf-ngx-charts';

@Component({
  selector: 'hl-diagram-bars',
  template: `
    <ngx-charts-bar-vertical #chart [view]="view" [roundDomains]="true" [ngClass]="{'legend-below': legendPosition === 'below'}"
      [roundEdges]="false" [gradient]="gradient" [customColors]="customColors" [results]="results"
      [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel" [yScaleMax]="yScaleMax" [yScaleMin]="yScaleMin" [scheme]="scheme"
      [yAxisTickFormatting]="yAxisTickFormatting" [animations]="animations"
      [barPadding]="calculateBarPadding" (select)="onSelected($event)" [legendTitle]="legendTitle"
      [showDataLabel]="showDataLabel" [dataLabelFormatting]="dataLabelFormatting"
      [yAxisTicks]="yAxisTicks" [legendPosition]="calculateLegendPosition" >
    </ngx-charts-bar-vertical>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramBarsComponent implements AfterViewInit {
  @ViewChild('chart')
  private chart: BarVerticalComponent;

  @Input() scheme: string | Color;
  @Input() yAxisTickFormatting: any;
  @Input() animations: boolean;
  @Input() showLegend = false;
  @Input() barPadding: number;
  @Input() legendTitle: string;
  @Input() dataLabelFormatting: any;
  @Input() yAxisTicks: number[];
  @Input() legendPosition: LegendPosition;

  /**
   * Show the x axis names
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showXAxis = true;
  /**
   * show the y axis names
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showYAxis = true;
  /**
   * show Gridlines
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showGridLines = true;
  /**
   * Show label for x axis (need a value in xAxisLabel)
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showXAxisLabel = false;
  /**
   * Show label for y axis (need a value in yAxsisLabel)
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showYAxisLabel = false;
  /**
   * Label x axsis
   *
   * @type {string}
   * @memberof LclDiagramBarsComponent
   */
  @Input() xAxisLabel: string;
  /**
   * Label y axis
   *
   * @type {string}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yAxisLabel: string;
  /**
   * set a scale maximum
   *
   * @type {number}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yScaleMax: number;
  /**
   * set a scale minimum
   *
   * @type {number}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yScaleMin: number;
  /**
   * Colors aof the bars are gradient
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() gradient = true;
  /**
   * Show a label of the value in the top aof a chart
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showDataLabel = false;

  /**
   * Switch chart colors
   *
   * @memberof LclDiagramDoughnutComponent
   */
  @Input() useMonochrome = false;
  /**
   * Results for the diagram rendering
   *
   * @type {SeriesData[]}
   * @memberof DiagramComponent
   */
  @Input() results: SeriesData[] = [];
  /**
   * Options for a view of the diagram as array with [width,height]
   *
   * @type {[number, number]}
   * @memberof DiagramComponent
   */
  @Input() view: [number, number] = null;

  /**
   * Emitted when any bar is clicked. The data contains the name of clicked series.
   */
  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  /**
   * Emitted when the component after view init callback is called
   */
  @Output() onAfterViewInit: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Placeholder array for colors
   *
   * @type {object[]}
   * @memberof DiagramComponent
   */
  customColors: object[] = [];

  get calculateBarPadding(): number {
    if (this.barPadding !== undefined) {
      return this.barPadding;
    }
    return (this.chart?.width ? Math.ceil(this.chart.width / this.results.length * .7) : 45);
  }

  get calculateLegendPosition(): LegendPosition {
    if (this.legendPosition !== undefined) {
      return this.legendPosition;
    }
    return LegendPosition.Right;
  }

  onSelected($event) {
    this.onSelect.emit($event);
  }

  ngAfterViewInit(): void {
    this.onAfterViewInit.emit();
  }

  getNgxChart() {
    return this.chart;
  }
}
