import { Injectable, OnDestroy } from '@angular/core';
import { EquipmentRestService } from '../../core/rest-services/equipment-rest.service';
import { Observable, ReplaySubject } from 'rxjs';
import { ticketsAllRestName } from '../../core/rest-services/ticket-rest-constants.service';
import { equipmentRestName, equipmentStatusRestName } from '../../core/rest-services/equipment-rest-constants.service';
import { HttpCancelUtilService } from '../../core/utils/http-cancel-util.service';
import { TicketsWebsocketStateService } from '../../core/services/tickets/tickets-websocket-state.service';
import { EquipmentStatusWebsocketStateService } from '../../core/services/equipment/equipment-status-websocket-state.service';

@Injectable({ providedIn: 'root' })
export class MyEquipmentSwitchService implements OnDestroy {

  private showOnlyMineSubject = new ReplaySubject<boolean>(1);
  private loaded = false;
  private onlyMyEquipment = false;
  private loadedAllEquipments = false;
  private loadAllEquipment = false;

  constructor(
    private equipmentRestService: EquipmentRestService,
    private httpCancelUtilService: HttpCancelUtilService,
    private ticketsWebsocketStateService: TicketsWebsocketStateService,
    private equipmentStatusWebsocketStateService: EquipmentStatusWebsocketStateService) {
  }

  get showOnlyMine$(): Observable<boolean> {
    return this.showOnlyMineSubject.asObservable();
  }

  get isInitialized(): boolean {
    return this.loaded;
  }

  initialize() {
    this.equipmentRestService.getMyProfileChecked()
      .subscribe(({checked}) => {
        this.onlyMyEquipment = checked;
        this.loadedAllEquipments = !this.onlyMyEquipment;
        this.loaded = true;
        this.showOnlyMineSubject.next(checked);
      }, err =>  this.showOnlyMineSubject.next(false));
  }

  ngOnDestroy() {
    this.showOnlyMineSubject.complete();
  }

  toggleFilter(isChecked: boolean) {
    this.onlyMyEquipment = isChecked;
    this.manageCache();
    this.showOnlyMineSubject.next(isChecked);
  }

  toggle(isChecked: boolean) {
    this.equipmentRestService.postMyProfileChecked(isChecked)
      .subscribe(() => {
        this.toggleFilter(isChecked);
      }, err => {
        this.showOnlyMineSubject.next(!isChecked);
        this.loaded = false;
      });
  }

  private manageCache() {
    this.setLoadAllEquipmentDataAfterMyFilterSwitch();

    this.ticketsWebsocketStateService.manageCacheForWebsocketTickets();
    this.equipmentStatusWebsocketStateService.manageCacheForWebsocketEquipmentStatus();

    if (!this.loadedAllEquipments) {
      this.loadedAllEquipments = true;
      this.httpCancelUtilService.cancelPendingRequests();
      this.equipmentRestService.clearCache(equipmentRestName);
      this.equipmentRestService.clearCache(equipmentStatusRestName);

      this.equipmentRestService.clearCache(ticketsAllRestName);
    }
  }

  private setLoadAllEquipmentDataAfterMyFilterSwitch() {
    this.loadAllEquipment = !this.onlyMyEquipment && !this.loadedAllEquipments;
  }

  getLoadAllEquipments(): boolean {
    return this.loadAllEquipment;
  }
}
