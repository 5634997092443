import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeFormat'
})
export class FileSizeFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 0) {
      return '0 B';
    }
    const k = 1024;
    const sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(value) / Math.log(k));
    return `[${parseFloat((value / Math.pow(k, i)).toFixed(1))} ${sizes[i]}]`.replace('.', ',');
  }
}
