import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BarOrientation, ColorHelper, escapeLabel, formatLabel, Gradient, id, PlacementTypes, StyleTypes } from '@swimlane/tpf-ngx-charts';
import { ExtendedDataItem } from '../common/common-models';
import { from, groupBy, toArray } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export interface GuardianCircle {
  classNames: string[];
  value: string | number;
  label: string;
  data: ExtendedDataItem;
  cx: number;
  cy: number;
  radius: number;
  height: number;
  tooltipLabel: string;
  color: string;
  opacity: number;
  seriesName: string;
  gradientStops: Gradient[];
  min: number;
  max: number;
}

@Component({
  selector: 'g[ln-charts-guardian-circle]',
  template: `
    @if (circle) {
      <svg:g>
        <defs>
          <svg:g
            ngx-charts-svg-linear-gradient
            [orientation]="barOrientation.Vertical"
            [name]="gradientId"
            [stops]="circle.gradientStops"
          />
        </defs>
      @if (animations && barVisible) {
        <svg:rect
          [@animationState]="'active'"
          [attr.x]="circle.cx - circle.radius"
          [attr.y]="circle.cy"
          [attr.width]="circle.radius * 2"
          [attr.height]="circle.height"
          [attr.fill]="gradientFill"
          class="tooltip-bar"
        />
      }
      @if (!animations && barVisible) {
        <svg:rect
          [attr.x]="circle.cx - circle.radius"
          [attr.y]="circle.cy"
          [attr.width]="circle.radius * 2"
          [attr.height]="circle.height"
          [attr.fill]="gradientFill"
          class="tooltip-bar"
        />
      }
      <svg:g
        ngx-charts-circle
        class="circle"
        [cx]="circle.cx"
        [cy]="circle.cy"
        [r]="isActive(circle.data) ? (circle.radius * circleRadiusExpansion) : circle.radius"
        [fill]="circle.color"
        [class.active]="isActive(circle.data)"
        [pointerEvents]="disableZeroValue && circle.value === 0 ? 'none' : 'all'"
        [data]="circle.value"
        [classNames]="circle.classNames"
        (select)="onClick()"
        (activate)="activateCircle()"
        (deactivate)="deactivateCircle()"
        ngx-tooltip
        [tooltipDisabled]="tooltipDisabled"
        [tooltipPlacement]="placementTypes.Top"
        [tooltipType]="styleTypes.tooltip"
        [tooltipTitle]="tooltipTemplate ? undefined : getTooltipText(circle)"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipContext]="circle.data"
      />
    </svg:g>
  }
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(250, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class GuardianCircleComponent implements OnChanges, OnInit {
  @Input() data: ExtendedDataItem;
  @Input() groupValuesBySeriesName: Map<string, ExtendedDataItem[]>;
  @Input() index: number;
  @Input() circleRadiusExpansion = 1.5;
  @Input() xAxisBandwidthSize: number;
  @Input() xScale;
  @Input() yScale;
  @Input() colors: ColorHelper;
  @Input() disableZeroValue = false;
  @Input() activeEntries: ExtendedDataItem[];
  @Input() tooltipDisabled = false;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() animations = true;

  @Output() select: EventEmitter<ExtendedDataItem> = new EventEmitter();
  @Output() activate: EventEmitter<ExtendedDataItem> = new EventEmitter();
  @Output() deactivate: EventEmitter<ExtendedDataItem> = new EventEmitter();

  areaPath: any;
  circle: GuardianCircle;
  barVisible = false;
  gradientId: string;
  gradientFill: string;

  barOrientation = BarOrientation;
  placementTypes = PlacementTypes;
  styleTypes = StyleTypes;

  ngOnInit() {
    this.gradientId = 'grad' + id().toString();
    this.gradientFill = `url(#${this.gradientId})`;
  }

  ngOnChanges(): void {
    this.update();
  }

  update(): void {
    this.circle = this.mapDataPointToCircle(this.data, this.index, this.xAxisBandwidthSize);
  }

  mapDataPointToCircle(d: ExtendedDataItem, i: number, xAxisBandwidthSize: number): GuardianCircle {
    const seriesName = d.seriesName;
    const valueName = d.valueName;

    const value = d.value;
    let label = '';
    let tooltipLabel = ''

    from(this.groupValuesBySeriesName.get(seriesName)).pipe(
      groupBy(c => c.value),
      mergeMap(group => group.pipe(toArray()))
    ).subscribe(groupByValue => {
        groupByValue.filter(group => group.value === d.value).forEach(
          l => label = label !== '' ? label + ',' + (l.label || l.name) : (l.label || l.name)
        )
      }
    );
    tooltipLabel = formatLabel(label);

    const correctionOffset = xAxisBandwidthSize / 2;
    const cx = this.xScale(seriesName) + correctionOffset;

    const cy = this.yScale(value);
    const radius = 5;
    const height = this.yScale.range()[0] - cy;
    const opacity = 1;

    const color = this.colors.getColor(valueName);

    const data = Object.assign({}, d, {
      name: valueName,
      label
    });

    return {
      classNames: [`circle-data-${i}`],
      value,
      label,
      data,
      cx,
      cy,
      radius,
      height,
      tooltipLabel,
      color,
      opacity,
      seriesName,
      gradientStops: this.getGradientStops(color),
      min: d.min,
      max: d.max
    };
  }

  getTooltipText(circle: GuardianCircle): string {
    return `
      <span class="tooltip-label">${escapeLabel(formatLabel(circle.label))}</span>
      <span class="tooltip-val">${circle.value.toLocaleString()}</span>
    `;
  }

  getGradientStops(color: string): Gradient[] {
    return [
      {
        offset: 0,
        color,
        opacity: 0.2
      },
      {
        offset: 100,
        color,
        opacity: 1
      }
    ];
  }

  onClick(): void {
    this.select.emit(this.circle.data);
  }

  isActive(item): boolean {
    if (!this.activeEntries) {
      return false;
    }
    return this.activeEntries.some(d => d.name === item.name && d.value === item.value && d.seriesName === item.seriesName);
  }

  activateCircle(): void {
    this.barVisible = true;
    this.activate.emit(this.circle.data);
  }

  deactivateCircle(): void {
    this.barVisible = false;
    this.deactivate.emit(this.circle.data);
  }
}
