<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [animations]="animations"
  [activeEntries]="activeEntries"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event, true)"
  (legendLabelDeactivate)="onDeactivate($event, true)"
  xmlns:svg="http://www.w3.org/2000/svg"
  >
  <svg:g [attr.transform]="transform" class="chart">
    <svg:g
      ngx-charts-x-axis
      [showGridLines]="showXGridLines"
      [dims]="dims"
      [xScale]="xScale"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      (dimensionsChanged)="updateXAxisHeight($event)"
    />
    <svg:g
      ngx-charts-y-axis
      [showGridLines]="showYGridLines"
      [dims]="dims"
      [yScale]="yAxisScale"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      (dimensionsChanged)="updateYAxisWidth($event)"
    />
  </svg:g>
  <svg:g [attr.transform]="transform">
  @for (whisker of whiskers; track whisker) {
    <svg:g
      ln-charts-diagram-whisker
      [data]="whisker"
      [defaultPointer]="true"
      [strokeSize]="strokeSize"
      [xAxisBandwidthSize]="xAxisBandwidthSize"
      [bandwidthSplitSize]="bandwidthSplitSize"
      [xScale]="xScale"
      [yScale]="yScale"
      [colors]="colors"
      [activeEntries]="activeEntries"
      [tooltipDisabled]="true"
      [tooltipTemplate]="tooltipTemplate"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
    />
  }
  @for (circle of circles; track trackBy(index, circle); let index = $index) {
    <svg:g
      ln-charts-guardian-circle
      [groupValuesBySeriesName]="groupBySeriesName"
      [data]="circle"
      [index]="index"
      [xAxisBandwidthSize]="xAxisBandwidthSize"
      [xScale]="xScale"
      [yScale]="yScale"
      [colors]="colors"
      [activeEntries]="activeEntries"
      [tooltipDisabled]="tooltipDisabled"
      [tooltipTemplate]="tooltipTemplate"
      [animations]="animations"
      (select)="onClick($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
    />
  }
  </svg:g>
</ngx-charts-chart>
