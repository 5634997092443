import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { Pipe, PipeTransform } from '@angular/core';
import { gt, includes, isEqual, lte } from 'lodash-es';

@Pipe({
  name: 'activityDateDiff'
})
export class ActivityDateDiffPipe implements PipeTransform {

  transform(item: ActivitiesViewModel, hourLabel: string, minutesLabel: string): any {
    let dateDiff: any; // can be number or string
    const plannedStartDate = new Date(item.plannedStart);
    const plannedEndDate = new Date(item.plannedEnd);
    hourLabel = hourLabel.startsWith(' ') ? hourLabel : ' ' + hourLabel;
    minutesLabel = minutesLabel.startsWith(' ') ? minutesLabel : ' ' + minutesLabel;

    // this is a time in milliseconds
    const diff = new Date(plannedEndDate).getTime()
      - new Date(plannedStartDate).getTime();

    // get time in hours
    const diffInHours = diff / (60 * 60 * 1000);

    if (diffInHours >= 1) {
      const diffInString = diffInHours.toString();

      if (includes(diffInString, '.')) {
        const splitDiff = diffInString.split('.');
        const hour = splitDiff[0];
        const decimalValWithDot = '.' + splitDiff[1];
        const decimalValWithDotAsInt = parseFloat(decimalValWithDot);
        const inRoundedMints = this.getRoundedMints(decimalValWithDotAsInt * 60);

        // When the difference in minutes is greater than 45 we just add an hour
        if (isEqual(inRoundedMints, '1')) {
          dateDiff = parseInt(hour) + 1;
        } else {
          dateDiff = hour + ':' + inRoundedMints;
        }
      } else {
        dateDiff = diffInString;
      }
      dateDiff += hourLabel;
    } else {
      dateDiff = this.getRoundedMints(diffInHours * 60);
      if (isEqual(dateDiff, '1')) {
        dateDiff = '1' + hourLabel;
      } else {
        dateDiff += minutesLabel;
      }
    }
    return dateDiff;
  }

  /**
   *
   * @description
   * Get the rounded mints by calculation
   * 0 -> 0 Mints
   * 0<value<=15 -> 15 Mints
   * 15<value<=30 -> 30 Mints
   * 30<value<=45 -> 45 Mints
   *
   * If more than 45 and 60 we return 1, then calling function
   * will handle to add 1 hour or in case for minutes show 1 hour
   */
  getRoundedMints(value) {
    let mintsRounded = 1;

    if (isEqual(value, 0)) { // Note:- This case won't be valid for diffInHours >=1
      mintsRounded = 0;
    } else if (gt(value, 0) && lte(value, 15)) {
      mintsRounded = 15;
    } else if (gt(value, 15) && lte(value, 30)) {
      mintsRounded = 30;
    } else if (gt(value, 30) && lte(value, 45)) {
      mintsRounded = 45;
    }
    return mintsRounded.toString();
  }
}
