import { HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { sortBy } from 'lodash-es';

export class CacheItem {
  private static cachedRequestKeySeparator = '|';

  private readonly _cacheItemKey: string;
  private _response: any;
  private readonly _onLoadedResponse = new Subject<any>();
  private _isStopped = false;

  get response() {
    return this._response;
  }

  get cacheItemKey(): string {
    return this._cacheItemKey;
  }

  get responseObservable(): Observable<any> {
    if (this.isRequestFinished()) {
      return of(this._response);
    }
    return this._onLoadedResponse.asObservable();
  }

  constructor(url?: string, params?: HttpParams, response?: any) {
    this._cacheItemKey = CacheItem.generateKey(url, params);
    this._response = response;
  }

  public static generateKey(url: string, params?: HttpParams): string {
    if (!url) {
      return null;
    }
    let stringOfAllParams = '';
    const newHttpParams = this.parseUrlParams(url, params);
    const allParamsKeys = sortBy(newHttpParams.keys().concat());
    allParamsKeys.forEach((singleParamKey) => {
      stringOfAllParams +=
        CacheItem.cachedRequestKeySeparator + singleParamKey +
        CacheItem.cachedRequestKeySeparator;
      stringOfAllParams += sortBy(newHttpParams.getAll(singleParamKey).concat());
    });
    const urlParamsIndex = url.indexOf('?');
    return (((urlParamsIndex > 0) ? url.slice(0, urlParamsIndex) : url) + stringOfAllParams);
  }

  private static parseUrlParams(url: string, params?: HttpParams): HttpParams {
    let newOptions = params ?? new HttpParams();
    if (!url) {
      return newOptions;
    }

    const urlParamsIndex = url.indexOf('?');
    const urlOptionsStringArray = urlParamsIndex > 0 ? sortBy(url.slice(urlParamsIndex + 1).split('&')) : [];

    urlOptionsStringArray.forEach((urlOptionString) => {
      const urlOptionKey = urlOptionString.slice(0, urlOptionString.indexOf('='));
      const urlOptionValues = sortBy(urlOptionString.slice(urlOptionString.indexOf('=') + 1).split(','));

      if (urlOptionValues && newOptions.has(urlOptionKey)) {
        urlOptionValues
          .filter(
            (urlOptionValue) =>
              !newOptions
                .getAll(urlOptionKey)
                .find((val) => val === urlOptionValue)
          )
          .forEach((urlOptionValue) => {
            newOptions = newOptions.append(urlOptionKey, urlOptionValue);
          });
      } else if (urlOptionValues) {
        urlOptionValues.forEach((urlOptionValue, index) => {
          newOptions = index === 0
            ? newOptions.set(urlOptionKey, urlOptionValue)
            : newOptions.append(urlOptionKey, urlOptionValue);
        });
      }
    });

    return newOptions;
  }

  cache(value: any) {
    this._response = value;
    this._isStopped = true;
    this._onLoadedResponse.next(value);
    this._onLoadedResponse.complete();
  }

  raiseResponseSubjectError(value?: any) {
    this._isStopped = true;
    this._onLoadedResponse.error(value);
  }

  isRequestFinished(): boolean {
    return this._isStopped;
  }

  replaceCached(value: any) {
    this._response = value;
  }
}
