import { Modality } from '../models/equipment/modality';
import { EquipmentComponentItem, EquipmentDetails } from '../models/equipment/equipment-details';
import { EquipmentStatusForCustomer } from '../models/equipment/equipment-status';
import { LastViewedEquipment } from '../models/last-viewed-equipment';
import { EquipmentMyProfile, EquipmentMyProfileChecked } from '../models/equipment/equipment-my-profile';
import { Equipment } from '../models/equipment/equipment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  addRemoveMyEquipmentsRestName, allSelectableEquipments, equipmentDeativateRestName, equipmentDetailsRestName, equipmentDocumentsRestName, equipmentEnvironmentDataExistsRestName, equipmentEnvironmentDataRestName, equipmentEnvironmentOptionsRestName, equipmentForMyFiltersRestName, equipmentGetWidgetsOrderRestName, equipmentIndividualRestName, equipmentNwePortalDocumentsRestName, equipmentOptionsUpgradesQuoteRestName, equipmentOptionsUpgradesRecommendationRestName, equipmentOptionsUpgradesRestName, equipmentOptionsUpgradesTrialRestName, equipmentPatchDocumentsRestName, equipmentRestName, equipmentRwfWhitelisted, equipmentRwfWhitelistExists, equipmentSaveOrderWidgetsRestName, equipmentsComponentsRestName, equipmentsContactsRestName, equipmentSimulatorCreate, equipmentsMobileAddressesRestName, equipmentsPiiDetailsRestName, equipmentSrsStatusCheck, equipmentStatusMessageType, equipmentStatusPriorityRefreshRestName, equipmentStatusRefreshRestName, equipmentStatusRestName, equipmentSyngoVoucherRestName, equipmentSyngoVoucherWhitelisted, lastViewedEquipmentRestName, modalitiesGBRestName, modalitiesRestName, modalitiesWhitelistRestName, myProfileCheckedRestName, myProfileRestName, postLastViewedRestName, reportMissingEquipmentRestName, securityWhitePaperRestname
} from './equipment-rest-constants.service';
import { LifenetSystemCustom } from '../models/optionsUpgrades/lifenet-system-custom';
import { EqDocumentType } from '../models/equipment/eq-document-type';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { AdditionalContacts } from '../models/additional-contacts';
import { EqMobileAddress } from '../models/equipment/eq-mobile-address';
import { EquipmentPiiDetails } from '../models/equipment/equipment-pii-details';
import { EquipmentViewModel } from '../view-models/equipment-view-model';
import { HttpParams } from '@angular/common/http';
import { DownloadUrl } from '../models/download-url';
import { EquipmentEnvironmentData, EquipmentEnvironmentDataInput, EquipmentEnvironmentFilterOptions } from '../models/equipment/equipment-environment';
import { SyngoVoucherInput } from '../models/equipment/documents/syngo-voucher-input';
import { EquipmentSrsStatus } from '../models/equipment/equipment-srs-status';
import { RWFEquipment } from '../view-models/rwf-equipment';
import { CommunicationClient } from '../services/communication/communication-client';
import { take } from 'rxjs/operators';
import { CustomerIds } from '../models/customer/customer-ids';

@Injectable({providedIn: 'root'})
export class EquipmentRestService {

  constructor(private http: CacheableHttpClient,
    private client: CommunicationClient) {
  }

  private static getUrl(url: string, key: string) {
    return url.replace(/:id/g, key);
  }

  getLastViewedEquipment(): Observable<LastViewedEquipment[]> {
    return this.http.get<LastViewedEquipment[]>(lastViewedEquipmentRestName, {cache: false});
  }

  getMyProfile(): Observable<EquipmentMyProfile> {
    return this.http.get<EquipmentMyProfile>(myProfileRestName);
  }

  getMyProfileChecked(): Observable<EquipmentMyProfileChecked> {
    return this.http.get<EquipmentMyProfileChecked>(myProfileCheckedRestName, {cache: false});
  }

  getEquipmentStatus(): Observable<EquipmentStatusForCustomer[]> {
    return this.http.get<EquipmentStatusForCustomer[]>(equipmentStatusRestName);
  }

  getEquipmentStatusFromWebsocketMessages(topics$?: Observable<string[]>): Observable<EquipmentStatusForCustomer[]> {
    return this.client.get<EquipmentStatusForCustomer[]>({
      url: '',
      messageType: equipmentStatusMessageType,
      topics$,
      merger: this.getEquipmentStatusForCustomerMerger
    }, true);
  }

  private getEquipmentStatusForCustomerMerger = (cacheValues: EquipmentStatusForCustomer[], messageValues: EquipmentStatusForCustomer[]) => {
    return [...messageValues, ...cacheValues].reduce((uniqueList: EquipmentStatusForCustomer[], uniqueListItem: EquipmentStatusForCustomer) => {
      return uniqueList.find(item => item.customerId === uniqueListItem.customerId) ? uniqueList : [...uniqueList, uniqueListItem];
    }, []);
  }

  postEquipmentStatusRefresh(customerIds: CustomerIds) {
    this.http.post(equipmentStatusRefreshRestName, customerIds, {
      responseType: 'text'
    }).pipe(take(1)).subscribe(() => {
      // do nothing.
    });
  }

  postEquipmentStatusPriorityRefresh(customerIds: CustomerIds) {
    this.http.post(equipmentStatusPriorityRefreshRestName, customerIds, {
      responseType: 'text'
    }).pipe(take(1)).subscribe(() => {
      // do nothing.
    });
  }

  getEquipment(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(equipmentRestName);
  }

  getEquipmentDetails(key: string): Observable<EquipmentDetails> {
    return this.http.get(EquipmentRestService.getUrl(equipmentDetailsRestName, key));
  }

  postAddEquipmentToMyProfile(key): Observable<any> {
    this.clearCache(myProfileRestName);
    return this.http.post(EquipmentRestService.getUrl(addRemoveMyEquipmentsRestName, key), null);
  }

  postEditComponent(component: EquipmentComponentItem, equipmentKey: string): Observable<any> {
    this.clearEquipmentDetailsCache(equipmentKey);
    return this.http.post(EquipmentRestService.getUrl(equipmentsComponentsRestName, equipmentKey), component);
  }

  postMyProfileChecked(isChecked: boolean): Observable<Object> {
    return this.http.post(myProfileCheckedRestName, {checked: isChecked});
  }

  postLastViewedEquipment(key: string) {
    this.http.post(EquipmentRestService.getUrl(postLastViewedRestName, key), null, {
      responseType: 'text'
    }).subscribe(() => {
      // do nothing.
    });
  }

  postEditEquipment(key, body): Observable<any> {
    this.clearGetEquipmentsListCache();
    return this.http.post(EquipmentRestService.getUrl(equipmentIndividualRestName, key), body);
  }

  clearGetEquipmentsListCache() {
    this.clearCache(equipmentRestName);
    this.clearCache(equipmentRestName + '/equipmentsList');
  }

  clearEquipmentDetailsCache(key: string) {
    this.clearCache(EquipmentRestService.getUrl(equipmentDetailsRestName, key));
  }

  removeEquipmentFromMyProfile(key): Observable<any> {
    this.clearCache(myProfileRestName);
    return this.http.delete(EquipmentRestService.getUrl(addRemoveMyEquipmentsRestName, key));
  }

  deactivateEquipment(key, body) {
    return this.http.post(EquipmentRestService.getUrl(equipmentDeativateRestName, key), body);
  }

  getOptionsAndUpgrades(key: string): Observable<LifenetSystemCustom[]> {
    return this.http.get<LifenetSystemCustom[]>(EquipmentRestService.getUrl(equipmentOptionsUpgradesRestName, key));
  }

  postRecommendation(key: string, body: any): Observable<any> {
    return this.http.post(EquipmentRestService.getUrl(equipmentOptionsUpgradesRecommendationRestName, key), body);
  }

  postGetQuote(body: any, marketingNameGuid: string = ''): Observable<any> {
    return this.http.post(equipmentOptionsUpgradesQuoteRestName, body, {
      params: new HttpParams().set('marketingNameGuid', marketingNameGuid)
    });
  }

  postGetTrial(body: any, marketingNameGuid: string = ''): Observable<any> {
    return this.http.post(equipmentOptionsUpgradesTrialRestName, body, {
      params: new HttpParams().set('marketingNameGuid', marketingNameGuid)
    });
  }

  clearCache(restName: string) {
    this.http.clearCache(restName);
  }

  clearEquipmentCache() {
    this.clearCache(equipmentRestName + '/equipmentInfo');
    this.clearCache(equipmentRestName + '/equipmentsList');
  }

  getDocuments(key: string): Observable<EqDocumentType[]> {
    return this.http.get<EqDocumentType[]>(EquipmentRestService.getUrl(equipmentDocumentsRestName, key));
  }

  getNwePortalDocuments(key: string): Observable<EqDocumentType[]> {
    return this.http.get<EqDocumentType[]>(EquipmentRestService.getUrl(equipmentNwePortalDocumentsRestName, key));
  }

  getPatchDocuments(key: string): Observable<EqDocumentType[]> {
    return this.http.get<EqDocumentType[]>(EquipmentRestService.getUrl(equipmentPatchDocumentsRestName, key));
  }

  requestSyngoVoucher(body: SyngoVoucherInput) {
    return this.http.post(equipmentSyngoVoucherRestName, body);
  }

  getModalities(): Observable<Modality[]> {
    return this.http.get<Modality[]>(modalitiesRestName);
  }

  getModalityGBCmdbDescriptions(): Observable<string[]> {
    return this.http.get<string[]>(modalitiesGBRestName);
  }

  getModalitiesFromWhitelist(): Observable<Modality[]> {
    return this.http.get<Modality[]>(modalitiesWhitelistRestName);
  }

  getEquipmentContacts(equipmentKey: string): Observable<AdditionalContacts[]> {
    this.clearCache(EquipmentRestService.getUrl(equipmentsContactsRestName, equipmentKey));
    return this.http.get<AdditionalContacts[]>(EquipmentRestService.getUrl(equipmentsContactsRestName, equipmentKey));
  }

  getMobileEquipmentAddresses(id): Observable<EqMobileAddress[]> {
    return this.http.get<EqMobileAddress[]>(EquipmentRestService.getUrl(equipmentsMobileAddressesRestName, id));
  }

  getEquipmentPiiDetails(key: string): Observable<EquipmentPiiDetails> {
    return this.http.get(EquipmentRestService.getUrl(equipmentsPiiDetailsRestName, key));
  }

  getAllSelectableEquipments(country: string, searchString: string): Observable<EquipmentViewModel[]> {
    return this.http.get<EquipmentViewModel[]>(allSelectableEquipments, {
      params: new HttpParams().set('country', country).set('searchString', searchString)
    });
  }

  getSecurityWhitepaperUrl(path: string): Observable<DownloadUrl> {
    this.clearCache(securityWhitePaperRestname);
    const params = new HttpParams().set('path', path);
    return this.http.get<DownloadUrl>(securityWhitePaperRestname, {params});
  }

  reportMissingEquipment(body: any): Observable<any> {
    return this.http.post(reportMissingEquipmentRestName, body);
  }

  getEquipmentEnvironmentFilterOptions(equipmentKey: string) {
    return this.http.get<EquipmentEnvironmentFilterOptions>(EquipmentRestService.getUrl(equipmentEnvironmentOptionsRestName, equipmentKey));
  }

  getEquipmentEnvironmentData(equipmentKey: string, input: EquipmentEnvironmentDataInput) {
    return this.http.post<EquipmentEnvironmentData>(EquipmentRestService.getUrl(equipmentEnvironmentDataRestName, equipmentKey), input);
  }

  getEquipmentForMyFilters() {
    this.clearCache(equipmentForMyFiltersRestName);
    return this.http.get<Equipment[]>(equipmentForMyFiltersRestName, {cache: false});
  }

  getEquipmentEnvironmentDataExists(): Observable<boolean> {
    return this.http.get<boolean>(equipmentEnvironmentDataExistsRestName, {cache: false});
  }

  getRemoteWorkForceEquipmentWhiteList(): Observable<RWFEquipment[]> {
    return this.http.get<RWFEquipment[]>(equipmentRwfWhitelisted);
  }

  getRemoteWorkForceEquipmentWhiteListExists(): Observable<boolean> {
    return this.http.get<boolean>(equipmentRwfWhitelistExists);
  }

  checkIfEquipmentIsWhitelistedForSyngoVoucher(equipmentKey: string): Observable<boolean> {
    return this.http.get<boolean>(EquipmentRestService.getUrl(equipmentSyngoVoucherWhitelisted, equipmentKey));
  }

  getEquipmentSrsStatus(equipmentKey: string): Observable<EquipmentSrsStatus> {
    return this.http.get(equipmentSrsStatusCheck.replace(/:id/g, equipmentKey), {cache: false});
  }

  getShsShopUrl(url: string): Observable<any> {
    return this.http.get(url);
  }

  postCreateSimulatorEquipment(body): Observable<any> {
    this.clearGetEquipmentsListCache();
    return this.http.post(equipmentSimulatorCreate, body);
  }

  saveEquipmentWidgetsOrder(widgets: string[]): Observable<any> {
    return this.http.post(equipmentSaveOrderWidgetsRestName, widgets);
  }

  getEquipmentWidgetsOrder(): Observable<string[]>{
    return this.http.get(equipmentGetWidgetsOrderRestName, {cache: false});
  }
}
