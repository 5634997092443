@if (parentForm) {
  <div class="textarea"
    [formGroup]="parentForm"
       [ngClass]="{
      'textarea--large': isLarge,
      'is-active': isActive || textareaContent,
      'has-focus': isFocused,
      'is-invalid': isInvalid,
      'is-valid': isValid,
      'is-disabled': isDisabled}">
    <textarea #textarea
      class="textarea__area"
      [id]="textareaId"
      [name]="name"
      [rows]="rows"
      [formControlName]="name" [maxlength]="maxlength" [required]="isRequired" [hlNoWhiteSpace]="noWhiteSpace"
      [placeholder]="placeholderText | translate"
      [ngModel]="textareaContent"
      (ngModelChange)="onChange($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [readonly]="isReadonly">
    </textarea>
    <label class="textarea__label" [for]="textareaId">
      <span>{{labelText | translate}}</span>
      @if (isRequired) {
        <span>*</span>
      }
    </label>
  </div>
} @else {
  <div class="textarea"
       [ngClass]="{
      'textarea--large': isLarge,
      'is-active': isActive || textareaContent,
      'has-focus': isFocused,
      'is-invalid': isInvalid,
      'is-valid': isValid,
      'is-disabled': isDisabled}">
    <textarea #textarea="ngModel"
      ngModel
      class="textarea__area"
      [id]="textareaId"
      [name]="name"
      [rows]="rows" [maxlength]="maxlength" [required]="isRequired" [hlNoWhiteSpace]="noWhiteSpace"
      [placeholder]="placeholderText | translate"
      [ngModel]="textareaContent"
      (ngModelChange)="onChange($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [ngModelOptions]="{standalone: true}"
      [readonly]="isReadonly">
    </textarea>
    <label class="textarea__label" [for]="textareaId">
      <span>{{labelText | translate}}</span>
      @if (isRequired) {
        <span>*</span>
      }
    </label>
  </div>
}

<ng-content></ng-content>
