import { Pipe, PipeTransform } from '@angular/core';
import { DateRangeArrayObject } from 'app/shared/filtering/dateRange/date-range-array-object';
import { filter, gte, lte } from 'lodash-es';

@Pipe({
  name: 'dateRangeArray'
})
export class DateRangeArrayPipe implements PipeTransform {

  parseDateToUnixTimeStamp(input: string | number): number {
    if (typeof input === 'string') {
      const dateSplit = input.split('T');
      return new Date(dateSplit[0]).getTime();
    }
    return input;
  }

  parseTimeZoneOffsetAndReturnTimeStamp(input) {
    // check for valid date
    if (!isNaN(Date.parse(input))) {
      const d = new Date(input);
      d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);

      const offsetDate = new Date(d).toISOString();
      return this.parseDateToUnixTimeStamp(offsetDate);
    } else {
      return false;
    }
  }

  transform(dataset: any[], dateRangeObject: DateRangeArrayObject) {
    if (dateRangeObject.rangeOfDate.fromDate === null && dateRangeObject.rangeOfDate.toDate === null) {
      return dataset;
    }

    if (dateRangeObject.rangeOfDate.fromDate !== null && dateRangeObject.rangeOfDate.toDate === null) {
      return this.transformByFromDate(dataset, dateRangeObject);
    }

    if (dateRangeObject.rangeOfDate.fromDate === null && dateRangeObject.rangeOfDate.toDate !== null) {
      return this.transformByToDate(dataset, dateRangeObject);
    }

    if (dateRangeObject.rangeOfDate.fromDate !== null && dateRangeObject.rangeOfDate.toDate !== null) {
      return this.transformByBothDates(dataset, dateRangeObject);
    }
  }

  transformByFromDate(dataset: any[], dateRangeObject: DateRangeArrayObject) {
    const dateFrom = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.fromDate);

    return this.transformByDate(dataset, dateRangeObject,
      !!dateFrom,
      (readPropertyParsed) => gte(readPropertyParsed, dateFrom));
  }

  transformByToDate(dataset: any[], dateRangeObject: DateRangeArrayObject) {
    let dateToParsed = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.toDate);
    if (typeof dateToParsed === 'number') {
      dateToParsed += 86399000;
    }

    return this.transformByDate(dataset, dateRangeObject,
      !!dateToParsed,
      (readPropertyParsed) => lte(readPropertyParsed, dateToParsed));
  }

  transformByBothDates(dataset: any[], dateRangeObject: DateRangeArrayObject) {
    const dateFrom = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.fromDate);
    let dateTo = this.parseTimeZoneOffsetAndReturnTimeStamp(dateRangeObject.rangeOfDate.toDate);
    if (typeof dateTo === 'number') {
      dateTo += 86399000;
    }

    return this.transformByDate(dataset, dateRangeObject,
      (!!dateFrom && !!dateTo),
      (readPropertyParsed) => gte(readPropertyParsed, dateFrom) && lte(readPropertyParsed, dateTo));
  }

  transformByDate(dataset: any[], dateRangeObject: DateRangeArrayObject, shouldProcess: boolean,
                  compareFunc: (timestamp: number) => boolean) {
    const result = [];
    if (shouldProcess) {
      filter(dataset, (el) => {
        const propertyValues = el[dateRangeObject.propertyKey];
        if (propertyValues.length > 0) {
          propertyValues.forEach(value => {
            const readPropertyParsed = this.parseDateToUnixTimeStamp(value[dateRangeObject.arrayProperty]);
            if (compareFunc(readPropertyParsed) && !result.includes(el)) {
              result.push(el);
            }
          });
        }
      });
    }
    return result;
  }
}
