import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SeriesData } from './diagram';
import { BarVerticalComponent } from '@swimlane/tpf-ngx-charts';

@Component({
  selector: 'hl-widget-diagram-bars',
  template: `
    <div class="diagram">
      <div class="aspect-ratio-4x3 aspect-ratio-md-1x1 aspect-ratio-lg-4x3 display-flex">
        <hl-diagram-bar-vertical-candles #chart [view]="view ? view : ''" [roundDomains]="true"
          [roundEdges]="false" [gradient]="gradient" [customColors]="customColors"
          [results]="results" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false"
          [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
          [yScaleMax]="yScaleMax" [scheme]="scheme" [yAxisTickFormatting]="yAxisTickFormatting"
          [animations]="animations" [barPadding]="barPadding" (select)="onSelected($event)">
          <ng-template #tooltipTemplate let-model="model">
            <div class="area-tooltip-container font-size-small">
              {{ model.extra.highestAvgSystemLabel }}<br>
              {{ model.extra.avgSystemModalityLabel }}<br>
              {{ model.extra.lowestAvgSystemLabel }}
            </div>
          </ng-template>
        </hl-diagram-bar-vertical-candles>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDiagramBarsComponent {
  @ViewChild('chart')
  private chart: BarVerticalComponent;

  @Input() scheme: any;
  @Input() yAxisTickFormatting: any;
  @Input() animations: boolean;
  @Input() showXAxis = true;
  @Input() showYAxis = true;
  @Input() showGridLines = true;
  @Input() showXAxisLabel = false;
  @Input() showYAxisLabel = false;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() yScaleMax: number;
  @Input() gradient = true;
  @Input() showDataLabel = false;

  @Input() useMonochrome = false;
  @Input() results: SeriesData[] = [];
  @Input() view: number[] = null;

  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  @Input() customColors: object[] = [];

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  get barPadding(): number {
    return this.chart && this.chart.width ? Math.ceil(this.chart.width / this.results.length * .7) : 45;
  }

  onSelected($event) {
    this.onSelect.emit($event);
  }
}
