import { Component, OnInit } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {DeviceDetectionService} from "../../../core/services/device-detection.service";

interface LegalLinksView {
  copyrightLeftLabel: string;
  licenseTermsUrl: string;
  digitalServiceAct: string;
}

@Component({
  selector: 'hl-legal-links',
  templateUrl: './legal-links.component.html'
})
export class LegalLinksComponent implements OnInit {

  view$: Observable<LegalLinksView>;
  isMobileApp = false;

  constructor(private configService: CountryConfigRestService,
              private deviceDetectionService: DeviceDetectionService) {
  }

  ngOnInit(): void {
    this.view$ = this.configService.getConfig()
      .pipe(map(config => this.getConfigProperties(config)));
    this.isMobileApp = this.deviceDetectionService.isMobileApp();
  }

  private getConfigProperties(config): LegalLinksView {
    const currentYear = new Date().getFullYear();
    return {
      copyrightLeftLabel: config.COPYRIGHT_NOTICE + ' ' + currentYear,
      licenseTermsUrl: config.LICENSE_TERMS_URL,
      digitalServiceAct: config.DIGITAL_SERVICE_ACT_URL
    };
  }

}
