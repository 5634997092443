import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';
import { ExcelEntry, ExcelExportRequest } from '../models/excel-export';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';

const generateExcelRestName = restEndPoint + 'generateExcel';

@Injectable({providedIn: 'root'})
export class GenerateExcelRestService {

  constructor(private http: CacheableHttpClient) {
  }

  generateExcelFile<T extends ExcelEntry>(request: ExcelExportRequest<T>): Observable<ArrayBuffer> {
    return this.http.post(generateExcelRestName, request, {responseType: 'arraybuffer'});
  }

  postExcelData(restEndpoint: string, data: any): Observable<any> {
    return this.http.post(restEndpoint, data, {responseType: 'arraybuffer'});
  }
}
