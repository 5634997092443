import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterHidden'
})

export class FilterHiddenPipe implements PipeTransform {
  transform(items: any[]): any {
    if (!items) {
      return items;
    }
    return items.filter(item => !item.hidden);
  }
}
