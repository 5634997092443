import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Observable } from 'rxjs';
import { SecurityAdvisory } from '../models/security-advisory';
import { DownloadUrl } from '../models/download-url';
import { HttpParams } from '@angular/common/http';
import { AdvisoryAttachment } from '../models/securityAdvisories/advisory-attachment';

@Injectable({providedIn: 'root'})
export class SecurityAdvisoriesRestService {

  securityAdvisoriesAllRestName = restEndPoint + 'security-advisories/all';
  securityAdvisoriesActiveRestName = restEndPoint + 'security-advisories/active';
  securityAdvisoriesCreateRestName = restEndPoint + 'security-advisories/create';
  securityAdvisoriesEditRestName = restEndPoint + 'security-advisories/edit';
  securityAdvisoriesDeleteRestName = restEndPoint + 'security-advisories/delete/:id';
  securityAdvisoriesPdfDownloadUrlRestName = restEndPoint + 'security-advisories/download-url';
  securityAdvisoriesAttachmentsRestName = restEndPoint + 'security-advisories/attachments';
  securityAdvisoriesSendEmailsRestName = restEndPoint + 'security-advisories/send-emails';
  securityAdvisoriesUploadHeadersRestName = restEndPoint + 'security-advisories/upload-attachments';

  constructor(private http: CacheableHttpClient) {
  }

  getAllSecurityAdvisories(): Observable<SecurityAdvisory[]> {
    return this.http.get<SecurityAdvisory[]>(this.securityAdvisoriesAllRestName);
  }

  getActiveSecurityAdvisories(): Observable<SecurityAdvisory[]> {
    return this.http.get<SecurityAdvisory[]>(this.securityAdvisoriesActiveRestName);
  }

  getActiveSecurityAdvisoriesWithLimit(limit: number): Observable<SecurityAdvisory[]> {
    const httpParams = new HttpParams().set('limit', limit.toString());
    return this.http.get<SecurityAdvisory[]>(this.securityAdvisoriesActiveRestName, {
      params: httpParams,
      cache: false
    });
  }

  getDownloadUrl(id: number, documentType: string, languageCode: string): Observable<DownloadUrl> {
    const httpParams = new HttpParams().set('id', id.toString()).set('fileType', documentType).set('languageCode', languageCode);
    return this.http.get<DownloadUrl>(this.securityAdvisoriesPdfDownloadUrlRestName, {
      cache: false,
      params: httpParams
    });
  }

  getSecurityAdvisoryAttachments(id: number, languageCode: string) {
    const httpParams = new HttpParams().set('id', id.toString()).set('languageCode', languageCode);
    return this.http.get<AdvisoryAttachment[]>(this.securityAdvisoriesAttachmentsRestName, {
      cache: false,
      params: httpParams
    });
  }

  createOrEditAdvisory(body, isEdit): Observable<any> {
    if (isEdit) {
      return this.http.post(this.securityAdvisoriesEditRestName, body);
    }
    return this.http.post(this.securityAdvisoriesCreateRestName, body);
  }

  deleteAdvisory(id: number) {
    this.clearAdvisoriesCache();
    const url = this.securityAdvisoriesDeleteRestName.replace(/:id/g, id.toString());

    return this.http.delete(url);
  }

  sendEmails(id: number, timestamp: string) {
    const httpParams = new HttpParams().set('id', id.toString()).set('timestamp', timestamp);
    return this.http.get(this.securityAdvisoriesSendEmailsRestName, {
      cache: false,
      params: httpParams
    });
  }

  clearAdvisoriesCache() {
    this.http.clearCache(this.securityAdvisoriesAllRestName);
    this.http.clearCache(this.securityAdvisoriesActiveRestName);
  }

  getUploadHeaders(body): Observable<any> {
    return this.http.post(this.securityAdvisoriesUploadHeadersRestName, body);
  }
}
