import { combineLatest, Observable } from 'rxjs';
import { UserRestService } from '../../rest-services/user-rest.service';
import { Injectable } from '@angular/core';
import { isEqual, some } from 'lodash-es';
import { User } from '../../models/user';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { map } from 'rxjs/operators';
import { type Roles } from '../../core-constants.service';
import { UserAccountViewModel } from '../../../customer-administration/user-accounts/user-account-view-model';

@Injectable({providedIn: 'root'})
export class UserUtilService {

  constructor(private userRestService: UserRestService,
    private configService: CountryConfigRestService) {
  }

  checkUserRoles<T extends Record<string, Roles>>(userRolesToBeChecked: T): Observable<Record<keyof T, boolean>> {
    return this.userRestService.getUserRoles().pipe(
      map(userRolesResponse => {
        const entries = Object.entries(userRolesToBeChecked)
          .map(([role, identifier]) => [role, some(userRolesResponse, {identifier})]);
        return Object.fromEntries(entries);
      })
    );
  }

  getUser(): Observable<User> {
    return this.userRestService.getUser();
  }

  showIntroModal(): Observable<boolean> {
    return combineLatest([
      this.configService.getConfig(),
      this.userRestService.showIntroModal()
    ]).pipe(
      map(([config, showModal]) =>
        isEqual(config.INTRODUCTION_FEATURE_AVAILABLE, 'true') && !showModal.shown
      )
    );
  }

  postIntroModalClosed(): Observable<any> {
    return this.userRestService.postIntroModalClosed();
  }

  getUserAccounts(showCity = false, showState = false): Observable<UserAccountViewModel[]> {
    return this.userRestService.getUserAccounts().pipe(map(accounts => accounts.map(account => ({
      ...account,
      roleList: account.roles.join(', '),
      customerGroupNames: account.customerGroups.map(g => g.groupName),
      customerGroupNameList: account.customerGroups.map(g => g.groupName).join(', '),
      showCity: showCity && account.customers.some(customer => !!customer.city),
      showState: showState && account.customers.some(customer => !!customer.state),
      expanded: false
    } as UserAccountViewModel))));
  }

  getRolesTranslatedAvailable() {
    return this.userRestService.getTranslatedRoles();
  }

  isInternalUser(): Observable<boolean> {
    return this.userRestService.getUser().pipe(
      map(user => user.isInternalUser)
    );
  }
}
