@for (option of options; track option; let i = $index) {
  @if (!option.hidden) {
    <li class="menu__item multi-selectbox__option display-flex flex-row nested-multi-selectbox__option padding-0"
      (click)="toggleSelected($event, option)" [class.option-disabled]="option.disabled">
      <span class="nested-multi-selectbox__selectable-area" [attr.data-cy]="'nested-tree-option-' + dataCy + (i + 1)">
        <input type="checkbox" class="checkbox" [checked]="option.isSelected && !(option | indeterminateCheckbox)"
          [indeterminate]="option | indeterminateCheckbox" [disabled]="option.disabled"/>
          <label>
            @if (showIcon) {
              <span class="icon icon-status-{{option.color}}"></span>
            } <span>{{ translateKeys ? (option.title | translate) : option.title }}</span>
          </label>
          </span>
      @if (option.children && option.children.length) {
        <i class="icon-arrow-down-large vertical-align-before-middle text-align-right nested-multi-selectbox__arrow"
          [class.icon-arrow-up-large]="option.isExpanded" [class.color-orange]="option.isExpanded"
          [class.nested-multi-selectbox__arrow--disabled]="option.disabled" [class.nested-multi-selectbox__non-clickable-area]="option.disabled"
          (click)="toggleExpand($event, option)" [attr.data-cy]="'option-arrow-' + dataCy + (i + 1)" aria-hidden="true"></i>
      } @else {
        <span class="nested-multi-selectbox__non-clickable-area" (click)="stopPropagateEvent($event)"></span>
      }
    </li>
  }
  @if (option.children && option.children.length && option.isExpanded) {
    <div class="nested-multi-selectbox__children-margin">
      <hl-nested-multi-selectbox-tree [options]="option.children" (superSelectedEvent)="toggleSelected(null, option, $event)" [showIcon]="showIcon"
        [chainShrinking]="chainShrinking" [scrollOnExpand]="scrollOnExpand" [translateKeys]="translateKeys"
        (superScrollEvent)="superScrollEvent.emit($event)" [dataCy]="'embedded-'"></hl-nested-multi-selectbox-tree>
    </div>
  }
}
