@if (!showSpinner) {
  <button
    type="button" [ngClass]="buttonClasses"
    [disabled]="disabled"
    [class.margin-bottom-0]="!withBottomMargin"
    (click)="emitButtonClick()"
    attr.data-cy="{{dataCy}}">
    <span #labelSpan>
      <span [translate]="label"></span>
      <ng-content></ng-content>
    </span>
  </button>
} @else {
  <button
    type="button" [ngClass]="buttonClasses"
    [class.margin-bottom-0]="!withBottomMargin">
    <span class="loading-animation ln-loading-animation loading-animation--export font-size-xsmall"></span>
  </button>
}
