import { Pipe, PipeTransform } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';

@Pipe({
  name: 'plural',
  pure: false
})
export class PluralPipe implements PipeTransform {

  constructor(private config: CountryConfigRestService) {
  }

  transform(value: number | any[] | null | undefined, singular: string, plural: string): Observable<string> {
    const count = typeof value === 'number' ? value : value?.length;
    return this.config.getConfig().pipe(
      map(config => isEqual(config.TECHNICAL_DISPLAY_LIST_COUNT, 'true')),
      map(isEnabled => isEnabled && count === 1 ? singular : plural),
      distinctUntilChanged()
    );
  }

}
