import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from '../../../core/utils/date-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-calendar-icon',
  templateUrl: './calendar-icon.component.html'
})
export class CalendarIconComponent implements OnInit, OnDestroy {
  @Input() dateStart: string;

  date = {
    content: null,
    month: ''
  };
  private unsubscribe$ = new Subject<void>();

  constructor(private configService: CountryConfigRestService,
    private dateUtilService: DateUtilService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {

      // tslint:disable-next-line:ban
      const monthsTrans = this.translateService.instant('MONTHS');
      const monthNames = monthsTrans.split(',');

      const date = this.dateUtilService.getDateFromUTCString(this.dateStart);

      this.date = {
        content: date.getDate(),
        month: monthNames[date.getMonth()] === undefined ? date.getMonth() + 1 : monthNames[date.getMonth()]
      };
    });
  }
}
