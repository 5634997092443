import { Directive, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';
import { WalkMeUtilService } from '../../../core/utils/walk-me-util.service';
import { TEST_BOT } from '../../../core/core-constants.service';
import { isEmpty } from 'lodash-es';

@Directive({
  selector: '[hlWalkMe]'
})
export class WalkMeDirective implements OnInit, OnDestroy {

  script: any;

  constructor(
    private environmentConfigRestService: EnvironmentConfigRestService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private walkMeUtilService: WalkMeUtilService) {
  }

  ngOnInit() {
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.addWalkMe(configResponse.WALKME_CONFIGURATION_IDENTIFIER);
    });
  }

  ngOnDestroy() {
    this.renderer2.removeChild(this.document.body, this.script);
  }

  addWalkMe(walkMeIdentifier: string) {
    if (navigator.userAgent.includes(TEST_BOT) || isEmpty(walkMeIdentifier)) {
      return;
    }
    this.script = this.renderer2.createElement('script');
    this.script.type = `text/javascript`;
    this.script.text = this.getWalkMeScript(walkMeIdentifier);
    this.walkMeUtilService.isWalkMeCreated.subscribe(wasCreated => {
      if (!wasCreated) {
        this.renderer2.appendChild(this.document.body, this.script);
        this.walkMeUtilService.isWalkMeCreated.next(true);
      }
    });
  }

  getWalkMeScript(walkMeIdentifier: string) {
    let WALK_ME_SCRIPT = '(function() {var walkme = document.createElement(\'script\'); ';
    WALK_ME_SCRIPT += this.getWalkMeLanguageConfigurationScript();
    WALK_ME_SCRIPT += 'walkme.type = \'text/javascript\'; walkme.async = true; ';
    WALK_ME_SCRIPT += 'walkme.src = \'' + walkMeIdentifier + '\'; ';
    WALK_ME_SCRIPT += 'var s = document.getElementsByTagName(\'script\')[0]; ';
    WALK_ME_SCRIPT += 's.parentNode.insertBefore(walkme, s); ';
    WALK_ME_SCRIPT += 'window._walkmeConfig = {smartLoad:true}; })();';
    return WALK_ME_SCRIPT;
  }

  /**
   * Language setting
   */
  getWalkMeLanguageConfigurationScript() {
    let WALK_ME_GET_LANGUAGE = 'window.walkme_get_language = function (){ ';
    WALK_ME_GET_LANGUAGE += 'if (window.walkMeLanguage) { return window.walkMeLanguage }';
    WALK_ME_GET_LANGUAGE += ' else {  return \'\' }';
    WALK_ME_GET_LANGUAGE += '}; ';
    return WALK_ME_GET_LANGUAGE;
  }

}
