// tickets
import { restEndPoint } from '../core-constants.service';

export const ticketsAllRestName = restEndPoint + 'tickets';
export const ticketsIndividualRestName = restEndPoint + 'tickets/:id';
export const ticketsHistoryRestName = restEndPoint + 'tickets';
export const problemSeveritiesRestName = restEndPoint + 'tickets/problemSeverities';
export const ticketTypesRestName = restEndPoint + 'tickets/types';
export const ticketSalutationsRestName = restEndPoint + 'tickets/salutations';
export const ticketTitlesRestName = restEndPoint + 'tickets/titles';
export const ticketCreateRestName = restEndPoint + 'tickets/create';
export const ticketUpdateRestName = restEndPoint + 'tickets/update';
export const ticketCloseRestName = restEndPoint + 'tickets/close';
export const ticketTypedModalities = restEndPoint + 'tickets/typedModalities';
export const ticketServiceReportsRestName = restEndPoint + 'reports/list/:id';
export const ticketSurveyRestName = restEndPoint + 'tickets/survey';
export const ticketRefreshRestName = restEndPoint + 'tickets/refresh';
export const ticketPriorityRefreshRestName = restEndPoint + 'tickets/priorityRefresh';
export const ticketSparePartsRestName = restEndPoint + 'spare-parts/material-numbers';

export const ticketStatusMessageType = 'ticket-status';
