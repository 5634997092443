import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LifeNetUtilService } from './life-net-util.service';
import { mergeMap } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class WalkMeUtilService {

  WINDOW_LANGUAGE_PARAM = 'language';
  WINDOW_COUNTRY_PARAM = 'country';
  WINDOW_WALK_ME_LANGUAGE_PARAM = 'walkMeLanguage';
  WALK_ME_LANGUAGE_KEY = 'WALKME_LANGUAGE';
  isWalkMeCreated = new ReplaySubject<boolean>(1);

  constructor(
    private translateService: TranslateService,
    private lifeNetUtilService: LifeNetUtilService) {
    this.isWalkMeCreated.next(false);
  }

  /**
   * Configures WalkMe widget (window.country and window.language)
   * @param window
   * @param language
   * @param country
   */
  setLanguageAndCountry(window: Window, language: string, country: string) {
    window[this.WINDOW_LANGUAGE_PARAM] = language;
    window[this.WINDOW_COUNTRY_PARAM] = country;
    if (language && country) {
      this.lifeNetUtilService.setTranslationFile(language + '_' + country).pipe(
        mergeMap(() => this.translateService.get(this.WALK_ME_LANGUAGE_KEY))
      ).subscribe((walkMeLanguage: string) => {
        this.setWalkMeLanguage(walkMeLanguage);
      });
    } else {
      this.translateService.get(this.WALK_ME_LANGUAGE_KEY).subscribe((walkMeLanguage: string) => {
        this.setWalkMeLanguage(walkMeLanguage);
      });
    }
  }

  private setWalkMeLanguage(walkMeLanguage: string) {
    if (walkMeLanguage === 'EN') {
      delete window[this.WINDOW_WALK_ME_LANGUAGE_PARAM];
    } else {
      window[this.WINDOW_WALK_ME_LANGUAGE_PARAM] = walkMeLanguage;
    }
  }
}
