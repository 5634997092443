import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[hlTooltipWithScrollbar]'
})
export class TooltipWithScrollbarDirective implements OnDestroy {
  isShown = false;
  supportsTouch = false;
  instance = null;
  instanceArea = null;
  mouseMoveOnTooltip = false;
  mouseMoveOnTooltipArea = false;
  touchListener: () => void;

  @Input()
  title: string;
  @Input()
  isActive = true;

  private static createCloseButton(instance: any, renderer: Renderer2): void {
    const closeButton = renderer.createElement('div');
    renderer.addClass(closeButton, 'tooltip__close-button');
    renderer.addClass(closeButton, 'font-size-xsmall');

    const closeButtonIcon = renderer.createElement('i');
    renderer.addClass(closeButtonIcon, 'icon-close');
    renderer.appendChild(closeButton, closeButtonIcon);
    renderer.appendChild(instance, closeButton);
    renderer.addClass(instance, 'is-clickable');
    return closeButton;
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: Event): void {
    if (!this.isShown) {
      this.show();
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: Event): void {
    setTimeout(() => {
    if (!this.mouseMoveOnTooltipArea) {
      this.hideIfNeeded(); }
    }, 50);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.mouseMoveOnTooltip && this.isShown) {
      this.hideIfNeeded();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    setTimeout(() => {
    if (!this.supportsTouch && !this.isShown) {
      this.show();
      this.mouseMoveOnTooltip = true;
    } else {
      this.hide();
      this.mouseMoveOnTooltip = false;
    }
    }, 1);
  }

  hideIfNeeded() {
    if (!this.supportsTouch && this.isShown) {
      this.hide();
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnDestroy(): void {
    if (this.touchListener) {
      this.touchListener();
    }
  }

  scroll = (): void => {
    this.calculatePosition(this.instance , 0);
  }

  calculatePosition(instance: any , displacement: number) {
    if (!this.isShown) {
      return;
    }

    const divider = 2;
    const win = {
      width: window.outerWidth,
      top: window.pageYOffset || document.documentElement.scrollTop,
      left: window.pageXOffset || document.documentElement.scrollLeft
    };

    const tooltip = {
      width: instance.offsetWidth,
      height: instance.offsetHeight,
      position: {
        x: 0,
        y: 0
      }
    };

    const elRect = this.el.nativeElement.getBoundingClientRect();
    const container = {
      width: this.el.nativeElement.offsetWidth,
      height: this.el.nativeElement.offsetHeight,
      offset: {
        left: elRect.left + win.left,
        top: elRect.top + win.top
      }
    };

    const scrollDistance = window.scrollY;

    // set initial position information
    tooltip.position.x = container.offset.left + (container.width / divider) - (tooltip.width / divider);
    tooltip.position.y = container.offset.top - tooltip.height;

    if (tooltip.position.x < 0) {
      tooltip.position.x = container.offset.left;
      this.renderer.addClass(instance, 'tooltip--left');
    } else {
      this.renderer.removeClass(instance, 'tooltip--left');
    }

    if (tooltip.position.x + tooltip.width > win.width) {
      tooltip.position.x =
          container.offset.left + container.width - tooltip.width;
      this.renderer.addClass(instance, 'tooltip--right');
    } else {
      this.renderer.removeClass(instance, 'tooltip--right');
    }

    if (tooltip.position.y < scrollDistance) {
      tooltip.position.y = container.offset.top + container.height;
      this.renderer.addClass(instance, 'tooltip--top');
    } else {
      this.renderer.removeClass(instance, 'tooltip--top');
    }

    this.renderer.setStyle(instance, 'left', tooltip.position.x + 'px');
    this.renderer.setStyle(instance, 'top', tooltip.position.y + displacement + 'px');
    this.renderer.setStyle(instance, 'word-wrap', 'break-word');

  }

  show() {
    if (this.isActive) {
      this.instanceArea = this.createTooltipArea();
      this.renderer.appendChild(document.body, this.instanceArea);

      this.instance = this.createTooltip();
      this.renderer.appendChild(document.body, this.instance);

      this.isShown = true;
      this.renderer.addClass(this.instance, 'is-visible');

      this.calculatePosition(this.instance , 0);
      window.addEventListener('scroll', this.scroll, true);
      (this.instance as HTMLElement).addEventListener('mouseenter', () => this.onMouseMoveOnTooltipEvent());
      (this.instance as HTMLElement).addEventListener('mouseleave', () => this.onMouseLeaveFromTooltip());

      this.calculatePosition(this.instanceArea , 10);
      (this.instanceArea as HTMLElement).addEventListener('mouseenter', () => this.onMouseMoveFromTooltipAreaEvent());
      (this.instanceArea as HTMLElement).addEventListener('mouseleave', () => this.onMouseLeaveFromTooltipArea());
    }
  }

  private createTooltip() {
    const instance = this.renderer.createElement('div');
    this.renderer.addClass(instance, 'tooltip');
    this.renderer.addClass(instance, 'ln-tooltip');

    const tooltipText = this.renderer.createElement('div');
    this.renderer.addClass(tooltipText, 'icon-info-with-scrollbar');
    this.renderer.setProperty(tooltipText, 'innerHTML', this.title);

    if (this.supportsTouch) {
      const closeButton = TooltipWithScrollbarDirective.createCloseButton(instance, this.renderer);
      this.touchListener = this.renderer.listen(
          closeButton,
          'touchstart',
          event => {
            this.hide();
          }
      );
    }
    this.renderer.appendChild(instance, tooltipText);
    return instance;
  }

  private createTooltipArea () {
    const tooltipArea = this.renderer.createElement('div');
    this.renderer.addClass(tooltipArea, 'icon-info-area');

    return  tooltipArea;
  }

  onMouseMoveOnTooltipEvent() {
    this.mouseMoveOnTooltip = true;
  }

  onMouseLeaveFromTooltip() {
        this.hide();
        this.mouseMoveOnTooltip = false;
  }

  onMouseMoveFromTooltipAreaEvent() {
    if (this.isShown) { // condition fixes occasional show bug
      this.mouseMoveOnTooltipArea = true;
    }
  }

  onMouseLeaveFromTooltipArea() {
        setTimeout(() => { // fixes respond lagg bug
        if (this.instance && this.isShown && !this.mouseMoveOnTooltip) {
          this.hide(); }
      }, 50);
  }

  hide() {
    window.removeEventListener('scroll', this.scroll, true);
    if (this.instance) {
      this.renderer.removeClass(this.instance, 'is-visible');

      if (this.instance.parentNode) {
        this.instance.parentNode.removeChild(this.instance);
        this.instanceArea.parentNode.removeChild(this.instanceArea);
      }
    }

    // Clear TooltipArea layer
    this.mouseMoveOnTooltipArea = false;
    this.isShown = false;
  }
}
