import { NgModule } from '@angular/core';
import { SearchFieldComponent } from '../shared/components/search-field/search-field.component';
import { MultiSelectboxComponent } from '../shared/components/multi-selectbox/multi-selectbox.component';
import { FilterTabsComponent } from '../shared/components/filter-tabs/filter-tabs.component';
import { TabsComponent } from '../shared/components/tabs/tabs.component';
import { NavListLinkComponent } from '../shared/components/nav-list-link/nav-list-link.component';
import { MultilineInputComponent } from '../shared/components/multiline-input/multiline-input.component';
import { ToasterComponent } from '../shared/components/toaster/toaster.component';
import { CalendarIconComponent } from '../shared/components/calendar-icon/calendar-icon.component';
import { ListCounterComponent } from '../shared/components/list-counter/list-counter.component';
import { ProgressIndicatorComponent } from '../shared/components/progress-indicator/progress-indicator.component';
import { DatePickerComponent } from '../shared/components/date-picker/date-picker.component';
import { ListSorterComponent } from '../shared/components/list-sorter/list-sorter.component';
import { ExcelExportComponent } from '../shared/components/excel-export/excel-export.component';
import { ExcelExportFromBeComponent } from '../shared/components/excel-export/excel-export-from-be.component';
import { SearchMultiSelectboxComponent } from '../shared/components/search-multi-selectbox/search-multi-selectbox.component';
import { TableSelectboxComponent } from '../shared/components/selectbox/table-selectbox/table-selectbox.component';
import { NestedMultiSelectboxComponent } from '../shared/components/nested-multi-selectbox/nested-multi-selectbox.component';
import { NestedMultiSelectboxTreeComponent } from '../shared/components/nested-multi-selectbox/nested-multi-selectbox-tree.component';
import { NestedSelectboxComponent } from '../shared/components/nested-selectbox/nested-selectbox.component';
import { NestedSelectboxTreeComponent } from '../shared/components/nested-selectbox/nested-selectbox-tree.component';
import { SelectDropdownMenuComponent } from '../shared/components/selectbox/table-selectbox/select-dropdown-menu.component';
import { ListComponent } from '../shared/components/list/list.component';
import { GroupButtonsComponent } from '../shared/components/group-buttons/group-buttons.component';
import { SelectboxComponent } from '../shared/components/selectbox/selectbox.component';
import { TimezoneComponent } from '../shared/components/timezone/timezone.component';
import { TimePickerComponent } from '../shared/components/time-picker/time-picker.component';
import { TextareaComponent } from '../shared/components/textarea/textarea.component';
import { TextfieldComponent } from '../shared/components/textfield/textfield.component';
import { DirectionArrowsComponent } from '../shared/components/direction-arrows/direction-arrows.component';
import { ButtonRowComponent } from '../shared/components/button-row/button-row.component';
import { CardSliderComponent } from '../shared/components/card-slider/card-slider.component';
import { MonthPickerComponent } from '../shared/components/month-picker/month-picker.component';
import { TimelineComponent } from '../shared/components/timeline/timeline.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DirectivesModule } from './directives.module';
import { ButtonWithSpinnerComponent } from 'app/shared/components/button-with-spinner/button-with-spinner.component';
import { LegalLinksComponent } from '../shared/components/legal-links/legal-links.component';
import { RouterLink } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    FlatpickrModule.forRoot(),
    TooltipModule,
    DirectivesModule,
    PipesModule,
    RouterLink,
    ProgressIndicatorComponent
  ],
  declarations: [
    SearchFieldComponent,
    MultiSelectboxComponent,
    FilterTabsComponent,
    TabsComponent,
    NavListLinkComponent,
    MultilineInputComponent,
    ToasterComponent,
    ListCounterComponent,
    CalendarIconComponent,
    DatePickerComponent,
    ListSorterComponent,
    ExcelExportComponent,
    ExcelExportFromBeComponent,
    SearchMultiSelectboxComponent,
    TableSelectboxComponent,
    LegalLinksComponent,
    NestedMultiSelectboxComponent,
    NestedMultiSelectboxTreeComponent,
    NestedSelectboxComponent,
    NestedSelectboxTreeComponent,
    SelectDropdownMenuComponent,
    ListComponent,
    GroupButtonsComponent,
    SelectboxComponent,
    TimezoneComponent,
    TimePickerComponent,
    TextareaComponent,
    TextfieldComponent,
    DirectionArrowsComponent,
    ButtonRowComponent,
    ButtonWithSpinnerComponent,
    CardSliderComponent,
    MonthPickerComponent,
    TimelineComponent
  ],
  exports: [
    CommonModule,
    SearchFieldComponent,
    MultiSelectboxComponent,
    FilterTabsComponent,
    TabsComponent,
    NavListLinkComponent,
    MultilineInputComponent,
    ToasterComponent,
    ListCounterComponent,
    CalendarIconComponent,
    ProgressIndicatorComponent,
    DatePickerComponent,
    ListSorterComponent,
    ExcelExportComponent,
    ExcelExportFromBeComponent,
    SearchMultiSelectboxComponent,
    TableSelectboxComponent,
    LegalLinksComponent,
    NestedMultiSelectboxComponent,
    NestedMultiSelectboxTreeComponent,
    NestedSelectboxComponent,
    NestedSelectboxTreeComponent,
    SelectDropdownMenuComponent,
    ListComponent,
    GroupButtonsComponent,
    SelectboxComponent,
    TimezoneComponent,
    TimePickerComponent,
    TextareaComponent,
    TextfieldComponent,
    DirectionArrowsComponent,
    ButtonRowComponent,
    ButtonWithSpinnerComponent,
    CardSliderComponent,
    MonthPickerComponent,
    TimelineComponent,
    DirectivesModule,
    PipesModule,
    TranslateModule
  ]
})
export class BasicComponentsSharedModule {
}
