import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input } from '@angular/core';

const TEXTFIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextfieldComponent),
  multi: true
};

@Component({
  selector: 'hl-textfield',
  templateUrl: './textfield.component.html',
  styles: [],
  providers: [TEXTFIELD_VALUE_ACCESSOR]
})
export class TextfieldComponent implements ControlValueAccessor {
  @Input() formControl: UntypedFormControl;
  @Input() isActive: boolean;
  @Input() _value: string;
  @Input() isDisabled: boolean;
  @Input() isInvalid: boolean;
  @Input() label: string;
  @Input() invalidLabel: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() type = 'text';
  @Input() min: number;
  @Input() max: number;
  @Input() additionalClass: string;
  @Input() isRequired = false;
  @Input() disableAutocomplete = false;
  @Input() maxlength: number;
  @Input() dataCy: string;

  @Input()
  set value(val: string) {
    this._value = val;
    this.isActive = this._value !== null && this._value !== undefined && this._value !== '';

    this.onModelChange(this.value);
    this.onModelTouched();
  }

  get value() {
    return this._value;
  }

  constructor(private translateService: TranslateService) {}

  onValueChange(value) {
    this.onModelChange(value);
    this.onModelTouched();
  }

  onModelChange: Function = (_: any) => {};
  onModelTouched: Function = () => {};

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
