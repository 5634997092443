import { Injectable } from '@angular/core';
import { EquipmentStatusForCustomer } from '../../models/equipment/equipment-status';
import { MyFilterWebsocketService } from '../my-filter-websocket/my-filter-websocket.service';

@Injectable({providedIn: 'root'})
export class EquipmentWidgetStatusService {
  equipmentStatusLastUpdateTime: string;

  constructor(private myFilterWebsocketService: MyFilterWebsocketService) {
  }

  public canChangeState(): boolean {
    return !this.myFilterWebsocketService.isEquipmentStatusWidgetLight;
  }

  setEquipmentStatusLastUpdateTime(equipmentStatusForCustomer: EquipmentStatusForCustomer[]) {
    if (!this.myFilterWebsocketService.isEquipmentStatusWidgetLight) {
      const myFilteredEquipmentStatus = this.filterEquipmentStatusByMyFilter(equipmentStatusForCustomer);

      if (myFilteredEquipmentStatus && myFilteredEquipmentStatus.length > 0) {
        const filteredArray = myFilteredEquipmentStatus
          .filter(equipmentStatus => equipmentStatus.lastUpdate !== null);

        this.equipmentStatusLastUpdateTime = filteredArray.length > 0 ?
          filteredArray.reduce((a, b) => new Date(a.lastUpdate) < new Date(b.lastUpdate) ? a : b).lastUpdate : undefined;
      }
    }
  }

  filterEquipmentStatusByMyFilter(equipmentStatusForCustomer: EquipmentStatusForCustomer[]) {
    return equipmentStatusForCustomer?.filter(equipment =>
      this.myFilterWebsocketService.myShowingEquipmentCustomersList$.value.find(customer =>
        customer.customerId === equipment.customerId));
  }
}
