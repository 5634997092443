import { Pipe, PipeTransform } from '@angular/core';
import { ColorStatusBinding } from '../../../core/models/color-status-map';
import { Activities } from '../../../core/models/activities/activities';
import { ActivitiesStatusDescriptionTranslationService } from '../../../core/services/activities/activities-status-description-translation-service';

const green = 'status-green';
const yellow = 'status-yellow';
const red = 'status-red';
const cyan = 'status-cyan';
const gray = 'status-gray';
const light_gray = 'status-black-7';

@Pipe({
  name: 'getActivityStatusColor'
})
export class GetActivityStatusColorPipe implements PipeTransform {

  activityStatusColorD35 = {
    0: yellow,
    1: yellow,
    2: green,
    7: red,
    8: green
  };

  activityStatusColorP40 = {
    0: green,
    1: yellow,
    2: red,
    3: light_gray,
    4: light_gray
  };

  activityStatusColor = {
    0: yellow,
    1: green,
    2: yellow,
    3: red,
    4: yellow
  };

  appointmentsStatusColorP58 = {
    1: yellow,
    2: green,
    3: red,
    4: gray,
    5: cyan
  };

  constructor(private descriptionTranslationService: ActivitiesStatusDescriptionTranslationService) {
  }

  /**
   *
   * @param item
   *
   * @description
   * Generate the label and status color map based on pmStatus or activity status.
   * When activity is returned from the webService (in case of closed activities)
   *      - activityStatus is filled with the value from WS
   *      - pmStatus is set to -1 (default).
   * When activity is retrieved from the database
   *      - activityStatus is empty (i.e. '')
   *      - pmStatus is set from the DB.
   */

  transform(item: Activities): any {
    const statusMap: { [k: string]: any } = {};
    // if item is defined
    if (item) {
      statusMap.label = this.descriptionTranslationService.getActivityStatusDescriptionTranslationKey(item);

      if (item.sapSystem === 'P58' && item.colorStatus) {
        statusMap.colorClass = this.appointmentsStatusColorP58[item.colorStatus];
      } else if (!item.pmStatus || item.pmStatus === '-1') {
        statusMap.colorClass = gray;
      } else if (item.sapSystem === 'P40') {
        statusMap.colorClass = this.activityStatusColorP40[item.pmStatus];
      } else {
        statusMap.colorClass = item.sapSystem === 'D35' ? this.activityStatusColorD35[item.pmStatus]
          : this.activityStatusColor[item.pmStatus];
      }
    }
    return statusMap as ColorStatusBinding;
  }
}
