import { Pipe, PipeTransform } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'attachmentName'
})
export class AttachmentNamePipe implements PipeTransform {

  constructor(private configService: CountryConfigRestService) {
  }

  transform(attachmentName: string): Observable<string> {
    return this.configService.getConfig().pipe(
      map(configResponse => {
        const prefixRemoveRegex: string = configResponse.TICKET_ATTACHMENT_PREFIX_REMOVE_REGEX;
        return !!prefixRemoveRegex ? attachmentName.replace(new RegExp(prefixRemoveRegex), '') : attachmentName;
      })
    );
  }
}
