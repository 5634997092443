import { Directive, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';
import { TEST_BOT } from '../../../core/core-constants.service';

@Directive({
  selector: '[hlCookieBanner]'
})
export class CookieBannerDirective implements OnInit, OnDestroy {

  script: any;

  constructor(
    private environmentConfigRestService: EnvironmentConfigRestService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    if (navigator.userAgent.includes(TEST_BOT)) {
      return;
    }
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.addCookieBanner(configResponse.FEATURE_TOGGLE_COOKIE_BANNER, configResponse.COOKIE_BANNER_CONFIGURATION_IDENTIFIER);
    });
  }

  ngOnDestroy() {
    this.renderer2.removeChild(this.document.body, this.script);
  }

  private addCookieBanner(toggle: string, environmentIdentifier: string) {
    if (toggle === 'true' && environmentIdentifier && environmentIdentifier.length > 0) {
      this.script = this.renderer2.createElement('script');
      this.script.type = `text/javascript`;
      this.script.text = this.getCookieBannerScript(environmentIdentifier);
      this.renderer2.appendChild(this.document.body, this.script);
    }
  }

  private getCookieBannerScript(cookieEnvironmentIdentifier: string) {
    let SCRIPT = '(function() {var cookieBanner = document.createElement(\'script\'); ';
    SCRIPT += 'cookieBanner.id = \'Cookiebot\';';
    SCRIPT += 'cookieBanner.src = \'https://consent.cookiebot.com/uc.js?cbid=' + cookieEnvironmentIdentifier + '\';';
    SCRIPT += 'cookieBanner.type = \'text/javascript\';';
    SCRIPT += 'cookieBanner.async = true; ';
    SCRIPT += 'var s = document.getElementsByTagName(\'script\')[0]; ';
    SCRIPT += 's.parentNode.insertBefore(cookieBanner, s); ';
    SCRIPT += '})();';
    return SCRIPT;
  }
}
