<div hlTextfieldAnimator
  class="textfield textfield--large date-time-picker textfield--with-prefix"
  [class.is-active]="timeModel !== undefined"
  [class.is-disabled]="disabled"
  data-mode="time">
  <i class="textfield__prefix icon-time" aria-hidden="true"></i>

  <input id="{{timeId}}"
    mwlFlatpickr
    type="text"
    class="textfield__input date-time-picker__input"
    [(ngModel)]="timeModel"
    (input)="onTimePickerSelect($event.target.value)"
    (ngModelChange)="onTimePickerSelect($event)"
    [noCalendar]="true"
    [enableTime]="true"
    [time24hr]="true"
    [disabled]="disabled"
    [formatDate]="formatDate"
    (flatpickrValueUpdate)="onTimePickerSelect($event.selectedDates[0])"
    [appendTo]="appendToElement"
    [allowInput]="false"
    >
    <label for="{{timeId}}" class="textfield__label date-time-picker__label">
      <span>{{labelText | translate}}</span>
      @if (isRequired) {
        <span>*</span>
      }
    </label>
  </div>
