import { Injectable } from '@angular/core';
import { NotifStatus } from '../../core-constants.service';

@Injectable({providedIn: 'root'})
export class TicketsCacheService {

  private openClosedStatus: NotifStatus = NotifStatus.OPEN;
  private prefilledFields: object;

  setSelectedOpenClosedStatus(openClosedStatus: NotifStatus) {
    this.openClosedStatus = openClosedStatus;
  }

  getSelectedOpenClosedStatus(): NotifStatus {
    return this.openClosedStatus;
  }

  setPrefilledFields(fields: {
    details: { problemSeverityID: string, longText: string, dangerForPatients: string, description: string }
  }) {
    this.prefilledFields = fields;
  }

  getPrefilledFields(): object {
    return this.prefilledFields;
  }

  resetPrefilledFields(): void {
    this.prefilledFields = undefined;
  }
}
