import { Pipe, PipeTransform } from '@angular/core';
import { filter, forEach } from 'lodash-es';

@Pipe({
  name: 'myEquipment'
})
export class MyEquipmentPipe implements PipeTransform {

  transform(dataset: any, checkForMyEquipment?: any): any {
    // check if there is at least one item in list, status is already merged.
    if (dataset.length > 0 && checkForMyEquipment.isMyEquipmentChecked) {
      return this.getMySiemensEquipment(dataset, checkForMyEquipment);
    } else {
      return dataset;
    }
  }

  getMySiemensEquipment(dataset, checkForMyEquipment) {
    const filtered = [];
    forEach(checkForMyEquipment.myEquipmentList, value => {
      const findObj = {};
      findObj[checkForMyEquipment.keyName] = value;

      // Here we need to use filter and not find because there can be more than one hit
      const filteredArr = filter(dataset, findObj);
      Array.prototype.push.apply(filtered, filteredArr);
    });
    return filtered;
  }
}
