@for (option of options; track option) {
  @if (!option[hiddenProperty] && !option.hidden) {
    <li class="menu__item multi-selectbox__option display-flex flex-row nested-selectbox__option" [ngClass]="{
    'nested-selectbox__option-selected': isSelected(option),
    'nested-selectbox__option-no-padding': isSelectableExpandable(option),
    'nested-selectbox__option-only-expandable': isOnlyExpandable(option)
    }" (click)="onOptionClick($event, option)" attr.data-cy="nested-selectbox_option_{{option.title}}">
      <span [class.nested-selectbox__option__selectable-area]="isSelectableExpandable(option)" (click)="onSelect($event, option)"
        [ngClass]="{'nested-selectbox__option__italic-font-text': option.cursive!}">{{ option.title | translate }}</span>
      @if (isExpandable(option)) {
        <i class="icon-arrow-down-large vertical-align-before-middle text-align-right nested-selectbox__option__arrow"
          [class.icon-arrow-up-large]="option.isExpanded" [class.color-orange]="option.isExpanded"
          [class.nested-selectbox__option__expandable-area]="isSelectableExpandable(option)" (click)="toggleExpand($event, option)"></i>
      }
    </li>
  }
  @if (showChildren(option)) {
    <div class="nested-selectbox__children-margin">
      <hl-nested-selectbox-tree [options]="option.extendedChildren" [selectedOption]="selectedOption"
        (superSelectedEvent)="onOptionClick(null, $event)" [enableCollapsing]="enableCollapsing" [scrollOnExpand]="scrollOnExpand"
        data-cy="spr-widget-nested-selectbox-tree" (superScrollEvent)="superScrollEvent.emit($event)"></hl-nested-selectbox-tree>
    </div>
  }
}

