<div class="selectbox" (click)="dropdownToggle()" [class.is-disabled]="isDisabled" [class.is-open]="isDropdownOpen" [class.has-focus]="isDropdownOpen"
  [class.is-active]="isActive" hlTooltip [isActive]="tooltipSelected && !isDropdownOpen" [title]="tooltipSelected" [documentOverlay]=documentOverlay>
  <select id="selectbox" class="selectbox__select" [(ngModel)]="value" (ngModelChange)="valueChange" style="pointer-events: none">
    <option class="selectbox__select-option"></option>
    @if (translatedOptions$ | async; as translatedOptions) {
      @for (option of translatedOptions; track option) {
        <option class="selectbox__select-option" [ngValue]="option.value">{{ (option.title) | replaceBreakLinesWithComma }}</option>
      }
    }
  </select>
  <label class="selectbox__label" for="selectbox">
    <span>{{ label | translate }}</span>
    @if (isRequired) {
      <span>*</span>
    }
  </label>
  @if (invalidLabel && isInvalid) {
    <small class="selectbox__hint selectbox__hint--validation" [translate]="invalidLabel"></small>
  }
  @if (!isDisabled) {
    <ul class="menu dropdown__panel selectbox__option-list col-12">
      @if (translatedOptions$ | async; as translatedOptions) {
        @for (option of translatedOptions; track option) {
          <li class="menu__item selectbox__option" (click)="setSelected(option)" [innerHTML]="option.title" [attr.data-cy]="option.title"
            [documentOverlay]="documentOverlay" [isActive]="tooltip" [title]="option.title" hlTooltip></li>
        }
      }
    </ul>
  }
  <ng-content></ng-content>
</div>
