import { TicketTitle } from '../models/tickets/ticket-title';
import { TicketSalutation } from '../models/tickets/ticket-salutation';
import { HttpParams } from '@angular/common/http';
import { TicketProblemSeverity } from '../models/tickets/ticket-problem-severity';
import { NotifStatus } from '../core-constants.service';
import { problemSeveritiesRestName, ticketCloseRestName, ticketCreateRestName, ticketPriorityRefreshRestName, ticketRefreshRestName, ticketsAllRestName, ticketSalutationsRestName, ticketServiceReportsRestName, ticketsHistoryRestName, ticketsIndividualRestName, ticketSparePartsRestName, ticketStatusMessageType, ticketSurveyRestName, ticketTitlesRestName, ticketTypedModalities, ticketUpdateRestName } from './ticket-rest-constants.service';
import { equipmentStatusRestName } from './equipment-rest-constants.service';
import { CacheableHttpClient, GetRequestOptions } from '../services/cache/cacheable-http-client';
import { Ticket } from '../models/tickets/ticket';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ModalityTypeTicketTypeRel } from '../models/tickets/modality-type-ticket-type-rel';
import { TimezoneApiModel } from '../models/tickets/timezone-api-model';
import { ClearCacheUtilService } from '../utils/clear-cache-util.service';
import { SurveyTicketLink } from '../models/tickets/survey-ticket-link';
import { CommunicationClient } from '../services/communication/communication-client';
import { take } from 'rxjs/operators';
import { CustomerIds } from '../models/customer/customer-ids';
import { TicketStatusForCustomer } from '../models/tickets/ticket-status';

const timezoneAPI = 'https://timezoneapi.io/api/address/?';

@Injectable({providedIn: 'root'})
export class TicketsRestService {
  lastSelectedEquipmentKey: string;

  constructor(private http: CacheableHttpClient,
    private client: CommunicationClient,
    private clearCacheUtilService: ClearCacheUtilService) {
  }

  /**
   * @description get list of tickets from BE (/rest/v1/tickets?statusFilter=1)
   */
  getTickets(notifStatus: NotifStatus, refreshData = false): Observable<Ticket[]> {
    const httpParams = new HttpParams().set('statusFilter', notifStatus);

    if (refreshData) {
      this.clearCache(ticketsAllRestName, {params: httpParams});
    }

    return this.http.get<Ticket[]>(ticketsAllRestName, {params: httpParams});
  }

  /**
   * @description get a single ticket from BE
   */
  getTicket(ticketKey: string, customerId: string, equipmentKey: string): Observable<Ticket> {
    const url = ticketsIndividualRestName.replace(/:id/, ticketKey);

    const httpParams = new HttpParams()
      .set('customerId', customerId)
      .set('equipmentKey', equipmentKey);
    return this.http.get<Ticket>(url, {params: httpParams});
  }

  /**
   * @description get ticket history for an equipment
   */
  getTicketHistory(equipmentKey: string, dateStartFilter: string, dateEndFilter: string, refreshDataFromDB = false): Observable<Ticket[]> {

    let httpParams = new HttpParams()
      .set('equipmentKey', equipmentKey)
      .set('statusFilter', '3');

    if (dateStartFilter) {
      httpParams = httpParams.set('dateStartFilter', dateStartFilter);
    }

    if (dateEndFilter) {
      httpParams = httpParams.set('dateEndFilter', dateEndFilter);
    }

    if (this.lastSelectedEquipmentKey && this.lastSelectedEquipmentKey !== equipmentKey || refreshDataFromDB) {
      this.clearCache(ticketsHistoryRestName);
    }
    this.lastSelectedEquipmentKey = equipmentKey;

    return this.http.get<Ticket[]>(ticketsHistoryRestName, {params: httpParams});
  }

  getTicketsStatusFromWebsocketMessages(topics$: Observable<string[]>): Observable<TicketStatusForCustomer> {
    return this.client.get<TicketStatusForCustomer>({
      url: '',
      messageType: ticketStatusMessageType,
      topics$,
      merger(oldValue: any, newValue: any): any {
        return newValue;
      }
    }, true);
  }

  /**
   * @description get severities for tickets
   */
  getProblemSeverities(): Observable<TicketProblemSeverity[]> {
    return this.http.get<TicketProblemSeverity[]>(problemSeveritiesRestName);
  }

  /**
   * @description get salutation for create ticket screen and update ticket
   * Note:- It is returned by calling /rest/v1/tickets/salutations and hence it is bound to tickets and
   * not users.
   */
  getContactsSalutation(): Observable<TicketSalutation[]> {
    return this.http.get<TicketSalutation[]>(ticketSalutationsRestName);
  }

  /**
   * @description get titles for create ticket screen and update ticket
   * Note:- It is returned by calling /rest/v1/tickets/titles and hence it is bound to tickets and
   * not users.
   */
  getContactsTitle(): Observable<TicketTitle[]> {
    return this.http.get<TicketTitle[]>(ticketTitlesRestName);
  }

  postCreateTicket(body: object): Observable<Object> {
    // clear tickets cache -> statusFilter=1
    this.clearCache(ticketsAllRestName);

    // clear equipment status cache -> equipments/status
    this.clearCacheUtilService.clear(equipmentStatusRestName);

    return this.http.post(ticketCreateRestName, body);
  }

  refreshTickets(customerIds: CustomerIds) {
    this.http.post(ticketRefreshRestName, customerIds, {
      responseType: 'text'
    }).pipe(take(1)).subscribe(() => {
      // do nothing.
    });
  }

  priorityRefreshTickets(customerIds: CustomerIds) {
    this.http.post(ticketPriorityRefreshRestName, customerIds, {
      responseType: 'text'
    }).pipe(take(1)).subscribe(() => {
      // do nothing.
    });
  }

  updateTicket(body, cacheUrl) {

    // clear single ticket individual request
    this.clearCache(cacheUrl);
    return this.http.put(ticketUpdateRestName, body);
  }

  closeTicket(body, cacheUrl) {

    // clear single ticket individual request
    this.clearCache(cacheUrl);
    return this.http.put(ticketCloseRestName, body);
  }

  clearCache(restName: string, options?: GetRequestOptions) {
    this.http.clearCache(restName, options);
  }

  getTicketTypesAndSeverityOptions(option: string): Observable<ModalityTypeTicketTypeRel[]> {
    return this.http.get<ModalityTypeTicketTypeRel[]>(ticketTypedModalities, {params: new HttpParams().set('mode', option)});
  }

  getTimezone(city: string, state: string): Observable<TimezoneApiModel> {
    const url = timezoneAPI + city + '+' + state;
    return this.http.get<TimezoneApiModel>(url);
  }

  getServiceReportsForTicket(ticketNumber: string): Observable<any[]> {
    return this.http.get<any[]>(ticketServiceReportsRestName.replace(/:id/, ticketNumber));
  }

  getTicketSurveyLink(ticketNumber: string): Observable<SurveyTicketLink> {
    const params = new HttpParams().set('ticketNumber', ticketNumber);
    return this.http.get<SurveyTicketLink>(ticketSurveyRestName, {params});
  }

  getTicketSparePartMaterialNumbers(equipmentKey: string, ticketNumber: string): Observable<string[]> {
    const params = new HttpParams().appendAll({ equipmentKey, ticketNumber});
    return this.http.get<string[]>(ticketSparePartsRestName, {params});
  }
}
