@if (fixedStatus) {
  <div id="toast-alert" class="alert alert--{{type}} toast-message alert__border alert__border--{{type}}" [ngClass]="{'fixed': fixedStatus}"
    data-cy="toast">
    <button type="button" class="alert__close margin-bottom-0" (click)="fixedStatus=!fixedStatus" data-cy="alert-close-button">
      <i aria-hidden="true" class="icon-close"></i>
    </button>
    <div class="align-items-start display-flex">
      <div class="alert__indicator-container">
        <i class="icon alert__indicator"></i>
      </div>
      <div class="alert__body">
        <p class="alert__text" [innerHTML]="isBodyTranslationKey ? (body | translate) : body"></p>
      </div>
    </div>
  </div>
}
