import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { MyFiltersAdapterService } from '../services/my-filters-adapter.service';

@Injectable({providedIn: 'root'})
export class ClearCacheUtilService {

  constructor(
    private myFiltersService: MyFiltersAdapterService,
    private http: CacheableHttpClient
  ) {
  }

  clear(url?: string) {
    this.http.clearCache(url);
    this.myFiltersService.initialize();
  }
}
