import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, SlicePipe } from '@angular/common';
import { RemoveLeadingZeroPipe } from '../shared/pipes/removeLeadingZero/remove-leading-zero.pipe';
import { RemoveCountryPrefixPipe } from '../shared/pipes/remove-country-prefix/remove-country-prefix.pipe';
import { ClinicalFieldsPipe } from '../shared/pipes/clinical-fields/clinical-fields.pipe';
import { DiagramCalculateHeightPipe } from '../shared/pipes/diagram-calculate-height/diagram-calculate-height.pipe';
import { DateRangePipe } from '../shared/pipes/date-range/date-range.pipe';
import { TruncatePipe } from '../shared/pipes/truncate/truncate.pipe';
import { MultiSelectPipe } from '../shared/pipes/multi-select/multi-select.pipe';
import { ActivitiesStatusPipe } from '../shared/pipes/activities-status/activities-status.pipe';
import { ActivityAppointmentsStatusPipe } from '../shared/pipes/activities-status/activity-appointments-status.pipe';
import { DangerForPatientPipe } from '../shared/pipes/danger-for-patient/danger-for-patient.pipe';
import { GetItemAvailabilityOptionsPipe } from '../shared/pipes/get-item-availability-options/get-item-availability-options.pipe';
import { DateRangeArrayPipe } from '../shared/pipes/date-range-array/date-range-array.pipe';
import { HighlightValidationPipe } from '../shared/pipes/highlight-validation/highlight-validation.pipe';
import { FilterHiddenPipe } from '../shared/pipes/filter-hidden/filter-hidden.pipe';
import { GetContractStatusColorPipe } from '../shared/pipes/get-contract-status-color/get-contract-status-color.pipe';
import { ActivitiesOpenClosedPipe } from '../shared/pipes/activities-open-closed/activities-open-closed.pipe';
import { SearchPipe } from '../shared/pipes/search/search.pipe';
import { GroupTypePipe } from '../shared/pipes/group-type/group-type.pipe';
import { GetActivityStatusColorPipe } from '../shared/pipes/get-activity-status-color/get-activity-status-color.pipe';
import { IndeterminateCheckboxPipe } from '../shared/pipes/indeterminate-checkbox/indeterminate-checkbox.pipe';
import { MyEquipmentPipe } from '../shared/pipes/my-equipment/my-equipment.pipe';
import { ContractAvailabilityPipe } from '../shared/pipes/contract-availability/contract-availability.pipe';
import { AddPercentPipe } from '../shared/pipes/add-percent/add-percent.pipe';
import { ActivityDateDiffPipe } from '../shared/pipes/activity-date-diff/activity-date-diff.pipe';
import { OperationalStatusPipe } from '../shared/pipes/operational-status/operational-status.pipe';
import { SafeDomPipe } from '../shared/pipes/safe-dom/safe-dom.pipe';
import { ToHtmlPipe } from '../shared/pipes/to-html/to-html.pipe';
import { FileSizeFormatPipe } from '../shared/pipes/file-size-format/file-size-format.pipe';
import { ReplaceBreakLinesWithCommaPipe } from '../shared/pipes/replace-break-lines-with-comma/replace-break-lines-with-comma.pipe';
import { PsrRequestStatusPipe } from '../shared/pipes/psr-request-status/psr-request-status.pipe';
import { EquipmentStatusPipe } from '../shared/pipes/equipment-status/equipment-status.pipe';
import { DatePipeWrapperPipe } from '../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { ReplaceUnderscoresWithSpacePipe } from '../shared/pipes/replace-underscores-with-space/replace-underscores-with-space.pipe';
import { HighlightNameValidationPipe } from '../shared/pipes/highlight-validation/highlight-name-validation.pipe';
import { OrderByPipe } from '../shared/pipes/order-by/order-by.pipe';
import { GetTicketProblemSeverityColorPipe } from '../shared/pipes/get-ticket-problem-severity-color/get-ticket-problem-severity-color.pipe';
import { TicketStatusPipe } from '../shared/pipes/ticket-status/ticket-status.pipe';
import { AttachmentNamePipe } from '../shared/pipes/attachment-name/attachment-name-pipe';
import { TicketListItemTemplateMapPipe } from '../shared/pipes/ticket-list-item-template-map/ticket-list-item-template-map.pipe';
import { LinkifyPipe } from '../shared/pipes/linkify/linkify.pipe';
import { RemovePrefixPipe } from 'app/shared/pipes/remove-prefix/remove-prefix.pipe';
import { PluralPipe } from '../shared/pipes/plural/plural.pipe';
import { LabelColorStatusPipe } from '../shared/pipes/label-color-status/label-color.pipe';
import { PreliminaryInvoiceStatusColorPipe } from '../shared/pipes/preliminary-invoice-status-color/preliminary-invoice-status-color.pipe';
import { PreliminaryInvoiceStatusLabelPipe } from '../shared/pipes/preliminary-invoice-status-label/preliminary-invoice-status-label.pipe';
import { WescanStatusColorPipe } from '../shared/pipes/wescan-status-color/wescan-status-color.pipe';
import { NumberFromIdPipe } from 'app/shared/pipes/number-from-id/number-from-id.pipe';

@NgModule({
  declarations: [
    AttachmentNamePipe,
    SafeDomPipe,
    MultiSelectPipe,
    SearchPipe,
    OperationalStatusPipe,
    MyEquipmentPipe,
    OrderByPipe,
    DateRangePipe,
    DateRangeArrayPipe,
    RemoveLeadingZeroPipe,
    DangerForPatientPipe,
    TicketStatusPipe,
    FileSizeFormatPipe,
    ActivitiesStatusPipe,
    ActivityAppointmentsStatusPipe,
    ActivitiesOpenClosedPipe,
    ActivityDateDiffPipe,
    ClinicalFieldsPipe,
    GroupTypePipe,
    ContractAvailabilityPipe,
    PsrRequestStatusPipe,
    AddPercentPipe,
    DatePipeWrapperPipe,
    EquipmentStatusPipe,
    TicketListItemTemplateMapPipe,
    TruncatePipe,
    GetActivityStatusColorPipe,
    GetContractStatusColorPipe,
    GetTicketProblemSeverityColorPipe,
    GetItemAvailabilityOptionsPipe,
    ReplaceBreakLinesWithCommaPipe,
    ReplaceUnderscoresWithSpacePipe,
    ToHtmlPipe,
    LinkifyPipe,
    IndeterminateCheckboxPipe,
    RemoveCountryPrefixPipe,
    RemovePrefixPipe,
    HighlightValidationPipe,
    HighlightNameValidationPipe,
    DiagramCalculateHeightPipe,
    FilterHiddenPipe,
    PluralPipe,
    PreliminaryInvoiceStatusColorPipe,
    PreliminaryInvoiceStatusLabelPipe,
    LabelColorStatusPipe,
    WescanStatusColorPipe
  ],
  providers: [
    AttachmentNamePipe,
    SlicePipe,
    DatePipe,
    SafeDomPipe,
    MultiSelectPipe,
    SearchPipe,
    OperationalStatusPipe,
    MyEquipmentPipe,
    OrderByPipe,
    DateRangePipe,
    DateRangeArrayPipe,
    DatePipeWrapperPipe,
    EquipmentStatusPipe,
    TicketListItemTemplateMapPipe,
    RemoveLeadingZeroPipe,
    DangerForPatientPipe,
    TicketStatusPipe,
    FileSizeFormatPipe,
    ActivitiesStatusPipe,
    ActivityAppointmentsStatusPipe,
    ActivitiesOpenClosedPipe,
    ActivityDateDiffPipe,
    ClinicalFieldsPipe,
    GroupTypePipe,
    ContractAvailabilityPipe,
    PsrRequestStatusPipe,
    AddPercentPipe,
    GetActivityStatusColorPipe,
    GetContractStatusColorPipe,
    GetTicketProblemSeverityColorPipe,
    ReplaceBreakLinesWithCommaPipe,
    ReplaceUnderscoresWithSpacePipe,
    IndeterminateCheckboxPipe,
    RemoveCountryPrefixPipe,
    RemovePrefixPipe,
    HighlightValidationPipe,
    HighlightNameValidationPipe,
    DiagramCalculateHeightPipe,
    DateRangeArrayPipe,
    NumberFromIdPipe
  ],
  exports: [
    AttachmentNamePipe,
    SlicePipe,
    DatePipe,
    SafeDomPipe,
    MultiSelectPipe,
    SearchPipe,
    OperationalStatusPipe,
    MyEquipmentPipe,
    OrderByPipe,
    DateRangePipe,
    DateRangeArrayPipe,
    RemoveLeadingZeroPipe,
    DangerForPatientPipe,
    TicketStatusPipe,
    FileSizeFormatPipe,
    ActivitiesStatusPipe,
    ActivityAppointmentsStatusPipe,
    ActivitiesOpenClosedPipe,
    ActivityDateDiffPipe,
    ClinicalFieldsPipe,
    GroupTypePipe,
    ContractAvailabilityPipe,
    PsrRequestStatusPipe,
    AddPercentPipe,
    DatePipeWrapperPipe,
    EquipmentStatusPipe,
    TicketListItemTemplateMapPipe,
    TruncatePipe,
    GetActivityStatusColorPipe,
    GetContractStatusColorPipe,
    GetTicketProblemSeverityColorPipe,
    GetItemAvailabilityOptionsPipe,
    ToHtmlPipe,
    LinkifyPipe,
    ReplaceUnderscoresWithSpacePipe,
    IndeterminateCheckboxPipe,
    RemoveCountryPrefixPipe,
    RemovePrefixPipe,
    HighlightValidationPipe,
    HighlightNameValidationPipe,
    DiagramCalculateHeightPipe,
    ReplaceBreakLinesWithCommaPipe,
    FilterHiddenPipe,
    PluralPipe,
    PreliminaryInvoiceStatusColorPipe,
    PreliminaryInvoiceStatusLabelPipe,
    LabelColorStatusPipe,
    WescanStatusColorPipe,
    NumberFromIdPipe
  ],
  imports: [
    CommonModule,
    NumberFromIdPipe
  ]
})
export class PipesModule {
}
