import { Pipe, PipeTransform } from '@angular/core';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { filter, flatten } from 'lodash-es';

@Pipe({
  name: 'activities-open-closed'
})
export class ActivitiesOpenClosedPipe implements PipeTransform {

  transform(dataset: ActivitiesViewModel[], activityStatus: any): any {
    return activityStatus && activityStatus.length > 0
      ? this.filterResult(dataset, flatten(activityStatus))
      : dataset;
  }

  filterResult(dataset: ActivitiesViewModel[], activityStatus: string[]): ActivitiesViewModel[] {
    return filter(dataset, activity => activityStatus.indexOf(activity.activityStatus) !== -1);
  }

}
