import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownOptions, NestedDropdownOption } from '../../../../core/models/dropdown-options';
import { cloneDeep } from 'lodash-es';

const SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectDropdownMenuComponent),
  multi: true
};

@Component({
  selector: 'hl-select-dropdown-menu',
  templateUrl: './select-dropdown-menu.component.html',
  providers: [SELECT_VALUE_ACCESSOR]
})
export class SelectDropdownMenuComponent implements OnChanges, ControlValueAccessor {

  @Input() isDropdownOpen = false;
  isActive = false;
  @Input() translatedOptions$: Observable<DropdownOptions[]>;

  @Input() options: DropdownOptions[]; // dropdown list options
  @Input() translateKeys?: boolean; // translate keys
  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() isInvalid: boolean;
  @Input() label: string; // translation key
  @Input() invalidLabel: string;
  @Input() setPositionAbsolute: boolean;
  @Output() isDropdownOpened = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<string>(true);
  @ViewChild('selectBox') selectBox: NestedDropdownOption;

  constructor(
    private translate: TranslateService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.el = el;
  }

  _value = '';

  get value() {
    return this._value;
  }

  @Input() set value(val: string) {
    if (val === this._value) {
      return;
    }
    if (val === '' && this.isRequired && this.options && this.options.length === 1) {
      this._value = this.options[0].value;
    } else {
      this._value = val;
    }
    this.isActive = !!this._value;
  }

  ngOnChanges(changes: SimpleChanges) {

    const options = cloneDeep(this.options || []);
    if (changes.options && this.isRequired && options && options.length === 1) {
      this.setSelected(options[0]);
    }

    this.translatedOptions$ = this.translateKeys && options.length
      ? this.translate.get(options.map(o => o.title)).pipe(
        map(resp => options.map(o => ({
          ...o, title: resp[o.title] || o.title
        })))
      )
      : of(options);

    if (this.setPositionAbsolute) {
      this.calculatePosition();
    }
  }

  calculatePosition() {
    const dropdownEl = this.el.nativeElement;
    const displacementEl = document.querySelector('div#scroledDiv');
    if (this.isDropdownOpen) {
      this.renderer.setStyle(dropdownEl, 'top', (dropdownEl.offsetTop - displacementEl.scrollTop) + 'px');
    } else {
      this.renderer.removeStyle(dropdownEl, 'top');
    }
  }

  setSelected(option) {
    if (!option.disabled) {
      this.value = option.value;
      this.valueChange.emit(this.value);
      this.onModelChange(this.value);
      this.onModelTouched();
    }
  }

  onModelChange: Function = (_: any) => {
    // This is intentional
  }
  onModelTouched: Function = () => {
    // This is intentional
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
