import { Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { FavoriteRestService } from '../../../core/rest-services/favorite-rest.service';
import { AddRemoveFavoriteEventService } from '../../../core/component-communication-services/add-remove-favorite-event/add-remove-favorite-event.service';
import { startWith, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hl-filter-tabs',
  templateUrl: './filter-tabs.component.html'
})
export class FilterTabsComponent implements OnInit {

  @Input() isMyInstitutions: false;
  @Input() listType: string;

  private readonly destroyRef$ = inject(DestroyRef);
  private readonly favoriteRestService = inject(FavoriteRestService);
  private readonly addRemoveFavoriteEventService = inject(AddRemoveFavoriteEventService);

  numberOfFavorites = signal<number>(0);

  FILTER_OPTION_FILTER = 'Filter';
  FILTER_OPTION_SAVED_FILTERS = 'SavedFilters';
  selectedFilterOption = this.FILTER_OPTION_FILTER;

  ngOnInit() {
    this.addRemoveFavoriteEventService.addRemoveFavoriteSource$.pipe(
      startWith(null),
      switchMap(() => this.favoriteRestService.getNumberOfFavorites(this.listType)),
      takeUntilDestroyed(this.destroyRef$)
      ).subscribe(numberOfFavorites => this.numberOfFavorites.set(numberOfFavorites));
  }
}
