import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { UserRestService } from '../../rest-services/user-rest.service';
import { MyFiltersAdapterService } from '../my-filters-adapter.service';
import { Customer } from '../../models/customer/customer';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class MyFilterWebsocketService {
  myShowingEquipmentCustomersList$ = new BehaviorSubject<Customer[]>([]);

  isEquipmentStatusWidgetLight = false;
  lightUpEquipmentStatusWidget$ = new Subject<boolean>();
  impersonationChangedAfterInitialisation = false;

  constructor(private userRestService: UserRestService, private myFilterAdapterService: MyFiltersAdapterService) {
    this.registerListenerForEquipmentCustomerIdList();
  }

  registerListenerForEquipmentCustomerIdList() {
    this.myFilterAdapterService.status$.pipe(
      switchMap(myFilterStatus => {
        return this.userRestService.getAssignedCustomers().pipe(
          map(customers =>
            customers.filter(customer => myFilterStatus.equipment.find(equipment => {
              return equipment.customerId === customer.customerId
            })).map(customer => {
              return {
                customerId: customer.customerId
              };
            })));
      })).subscribe(customerOfShownEquipments => {
      this.myShowingEquipmentCustomersList$.next(customerOfShownEquipments);

      this.evaluateEquipmentStatusGreyOut();
    });
  }

  private evaluateEquipmentStatusGreyOut() {
    if (this.impersonationChangedAfterInitialisation) {
      this.isEquipmentStatusWidgetLight = true;
    }

    this.impersonationChangedAfterInitialisation = true;
  }
}
