import { Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment.prod';
import { EquipmentStatus } from '../../../core/models/equipment/equipment-status';
import { StatusCountViewModel } from '../../../core/models/status-count-view-model';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { LogService } from '../../../core/services/log/log.service';
import { WindowService } from '../../../core/window.service';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { forEach, isEqual } from 'lodash-es';

@Directive({
  selector: '[hlGoogleMaps]'
})
export class GoogleMapsDirective implements OnInit, OnChanges, OnDestroy {

  @Input() mapDataLoaded: boolean;
  @Input() equipmentStatusData: EquipmentStatus[];
  @Input() type: string;

  locationNamingToggle = false;
  google: any;
  map: any;
  bounds: any;
  markers: any[];
  infoWindow = null;
  listItems: StatusCountViewModel[];
  title: string;
  customerNumbers: string[];
  googleMapsIcon = {
    green: '../' + environment.version + 'assets/images/map_marker_green.png',
    red: '../' + environment.version + 'assets/images/map_marker_red.png',
    yellow: '../' + environment.version + 'assets/images/map_marker_yellow.png',
    gray: '../' + environment.version + 'assets/images/map_marker_grey.png',
    empty: '../' + environment.version + 'assets/images/map_marker_empty.png'
  };
  mapOptions: {
    gestureHandling: string;
  };
  MAX_WIDTH_FOR_COOPERATIVE_MAP = 1200;

  // Observable whether the google maps are loaded sources
  private googleMapsLoadedSource = new Subject<void>();
  // Observable  streams
  private googleMapsLoadedSource$ = this.googleMapsLoadedSource.asObservable();

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(private userRestService: UserRestService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private countryConfigService: CountryConfigRestService,
    private windowService: WindowService,
    private logService: LogService,
    private elementRef: ElementRef,
    private equipmentUtilService: EquipmentUtilService,
    private router: Router,
    private translate: TranslateService,
    public ngZone: NgZone) {
  }

  ngOnInit() {
    this.windowService.nativeWindow['mapsNavigationComponentRef'] = {
      component: this,
      zone: this.ngZone
    };
    this.mapOptions = {
      gestureHandling: ''
    };
    this.mapOptions.gestureHandling = this.isSmallDisplaySize() ? 'cooperative' : 'greedy';
    this.countryConfigService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      if (isEqual(configResponse.FEATURE_TOGGLE_LOCATION_NAMING_MAPS, 'true')) {
        this.locationNamingToggle = true;
      }
      this.loadMapApi();
    });
  }

  ngOnChanges(changes: any) {
    if (this.mapDataLoaded && this.equipmentStatusData &&
      this.windowService.nativeWindow.google && this.windowService.nativeWindow.google.maps) {
      this.update();
    }
  }

  ngOnDestroy(): void {
    this.windowService.nativeWindow['mapsNavigationComponentRef'] = null;
    // google object and script tag will be kept - if this a problem do the following
    // this.windowService.nativeWindow.google = null + remove script from DOM

    this.destroy();
  }

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadMapApi() {
    if (this.windowService.nativeWindow.google && this.windowService.nativeWindow.google.maps) {
      this.update();
    } else {
      this.environmentConfigRestService.getEnvironmentConfig().subscribe(configResponse => {
        if (configResponse.GOOGLE_MAPS_API_URL) {
          this.lazyLoadApi(configResponse.GOOGLE_MAPS_API_URL);
          this.onLoadedSource();
        } else {
          this.logService.log('Google Maps API not loaded');
        }
      });
    }
  }

  update() {
    if ((isEqual(this.type, 'widget') && this.mapDataLoaded) ||
      (isEqual(this.type, 'equipment') && this.equipmentStatusData)) {
      this.init();
    }
  }

  lazyLoadApi(googleMapsApiUrl): void {
    const s = document.createElement('script');
    s.src = googleMapsApiUrl;
    document.body.appendChild(s);

    // the "initialize" method is a callback method, called by google-maps when the
    // the map is ready
    this.windowService.nativeWindow.initialize = () => {
      this.googleMapsLoadedSource.next();
    };
  }

  onLoadedSource(): void {
    this.googleMapsLoadedSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.windowService.nativeWindow.google && this.windowService.nativeWindow.google.maps) {
        this.update();
      } else {
        this.logService.log('Google Maps API not loaded');
      }
    });
  }

  init() {
    if (this.mapDataLoaded && this.equipmentStatusData) {

      this.getAssignedCustomers().subscribe(assignedCustomersResponse => {
        this.initMap();
        this.deleteMarkers();
        if (assignedCustomersResponse) {
          if (this.locationNamingToggle) {
            forEach(assignedCustomersResponse, assignedCustomerArray => {
              this.setMarkerToMap(this.map, assignedCustomerArray);
            });
          } else {
            forEach(assignedCustomersResponse, (customerDetailData) => {
              this.setMarkerToMap(this.map, [customerDetailData]);
            });
          }
        }
      });
    }
  }

  private getAssignedCustomers(): Observable<any> {
    if (this.locationNamingToggle) {
      return this.userRestService.getAssignedCustomersByLocation();
    } else {
      return this.userRestService.getAssignedCustomers();
    }
  }

  initMap() {
    this.bounds = new this.windowService.nativeWindow.google.maps.LatLngBounds();
    this.map = new this.windowService.nativeWindow.google.maps.Map(this.elementRef.nativeElement, this.mapOptions);
  }

  deleteMarkers() {
    this.setMapOnAll(null);
    this.markers = [];
  }

  setMarkerToMap(mapObj, customerDetailArray) {
    let marker;
    const customerIds = customerDetailArray.map(customerDetail => customerDetail.customerId);
    const position = new this.windowService.nativeWindow.google.maps.LatLng(customerDetailArray[0].latitude,
      customerDetailArray[0].longitude);
    const title = customerDetailArray[0].customerName;
    const markerOptions = {
      position,
      map: mapObj,
      title,
      icon: undefined
    };

    this.equipmentUtilService.getWorstCustomerEquipmentStatus(customerIds, this.equipmentStatusData)
      .subscribe((statusNumber) => {
        if (statusNumber === 0) {
          markerOptions.icon = this.googleMapsIcon.green;
        } else if (statusNumber === 1) {
          markerOptions.icon = this.googleMapsIcon.red;
        } else if (statusNumber === 2) {
          markerOptions.icon = this.googleMapsIcon.yellow;
        } else if (statusNumber === -1) {
          // ignore when customer has no equipments
          if (this.equipmentStatusData.length > 0) {
            return;
          }
          // use empty marker instead of gray one according requirement
          markerOptions.icon = this.googleMapsIcon.empty;
        }

        // add the marker and store it to the markers array
        marker = new this.windowService.nativeWindow.google.maps.Marker(markerOptions);
        this.markers.push(marker);
        this.bounds.extend(position);

        // Don't zoom in too far on only one marker
        if (this.bounds.getNorthEast().equals(this.bounds.getSouthWest())) {
          const extendPoint1 = new this.windowService.nativeWindow.google.maps.LatLng(
            this.bounds.getNorthEast().lat() + 0.01,
            this.bounds.getNorthEast().lng() + 0.01);

          const extendPoint2 = new this.windowService.nativeWindow.google.maps.LatLng(
            this.bounds.getNorthEast().lat() - 0.01,
            this.bounds.getNorthEast().lng() - 0.01);

          this.bounds.extend(extendPoint1);
          this.bounds.extend(extendPoint2);
        }

        /**
         * Add new bounds object to map.
         * Note: Here after each marker is painted add it to bounds.
         */
        mapObj.fitBounds(this.bounds);

        // add the click event listener
        this.windowService.nativeWindow.google.maps.event.addListener(marker, 'click',
          ((markerParam, customerNumberArrayParam, titleParam) => {

            return () => {

              // get the compiled content for the info window
              this.getInfoWindowContent(customerNumberArrayParam, titleParam).subscribe((contentResponse) => {

                // close already existing/open info windows
                if (this.infoWindow) {
                  this.infoWindow.close();
                }

                // create the new info window
                this.infoWindow = new this.windowService.nativeWindow.google.maps.InfoWindow({
                  content: contentResponse
                });

                // we need to make use of timeout here to
                // prevent a flash of un-styled content on clicking
                // info window
                setTimeout(() => {
                  this.infoWindow.open(mapObj, markerParam);
                }, 100);
              });
            };
          })(marker, customerIds, title));
      });
  }

  getInfoWindowContent(customerNumbers, title: string): Observable<string> {
    return this.equipmentUtilService.getCustomerEquipmentStatusValues(customerNumbers, this.equipmentStatusData, false)
      .pipe(map(response => {
        this.listItems = response;
        this.title = title;
        this.customerNumbers = customerNumbers;
        // define the template
        return this.getContent(this.type);
      }));
  }

  getContent(contentType: string): string {
    let content =
      `<div class="google-maps-popup">
      <p>${this.title}</p>
        <ul>`;

    forEach(this.listItems, (item) => {

      // tslint:disable-next-line:ban
      const translatedTitle = this.translate.instant(item.title);

      let titleElem: string;

      if (isEqual(contentType, 'widget')) {
        titleElem = `
        <a class="link"
          onclick="window.mapsNavigationComponentRef.zone.run(function() {
            window.mapsNavigationComponentRef.component.navigateToEquipment(
              '${item.class}', '${this.customerNumbers}'
            );
          })">
          ${translatedTitle}</a>
        `;
      } else {
        titleElem = translatedTitle;
      }

      const itemTemplate = `
      <li class="${item.class}">
            <strong> ${item.count} ${titleElem} </strong>
      </li>
      `;
      content += itemTemplate;
    });
    content += `</ul> </div>`;

    return content;
  }

  public navigateToEquipment(itemclass: string, customerIdentifier: string) {
    // equipmentIdentifier query param sets the searchInput which searches also
    // on customerNumbers
    const customerNumbers = customerIdentifier.split(',');
    this.router.navigate(['/equipment'],
      {
        queryParams:
          {
            'operationalState': itemclass,
            'equipmentIdentifier': customerNumbers.toString(),
            'searchWithoutQuotes': (customerNumbers.length > 1),
            'showTicketsTab': true
          }
      });
  }

  setMapOnAll(mapObj: any) {
    forEach(this.markers, (value, i) => {
      this.markers[i].setMap(mapObj);
    });
  }

  private isSmallDisplaySize(): boolean {
    return this.checkMobileUser() || (window.innerWidth < this.MAX_WIDTH_FOR_COOPERATIVE_MAP);
  }

  private checkMobileUser(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }
}
