<div class="diagram {{getDiagramAdditionalClass()}}">
  @if (hasValue()) {
    <div class="diagram__canvas {{hideLegends ? 'bg-color-black-7 diagram__canvas--no-legend' : ''}}">
      <div [ngClass]="getDiagramClass()">
        @if (total || (dataInput && dataInput.length)) {
          <ngx-charts-pie-chart [results]="results" [labels]="labels" [doughnut]="doughnut" [maxLabelLength]="maxLabelLength"
            [labelFormatting]="valueBeforeLabel ? labelFormatting : ''" [arcWidth]="arcWidth" [customColors]="customColors"
            (select)="onSelectItem($event)" [margins]="getMargins()">
            @if (showCustomTooltip) {
              <ng-template #tooltipTemplate let-model="model">
                <span class="tooltip-label">{{ model | sprStackedBarsTooltipLabel }}</span>
                <span class="tooltip-val">{{ model.value.toLocaleString() }} ({{ model.extra.tooltipValue }}%)</span>
              </ng-template>
            }
          </ngx-charts-pie-chart>
        }
        <div class="diagram__total-amount">
          @if (!totalRouterLink) {
            <span class="diagram__total-number" [ngClass]="total | getCountWithUnit: (totalUnit) | getTotalFontSize" hlTooltip [title]="totalTooltip"
              [isActive]="totalShowTooltip" [darkTransparent75]="true">{{ total | getCountWithUnit:(totalUnit) }}</span>
          } @else {
            <a (click)="$event.preventDefault();navigate(totalRouterLink, queryParams)" href="#" data-cy="diagram-total-number">
              <span class="diagram__total-number" hlTooltip [title]="totalTooltip" [isActive]="totalShowTooltip" [darkTransparent75]="true"
                [ngClass]="total | getCountWithUnit: (totalUnit) | getTotalFontSize">{{ total | getCountWithUnit:(totalUnit) }}</span>
            </a>
          }
        </div>
      </div>
    </div>
    @if (!hideLegends) {
      <div class="diagram__legend">
        <table class="legend" aria-label="legend">
          @for (item of dataInput; track item) {
            <tr class="legend__item">
              <td [ngStyle]="{'color': getItemColor(item.color)}"
                class="legend__value font-size-large">{{ item.value | getCountWithUnit: item.valueUnit }}
              </td>
              <th scope="row" class="legend__label">
                @if (totalRouterLink) {
                  <a (click)="$event.preventDefault();onSelectItem(item)" href="#">{{ item.name }}</a>
                }
              </th>
            </tr>
          }
        </table>
      </div>
    }
  } @else {
    <div class="diagram__canvas diagram__canvas--empty">
      <div [ngClass]="getDiagramClass()">
        <div class="diagram__total-amount">
          @if (!totalRouterLink) {
            <span class="diagram__total-number"
              [ngClass]="total | getCountWithUnit: (totalUnit) | getTotalFontSize">{{ 'LABEL_REPORTING_NOT_AVAILABLE' | translate }}</span>
          }
        </div>
      </div>
    </div>
  }
</div>
