import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { curveLinear } from 'd3-shape';
import { scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { BaseChartComponent, calculateViewDimensions, ColorHelper, getScaleType, getUniqueXDomainValues, id, LegendPosition, Timeline, ViewDimensions } from '@swimlane/tpf-ngx-charts';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[hl-diagram-timeline]',
  template: `
<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)"
  >
  <svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect
        [attr.width]="dims.width + 10"
        [attr.height]="dims.height + 10"
        [attr.transform]="'translate(-5, -5)'"
      />
    </svg:clipPath>
    </svg:defs>
    <svg:g [attr.transform]="transform" class="line-chart chart">
    @if (xAxis) {
      <svg:g
        ngx-charts-x-axis
        [xScale]="xScale"
        [dims]="dims"
        [showGridLines]="showGridLines"
        [showLabel]="showXAxisLabel"
        [labelText]="xAxisLabel"
        [trimTicks]="trimXAxisTicks"
        [rotateTicks]="rotateXAxisTicks"
        [maxTickLength]="maxXAxisTickLength"
        [tickFormatting]="xAxisTickFormatting"
        [ticks]="xAxisTicks"
        (dimensionsChanged)="updateXAxisHeight($event)"
      ></svg:g>
    }
    @if (yAxis) {
      <svg:g
        ngx-charts-y-axis
        [yScale]="yScale"
        [dims]="dims"
        [showGridLines]="showGridLines"
        [showLabel]="showYAxisLabel"
        [labelText]="yAxisLabel"
        [trimTicks]="trimYAxisTicks"
        [maxTickLength]="maxYAxisTickLength"
        [tickFormatting]="yAxisTickFormatting"
        [ticks]="yAxisTicks"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        (dimensionsChanged)="updateYAxisWidth($event)"
      ></svg:g>
    }
    <svg:g [attr.clip-path]="clipPath">
      @for (series of results; track trackBy(series)) {
        <svg:g>
          <svg:g
            ngx-charts-line-series
            [xScale]="xScale"
            [yScale]="yScale"
            [colors]="colors"
            [data]="series"
            [activeEntries]="activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [rangeFillOpacity]="rangeFillOpacity"
            [hasRange]="hasRange"
            [animations]="animations"
          />
        </svg:g>
      }
      @if (!tooltipDisabled) {
        <svg:g (mouseleave)="hideCircles()">
          <svg:g
            ngx-charts-tooltip-area
            [dims]="dims"
            [xSet]="xSet"
            [xScale]="xScale"
            [yScale]="yScale"
            [results]="results"
            [colors]="colors"
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTemplate]="seriesTooltipTemplate"
            (hover)="updateHoveredVertical($event)"
          />
          @for (series of results; track series) {
            <svg:g>
              <svg:g
                ngx-charts-circle-series
                [xScale]="xScale"
                [yScale]="yScale"
                [colors]="colors"
                [data]="series"
                [scaleType]="scaleType"
                [visibleValue]="hoveredVertical"
                [activeEntries]="activeEntries"
                [tooltipDisabled]="tooltipDisabled"
                [tooltipTemplate]="tooltipTemplate"
                (select)="onClick($event)"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
              />
            </svg:g>
          }
        </svg:g>
      }
      </svg:g>
      </svg:g>
      @if (timeline && scaleType != 'ordinal') {
        <svg:g
          ngx-charts-timeline #timelineRef
          [attr.transform]="timelineTransform"
          [results]="results"
          [view]="[timelineWidth, height]"
          [height]="timelineHeight"
          [scheme]="scheme"
          [customColors]="customColors"
          [scaleType]="scaleType"
          [legend]="legend"
          (onDomainChange)="updateDomain($event)"
        >
      @for (series of results; track trackBy(series)) {
        <svg:g>
          <svg:g
            ngx-charts-line-series
            [xScale]="timelineXScale"
            [yScale]="timelineYScale"
            [colors]="colors"
            [data]="series"
            [scaleType]="scaleType"
            [curve]="curve"
            [hasRange]="hasRange"
            [animations]="false"
          />
        </svg:g>
      }
    </svg:g>
  }
</ngx-charts-chart>
`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramTimelineComponent extends BaseChartComponent implements AfterViewInit {

  @Input() legend = true;
  @Input() legendTitle = '';
  @Input() legendPosition = LegendPosition.Right;
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() autoScale;
  @Input() timeline;
  @Input() gradient = false;
  @Input() showGridLines = true;
  @Input() curve: any = curveLinear;
  @Input() activeEntries: any[] = [];
  @Input() rangeFillOpacity: number;
  @Input() trimXAxisTicks = true;
  @Input() trimYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yAxisTicks: any[];
  @Input() roundDomains = true;
  @Input() tooltipDisabled = false;
  @Input() showRefLines = false;
  @Input() referenceLines: any;
  @Input() showRefLabels = true;
  @Input() xScaleMin: any;
  @Input() xScaleMax: any;
  @Input() yScaleMin: number;
  @Input() yScaleMax: number;

  private emit = true;
  private zoomDomainAfterInit: [Date, Date];
  @Input() set zoomDomain(domain: [Date, Date]) {
    if (!domain) {
      return;
    }

    if (!this.timelineRef) {
      this.zoomDomainAfterInit = domain;
      return;
    }

    domain = [
      domain[0] < this.timelineXDomain[0] ? this.timelineXDomain[0] : domain[0],
      domain[1] > this.timelineXDomain[1] ? this.timelineXDomain[1] : domain[1]
    ];

    this.emit = false;
    const brushRef = select(this.timelineRef.element).select('.brush');
    if (domain[0] <= this.timelineXDomain[0] && domain[1] >= this.timelineXDomain[1]) {
      brushRef.call(this.timelineRef.brush.clear);
    } else {
      brushRef.call(this.timelineRef.brush.move, domain.map(this.timelineXScale));
    }
    this.emit = true;
  }

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @Output() domainZoomed = new EventEmitter<[Date, Date]>();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate') seriesTooltipTemplate: TemplateRef<any>;

  @ViewChild(Timeline) timelineRef: Timeline;

  dims: ViewDimensions;
  xSet: any;
  xDomain: any;
  yDomain: any;
  seriesDomain: any;
  yScale: any;
  xScale: any;
  colors: ColorHelper;
  scaleType: string;
  transform: string;
  clipPath: string;
  clipPathId: string;
  series: any;
  areaPath: any;
  margin = [10, 20, 10, 20];
  hoveredVertical: any; // the value of the x axis that is hovered over
  xAxisHeight = 0;
  yAxisWidth = 0;
  filteredDomain: any;
  legendOptions: any;
  hasRange: boolean; // whether the line has a min-max range around it
  timelineWidth: any;
  timelineHeight = 50;
  timelineXScale: any;
  timelineYScale: any;
  timelineXDomain: any;
  timelineTransform: any;
  timelinePadding = 10;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.zoomDomainAfterInit) {
      this.zoomDomain = this.zoomDomainAfterInit;
      this.zoomDomainAfterInit = [null, null];
    }
  }

  update(): void {
    super.update();

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.timeline) {
      this.dims.height -= this.timelineHeight + this.margin[2] + this.timelinePadding;
    }

    this.xDomain = this.getXDomain();
    if (this.filteredDomain) {
      this.xDomain = this.filteredDomain;
    }

    this.yDomain = this.getYDomain();
    this.seriesDomain = this.getSeriesDomain();

    this.xScale = this.getXScale(this.xDomain, this.dims.width);
    this.yScale = this.getYScale(this.yDomain, this.dims.height);

    this.updateTimeline();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

    this.clipPathId = 'clip' + id().toString();
    this.clipPath = `url(#${this.clipPathId})`;
  }

  updateTimeline(): void {
    if (this.timeline) {
      this.timelineWidth = this.dims.width;
      this.timelineXDomain = this.getXDomain();
      this.timelineXScale = this.getXScale(this.timelineXDomain, this.timelineWidth);
      this.timelineYScale = this.getYScale(this.yDomain, this.timelineHeight);
      this.timelineTransform = `translate(${this.dims.xOffset}, ${-this.margin[2]})`;
    }
  }

  getXDomain(): any[] {
    let values = getUniqueXDomainValues(this.results);

    this.scaleType = getScaleType(values);
    let domain = [];

    if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
    }

    let min;
    let max;
    if (this.scaleType === 'time' || this.scaleType === 'linear') {
      min = this.xScaleMin ? this.xScaleMin : Math.min(...values);

      max = this.xScaleMax ? this.xScaleMax : Math.max(...values);
    }

    if (this.scaleType === 'time') {
      domain = [new Date(min), new Date(max)];
      this.xSet = [...values].sort((a, b) => {
        const aDate = a.getTime();
        const bDate = b.getTime();
        if (aDate > bDate) return 1;
        if (bDate > aDate) return -1;
        return 0;
      });
    } else if (this.scaleType === 'linear') {
      domain = [min, max];
      // Use compare function to sort numbers numerically
      this.xSet = [...values].sort((a, b) => a - b);
    } else {
      domain = values;
      this.xSet = values;
    }

    return domain;
  }

  getYDomain(): any[] {
    const domain = [];
    for (const results of this.results) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          this.hasRange = true;
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          this.hasRange = true;
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    const values = [...domain];
    if (!this.autoScale) {
      values.push(0);
    }

    const min = this.yScaleMin ? this.yScaleMin : Math.min(...values);

    const max = this.yScaleMax ? this.yScaleMax : Math.max(...values);

    return [min, max];
  }

  getSeriesDomain(): any[] {
    return this.results.map(d => d.name);
  }

  getXScale(domain, width): any {
    let scale;

    if (this.scaleType === 'time') {
      scale = scaleTime()
        .range([0, width])
        .domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear()
        .range([0, width])
        .domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([0, width])
        .padding(0.1)
        .domain(domain);
    }

    return scale;
  }

  getYScale(domain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomain = this.filteredDomain;
    this.xScale = this.getXScale(this.xDomain, this.dims.width);

    if (this.emit) {
      this.domainZoomed.emit(domain);
    }
  }

  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  onClick(data): void {
    this.select.emit(data);
  }

  trackBy(item): string {
    return item.name;
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.seriesDomain;
    } else {
      domain = this.yDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.yDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateYAxisWidth({ width }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item) {
    this.deactivateAll();

    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }
}
