import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'numberFromIdPipe'
})
export class NumberFromIdPipe implements PipeTransform {
  transform(id: string): string {
    if (!id) {
      return '';
    }
    const parts: string[] = id.split('_');
    return parts[parts.length - 1];
  }
}
