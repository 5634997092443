import { Pipe, PipeTransform } from '@angular/core';
import { filter, forEach, isEmpty } from 'lodash-es';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  transform(dataset: any, selectedValue: any): any {
    let filtered = [];

    if (isEmpty(selectedValue)) {
      filtered = dataset;
    } else {
      let valuesString = '';
      for (const item of selectedValue) {
        valuesString += item + ',';
      }
      const closedTicketIds = valuesString.split(',');
      forEach(closedTicketIds, id => {
        const t = filter(dataset, {ticketStatus: id});
        filtered = filtered.concat(t);
      });
    }
    return filtered;
  }
}
