import { Pipe, PipeTransform } from '@angular/core';
import { green, red, yellow } from '../../../core/core-constants.service';
import { SubTypeOfPreliminaryInvoices } from '../../../invoices/type-of-invoices';

const statusColor = new Map<string, string>([
  [SubTypeOfPreliminaryInvoices.UNVIEWED, red],
  [SubTypeOfPreliminaryInvoices.UNSUBMITTED, yellow],
  [SubTypeOfPreliminaryInvoices.SUBMITTED, green]
]);

@Pipe({
  name: 'preliminaryInvoiceStatusColorPipe'
})
export class PreliminaryInvoiceStatusColorPipe implements PipeTransform {

  transform(preliminaryInvoiceStatus: string): string {
    return 'label-status-' + statusColor.get(preliminaryInvoiceStatus);
  }
}
