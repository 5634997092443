import { Component, EventEmitter } from '@angular/core';
import { BaseChartComponent, ColorHelper, LegendPosition } from '@swimlane/tpf-ngx-charts';
import { SeriesData } from '../diagram';

@Component({
  selector: 'hl-diagram-bar-double-axis-base',
  template: '<div></div>'
})
export class DiagramBarDoubleAxisBaseComponent extends BaseChartComponent {
  legendPosition: LegendPosition;
  legendTitle;
  colors: ColorHelper;
  innerDomain: any[];
  valuesMainDomain: any[];

  valuesSecondaryDomain: any[];
  chartsData: any;

  dataMainAxis: SeriesData[];
  dataSecondaryAxis: SeriesData[];
  axisScaleMax: number;

  activeEntries: any[];
  activate: EventEmitter<any>;
  deactivate: EventEmitter<any>;

  xAxisHeight = 0;
  yAxisWidth = 0;

  dataSplit() {
    [this.dataMainAxis, this.dataSecondaryAxis] = this.chartsData.reduce((acc, item) => {
      item.series.forEach(seriesData => {
        if (seriesData.secondAxis) {
          acc[1].push(seriesData);
        } else {
          acc[0].push(seriesData);
        }
      });

      return acc;
    }, [[], []]);

    if (this.dataSecondaryAxis.length) {
      const [min1, max1] = this.getValueDomain(this.dataMainAxis);
      const [min2, max2] = this.getValueDomain(this.dataSecondaryAxis);

      this.results = this.chartsData.map(series => {
        return {
          name: series.name,
          series: series.series.map(dataSeries => {
            return {
              name: dataSeries.name,
              value: dataSeries.secondAxis && dataSeries.value > 0 ?
                (((dataSeries.value - min2) * (max1 - min1) / (max2 - min2)) + min1) :
                dataSeries.value,
              extra: {
                originalValue: dataSeries.value
              }
            };
          })
        };
      });

    } else {
      this.results = this.chartsData;
    }
  }

  updateTooltips(): void {
    this.results.forEach(series => {
      series.series.forEach(dataSeries => {
        if (dataSeries.extra && dataSeries.extra.originalValue) {
          dataSeries.tooltipText = '\n' + dataSeries.label + '\n' + dataSeries.extra.originalValue;
        } else {
          dataSeries.tooltipText = '\n' + dataSeries.label + '\n' + dataSeries.value;
        }
      });
    });
  }

  copySeriesNamesToLabels() {
    this.results.forEach(group => {
      group.label = group.name;

      if (group.series) {
        group.series.forEach(data => {
          data.label = data.name;
        });
      }
    });
  }

  getValueDomain(data: SeriesData[]): any[] {
    const domain = [];

    for (const d of data) {
      if (!domain.includes(d.value)) {
        domain.push(d.value);
      }
    }

    const min = Math.min(0, ...domain);
    const max = this.axisScaleMax ? Math.max(this.axisScaleMax, ...domain) : Math.max(1, ...domain);
    return [min, max];
  }

  getGroupDomain(): any[] {
    const domain = [];

    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }

    return domain;
  }

  getInnerDomain(): any[] {
    const domain = [];

    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }

    return domain;
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.innerDomain;
    } else {
      domain = this.valuesMainDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valuesMainDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({width}): void {
    this.yAxisWidth = width + 20;
    this.update();
  }

  updateXAxisHeight({height}): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map(g => g.series)
      .flat()
      .filter(i => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({value: item, entries: this.activeEntries});
  }

  onDeactivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter(i => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({value: item, entries: this.activeEntries});
  }

  onClick(data, group?): void {
    if (group) {
      data.series = group.name;
      const index = group.series.findIndex(el => el.name === data.name);
      data['color'] = this.colors.colorDomain[index];
    }

    this.select.emit(data);
  }
}
