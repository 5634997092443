import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BarChartType, ColorHelper, LegendOptions, ScaleType } from '@swimlane/tpf-ngx-charts';
import { GuardianDetailSeriesValue, GuardianSeries } from '../diagram-guardian-common/guardian-detail-series';
import { animate, style, transition, trigger } from '@angular/animations';
import { scaleBand, scaleLinear } from 'd3-scale';
import { GuardianBaseChartComponent } from '../diagram-guardian-common/guardian-base-chart.component';
import { ExtendedDataItem } from '../common/common-models';

export interface GuardianDetailProduct {
  bars: GuardianDetailSeriesValue[];
  circles: GuardianDetailSeriesValue;
}

@Component({
  selector: 'hl-diagram-guardian-product',
  templateUrl: './diagram-guardian-product.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({opacity: 0, transform: 'scale(0)'}))
      ])
    ])
  ]
})
export class DiagramGuardianProductComponent extends GuardianBaseChartComponent {
  @Input() barPadding = 8;
  @Input('results') chartsData: GuardianDetailProduct;

  barChartType = BarChartType;

  groupDomain: string[];
  innerDomain: string[];
  valueDomain: [number, number];

  bars: GuardianSeries[] = [];
  circles: ExtendedDataItem[] = [];
  whiskers: ExtendedDataItem[] = [];

  updateDomainValues(): void {
    const {groupDomainValues, innerDomainValues, valueDomain} = this.getDomainValues();
    this.groupDomain = groupDomainValues;
    this.innerDomain = innerDomainValues;
    this.valueDomain = this.getValueDomain(valueDomain);
  }

  getDomainValues(): {groupDomainValues: string[], innerDomainValues: string[], valueDomain: number[]} {
    const groupDomainValues = new Set<string>();
    const innerDomainValues = new Set<string>();
    const seriesDomainValues = new Set<number>();

    for (const group of this.chartsData.bars) {
      groupDomainValues.add(group.name);
      for (const d of group.series) {
        innerDomainValues.add(d.name);
        seriesDomainValues.add(d.value);
      }
      for (const w of group.whiskers) {
        innerDomainValues.add(w.name);
        seriesDomainValues.add(w.value);
      }
    }

    innerDomainValues.add(this.chartsData.circles.name);
    groupDomainValues.add(this.chartsData.circles.name);
    for (const d of this.chartsData.circles.series) {
      seriesDomainValues.add(d.value);
    }

    return {
      groupDomainValues: Array.from(groupDomainValues),
      innerDomainValues: Array.from(innerDomainValues).filter(name => !!name),
      valueDomain: Array.from(seriesDomainValues)
    };
  }

  getValueDomain(domainValues: number[]): [number, number] {
    const domain = [...domainValues];
    if (!this.autoScale) {
      domain.push(0);
    }

    const min = Math.min(...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }

  getXScale() {
    const spacing = this.groupDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().rangeRound([0, this.dims.width]).paddingInner(spacing).domain(this.groupDomain);
  }

  getYScale() {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  getColors() {
    let domain: any[];
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.innerDomain;
    } else {
      domain = this.valueDomain;
    }

    domain = [...domain];
    domain.unshift(domain.pop());

    return new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions(): LegendOptions {
    const opts = {
      scaleType: this.schemeType as any,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valueDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  groupTransform(group: GuardianSeries): string {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }

  onActivate(item, fromLegend = false): void {
    super.onActivate(this.bars
      .reduce((series, bar) => series.concat(bar.series), [])
      .concat(this.circles)
      .concat(this.whiskers), item, fromLegend);
  }

  processChartData() {
    const barData = [];
    const circleData = [];
    const whiskerData = [];

    this.chartsData.bars.forEach(bar => {
      bar.whiskers.forEach(series =>
        whiskerData.push({
          ...series,
          label: series.name,
          valueName: series.name,
          seriesName: bar.name
        }));
      barData.push({
        name: bar.name,
        series: bar.series.map(series => ({
          ...series,
          valueName: series.name,
          seriesName: bar.name
        }))
      });
    });
    this.chartsData.circles.whiskers.forEach(series =>
      whiskerData.push({
        ...series,
        name: this.chartsData.circles.name,
        label: series.name,
        valueName: series.name,
        seriesName: this.chartsData.circles.name
      }));
    this.chartsData.circles.series.forEach(series =>
      circleData.push({
        ...series,
        name: this.chartsData.circles.name,
        label: series.name,
        valueName: this.chartsData.circles.name,
        seriesName: this.chartsData.circles.name
      }));

    this.bars = barData;
    this.circles = circleData;
    this.whiskers = whiskerData;
  }
}
