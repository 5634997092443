export enum TypeOfInvoices {
  PUBLISHED =  'invoices-published',
  SERVICE =  'invoices-service',
  PRELIMINARY = 'invoices-prelim',
  CONTRACT = 'invoices-contract',
  PROF = 'invoices-prof'
}

export enum SubTypeOfPreliminaryInvoices {
  UNVIEWED = 'unviewed',
  UNSUBMITTED = 'unsubmitted',
  SUBMITTED = 'submitted'
}
