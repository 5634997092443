import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'hl-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressIndicatorComponent {
  loading  = input<boolean>(true);
  showLabel = input<boolean>(false);
  label = input<string>('');
  includeMargin = input<boolean>(true);
}
