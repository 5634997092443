<div>
  <p class="form__label" [translate]="label"></p>
  <div class="button-group button-group--large" [class.button-group--spr-button-size]="iconVisible">
    @for (option of options; track option; let i = $index) {
      <button class="button toggle-button" [class.toggle-button__icon-size]="iconVisible" [title]="option.title | translate"
        [class.is-active]="isSelected(option.value)" attr.data-cy="{{dataCy}}-{{i+1}}" (click)="setSelected($event, option)">
        @if (iconVisible && option.icon) {
          <i [class]="option.icon"></i>
        }
        @if (multiButtonLabelVisible) {
          <span>{{ option.title | translate }}</span>
        }
      </button>
    }
  </div>
</div>
