import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from 'app/core/window.service';

@Directive({
  selector: '[hlHtmlLink]'
})
export class HtmlLinkDirective {

  constructor(private router: Router, private windowService: WindowService) {}

  @HostListener('click', ['$event'])
  handleClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if ((e.target as HTMLElement).tagName === 'A') {
      const url = (e.target as HTMLElement).getAttribute('href');
      if (url.startsWith('.')) {
        this.router.navigateByUrl(url.replace('.', this.windowService.nativeWindow.location.pathname));
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }
}
