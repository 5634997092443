import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-toaster',
  templateUrl: './toaster.component.html'
})
export class ToasterComponent implements OnInit, OnDestroy {

  fixedStatus = false;
  type: string; // message type (success, error, info)
  body: string;
  timeOut = 5000;
  isBodyTranslationKey: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private toasterService: ToasterService,
              private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      // set the timeout based on country
      this.timeOut = parseInt(configResponse.TOASTER_TIMEOUT, 10);
      this.registerToasterListener();
    });
  }

  registerToasterListener() {
    this.toasterService.toastSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      toast => {
        this.type = toast.type;
        this.isBodyTranslationKey = toast.isBodyTranslationKey;
        this.body = toast.body;
        // show the toast
        this.fixedStatus = true;

        setTimeout(() => {
          this.fixedStatus = false;
        }, this.timeOut);
      });
  }
}
