import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, scan, withLatestFrom } from 'rxjs/operators';
import { Equipment } from 'app/core/models/equipment/equipment';
import { EquipmentRestService } from 'app/core/rest-services/equipment-rest.service';
import { UserRestService } from 'app/core/rest-services/user-rest.service';
import { flatMap, flow, isEqual, uniq } from 'lodash-es';
import { roles } from '../core-constants.service';
import { UserUtilService } from './user/user-util.service';
import { HttpCancelUtilService } from '../utils/http-cancel-util.service';
import {
  equipmentRestName,
  equipmentStatusRestName
} from '../rest-services/equipment-rest-constants.service';
import { ticketsAllRestName } from '../rest-services/ticket-rest-constants.service';
import {
  DEFAULT_USER_FILTER_VALUES,
  FilterValues,
  MyFiltersStatusWithLoaded,
  UserModality
} from '../models/my-filters.model';
import { filterEquipment } from '../utils/my-filters.util';
import { AuthService } from '../../auth.service';
import { MyEquipmentService } from './my-equipment.service';

const getModalityCodes = flow(x => flatMap(x, m => m.modalityCodes), uniq);

@Injectable({providedIn: 'root'})
export class MyFiltersService implements OnDestroy {

  private readonly filterValues$ = new BehaviorSubject<FilterValues>({...DEFAULT_USER_FILTER_VALUES});
  private readonly allEquipment$ = new ReplaySubject<Equipment[]>(1);
  private readonly userModalities$ = new ReplaySubject<UserModality[]>(1);
  private isAllDataLoaded = false;

  get myFilters$() {
    return this.filterValues$.asObservable().pipe(
      distinctUntilChanged(isEqual)
    );
  }

  get status$(): Observable<MyFiltersStatusWithLoaded> {
    return combineLatest([this.filterValues$, this.allEquipment$, this.myEquipmentService.myEquipmentObservable$]).pipe(
      withLatestFrom(this.userModalities$, (x, userModalities) =>
        [...x, getModalityCodes(userModalities)]
      ),
      map(filterEquipment),
      distinctUntilChanged(isEqual),
      scan((formerStatus, newStatus) => {
        const isAllDataLoaded = formerStatus.isAllDataLoaded || !newStatus.overallSwitch;
        return ({...newStatus, isAllDataLoaded});
      })
    );
  }

  get filterEquipmentKeys$(): Observable<string[]> {
    return this.status$.pipe(
      map(status => status.equipment.map(e => e.key)),
      distinctUntilChanged(isEqual)
    );
  }

  get myFiltersValue() {
    const {overallSwitch, ...filters} = this.filterValues$.value;
    return filters;
  }

  constructor(
    private readonly userRestService: UserRestService,
    private readonly userUtilService: UserUtilService,
    private readonly equipmentRestService: EquipmentRestService,
    private readonly httpCancelUtilService: HttpCancelUtilService,
    private readonly myEquipmentService: MyEquipmentService,
    private authService: AuthService
  ) {
  }

  ngOnDestroy() {
    this.filterValues$.complete();
    this.allEquipment$.complete();
  }

  initialize() {
    this.authService.isUserAuthenticated
      .subscribe(isAuth => {
        if (isAuth) {
          const userFilterValues$ = this.userRestService.getUserFilterValues();
          const userModalities$ = this.userRestService.getUserModalitiesList();
          const allEquipment$ = this.equipmentRestService.getEquipmentForMyFilters();
          const rolesToCheck = {
            viewEquipmentRole: roles.viewEquipmentRole,
            impersonateUserRole: roles.impersonateUserRole
          };
          const hasRole$ = this.userUtilService.checkUserRoles(rolesToCheck);

          forkJoin([userFilterValues$, userModalities$, allEquipment$, hasRole$])
            .subscribe(([userFilterValues, userModalities, allEquipment, hasRole]) => {
              userFilterValues.overallSwitch = hasRole.viewEquipmentRole && userFilterValues.overallSwitch;
              this.isAllDataLoaded = !userFilterValues.overallSwitch;
              this.update(userFilterValues);
              this.userModalities$.next(userModalities);
              this.allEquipment$.next(allEquipment);
            });
        }
      });
  }

  save(values: FilterValues) {
    const filterValues = {...this.filterValues$.value, ...values};
    this.userRestService.postUserFilterValues(filterValues)
      .subscribe(() => {
        this.manageCache(values);
        this.update(filterValues);
      });
  }

  private manageCache(values: FilterValues) {
    if (!this.isAllDataLoaded) {
      this.httpCancelUtilService.cancelPendingRequests();
      this.equipmentRestService.clearCache(equipmentRestName);
      this.equipmentRestService.clearCache(equipmentStatusRestName);
      this.equipmentRestService.clearCache(ticketsAllRestName);
    }

    if (!this.isAllDataLoaded && !values.overallSwitch) {
      this.isAllDataLoaded = true;
    }
  }

  private update(values: FilterValues) {
    const filterValues = {...this.filterValues$.value, ...values};
    this.filterValues$.next(filterValues);
  }

  getUserModalitiesList() {
    return this.userModalities$.asObservable();
  }
}
