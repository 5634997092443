@if (timelineMilestones?.length > 0 && !isMobile) {
  <ul class="timeline" id="timeline" #timeline>
    @for (singleMilestone of timelineMilestones; track singleMilestone; let i = $index) {
      <li [class.complete]="singleMilestone.completed">
        <div class="status"
          [class.border]="shouldShowBorder(i)"
          [class.no-border]="!shouldShowBorder(i)"
          [class.status-ok]="singleMilestone.completed"
          [class.status-first]="isFirstInMoreThanOneMilestones(i)">
          @if (shouldShowLeftHalfBorder(i)) {
            <div class="status-line-start"></div>
          }
          <div class="milestone-text-wrapper"> {{ singleMilestone.milestone }} </div>
          @if (shouldShowRightHalfBorder(i)) {
            <div class="status-line-end"></div>
          }
        </div>
      </li>
    }
  </ul>
}
