import { BaseChartComponent, calculateViewDimensions, ColorHelper, LegendOptions, LegendPosition, ViewDimensions } from '@swimlane/tpf-ngx-charts';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, TrackByFunction } from '@angular/core';
import { GuardianSeries } from './guardian-detail-series';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { ExtendedDataItem } from '../common/common-models';

@Component({
  selector: 'hl-guardian-base-chart',
  template: ` <div></div> `
})
export abstract class GuardianBaseChartComponent extends BaseChartComponent {
  @Input() legend: boolean;
  @Input() legendTitle;
  @Input() legendPosition: LegendPosition = LegendPosition.Below;
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() showXAxisLabel: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() autoScale: boolean;
  @Input() showXGridLines = true;
  @Input() showYGridLines = false;
  @Input() activeEntries: ExtendedDataItem[] = [];
  @Input() roundDomains = false;
  @Input() tooltipDisabled = false;
  @Input() showRefLines = false;
  @Input() referenceLines: any;
  @Input() showRefLabels = true;
  @Input() xScaleMin: number;
  @Input() xScaleMax: number;
  @Input() yScaleMin: number;
  @Input() yScaleMax: number;
  @Input() strokeSize = 2;
  @Input() noBarWhenZero = false;
  @Input() showYscale: boolean;

  @Output() activate: EventEmitter<{value: ExtendedDataItem, entries: ExtendedDataItem[]}> = new EventEmitter();
  @Output() deactivate: EventEmitter<{value: ExtendedDataItem, entries: ExtendedDataItem[]}> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;

  yAxisScale: any;
  yScale: any;
  xScale: any;
  xAxisBandwidthSize: any;
  colors: ColorHelper;
  transform: string;
  margin: number[] = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions: any;

  bandwidthSplitSize = 5;

  update(): void {
    super.update();

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    this.updateDomainValues();

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();
    this.yAxisScale = this.showYscale ? this.yScale : null;

    this.xAxisBandwidthSize = Math.round(this.xScale.bandwidth());
    this.bandwidthSplitSize = this.dims.width > 500 ? 5 : 1.5;

    this.colors = this.getColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

    this.processChartData();
  }

  abstract updateDomainValues(): void;

  abstract getXScale(): ScaleBand<string>;

  abstract getYScale(): ScaleLinear<number, number>;

  abstract getColors(): ColorHelper;

  abstract getLegendOptions(): LegendOptions;

  abstract processChartData(): void;

  updateYAxisWidth({width}: { width: number }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({height}: { height: number }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onClick(data): void {
    this.select.emit(data);
  }

  trackBy: TrackByFunction<GuardianSeries | ExtendedDataItem> = (index: number, item: { name }) => item.name;

  protected onActivate(itemsToFilter: ExtendedDataItem[], item, fromLegend = false): void {
    const items = itemsToFilter.filter(dataItem => {
        if (fromLegend) {
          return dataItem.valueName === item.name;
        } else {
          return (dataItem.label || dataItem.name) === item.label &&
            dataItem.value === item.value &&
            dataItem.seriesName === item.seriesName;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({value: item, entries: this.activeEntries});
  }

  onDeactivate(item, fromLegend = false): void {
    this.activeEntries = this.activeEntries.filter(entry => {
      if (fromLegend) {
        return entry.valueName !== item.name;
      } else {
        return !((entry.label || entry.name) === item.label &&
          entry.value === item.value &&
          entry.seriesName === item.seriesName);
      }
    });

    this.deactivate.emit({value: item, entries: this.activeEntries});
  }
}
