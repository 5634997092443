import { Pipe, PipeTransform } from '@angular/core';
import { escapeLabel, formatLabel } from '@swimlane/tpf-ngx-charts';

@Pipe({
  name: 'sprStackedBarsTooltipLabel'
})
export class SprStackedBarsTooltipLabelPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let tooltipLabel = formatLabel(value.label || value.name);
    if (value.series !== null && value.series !== undefined) {
      tooltipLabel = `${value.series} • ${tooltipLabel}`;
    }
    return escapeLabel(tooltipLabel);
  }

}
