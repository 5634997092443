import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { SecurityNotifications } from '../models/securityNotifications/security-notifications';

export const securityNotificationExportExcelRestName = restEndPoint + 'security-notifications/export';

@Injectable({providedIn: 'root'})
export class SecurityNotificationsRestService {
  securityNotificationsRestName = restEndPoint + 'security-notifications';
  securityNotificationByIDRestName = restEndPoint + 'security-notifications/:notificationID';

  constructor(private http: CacheableHttpClient) {
  }

  getSecurityNotifications(): Observable<SecurityNotifications[]> {
    return this.http.get<SecurityNotifications[]>(this.securityNotificationsRestName);
  }

  getEquipmentNotificationByID(notificationID: string): Observable<SecurityNotifications> {
    const url = this.securityNotificationByIDRestName.replace(/:notificationID/, notificationID);
    return this.http.get<SecurityNotifications>(url);
  }

}
