import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Bar, BarOrientation, BaseChartComponent, calculateViewDimensions, ColorHelper, escapeLabel, formatLabel, Gradient, LegendPosition, StyleTypes, ViewDimensions } from '@swimlane/tpf-ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';
import { DataItem } from '@swimlane/tpf-ngx-charts/lib/models/chart-data.model';
import { ExtendedDataItem } from '../common/common-models';

export interface HeliumEquipmentLevelData {
  value?: number;
  thresholdValue?: number;
  label: string;
  valuePlaceholder?: string;
  thresholdLabel: string;
  showThreshold: boolean;
  status: number;
}

@Component({
  selector: 'hl-diagram-equipment-helium-level',
  templateUrl: './diagram-equipment-helium-level.component.html'
})
export class DiagramEquipmentHeliumLevelComponent extends BaseChartComponent {
  @Input() data: HeliumEquipmentLevelData;
  @Input() strokeSize = 2;
  @Input() tooltipDisabled = false;
  @Input() activeEntries: any[] = [];

  @Output() activate: EventEmitter<{value: DataItem, entries: DataItem[]}> = new EventEmitter();
  @Output() deactivate: EventEmitter<{value: DataItem, entries: DataItem[]}> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;

  xScale: any;
  yScale: any;
  margin: number[] = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  colors: ColorHelper;
  transform: string;

  xDomain: any = ['helium'];
  yDomain: [number, number] = [0, 100];
  seriesDomain: any = ['helium', 'threshold', 'fill'];

  bar: Bar;
  strokeColor: string;

  threshold: ExtendedDataItem;
  xAxisBandwidthSize: any;
  bandwidthSplitSize = 1;
  thresholdStrokeSizeRatio = 5;

  barOrientation = BarOrientation;
  styleTypes = StyleTypes;

  update(): void {
    super.update();

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: false,
      showYAxis: false,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: false,
      showYLabel: false,
      showLegend: false,
      legendType: this.schemeType,
      legendPosition: LegendPosition.Below
    });

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();
    this.xAxisBandwidthSize = Math.round(this.xScale.bandwidth());

    this.colors = this.getColors();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

    if (this.data) {
      const widthReduction = this.xAxisBandwidthSize / 4;

      const value = this.data.value ? Math.min(Math.max(this.data.value, 0), 100) : this.data.value;
      const thresholdValue = this.data.thresholdValue ? Math.min(Math.max(this.data.thresholdValue, 0), 100) : this.data.thresholdValue;

      this.strokeColor = this.colors.getColor('helium');

      this.bar = {
        label: this.data.label,
        formattedLabel: this.data.label,
        value,
        x: this.xScale('helium') + widthReduction,
        y: this.yScale(100),
        width: this.xAxisBandwidthSize - (widthReduction * 2),
        height: this.yScale(0) - this.yScale(100),
        color: 'rgba(255,255,255,0)',
        tooltipText: this.getBarTooltipText(this.data.label, value?.toLocaleString() || this.data.valuePlaceholder),
        ariaLabel: 'helium-equipment-volume',
        roundEdges: false,
        data: {
          name: this.data.label,
          value
        },
        gradientStops: this.data.showThreshold ? this.getGradientSteps(value) : undefined
      };

      this.threshold = {
        name: 'threshold',
        label: this.data.thresholdLabel,
        value: thresholdValue,
        seriesName: 'helium',
        valueName: 'threshold'
      };
    }
  }

  getXScale() {
    return scaleBand().range([0, this.dims.width]).padding(0.1).domain(this.xDomain.map(d => d.toString()));
  }

  getYScale() {
    return scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);
  }

  getColors() {
    return new ColorHelper(this.scheme, this.schemeType, this.seriesDomain, this.customColors);
  }

  isActive(entry: DataItem): boolean {
    return this.activeEntries?.some(active =>
      entry.name === active.name && entry.value === active.value
    ) || false;
  }

  onActivate(item: DataItem): void {
    this.activeEntries = [item];
    this.activate.emit({value: item, entries: this.activeEntries});
  }

  onDeactivate(item: DataItem): void {
    this.activeEntries = this.activeEntries?.filter(entry =>
      !(entry.name === item.name && entry.value === item.value));

    this.deactivate.emit({value: item, entries: this.activeEntries});
  }

  getBarTooltipText(label: string, value: string): string {
    return `
      <span class="tooltip-label">${escapeLabel(formatLabel(label))}</span>
      <span class="tooltip-val">${value}</span>
    `;
  }

  getGradientSteps(value: number): Gradient[] {
    return [
      {
        offset: value,
        color: this.colors.getColor('helium'),
        opacity: 1
      },
      {
        offset: value,
        color: this.colors.getColor('fill'),
        opacity: 1
      }
    ];
  }
}
