import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, HostListener, Input, NgZone, OnDestroy, OnInit, Provider, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { takeUntil } from 'rxjs/operators';
import { WindowService } from '../../../core/window.service';

const MONTH_PICKER_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MonthPickerComponent),
  multi: true
};

@Component({
  selector: 'hl-month-picker',
  templateUrl: './month-picker.component.html',
  providers: [MONTH_PICKER_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  readonly MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  readonly ASSUMED_PICKER_HEIGHT = 204;

  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() isInvalid = false;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() label = '';
  @Input() invalidLabel?: string;
  @Input() showClearButton = true;
  @Input() customDatePattern?: string;
  @Input() useIso8601StringModels = false; // use string formatted in YYYY-MM-DD as model values, else use JS Date
  @Input() adjustPickerLocationOnOpen = true;
  @Input() filterFunction?: (date: Moment) => boolean;

  @ViewChild('month') dateInputElement: ElementRef;
  @ViewChild('picker') pickerElement: ElementRef;

  dateModel: Date | null = null;
  formattedDateModel: string | null = null;

  isOpen = false;
  openYear = new Date().getFullYear();

  private defaultDatePattern = 'MMM-YYYY';
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private configService: CountryConfigRestService,
    private renderer: Renderer2,
    private zone: NgZone,
    private thisElement: ElementRef,
    private cdRef: ChangeDetectorRef,
    private windowService: WindowService
  ) {
  }

  private onModelChanged: Function = () => {};
  private onModelTouched: Function = () => {};

  ngOnInit() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.defaultDatePattern = config['GENERIC_MONTH_PATTERN'];
      this.formattedDateModel = this.formatDateModel(this.dateModel);
      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  open() {
    if (!this.isOpen) {
      this.isOpen = true;

      if (this.adjustPickerLocationOnOpen) {
        this.adjustPickerLocationRelativeToViewport();
      }
    }
  }

  close() {
    this.isOpen = false;
  }

  select(year: number, monthIndex: number) {
    if (this.isMonthDisabled(year, monthIndex)) {
      return;
    }
    this.isOpen = false;
    this.updateDateModel(this.createDateModelFrom(year, monthIndex));
    this.onModelTouched();
  }

  clear() {
    this.updateDateModel(null);
    this.onModelTouched();
  }

  writeValue(value: Date | string | null) {
    if (typeof value === 'string') {
      // try to parse value as ISO 8601 date string
      const parsed = moment(value, 'YYYY-MM-DD');
      if (!parsed.isValid()) {
        return; // ignore invalid date string
      }
      value = parsed.toDate();
    }
    this.updateDateModel(value);
  }

  private updateDateModel(dateModel: Date | null) {
    if (dateModel && this.isMonthDisabled(dateModel.getFullYear(), dateModel.getMonth())) {
      return;
    }
    if (this.dateModel?.getTime() === dateModel?.getTime()) {
      return;
    }
    this.dateModel = dateModel;
    this.formattedDateModel = this.formatDateModel(dateModel);
    if (dateModel) {
      this.openYear = dateModel.getFullYear();
    }
    this.onModelChanged(this.useIso8601StringModels ? this.toIso8601(dateModel) : dateModel);
  }

  private formatDateModel(dateModel: Date | null): string | null {
    return dateModel ? moment(dateModel).format(this.datePattern) : null;
  }

  private toIso8601(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  selectFromString(dateModelAsString: string | null) {
    this.onModelTouched();

    if (!dateModelAsString) {
      if (this.showClearButton && this.dateModel !== null) {
        this.clear();
      }
      return;
    }

    const date = moment(dateModelAsString, this.datePattern, true);

    if (date.isValid() && this.formatDateModel(date.toDate()) === dateModelAsString) {
      this.updateDateModel(date.toDate());
    }
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event: Event) {
    if (this.isOpen) {
      if (!this.thisElement.nativeElement.contains(event.target)) {
        this.zone.run(() => this.isOpen = false);
      } else {
        // this is here to mimic "permanent" focus of input when using datepicker/flatpickr
        this.dateInputElement.nativeElement.focus();
      }
    }
  }

  isMonthSelected(year: number, monthIndex: number): boolean {
    return this.dateModel
      ? this.dateModel.getMonth() === monthIndex && this.dateModel.getFullYear() === year
      : false;
  }

  isMonthDisabled(year: number, monthIndex: number): boolean {
    const date = moment(this.createDateModelFrom(year, monthIndex));

    return (this.filterFunction && !this.filterFunction(date))
      || (this.minDate && date.isBefore(this.minDate))
      || (this.maxDate && date.isAfter(this.maxDate));
  }

  get datePattern(): string {
    return (this.customDatePattern || this.defaultDatePattern).toUpperCase();
  }

  private adjustPickerLocationRelativeToViewport() {
    const inputDOMRect = this.dateInputElement.nativeElement.getBoundingClientRect();
    const windowInnerHeight = this.windowService.nativeWindow.innerHeight;
    const pickerNativeElement = this.pickerElement.nativeElement;

    if (windowInnerHeight > (inputDOMRect.top + inputDOMRect.height + this.ASSUMED_PICKER_HEIGHT) ||
      (inputDOMRect.top - this.ASSUMED_PICKER_HEIGHT) <= 0) {
      this.renderer.setStyle(pickerNativeElement, 'top', (inputDOMRect.top + inputDOMRect.height) + 'px');
      this.renderer.setStyle(pickerNativeElement, 'left', inputDOMRect.left + 'px');
    } else {
      this.renderer.setStyle(pickerNativeElement, 'top', (inputDOMRect.top - this.ASSUMED_PICKER_HEIGHT) + 'px');
      this.renderer.setStyle(pickerNativeElement, 'left', inputDOMRect.left + 'px');
    }
  }

  private createDateModelFrom(year: number, monthIndex: number): Date {
    return new Date(year, monthIndex, 1);
  }

  registerOnChange(fn: Function): void {
    this.onModelChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
